import {getAuthenticatedUser, removeLocalData} from "../../data/services/auth";

/**
 * Check if user is in admins group
 * @author @leonard_lib
 * @date 05/08/2020
 * @param to
 * @param from
 * @param next
 */
export const isAdminClient = (to, from, next) => {
    const path = to.meta.redirect;
    const check = to.meta.check_for_is_admin;
    if (check) {
        const user = getAuthenticatedUser();
        if (user) {
            const groups = user.signInUserSession.accessToken.payload['cognito:groups'];

            if (groups && groups.includes("admins")) {
                next();
            } else {
                removeLocalData();
                next.redirect(path);
            }
        } else {
            removeLocalData();
            next.redirect(path);
        }
    } else {
        next();
    }
};


/**
 * Check if user is in admins group
 * @author @leonard_lib
 * @date 05/08/2020
 * @param to
 * @param from
 * @param next
 */
export const isNotAdminClient = (to, from, next) => {
    const path = to.meta.path_admin;
    const check = to.meta.check_for_is_not_admin_or_advisor;
    if (check) {
        const user = getAuthenticatedUser();
        if (user) {
            const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
            if (!groups || !groups.includes("admins")) {
                next();
            } else {
                next.redirect(path);
            }
        } else {
            next();
        }
    } else {
        next();
    }
};



export const isAdminOrAdvisorClient = (to, from, next) => {
    const path = to.meta.redirect;
    const path_unconfirmed = to.meta.redirect_no_confirmed;
    const check = to.meta.check_for_is_admin_or_advisor;

    if (check) {
        const user = getAuthenticatedUser();

        if (user) {
            const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
            if (groups && (groups.includes("admins") || groups.includes("advisers"))) {
                if(user.attributes && user.attributes.email_verified) {
                    next();
                }
                else {
                    next(path_unconfirmed);
                }
            } else {
                removeLocalData();
                next.redirect(path);
            }
        } else {
            removeLocalData();
            next.redirect(path);
        }
    } else {
        next();
    }
};
