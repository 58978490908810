import {DefaultText, SectionTitleSmall} from "../shared-components/text-styles";
import Strings from "../../assets/strings";
import {DivMargin} from "../shared-components/container-styles";
import React, {useState} from "react";
import styled from "styled-components";
import CreditAfisofomCard from "../credit-afisofom-card";
import AfisofomClient from "../../data/models/afisofom-client";
import PropTypes from "prop-types";
import CustomButton from "../button";
import Fonts from "../../assets/fonts";
import Colors from "../../assets/colors";
import PaymentsTables from "./payments-tables";
import AfisofomPaymentRegister from "../admins/afisofom-payment-register";
import AfisofomAddAnnexe from "../admins/afisofom-add-annexe";
import CustomInputFileLarge from "../input-file";
import {getFileTypes} from "../../data/services/utils";

const TitleContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const AnnexesWrapper = styled.div`
    max-width: 50%;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        max-width: 100%;
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        max-width: 100%;
    }
`;

const CreditInfo = ({ client }) => {
    const [isOpenPaymentRegister, setIsOpenPaymentRegister] = useState(false);
    const [isOpenAnnexeRegister, setIsOpenAnnexeRegister] = useState(false);
    const clientCopy = new AfisofomClient(client);
    const fileTypes = getFileTypes();

    return (
        <div>
            <DivMargin>
                <TitleContainer>
                    <SectionTitleSmall>
                        {Strings.AFISOFOM_GENERAL_INFO}
                    </SectionTitleSmall>
                </TitleContainer>
            </DivMargin>
            <DivMargin>
                <CreditAfisofomCard client={client} />
            </DivMargin>
            <DivMargin>
                <CustomButton font={Fonts.BOLD}
                              onClick={() => setIsOpenPaymentRegister(true)}
                              color={Colors.WHITE}
                              fullWidth={false}
                              align="center"
                              fullWidthMobile={false}
                              label={Strings.AFISOFOM_REGISTER_PAYMENT}
                              background={Colors.DARK_GRAY} />
            </DivMargin>
            <DivMargin>
                <PaymentsTables client={clientCopy} />
            </DivMargin>
            <DivMargin>
                <TitleContainer>
                    <SectionTitleSmall>
                        {Strings.AFISOFOM_ANNEXES}
                    </SectionTitleSmall>
                </TitleContainer>
            </DivMargin>
            <DivMargin>
                {
                    (!client?.afisofom_credit?.annexes || client?.afisofom_credit?.annexes?.length < 1) &&
                    <DefaultText>{Strings.AFISOFOM_NO_ANNEXES}</DefaultText>
                }
                <AnnexesWrapper>
                    {
                        client.afisofom_credit.annexes && client.afisofom_credit.annexes.map((annexe, index) =>
                            <CustomInputFileLarge isUploaded={true}
                                                  fileKey={annexe.key}
                                                  background={(index % 2) === 0 ? 'transparent' : Colors.LIGHT_GRAY}
                                                  id={'annexe-' + index}
                                                  disabled={true}
                                                  accept={fileTypes}
                                                  onChangeKey={() => {}}
                                                  label={annexe.name} />
                        )
                    }
                </AnnexesWrapper>
            </DivMargin>
            <DivMargin>
                <CustomButton font={Fonts.BOLD}
                              onClick={() => setIsOpenAnnexeRegister(true)}
                              color={Colors.WHITE}
                              fullWidth={false}
                              align="center"
                              fullWidthMobile={false}
                              label={Strings.AFISOFOM_ANNEXES_ADD}
                              background={Colors.DARK_GRAY} />
            </DivMargin>
            {
                client &&
                <AfisofomPaymentRegister client={client}
                                         onRegistered={() => setIsOpenPaymentRegister(false)}
                                         onClose={() => setIsOpenPaymentRegister(false)}
                                         isOpen={isOpenPaymentRegister} />
            }
            {
                client &&
                <AfisofomAddAnnexe client={client}
                                   onRegistered={() => setIsOpenAnnexeRegister(false)}
                                   onClose={() => setIsOpenAnnexeRegister(false)}
                                   isOpen={isOpenAnnexeRegister} />
            }
        </div>
    );
};

CreditInfo.propTypes = {
    client: PropTypes.instanceOf(AfisofomClient).isRequired,
};

export default CreditInfo;
