import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import LinearProgress from '@material-ui/core/LinearProgress';
import Colors from "../assets/colors";

const LoadingIndicatorWrapper = styled.div`
    .MuiLinearProgress-barColorPrimary {
        background-color: ${props => props.color};
    }
    
    .MuiLinearProgress-colorPrimary {
        background-color: ${props => props.background};
    }

    .MuiLinearProgress-root {
    	width: ${props => props.width};
    }
`;

const LoadingIndicator = ({ color, background, width }) => (
    <LoadingIndicatorWrapper color={color}
                             background={background}
                             width={width}>
        <LinearProgress />
    </LoadingIndicatorWrapper>
);

LoadingIndicator.propTypes = {
    color: PropTypes.string,
    background: PropTypes.string,
    width: PropTypes.string
};

LoadingIndicator.defaultProps = {
    color: Colors.DARK_GRAY,
    background: Colors.LIGHT_GRAY,
    width: '100%'
};

export default LoadingIndicator;
