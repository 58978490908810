import React from "react";
import LayoutClients from "../../components/layout-clients";
import ServiciosLoginForm from "../../components/afiservicios/login-form";
import {useChangeTitleFavicon} from "../../hooks/useChangeTitle";
import Strings from "../../assets/strings";

const AfiserviciosLogin = ({ history }) => {
    useChangeTitleFavicon(Strings.TITLE_LOGIN, "afiservicios");
    return (
        <LayoutClients site="servicios" showPrincipalButton={false} history={history}>
            <div className="container">
                <ServiciosLoginForm
                    onForgotPassword={() =>
                        history.push('/afiservicios/recuperar-contrasena')
                    }
                    onLogin={() =>
                        history.push('/afiservicios/dashboard')
                    }
                />
            </div>
        </LayoutClients>
    );
}

export default AfiserviciosLogin;
