import CustomInput from "./input";
import Strings from "../assets/strings";
import Colors from "../assets/colors";
import {
    MdEdit,
    MdLock
} from "react-icons/md";
import CustomSelect from "./select";
import React, {Component} from "react";
import {ErrorMsg, InfoMsg, SectionTitleSmall} from "./shared-components/text-styles";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import CustomButton from "./button";
import Fonts from "../assets/fonts";
import PropTypes from 'prop-types';
import {assign, getDataByZipcode} from "../data/services/utils";
import AfiserviciosClient from "../data/models/afiservicios-client";
import {getAdvisors} from "../data/services/admins/clients";

const PartialContainer = styled.div`
    display: grid;
    grid-template-columns: ${props => props.halfOnDesktop ? '1fr' : '1fr 1fr'};
    grid-gap: 2em;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
    }
`;

const PartialContainerForm = styled.div`
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    grid-gap: 1em;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
`;

const TitleIconContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const IconContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

class BasicInfoForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            requestError: null,
            infoMsg: null,
            errors: {
                'client.name': true,
                'client.lastname': true,
                'client.mother_lastname': true,
                'client.phone': true,
                'client.email': this.props.canEditEmail,
                'client.address.street': true,
                'client.address.colony': true,
                'client.address.city': true,
                'client.address.state': true,
                'client.address.zipcode': true
            },
            client: this.props.user,
            client_afiservicios: this.props.userAfiservicios,
            colonies: [],
            advisors: [],
            advisors_afiservicios: [],
            settedByColony: false
        };
    }

    componentDidMount() {
        this.getAdvisors();
    }

    async getAdvisors() {
        try {
            const res_afisofom = this.props.isFromAfisofom ? await getAdvisors(true) : [];
            const res_afiservicios = this.props.isFromAfiservicios ? await getAdvisors(false) : [];

            if (this.props.isFromAfisofom || this.props.isFromAfiservicios) {
                this.setState({
                    advisors: res_afisofom.advisors,
                    advisors_afiservicios: res_afiservicios.advisors
                }, () => {
                    this.initData();
                });
            } else {
                this.initData();
            }
        } catch (error) {
            console.log(error);
        }
    }

    getAdvisorName(from_afisofom, id) {
        let advisors = from_afisofom ? this.state.advisors : this.state.advisors_afiservicios;
        let name = '';

        advisors.forEach(item => {
            if (item.id === id) {
                name = item.full_name;
            }
        });

        return name;
    }

    initData() {
        if (this.props.user) {
            const auxErrors = {
                'client.name': !this.props.user.name,
                'client.lastname': !this.props.user.lastname,
                'client.mother_lastname': !this.props.user.mother_lastname,
                'client.phone': !this.props.user.phone,
                'client.email': this.props.canEditEmail ? !this.props.user.email : false,
                'client.address.street': !this.props.user.address.street,
                'client.address.colony': !this.props.user.address.colony,
                'client.address.city': !this.props.user.address.city,
                'client.address.state': !this.props.user.address.state,
                'client.address.zipcode': !this.props.user.address.zipcode
            };

            this.setState({
                errors: auxErrors
            }, ()  => {
                this.onChangeZipcode(false);
            });
        }
    }

    updateState(prop, value, valid) {
        const state = this.state;
        const errors = state.errors;

        assign(state, prop, value);
        errors[prop] = !valid;
        state.errors = errors;

        if (prop === 'client.address.zipcode') {
            assign(state, 'client.address.colony', '');
            this.onChangeZipcode();
        }

        if (prop === 'client.afisofom_credit.advisor_id') {
            const advisor = this.getAdvisorName(true, value);
            assign(state, 'client.afisofom_credit.advisor', advisor);
        }

        if (prop === 'client_afiservicios.afiservicios_form.advisor_id') {
            const advisor = this.getAdvisorName(false, value);
            assign(state, 'client_afiservicios.afiservicios_form.advisor', advisor);
        }

        this.setState(state);
    };

    onChangeZipcode(setData = true) {
        getDataByZipcode(this.state.client.address.zipcode).then(res => {
            if (res != null) {
                const auxClient = this.state.client;
                const auxErrors = this.state.errors;

                if (setData) {
                    auxClient.address.colony = res.colonias[0];
                    auxClient.address.city = res.municipio;
                    auxClient.address.state = res.estado;
                    auxErrors['client.address.city'] = false;
                    auxErrors['client.address.state'] = false;
                    auxErrors['client.address.colony'] = false;
                }

                this.setState({
                    errors: auxErrors,
                    settedByColony: true,
                    colonies: res.colonias,
                    client: auxClient
                });
            } else {
                this.setState({
                    colonies: [],
                    settedByColony: false
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }

    verifyData() {
        return !this.state.errors['client.name']
            && !this.state.errors['client.lastname']
            && !this.state.errors['client.mother_lastname']
            && !this.state.errors['client.phone']
            && (this.props.canEditEmail ? !this.state.errors['client.email'] : true)
            && !this.state.errors['client.address.street']
            && !this.state.errors['client.address.colony']
            && !this.state.errors['client.address.state']
            && !this.state.errors['client.address.city']
            && !this.state.errors['client.address.zipcode'];
    };

    onSave() {
        this.setState({
            submitted: true,
        }, () => {
            if (this.verifyData()) {
                this.props.onSubmit(this.state.client, this.state.client_afiservicios);
            }
        })
    };

    render() {
        return (
            <form noValidate autoComplete="off">
                <PartialContainer halfOnDesktop={this.props.halfOnDesktop}>
                    <div>
                        <TitleIconContainer>
                            <TitleContainer>
                                <SectionTitleSmall>
                                    {Strings.PERSONAL_INFO}
                                </SectionTitleSmall>
                            </TitleContainer>
                            <IconContainer>
                                {
                                    this.props.showEditButton &&
                                    <IconButton onClick={() => this.props.onClickEdit()}>
                                        {
                                            this.props.disabled
                                                ? <MdEdit />
                                                : <MdLock />
                                        }
                                    </IconButton>
                                }
                            </IconContainer>
                        </TitleIconContainer>

                        <CustomInput label={Strings.NAME + ' *'}
                                     name="name"
                                     id="name"
                                     value={this.state.client.name}
                                     color={Colors.BLACK}
                                     required={true}
                                     disabled={this.props.disabled}
                                     submitted={this.state.submitted}
                                     invalid={this.state.errors['client.name']}
                                     onChange={(val, v) => this.updateState('client.name', val, v)}
                                     type="text"/>
                        <CustomInput label={Strings.LASTNAME + ' *'}
                                     name="lastname"
                                     id="lastname"
                                     disabled={this.props.disabled}
                                     value={this.state.client.lastname}
                                     color={Colors.BLACK}
                                     required={true}
                                     submitted={this.state.submitted}
                                     invalid={this.state.errors['client.lastname']}
                                     onChange={(val, v) => this.updateState('client.lastname', val, v)}
                                     type="text"/>
                        <CustomInput label={Strings.MOTHER_LASTNAME + ' *'}
                                     name="mother_lastname"
                                     id="mother_lastname"
                                     disabled={this.props.disabled}
                                     value={this.state.client.mother_lastname}
                                     color={Colors.BLACK}
                                     required={true}
                                     submitted={this.state.submitted}
                                     invalid={this.state.errors['client.mother_lastname']}
                                     onChange={(val, v) => this.updateState('client.mother_lastname', val, v)}
                                     type="text"/>
                        <CustomInput label={Strings.PHONE + ' *'}
                                     name="phone"
                                     id="phone"
                                     disabled={this.props.disabled}
                                     color={Colors.BLACK}
                                     value={this.state.client.phone}
                                     required={true}
                                     submitted={this.state.submitted}
                                     invalid={this.state.errors['client.phone']}
                                     onChange={(val, v) => this.updateState('client.phone', val, v)}
                                     minLength={10}
                                     maxLength={10}
                                     type="number"/>
                        {
                            this.props.canEditEmail &&
                            <CustomInput label={Strings.EMAIL + ' *'}
                                         name="email"
                                         id="email"
                                         disabled={this.props.disabled}
                                         color={Colors.BLACK}
                                         required={true}
                                         value={this.state.client.email}
                                         submitted={this.state.submitted}
                                         invalid={this.state.errors['client.email']}
                                         onChange={(val, v) => this.updateState('client.email', val, v)}
                                         type="email"/>
                        }
                        {
                            this.props.canEditAdvisor && this.props.isFromAfisofom &&
                            <CustomSelect label={Strings.AFISOFOM_ADVISOR + ' *'}
                                          name="afisofom_advisor"
                                          id="afisofom_advisor"
                                          color={Colors.BLACK}
                                          value={this.state.client.afisofom_credit.advisor_id || ''}
                                          required={false}
                                          disabled={this.state.advisors.length < 1 || this.props.disabled}
                                          submitted={this.state.submitted}
                                          invalid={false}
                                          useNestedProps={true}
                                          valueProp="id"
                                          labelProp="full_name"
                                          isClearable={this.state.client.afisofom_credit.advisor_id?.length > 0}
                                          onClear={() => this.updateState('client.afisofom_credit.advisor_id', Strings.NO_ADVISOR, true)}
                                          onChange={(val, v) => this.updateState('client.afisofom_credit.advisor_id', val, v)}
                                          options={this.state.advisors} />
                        }
                        {
                            this.props.canEditAdvisorAfiservicios && this.props.isFromAfiservicios &&
                            <CustomSelect label={Strings.AFISERVICIOS_ADVISOR + ' *'}
                                          name="afiservicios_advisor"
                                          id="afiservicios_advisor"
                                          color={Colors.BLACK}
                                          value={this.state.client_afiservicios.afiservicios_form.advisor_id || ''}
                                          required={false}
                                          disabled={this.state.advisors_afiservicios.length < 1 || this.props.disabled}
                                          submitted={this.state.submitted}
                                          invalid={false}
                                          useNestedProps={true}
                                          valueProp="id"
                                          labelProp="full_name"
                                          isClearable={this.state.client_afiservicios.afiservicios_form.advisor_id?.length > 0}
                                          onClear={() => this.updateState('client_afiservicios.afiservicios_form.advisor_id', Strings.NO_ADVISOR, true)}
                                          onChange={(val, v) => this.updateState('client_afiservicios.afiservicios_form.advisor_id', val, v)}
                                          options={this.state.advisors_afiservicios} />
                        }
                    </div>
                    <div>
                        <TitleContainer style={this.props.showEditButton ? { padding: '12px 0' } : {}}>
                            <SectionTitleSmall>
                                {Strings.ADDRESS}
                            </SectionTitleSmall>
                        </TitleContainer>
                        <CustomInput label={Strings.STREET_LINE + ' *'}
                                     name="street"
                                     id="street"
                                     disabled={this.props.disabled}
                                     value={this.state.client.address.street}
                                     color={Colors.BLACK}
                                     required={true}
                                     submitted={this.state.submitted}
                                     invalid={this.state.errors['client.address.street']}
                                     onChange={(val, v) => this.updateState('client.address.street', val, v)}
                                     type="text"/>
                        <PartialContainerForm>
                            <CustomInput label={Strings.ZIPCODE + ' *'}
                                         name="zipcode"
                                         id="zipcode"
                                         disabled={this.props.disabled}
                                         color={Colors.BLACK}
                                         required={true}
                                         value={this.state.client.address.zipcode}
                                         submitted={this.state.submitted}
                                         invalid={this.state.errors['client.address.zipcode']}
                                         onChange={(val, v) => this.updateState('client.address.zipcode', val, v)}
                                         minLength={5}
                                         maxLength={5}
                                         type="number"/>
                            <CustomSelect label={Strings.COLONY + ' *'}
                                          name="service"
                                          id="service"
                                          color={Colors.BLACK}
                                          value={this.state.client.address.colony}
                                          required={true}
                                          disabled={this.state.colonies.length < 1 || this.props.disabled}
                                          submitted={this.state.submitted}
                                          invalid={this.state.errors['client.address.colony']}
                                          onChange={(val, v) => this.updateState('client.address.colony', val, v)}
                                          options={this.state.colonies} />
                        </PartialContainerForm>
                        <CustomInput label={Strings.CITY + ' *'}
                                     name="city"
                                     id="city"
                                     color={Colors.BLACK}
                                     value={this.state.client.address.city}
                                     required={true}
                                     disabled={this.state.settedByColony || this.props.disabled}
                                     submitted={this.state.submitted}
                                     invalid={this.state.errors['client.address.city']}
                                     onChange={(val, v) => this.updateState('client.address.city', val, v)}
                                     type="text"/>
                        <CustomInput label={Strings.STATE + ' *'}
                                     name="state"
                                     id="state"
                                     disabled={this.state.settedByColony || this.props.disabled}
                                     value={this.state.client.address.state}
                                     color={Colors.BLACK}
                                     required={true}
                                     submitted={this.state.submitted}
                                     invalid={this.state.errors['client.address.state']}
                                     onChange={(val, v) => this.updateState('client.address.state', val, v)}
                                     type="text"/>
                    </div>
                </PartialContainer>
                <PartialContainer>
                    <div style={{ marginTop: 30 }}>
                        {
                            this.props.success &&
                            <InfoMsg>
                                {this.props.success}
                                <br/>
                                <br/>
                            </InfoMsg>
                        }
                        {
                            this.state.requestError &&
                            <ErrorMsg>
                                {this.state.requestError}
                                <br/>
                                <br/>
                            </ErrorMsg>
                        }
                        {
                            this.props.error &&
                            <ErrorMsg>
                                {this.props.error}
                                <br/>
                                <br/>
                            </ErrorMsg>
                        }
                        <CustomButton label={Strings.SAVE}
                                      onClick={() => this.onSave()}
                                      color={Colors.WHITE}
                                      background={Colors.DARK_GRAY}
                                      font={Fonts.BOLD}
                                      disabled={this.props.disabled}
                                      isLoading={this.props.isLoadingButton}
                                      fullWidth={false}
                                      fullWidthMobile={true} />
                    </div>
                </PartialContainer>
            </form>
        )
    }
}

BasicInfoForm.propTypes = {
    showEditButton: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func,
    user: PropTypes.object.isRequired,
    userAfiservicios: PropTypes.object,
    isLoadingButton: PropTypes.bool,
    error: PropTypes.string,
    success: PropTypes.string,
    halfOnDesktop: PropTypes.bool,
    disabled: PropTypes.bool,
    canEditEmail: PropTypes.bool,
    canEditAdvisor: PropTypes.bool,
    canEditAdvisorAfiservicios: PropTypes.bool,
    isFromAfisofom: PropTypes.bool,
    isFromAfiservicios: PropTypes.bool,
};

BasicInfoForm.defaultProps = {
    showEditButton: false,
    error: null,
    success: null,
    isLoadingButton: false,
    halfOnDesktop: false,
    disabled: false,
    canEditEmail: true,
    canEditAdvisor: false,
    canEditAdvisorAfiservicios: false,
    isFromAfisofom: false,
    isFromAfiservicios: false,
    userAfiservicios: new AfiserviciosClient(),
    onClickEdit: () => {}
};

export default BasicInfoForm;
