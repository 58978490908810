import React, {Component} from "react";
import styled from "styled-components";
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import FormGroup from "@material-ui/core/FormGroup"
import PropTypes from "prop-types";
import Colors from "../assets/colors";

const CustomCheckboxWrapper = styled.div`
  .afi-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: ${Colors.WHITE};
    border: 4px solid ${props => props.background};
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .checkmark:hover {
    background-color: #ccc;
  }
  
  .afi-checkbox:checked ~ .checkmark {
    background-color: ${props => props.background};
    border: none;
    height: 25px;
    width: 25px;
  }
  
  .afi-checkbox:checked ~ .checkmark:after {
    display: block;
  }
  
  .checkmark:after {
    left: 8px;
    top: 2px;
    width: 27.5%;
    height: 50%;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const ErrorMsg = styled.label`
  color: ${Colors.ERROR};
  font-size: 1em;
  margin-top: 5px;
`;

class CustomCheckbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked
    }
  }

  render() {
    return (
      <CustomCheckboxWrapper style={this.props.style}
                             background={this.props.background}>
        <FormControl required
                     error={!this.state.checked && this.props.submitted && this.props.required}
                     component="fieldset">
          <FormGroup>
            <FormControlLabel
              style={{ marginLeft: '35px' }}
              label={this.props.label}
              control={
                <React.Fragment>
                  <input type="checkbox"
                         className="afi-checkbox"
                         id={this.props.id}
                         name={this.props.name}
                         onChange={() => this.onChange()}
                         checked={this.state.checked} />
                  <span className="checkmark" />
                </React.Fragment>
              }
            />
          </FormGroup>
          {
            !this.state.checked && this.props.submitted && this.props.required &&
            <ErrorMsg>{this.props.errorMsg}</ErrorMsg>
          }
        </FormControl>
      </CustomCheckboxWrapper>
    );
  }

  /**
   * Handle on change event
   * @author @leonard_lib
   * @date 29/07/2020
   */
  onChange() {
    const checked = this.state.checked;

    this.setState({
      checked: !checked
    }, () => {
      return this.props.onChange(this.state.checked);
    });
  }
}

CustomCheckbox.propTypes = {
  style: PropTypes.object,
  submitted: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errorMsg: PropTypes.string,
  background: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool
};

CustomCheckbox.defaultProps = {
  style: {},
  submitted: false,
  required: false,
  checked: false,
  errorMsg: ''
};

export default CustomCheckbox;
