/**
 * AWS Amplify messages languages
 * @author @leonard_lib
 * @date 29/07/2020
 */
export default {
    'es': {
        'Username cannot be empty': 'El correo electrónico es requerido',
        'User cannot be confirmed. Current status is CONFIRMED': 'El correo ya está confirmado',
        'Incorrect username or password.': 'Correo o contraseña incorrectos',
        'Invalid verification code provided, please try again.': 'Código de verificación incorrecto, intenta de nuevo.',
        'An account with the given email already exists.': 'El correo ya está registrado.',

        'Password cannot be empty': 'La contraseña no puede estar vacía',
        'Password does not conform to policy: Password must have symbol characters': 'La contraseña debe tener al menos un caracter simbólico.',
        'Password did not conform with policy: Password must have symbol characters': 'La contraseña debe tener al menos un caracter simbólico.',
        'Password does not conform to policy: Password not long enough': 'La contraseña debe ser de 8 caracteres mínimo.',
        'Password does not conform to policy: Password must have lowercase characters': 'La contraseña debe tener al menos una letra minúscula.',
        'Password does not conform to policy: Password must have uppercase characters': 'La contraseña debe tener al menos una letra mayúscula.',
        'Attempt limit exceeded, please try after some time.': 'Excediste el límite de intentos, espera un momento e intenta de nuevo.',
        'Password did not conform with policy: Password must have uppercase characters': 'La contraseña debe tener al menos una letra mayúscula.',
        'Password did not conform with policy: Password must have numeric characters': 'La contraseña debe tener al menos un digito numerico.',
    }
};
