/**
 * Moral person model
 * @author @leonard_lib
 * @date 05/08/2020
 */
export class MoralPerson {
    business_name;

    constructor(json = {}) {
        this.business_name = json.business_name ? json.business_name : '';
    }
}

/**
 * General address model
 * @author @leonard_lib
 * @date 05/08/2020
 */
class Address {
    street;
    colony;
    city;
    state;
    country;
    zipcode;

    constructor(json = {}) {
        this.street = json.street ? json.street : '';
        this.colony = json.colony ? json.colony : '';
        this.city = json.city ? json.city : '';
        this.state = json.state ? json.state : '';
        this.country = json.country ? json.country : 'México';
        this.zipcode = json.zipcode ? json.zipcode : '';
    }

    /**
     * Check if address is completed
     * @author @leonard_lib
     * @date 05/08/2020
     * @returns {*|boolean}
     */
    get data_completed() {
        return this.street && this.street.length > 0
            && this.colony && this.colony.length > 0
            && this.city && this.city.length > 0
            && this.state && this.state.length > 0
            && this.zipcode && this.zipcode.length > 0;
    }

    get string_line() {
        return `${this.street}, ${this.colony}, C.P. ${this.zipcode}, ${this.city}, ${this.state}, ${this.country}`;
    }

    get string_line_1() {
        return `${this.street}, ${this.colony},`;
    }

    get string_line_2() {
        return `C.P. ${this.zipcode}, ${this.city}, ${this.state}, ${this.country}`;
    }
}

/**
 * General client model
 * @author @leonard_lib
 * @date 05/08/2020
 */
class Client {
    id;
    name;
    lastname;
    mother_lastname;
    phone;
    email;
    is_moral_person;
    moral_person;
    address;
    search_term;
    admin;

    constructor(json = {}) {
        this.id = json.id ? json.id : null;
        this.name = json.name ? json.name : '';
        this.lastname = json.lastname ? json.lastname : '';
        this.mother_lastname = json.mother_lastname ? json.mother_lastname : '';
        this.phone = json.phone ? json.phone : '';
        this.email = json.email ? json.email : '';
        this.is_moral_person = json.is_moral_person ? json.is_moral_person : false;
        this.moral_person = json.moral_person ? new MoralPerson(json.moral_person) : null;
        this.address = json.address ? new Address(json.address) : new Address();
        this.search_term = `${this.name} ${this.lastname} ${this.mother_lastname} ${this.phone} ${this.email} ${this.is_moral_person ? this.moral_person.business_name : this.full_name}`;
        this.search_term = this.search_term.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        this.admin = json.admin;
    }

    static fromMultiple(array) {
        let items = [];

        if (array && array.length > 0) {
            array.forEach(item => {
                items.push(new Client(item));
            });
        }

        return items;
    }

    get full_name() {
        return `${this.name} ${this.lastname} ${this.mother_lastname}`;
    }

    /**
     * Check if data is completed
     * @author @leonard_lib
     * @date 05/08/2020
     * @returns {*|boolean}
     */
    get data_completed() {
        return this.name && this.name.length > 0
            && this.lastname && this.lastname.length > 0
            && this.mother_lastname && this.mother_lastname.length > 0
            && this.phone && this.phone.length > 0
            && this.email && this.email.length > 0
            && this.address.data_completed;
    }
}

export default Client;
