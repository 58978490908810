import React from "react";
import LayoutClients from "../../components/layout-clients";
import AfisofomRegisterForm from "../../components/afisofom/register-form";
import {useChangeTitleFavicon} from "../../hooks/useChangeTitle";
import Strings from "../../assets/strings";

const AfisofomRegister = ({ history }) => {
    useChangeTitleFavicon(Strings.TITLE_REGISTER, "afisofom");

    return (
        <LayoutClients site="sofom"
                       history={history}
                       showPrincipalButton={false}>
            <div className="container" style={{ marginTop: '130px' }}>
                <AfisofomRegisterForm onRegister={
                    () => history.push('/afisofom/confirmar-registro')
                } />
            </div>
        </LayoutClients>
    );
}

export default AfisofomRegister;
