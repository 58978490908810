import React, { Component } from "react";
import styled from "styled-components";
import Colors from "../../assets/colors";
import Fonts from "../../assets/fonts";
import Strings from "../../assets/strings";
import CustomInput from "../input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import CustomCheckbox from "../checkbox";
import CustomButton from "../button";
import {
  clientExists,
  isValidPassword,
  register,
  setClientData,
} from "../../data/services/auth";
import PropTypes from "prop-types";
import AfisofomClient, {
  AfisofomCredit,
} from "../../data/models/afisofom-client";
import PasswordHint from "../password-hint";
import { assign, getDataByZipcode } from "../../data/services/utils";
import CustomSelect from "../select";
import { registerAfisofom } from "../../data/services/afisofom/client";
import { sendMailAdminsUserReg } from "../../data/services/admins/clients";
import { Link } from "react-router-dom";
import { MoralPerson } from "../../data/models/client";
import { ErrorMsg } from "../shared-components/text-styles";

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 60%;
  min-height: 500px;
  height: auto;
  box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.16);
  background-color: ${Colors.WHITE};

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    box-shadow: none;
    width: 100%;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 100%;
  }
`;

const FormContainer = styled.div`
  padding: 30px 60px;
  margin: auto 0;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    padding: 0;
    margin-top: 50px;
    margin-bottom: 50px;
  }
`;

const FormTitle = styled.label`
  font-family: ${Fonts.BOLD};
  font-size: 1.5em;
  color: ${Colors.PRIMARY_AFI_SOFOM};
`;

const FormCheckbox = styled.span`
  font-family: ${Fonts.BOLD};
  font-size: 1em;
  color: ${Colors.BLACK};
`;

const ForgotPasswordContainer = styled.div`
  text-align: center;
`;

const FormLink = styled.a`
  font-family: ${Fonts.BOLD};
  font-size: 1em;
  color: ${Colors.PRIMARY_AFI_SOFOM};
  text-decoration: underline;
  cursor: pointer;
`;

const FormInfo = styled.span`
  font-family: ${Fonts.MEDIUM};
  font-size: 1em;
  color: ${Colors.BLACK};
`;

const FormSubtitle = styled.p`
  font-family: ${Fonts.BOLD};
  font-size: 1em;
  color: ${Colors.BLACK};
`;

const PartialContainer = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1.5fr;
  grid-gap: 1em;
`;

class AfisofomRegisterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      client: new AfisofomClient(),
      password: "",
      password_confirm: "",
      submitted: false,
      errors: {
        "client.name": true,
        "client.lastname": true,
        "client.mother_lastname": true,
        "client.phone": true,
        "client.email": true,
        password: true,
        password_confirm: true,
        "client.address.street": true,
        "client.address.colony": true,
        "client.address.city": true,
        "client.address.state": true,
        "client.address.zipcode": true,
        "client.moral_person.business_name": true,
        agree: true,
      },
      isLoading: false,
      show_password: false,
      show_password_confirm: false,
      error: null,
      already_registered: false,
      password_invalid: false,
      agree: false,
      colonies: [],
      setted_by_colony: false,
    };
  }

  componentDidMount() {
    let errors = this.state.errors;
    const is_moral_person = localStorage.getItem("personaMoral") === "true";
    const amount = localStorage.getItem("loanAmount")
      ? Number(localStorage.getItem("loanAmount"))
      : 0;
    const term_in_months = localStorage.getItem("paymentMonths")
      ? Number(localStorage.getItem("paymentMonths"))
      : 0;
    const credit_type = localStorage.getItem("creditType");
    const rateType = parseInt(localStorage.getItem("rateType"));
    const local_client = new AfisofomClient();

    local_client.is_moral_person = is_moral_person;
    local_client.moral_person = is_moral_person ? new MoralPerson() : null;
    local_client.afisofom_credit = new AfisofomCredit({
      amount: amount,
      stage: Strings.STAGE_OPEN,
      term: term_in_months,
      type: credit_type,
      rateType,
    });

    this.setState(
      {
        client: local_client,
        errors: errors,
      },
      () => {
        this.onChangeZipcode();
      }
    );
  }

  render() {
    const field_name_label = this.state.client.is_moral_person
      ? Strings.NAME_2
      : Strings.NAME;
    const field_lastname_label = this.state.client.is_moral_person
      ? Strings.LASTNAME_2
      : Strings.LASTNAME;
    const field_mother_lastname_label = this.state.client.is_moral_person
      ? Strings.MOTHER_LASTNAME_2
      : Strings.MOTHER_LASTNAME;

    return (
      <FormWrapper>
        <Container>
          <FormContainer>
            <FormTitle>{Strings.REGISTER}</FormTitle>
            <br />
            <FormSubtitle>{Strings.AFISOFOM_REGISTER_SUBTITLE}</FormSubtitle>
            <form noValidate autoComplete="off">
              {this.state.client.is_moral_person && (
                <CustomInput
                  label={Strings.BUSINESS_NAME + " *"}
                  name="business_name"
                  id="business_name"
                  value={this.state.client.moral_person.business_name}
                  color={Colors.BLACK}
                  required={true}
                  submitted={this.state.submitted}
                  invalid={
                    this.state.errors["client.moral_person.business_name"]
                  }
                  onChange={(val, v) =>
                    this.updateState(
                      "client.moral_person.business_name",
                      val,
                      v
                    )
                  }
                  type="text"
                />
              )}
              <CustomInput
                label={field_name_label + " *"}
                name="name"
                id="name"
                value={this.state.client.name}
                color={Colors.BLACK}
                required={true}
                submitted={this.state.submitted}
                invalid={this.state.errors["client.name"]}
                onChange={(val, v) => this.updateState("client.name", val, v)}
                type="text"
              />
              <CustomInput
                label={field_lastname_label + " *"}
                name="lastname"
                id="lastname"
                value={this.state.client.lastname}
                color={Colors.BLACK}
                required={true}
                submitted={this.state.submitted}
                invalid={this.state.errors["client.lastname"]}
                onChange={(val, v) =>
                  this.updateState("client.lastname", val, v)
                }
                type="text"
              />
              <CustomInput
                label={field_mother_lastname_label + " *"}
                name="mother_lastname"
                id="mother_lastname"
                value={this.state.client.mother_lastname}
                color={Colors.BLACK}
                required={true}
                submitted={this.state.submitted}
                invalid={this.state.errors["client.mother_lastname"]}
                onChange={(val, v) =>
                  this.updateState("client.mother_lastname", val, v)
                }
                type="text"
              />
              <CustomInput
                label={Strings.PHONE + " *"}
                name="phone"
                id="phone"
                color={Colors.BLACK}
                value={this.state.client.phone}
                required={true}
                submitted={this.state.submitted}
                invalid={this.state.errors["client.phone"]}
                onChange={(val, v) => this.updateState("client.phone", val, v)}
                minLength={10}
                maxLength={10}
                type="number"
              />
              <CustomInput
                label={Strings.EMAIL + " *"}
                name="email"
                id="email"
                color={Colors.BLACK}
                required={true}
                value={this.state.client.email}
                submitted={this.state.submitted}
                invalid={this.state.errors["client.email"]}
                onChange={(val, v) => this.updateState("client.email", val, v)}
                type="email"
              />
              <CustomInput
                label={Strings.PASSWORD + " *"}
                name="password"
                id="password"
                required={true}
                color={Colors.BLACK}
                value={this.state.password}
                submitted={this.state.submitted}
                invalid={this.state.errors["password"]}
                onChange={(val, v) => this.updateState("password", val, v)}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex="-1"
                        onClick={() =>
                          this.setState({
                            show_password: !this.state.show_password,
                          })
                        }
                      >
                        {this.state.show_password ? (
                          <MdVisibility />
                        ) : (
                          <MdVisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                type={this.state.show_password ? "text" : "password"}
              />
              <CustomInput
                label={Strings.PASSWORD_CONFIRM + " *"}
                name="password_confirm"
                id="password_confirm"
                required={true}
                value={this.state.password_confirm}
                color={Colors.BLACK}
                submitted={this.state.submitted}
                invalid={this.state.errors["password_confirm"]}
                onChange={(val, v) =>
                  this.updateState("password_confirm", val, v)
                }
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex="-1"
                        onClick={() =>
                          this.setState({
                            show_password_confirm:
                              !this.state.show_password_confirm,
                          })
                        }
                      >
                        {this.state.show_password_confirm ? (
                          <MdVisibility />
                        ) : (
                          <MdVisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                type={this.state.show_password_confirm ? "text" : "password"}
              />
              <br />
              <br />
              {this.state.password_invalid && (
                <div>
                  <PasswordHint />
                  <br />
                  <br />
                </div>
              )}
              <FormSubtitle>{Strings.ADDRESS}</FormSubtitle>
              <CustomInput
                label={Strings.STREET_LINE + " *"}
                name="street"
                id="street"
                value={this.state.client.address.street}
                color={Colors.BLACK}
                required={true}
                submitted={this.state.submitted}
                invalid={this.state.errors["client.address.street"]}
                onChange={(val, v) =>
                  this.updateState("client.address.street", val, v)
                }
                type="text"
              />
              <PartialContainer>
                <CustomInput
                  label={Strings.ZIPCODE + " *"}
                  name="zipcode"
                  id="zipcode"
                  color={Colors.BLACK}
                  required={true}
                  value={this.state.client.address.zipcode}
                  submitted={this.state.submitted}
                  invalid={this.state.errors["client.address.zipcode"]}
                  onChange={(val, v) =>
                    this.updateState("client.address.zipcode", val, v)
                  }
                  minLength={5}
                  maxLength={5}
                  type="number"
                />
                <CustomSelect
                  label={Strings.COLONY + " *"}
                  name="service"
                  id="service"
                  color={Colors.BLACK}
                  value={this.state.client.address.colony}
                  required={true}
                  disabled={this.state.colonies.length < 1}
                  submitted={this.state.submitted}
                  invalid={this.state.errors["client.address.colony"]}
                  onChange={(val, v) =>
                    this.updateState("client.address.colony", val, v)
                  }
                  options={this.state.colonies}
                />
              </PartialContainer>
              <CustomInput
                label={Strings.CITY + " *"}
                name="city"
                id="city"
                color={Colors.BLACK}
                value={this.state.client.address.city}
                required={true}
                disabled={this.state.setted_by_colony}
                submitted={this.state.submitted}
                invalid={this.state.errors["client.address.city"]}
                onChange={(val, v) =>
                  this.updateState("client.address.city", val, v)
                }
                type="text"
              />
              <CustomInput
                label={Strings.STATE + " *"}
                name="state"
                id="state"
                disabled={this.state.setted_by_colony}
                value={this.state.client.address.state}
                color={Colors.BLACK}
                required={true}
                submitted={this.state.submitted}
                invalid={this.state.errors["client.address.state"]}
                onChange={(val, v) =>
                  this.updateState("client.address.state", val, v)
                }
                type="text"
              />
              <br />
              <br />
              <CustomCheckbox
                name="agree"
                required={true}
                submitted={this.state.submitted}
                background={Colors.PRIMARY_AFI_SOFOM}
                errorMsg={Strings.FIELD_REQUIRED}
                onChange={(v) => this.updateState("agree", v, v)}
                label={
                  <FormCheckbox>
                    {Strings.POLICY_AGREE}{" "}
                    <FormLink
                      target="_blank"
                      href={"https://afisofom.com/aviso-de-privacidad"}
                    >
                      {Strings.POLICY}
                    </FormLink>
                  </FormCheckbox>
                }
                id="agree"
              />
              <br />
              <br />
              <ForgotPasswordContainer>
                <CustomButton
                  label={Strings.REGISTER_ME}
                  onClick={() => this.handleSignup()}
                  align="center"
                  color={Colors.WHITE}
                  isLoading={this.state.isLoading}
                  fullWidth={false}
                  background={Colors.PRIMARY_AFI_SOFOM}
                  font={Fonts.BOLD}
                />
                {this.state.error && (
                  <ErrorMsg>
                    <br />
                    {this.state.error}
                  </ErrorMsg>
                )}
                {this.state.already_registered && (
                  <FormInfo>
                    <br />
                    {Strings.ALREADY_REGISTER_INFO}
                    <FormLink to="/afisofom/iniciar-sesion">
                      {Strings.ALREADY_REGISTER_INFO_2}
                    </FormLink>
                    {Strings.ALREADY_REGISTER_INFO_3}
                  </FormInfo>
                )}
              </ForgotPasswordContainer>
            </form>
          </FormContainer>
        </Container>
      </FormWrapper>
    );
  }

  /**
   * Update state vars
   * @author @leonard_lib
   * @date 29/07/2020
   * @param prop
   * @param value
   * @param valid
   */
  updateState(prop, value, valid) {
    const state = this.state;
    const errors = state.errors;

    assign(state, prop, value);
    errors[prop] = !valid;
    state.errors = errors;

    if (prop === "client.address.zipcode") {
      assign(state, "client.address.colony", "");
      this.onChangeZipcode();
    }

    localStorage.setItem("aux_password", state.password);
    this.setState(state, () => {
      this.checkPassword();
    });
  }

  onChangeZipcode() {
    if (this.state.client.address.zipcode) {
      getDataByZipcode(this.state.client.address.zipcode)
        .then((res) => {
          if (res != null) {
            const client = this.state.client;
            const errors = this.state.errors;
            client.address.colony = res.colonias[0];
            client.address.city = res.municipio;
            client.address.state = res.estado;
            errors["client.address.city"] = false;
            errors["client.address.state"] = false;
            errors["client.address.colony"] = false;

            this.setState({
              colonies: res.colonias,
              client: client,
              errors: errors,
              setted_by_colony: true,
            });
          } else {
            this.setState({
              colonies: [],
              setted_by_colony: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            error: err,
          });
        });
    }
  }

  /**
   * Check if data is completed
   * @author @leonard_lib
   * @date 29/07/2020
   * @returns {boolean|boolean}
   */
  checkCompleted() {
    let valid_moral_person_name = this.state.client.is_moral_person
      ? !this.state.errors["client.moral_person.business_name"]
      : true;

    const v =
      this.checkPassword() &&
      !this.state.errors["client.name"] &&
      !this.state.errors["client.lastname"] &&
      !this.state.errors["client.mother_lastname"] &&
      !this.state.errors["client.phone"] &&
      !this.state.errors["client.email"] &&
      !this.state.errors["client.address.street"] &&
      !this.state.errors["client.address.colony"] &&
      !this.state.errors["client.address.state"] &&
      !this.state.errors["client.address.city"] &&
      !this.state.errors["client.address.zipcode"] &&
      !this.state.errors["password"] &&
      !this.state.errors["password_confirm"] &&
      !this.state.errors["agree"] &&
      !this.state.password_invalid &&
      valid_moral_person_name;
    return v;
  }

  /**
   * Check if password match
   * @author @leonard_lib
   * @date 29/07/2020
   */
  checkPassword() {
    const invalid_password = !isValidPassword(this.state.password);

    if (this.state.password !== this.state.password_confirm) {
      this.setState({
        password_match: false,
        password_invalid: invalid_password,
        error: Strings.PASSWORD_NOT_MATCH,
      });
      return false;
    } else {
      this.setState({
        password_match: true,
        password_invalid: invalid_password,
        error: null,
      });
      return true;
    }
  }

  /**
   * Handle signup button click
   * @author @leonard_lib
   * @date 29/07/2020
   */
  handleSignup() {
    this.setState(
      {
        submitted: true,
        error: null,
        already_registered: null,
      },
      () => {
        if (this.checkCompleted()) {
          this.setState(
            {
              isLoading: true,
            },
            () => {
              this.isRegistered();
            }
          );
        }
      }
    );
  }

  /**
   * Check if user exists
   * @author @leonard_lib
   * @date 05/08/2020
   */
  isRegistered() {
    clientExists(this.state.client.email)
      .then((exists) => {
        if (!exists) {
          this.registerOnCognito();
        } else {
          this.setState({
            isLoading: false,
            already_registered: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error,
        });
      });
  }

  /**
   * Register on cognito first
   * @author @leonard_lib
   * @date 05/08/2020
   */
  registerOnCognito() {
    sendMailAdminsUserReg(
      true,
      this.state.client.name,
      this.state.client.email
    );
    register(this.state.client.email, this.state.password)
      .then(() => {
        this.makeCallToRegister();
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error,
        });
      });
  }

  /**
   * Make call to register
   * @author @leonard_lib
   * @date 29/07/2020
   */
  makeCallToRegister() {
    registerAfisofom(this.state.client)
      .then((user) => {
        const state = this.state;
        assign(state, "client.id", user.data.createClient.id);

        this.setState(state, () => {
          setClientData(this.state.client);
          this.props.onRegister();
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error,
        });
      });
  }
}

AfisofomRegisterForm.propTypes = {
  onRegister: PropTypes.func.isRequired,
};

export default AfisofomRegisterForm;
