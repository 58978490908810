import Client from "./client";
import moment from "moment";
import Strings from "../../assets/strings";
import { pmt } from "../../utils/finance";
import { FIXED_RATE } from "../../components/afisofom/form-one-main";

/**
 * Amortization calendar model for afisofom client
 * @author @leonard_lib
 * @date 05/08/2020
 */
class AfisofomAmortizationCalendar {
  period;
  minAmount;
  maxAmount;
  payment_date;
  completed;
  file_key;

  constructor(json = {}) {
    this.period = json.period ? json.period : null;
    this.minAmount = json.minAmount ? json.minAmount : null;
    this.maxAmount = json.maxAmount ? json.maxAmount : null;
    this.payment_date = json.payment_date ? json.payment_date : null;
    this.file_key = json.file_key ? json.file_key : null;
    this.completed = !!json.completed;
  }

  static fromMultiple(array) {
    let items = [];

    if (array && array.length > 0) {
      array.forEach((item) => {
        items.push(new AfisofomAmortizationCalendar(item));
      });
    }

    return items;
  }
}

export class AfisofomCreditAnnexes {
  key;
  name;

  constructor(json = {}) {
    this.key = json.key ? json.key : null;
    this.name = json.name ? json.name : null;
  }

  static fromMultiple(array) {
    let items = [];

    if (array && array.length > 0) {
      array.forEach((item) => {
        items.push(new AfisofomCreditAnnexes(item));
      });
    }

    return items;
  }
}

/**
 * Credit model for afisofom client
 * @author @leonard_lib
 * @date 05/08/2020
 */
export class AfisofomCredit {
  static STAGE_OPEN = "En captura";
  static STAGE_PENDING = "Pendiente de revisión";
  static STAGE_APPROVED = "Aprobado";
  static STAGE_DECLINED = "Rechazado";
  static STAGE_DONE = "Terminado";

  static TYPE_AVIO = "Avío";
  static TYPE_REFACT = "Refaccionario";
  static TYPE_SIMPLE = "Simple";
  static TYPE_ACCOUNT = "Cuenta Corriente";
  static TYPE_CAPITAL = "Capital de Trabajo";
  static TYPE_FACT = "Factoraje";

  static PAYMENTS_ON_TIME = "A tiempo";
  static PAYMENTS_LATE = "Tardío";

  stage;
  amount;
  term;
  type;
  rateType;
  interest;
  advisor_id;
  advisor;
  chat_id;
  request_date;
  decline_reason;
  annexes;
  amortization_calendar;
  afisofom_credit_form_key;
  credit_buro_key;
  photo_id_front_key;
  photo_id_reverse_key;
  is_requested;

  constructor(json = {}) {
    this.stage = json.stage ? json.stage : AfisofomCredit.STAGE_OPEN;
    this.amount = json.amount ? json.amount : null;
    this.term = json.term ? json.term : null;
    this.type = json.type ? json.type : null;
    this.rateType = json.rateType ? json.rateType : null;
    this.interest = json.interest ? json.interest : 0;
    this.advisor_id = json.advisor_id ? json.advisor_id : null;
    this.chat_id = json.chat_id ? json.chat_id : null;
    this.advisor = json.advisor ? json.advisor : Strings.NO_ADVISOR;
    this.decline_reason = json.decline_reason ? json.decline_reason : null;
    this.is_requested = !!json.is_requested;
    this.request_date = json.request_date
      ? json.request_date
      : moment().format("DD/MM/YYYY");
    this.amortization_calendar = json.amortization_calendar
      ? AfisofomAmortizationCalendar.fromMultiple(json.amortization_calendar)
      : null;
    this.annexes = json.annexes
      ? AfisofomCreditAnnexes.fromMultiple(json.annexes)
      : null;
    this.afisofom_credit_form_key = json.afisofom_credit_form_key
      ? json.afisofom_credit_form_key
      : null;
    this.credit_buro_key = json.credit_buro_key ? json.credit_buro_key : null;
    this.photo_id_front_key = json.photo_id_front_key
      ? json.photo_id_front_key
      : null;
    this.photo_id_reverse_key = json.photo_id_reverse_key
      ? json.photo_id_reverse_key
      : null;
  }

  /**
   * Check if request data is completed
   * @author @leonard_lib
   * @date 05/08/2020
   * @returns {boolean}
   */
  get request_completed() {
    return (
      this.stage &&
      this.stage.length > 0 &&
      this.amount &&
      this.amount > 0 &&
      this.term &&
      this.term > 0 &&
      this.type
    );
  }

  get is_not_open() {
    return (
      this.stage !== AfisofomCredit.STAGE_PENDING &&
      this.stage !== AfisofomCredit.STAGE_OPEN
    );
  }

  get is_open() {
    return (
      this.stage === AfisofomCredit.STAGE_PENDING ||
      this.stage === AfisofomCredit.STAGE_OPEN
    );
  }

  get is_approved() {
    return this.stage === AfisofomCredit.STAGE_APPROVED;
  }

  get payments_data() {
    let rest = this.amount || 0;
    let pending_payments = this.term || 0;
    let next_payment_date = moment();
    let on_time = true;
    let pending_payments_info = [];
    let done_payments_info = [];
    let next_payment_info = null;

    if (this.amortization_calendar?.length > 0) {
      this.amortization_calendar.forEach((payment) => {
        if (payment.completed) {
          rest -= payment.amount;
          pending_payments--;
          done_payments_info.push(payment);
        } else {
          if (next_payment_info == null) {
            next_payment_info = payment;
          }

          next_payment_date = moment(payment.payment_date);
          pending_payments_info.push(payment);
        }
      });

      if (next_payment_date != null) {
        const today = moment();
        on_time = next_payment_date.isSameOrAfter(today);
      }
    }

    return {
      remaining_amount: rest,
      pending_payments: pending_payments,
      on_time: on_time,
      pending_payments_info: pending_payments_info,
      done_payments_info: done_payments_info,
      next_payment_info: next_payment_info,
    };
  }

  generateAmortizationCalendar(tiie) {
    let data = [];
    const minInterest =
      this.rateType === FIXED_RATE
        ? this.type.minFixedInterestRate
        : tiie.value / 100 + this.type.minVariableInterestRate;
    const maxInterest =
      this.rateType === FIXED_RATE
        ? this.type.maxFixedInterestRate
        : tiie.value / 100 + this.type.maxVariableInterestRate;
    const minValue = this.calculateAmortization(minInterest);
    const maxValue = this.calculateAmortization(maxInterest);
    let currentDate = moment();

    for (let i = 1; i <= this.term; i++) {
      const paymentDate = currentDate.add(1, "month");
      currentDate = paymentDate;
      const amorCalendar = new AfisofomAmortizationCalendar({
        period: i,
        minAmount: minValue,
        maxAmount: maxValue,
        payment_date: paymentDate.format("YYYY-MM-DD"),
      });
      data.push(amorCalendar);
    }

    this.amortization_calendar = data;
  }

  calculateAmortization(interestRate) {
    return pmt(interestRate / 12, this.term, this.amount);
  }
}

/**
 * Afisofom client model
 * @author @leonard_lib
 * @date 05/08/2020
 */
class AfisofomClient extends Client {
  afisofom_credit;

  constructor(json = {}) {
    super(json);
    this.afisofom_credit = json.afisofom_credit
      ? new AfisofomCredit(json.afisofom_credit)
      : new AfisofomCredit();
  }

  static fromMultiple(array) {
    let items = [];

    if (array && array.length > 0) {
      array.forEach((item) => {
        items.push(new AfisofomClient(item));
      });
    }

    return items;
  }

  /**
   * Check if signup data is completed
   * @author @leonard_lib
   * @date 05/08/2020
   * @returns {*|boolean}
   */
  get signup_completed() {
    return (
      super.data_completed &&
      this.afisofom_credit &&
      this.afisofom_credit.request_completed
    );
  }
}

export default AfisofomClient;
