import React, {Component} from "react";
import styled from "styled-components";
import cover from '../../assets/images/sofom-login.jpg';
import Colors from "../../assets/colors";
import Fonts from "../../assets/fonts";
import Strings from "../../assets/strings";
import CustomInput from "../input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {MdVisibility, MdVisibilityOff} from "react-icons/md";
import CustomCheckbox from "../checkbox";
import CustomButton from "../button";
import {login, setClientData} from "../../data/services/auth";
import PropTypes from 'prop-types';
import {getAfisofomClientDataByEmail} from "../../data/services/afisofom/client";

const SofomLoginFormWrapper = styled.div`
    width: 100%;
    min-height: calc(100vh - 110px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LoginContainer = styled.div`
    width: 100%;
    min-height: 600px;
    height: auto;
    box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.16);
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: ${Colors.WHITE};
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        box-shadow: none;
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        grid-template-columns: 1fr;
    }
`;

const LoginImageContainer = styled.div`
    background-image: url(${cover});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    display: grid;
    grid-template-rows: 0.8fr 0.2fr;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        display: none;
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        display: none;
    }
`;

const LoginImageOverContainer = styled.div`
    background-color: #000000;
    opacity: 0.44;
    grid-row: 1;
`;

const LoginImageTextContainer = styled.div`
    grid-row: 2;
    padding: 15px 30px;
    background-color: ${Colors.PRIMARY_AFI_SOFOM};
`;

const LoginImageText = styled.p`
    margin: 0;
    color: ${Colors.WHITE};
    font-family: ${Fonts.BOLD};
    font-size: 1.5em;
`;

const LoginFormContainer = styled.div`
    padding: 30px 60px;
    margin: auto 0;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        padding: 0;
        margin-top: 50px;
        margin-bottom: 50px;
    }
`;

const LoginFormTitle = styled.label`
    font-family: ${Fonts.BOLD};
    font-size: 1.5em;
    color: ${Colors.PRIMARY_AFI_SOFOM};
`;

const LoginFormCheckbox = styled.span`
    font-family: ${Fonts.BOLD};
    font-size: 1em;
    color: ${Colors.BLACK};
`;

const LoginFormSub = styled.span`
    font-family: ${Fonts.BOLD};
    font-size: 1em;
    color: ${Colors.BLACK};
`;

const ForgotPasswordContainer = styled.div`
    text-align: center;
`;

const LoginFormLink = styled.a`
    font-family: ${Fonts.BOLD};
    font-size: 1em;
    color: ${Colors.PRIMARY_AFI_SOFOM};
    text-decoration: underline;
    cursor: pointer;
`;

const LoginFormError = styled.span`
    font-family: ${Fonts.MEDIUM};
    font-size: 0.8em;
    color: ${Colors.ERROR};
`;

class SofomLoginForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            remember: false,
            submitted: false,
            errors: {
                email: true,
                password: true,
            },
            isLoading: false,
            show_password: false,
            error: null
        };
    }

    render() {
        return (
            <SofomLoginFormWrapper>
                <LoginContainer>
                    <LoginImageContainer>
                        <LoginImageOverContainer />
                        <LoginImageTextContainer>
                            <LoginImageText>
                                {Strings.AFISOFOM_LOGIN_IMAGE}
                            </LoginImageText>
                        </LoginImageTextContainer>
                    </LoginImageContainer>
                    <LoginFormContainer>
                        <LoginFormTitle>
                            {Strings.AFISOFOM_LOGIN_TITLE}
                        </LoginFormTitle>
                        <form noValidate autoComplete="off">
                            <br/>
                            <CustomInput label={Strings.EMAIL + ' *'}
                                         name="email"
                                         id="email"
                                         value={this.state.email}
                                         color={Colors.PRIMARY_AFI_SOFOM}
                                         required={true}
                                         submitted={this.state.submitted}
                                         onKeyDown={event => {if(event.key === "Enter") this.handleSignup() }}
                                         invalid={this.state.errors['email']}
                                         onChange={(val, v) => this.updateState('email', val, v)}
                                         type="email" />
                            <br/>
                            <CustomInput label={Strings.PASSWORD + ' *'}
                                         name="password"
                                         id="password"
                                         value={this.state.password}
                                         required={true}
                                         color={Colors.PRIMARY_AFI_SOFOM}
                                         submitted={this.state.submitted}
                                         invalid={this.state.errors['password']}
                                         onKeyDown={event => {if(event.key === "Enter") this.handleSignup() }}
                                         onChange={(val, v) => this.updateState('password', val, v)}
                                         inputProps={{
                                             endAdornment: (
                                                 <InputAdornment position="end">
                                                     <IconButton tabIndex="-1"  onClick={() => this.setState({
                                                         show_password: !this.state.show_password
                                                     })}>
                                                         {
                                                             this.state.show_password
                                                                 ? <MdVisibility />
                                                                 : <MdVisibilityOff />
                                                         }
                                                     </IconButton>
                                                 </InputAdornment>
                                             )
                                         }}
                                         type={this.state.show_password ? 'text' : 'password'} />
                            <br/>
                            <br/>
                            <CustomCheckbox name="remember"
                                            required={false}
                                            submitted={this.state.submitted}
                                            background={Colors.PRIMARY_AFI_SOFOM}
                                            errorMsg=""
                                            onChange={(v) => this.updateState("remember", v, v)}
                                            label={
                                                <LoginFormCheckbox>
                                                    {Strings.REMEMBER_USER}
                                                </LoginFormCheckbox>
                                            }
                                            id="remember"/>
                            <br/>
                            <br/>
                            <ForgotPasswordContainer>
                                <CustomButton label={Strings.SIGNIN}
                                              align="center"
                                              onClick={() => this.handleSignup()}
                                              color={Colors.WHITE}
                                              isLoading={this.state.isLoading}
                                              fullWidth={false}
                                              background={Colors.PRIMARY_AFI_SOFOM}
                                              font={Fonts.BOLD} />
                                {
                                    this.state.error &&
                                    <LoginFormError>
                                        <br/>
                                        {this.state.error}
                                    </LoginFormError>
                                }
                                <br/>
                                <br/>
                                <LoginFormSub>{Strings.AFISOFOM_LOGIN_SUB}</LoginFormSub>
                                <LoginFormLink href={"/afisofom/solicitud"}>
                                    {Strings.AFISOFOM_LOGIN_SUB_2}
                                </LoginFormLink>
                                <br/>
                                <br/>
                                <br/>
                                <LoginFormLink onClick={() => this.props.onForgotPassword()}>
                                    {Strings.FORGOT_PASSWORD}
                                </LoginFormLink>
                            </ForgotPasswordContainer>
                        </form>
                    </LoginFormContainer>
                </LoginContainer>
            </SofomLoginFormWrapper>
        );
    }

    /**
     * Update state vars
     * @author @leonard_lib
     * @date 29/07/2020
     * @param prop
     * @param value
     * @param valid
     */
    updateState(prop, value, valid) {
        const state = this.state;
        const errors = state.errors;

        state[prop] = value;
        errors[prop] = !valid;
        state.errors = errors;

        this.setState(state);
    };

    /**
     * Check if data is completed
     * @author @leonard_lib
     * @date 29/07/2020
     * @returns {boolean|boolean}
     */
    checkCompleted() {
        return !this.state.errors['email']
            && !this.state.errors['password'];
    }

    /**
     * Handle signup button click
     * @author @leonard_lib
     * @date 29/07/2020
     */
    handleSignup() {
        this.setState({
            submitted: true,
            error: null
        }, () => {
            if (this.checkCompleted()) {
                this.setState({
                    isLoading: true
                }, () => {
                    this.makeCall();
                })
            }
        });
    };

    /**
     * Make request to login
     * @author @leonard_lib
     * @date 29/07/2020
     */
    makeCall() {
        login(
            this.state.email,
            this.state.password
        ).then(isConfirmed => {
            if(isConfirmed) {
                this.getData();
            }
            else {
                setClientData({email: this.state.email})
                localStorage.setItem("aux_password", this.state.password);
                this.props.onUserNotConfirmed(this.state.email)
            }
        }).catch(error => {
            this.setState({
                isLoading: false,
                error: error
            });
        });
    }

    getData() {
        getAfisofomClientDataByEmail(
            this.state.email
        ).then(user => {
            setClientData(user);
            this.props.onLogin(user);
        }).catch(error => {
            this.setState({
                isLoading: false,
                error: error
            });
        });
    }
}

SofomLoginForm.propTypes = {
    onLogin: PropTypes.func.isRequired,
    onForgotPassword: PropTypes.func.isRequired,
};


export default SofomLoginForm;
