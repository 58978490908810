import React, {useEffect, useState} from "react";
import {getClients} from "../../data/services/admins/clients";
import Strings from "../../assets/strings";
import PropTypes from 'prop-types';
import GeneralTable from "../general-table";

const ClientsOfAdvisorTable = ({ id }) => {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getAfisofomClientsList = async () => {
        try {
            setIsLoading(true);
            const res = await getClients(true, 0, true, '', true, id);
            initData(res.clients);
        } catch (error) {
            console.log(error);
        }
    }

    const getAfiserviciosClientsList = async () => {
        try {
            setIsLoading(true);
            const res = await getClients(false, 0, true, '', true, id);
            initData(res.clients);
        } catch (error) {
            console.log(error);
        }
    }

    const initData = (clients) => {
        const columnsAux = [
            { title: "#", field: "index" },
            { title: Strings.NAME, field: "name" },
            { title: Strings.EMAIL, field: "email" }
        ];
        const rowsAux = rows;

        if (clients.length > 0) {
            clients.forEach((client, index) => {
                rowsAux.push({
                    index: index + 1,
                    id: client.id,
                    name: client.full_name,
                    email: client.email
                });
            });
        }

        setRows(rowsAux);
        setColumns(columnsAux);
        setIsLoading(false);
    };

    // on mount
    useEffect( () => {
        const getData = async () => {
            await getAfisofomClientsList();
            await getAfiserviciosClientsList();
        };
        getData();
        // eslint-disable-next-line
    }, []);

    return (
        <GeneralTable rows={rows}
                      showToolbar={false}
                      columns={columns}
                      isLoading={isLoading} />
    );
};

ClientsOfAdvisorTable.propTypes = {
    id: PropTypes.string.isRequired
};

export default ClientsOfAdvisorTable;
