import React from "react";
import LayoutAdmins from "../../components/layout-admins";
import AdminConfirmSignupForm from "../../components/admins/confirm-signup-form";
import LayoutClients from "../../components/layout-clients";
import {useChangeTitleFavicon} from "../../hooks/useChangeTitle";
import Strings from "../../assets/strings";

const AdminConfirmSignUp = ({ history, location }) => {
    const params = new URLSearchParams(location.search);
    const email = params.get('email');

    useChangeTitleFavicon(Strings.TITLE_CONFIRM_SIGNUP);
    return (
        <LayoutAdmins tabAdminSelected={1}
                      history={history}>
            <div className="container">
                <AdminConfirmSignupForm email={email}
                                        onConfirm={
                                            emailRestore => history.push('/admin/restaurar-contrasena?email=' + emailRestore)
                                        } />
            </div>
        </LayoutAdmins>
    );
};

export default AdminConfirmSignUp;
