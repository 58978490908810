import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {DivMargin, DivMarginBottom} from "./shared-components/container-styles";
import CustomInput from "./input";
import Strings from "../assets/strings";
import Colors from "../assets/colors";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {MdSend} from "react-icons/md/index";
import ChatMessageComponent from "./chat-message";
import moment from 'moment';
import Chat, {ChatMessage} from "../data/models/chat";
import PropTypes from 'prop-types';
import AdminClient from "../data/models/admin";
import Client from "../data/models/client";
import LoadingIndicator from "./loading-indicator";
import {createChatCall, getChatCall, sendEmailCall, updateChatCall} from "../data/services/chat";
import {DefaultText} from "./shared-components/text-styles";

const MessagesScreen = styled.div`
    height: calc(100vh - 500px);
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    
    &::-webkit-scrollbar {
        display: none;
    }
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        height: calc(100vh - 300px);
    }
`;

const ChatForm = ({ fromAdmin, advisor, client, onChatCreated, isFromAfisofom, showTitle, title }) => {
    const [chat, setChat] = useState(new Chat());
    const [text, setText] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const messagesEnd = useRef(null);
    // on mount
    useEffect(() => {
        getChat();
        // eslint-disable-next-line
    }, []);

    const getChat = async () => {
        const id = isFromAfisofom
            ? client.afisofom_credit.chat_id
            : client.afiservicios_form.chat_id;

        if (id !== null) {
            const res = await getChatCall(id);
            setChat(res);
            moveDown();
        }
    };

    const moveDown = () => {
        messagesEnd.current.scrollIntoView({ behavior: 'smooth' });
    };

    const onSend = () => {
        setSubmitted(true);
        setIsSending(true);

        if (text?.length > 0) {
            pushMessage();
        } else {
            setIsSending(false);
        }
    };

    const pushMessage = () => {
        const auxMessage = new ChatMessage();
        const auxChat = chat;

        if (fromAdmin) {
            auxMessage.client_id = null;
            auxMessage.client = null;
            auxMessage.advisor_id = advisor.id;
            auxMessage.advisor = advisor.full_name;
        } else {
            auxMessage.client_id = client.id;
            auxMessage.client = client.full_name;
            auxMessage.advisor_id = null;
            auxMessage.advisor = null;
        }

        auxMessage.sent_date = moment().format(Strings.FORMAT_DATE_CHAT);
        auxMessage.sent_date_text = moment().calendar();
        auxMessage.text = text;

        auxChat.messages.push(auxMessage);
        setChat(auxChat);
        setText('');

        updateOrCreateChat();
    };

    const updateOrCreateChat = async () => {
        try {
            const from = fromAdmin ? advisor.name : client.name
            const to = !fromAdmin ? advisor.email : client.email
            if (chat.id !== null) {
                await updateChatCall(chat);
                await sendEmailCall(from, to, text)
            } else {
                const newChat = await createChatCall(chat);
                await sendEmailCall(from, to, text)
                setChat(newChat);
                onChatCreated(newChat.id);
            }

            setIsSending(false);
            moveDown();
        } catch (error) {
            console.log(error);
        }
    };

    const onKeyDown = (event) => {
        if (event.keyCode === 13) {
            onSend();
        }
    };

    return (
        <div>
            {
                isSending &&
                <DivMarginBottom>
                    <LoadingIndicator />
                </DivMarginBottom>
            }
            {
                showTitle &&
                <DefaultText>
                    {title}
                </DefaultText>
            }
            <DivMargin>
                <MessagesScreen>
                    {
                        chat?.messages?.length > 0 && chat.messages.map((msg, key) =>
                            <ChatMessageComponent message={msg.text}
                                                  key={key}
                                                  color={msg.client_id ? Colors.DARK_GRAY : Colors.WHITE}
                                                  background={msg.client_id ? Colors.LIGHT_GRAY : Colors.DARK_GRAY}
                                                  from={msg.client_id ? msg.client : msg.advisor}
                                                  secondaryColor={msg.client_id ? Colors.GRAY : Colors.WHITE}
                                                  isSending={isSending}
                                                  align={msg.client_id ? 'left' : 'right'}
                                                  time={msg.sent_date_text} />
                        )
                    }
                    <div ref={messagesEnd} />
                </MessagesScreen>
            </DivMargin>
            <DivMargin>
                <CustomInput placeholder={Strings.NEW_MESSAGE}
                             onlyPlaceholder={true}
                             required={true}
                             value={text}
                             color={Colors.BLACK}
                             submitted={submitted}
                             onKeyDown={event => onKeyDown(event)}
                             onChange={val => setText(val)}
                             inputProps={{
                                 endAdornment: (
                                     <InputAdornment position="end">
                                         <IconButton tabIndex="-1" onClick={() => onSend()}>
                                             <MdSend />
                                         </IconButton>
                                     </InputAdornment>
                                 )
                             }}
                             type="text" />
            </DivMargin>
        </div>
    );
};

ChatForm.propTypes = {
    fromAdmin: PropTypes.bool.isRequired,
    advisor: PropTypes.instanceOf(AdminClient),
    client: PropTypes.object,
    onChatCreated: PropTypes.func.isRequired,
    isFromAfisofom: PropTypes.bool.isRequired,
    showTitle: PropTypes.bool,
    title: PropTypes.string.isRequired
};

ChatForm.defaultProps = {
    advisor: new AdminClient(),
    client: new Client(),
    showTitle: false
};

export default ChatForm;
