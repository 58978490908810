export const createChatCustom = /* GraphQL */ `
  mutation CreateChat(
    $input: CreateChatInput!
    $condition: ModelChatConditionInput
  ) {
    createChat(input: $input, condition: $condition) {
      id
      messages {
        advisor_id
        advisor
        client_id
        client
        text
        sent_date
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChatCustom = /* GraphQL */ `
  mutation UpdateChat(
    $input: UpdateChatInput!
    $condition: ModelChatConditionInput
  ) {
    updateChat(input: $input, condition: $condition) {
      id
      messages {
        advisor_id
        advisor
        client_id
        client
        text
        sent_date
      }
      createdAt
      updatedAt
    }
  }
`;
export const getChatCustom = /* GraphQL */ `
  query GetChat($id: ID!) {
    getChat(id: $id) {
      id
      messages {
        advisor_id
        advisor
        client_id
        client
        text
        sent_date
      }
      createdAt
      updatedAt
    }
  }
`;