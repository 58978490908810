import React from "react";
import LayoutClients from "../../components/layout-clients";
import ServiciosForgotPasswordForm from "../../components/afiservicios/forgot-password-form";
import {useChangeTitleFavicon} from "../../hooks/useChangeTitle";
import Strings from "../../assets/strings";

const AfiserviciosForgotPassword = ({ history }) => {
    useChangeTitleFavicon(Strings.TITLE_FORGOT_PASSWORD, "afiservicios");
    return (
        <LayoutClients history={history} site="servicios">
            <div className="container">
                <ServiciosForgotPasswordForm
                    onRecover={email =>
                        history.push('/afiservicios/restaurar-contrasena?email=' + email)
                    }
                />
            </div>
        </LayoutClients>
    );
}

export default AfiserviciosForgotPassword;
