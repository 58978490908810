import {API, Auth, graphqlOperation, I18n} from "aws-amplify";
import PasswordValidator from "password-validator";
import Strings from "../../assets/strings";
import {userExists} from "../queries/general";
import {attachUserToGroup} from "../../graphql/queries";

const AWS_USER_LOCAL = 'user_aws_local';
const CLIENT_USER_LOCAL = 'user_local_data';

/**
 * Make request to login
 * @author @leonard_lib
 * @date 29/07/2020
 * @param email
 * @param password
 * @returns {Promise<unknown>}
 */
export const login = (email, password) => {
    return new Promise((resolve, reject) => {
        Auth.signIn(
            email,
            password
        ).then(user => {
            localStorage.setItem(AWS_USER_LOCAL, JSON.stringify(user));
            resolve(true);
        }).catch(err => {
            if(err.code === "UserNotConfirmedException") {
                localStorage.setItem(AWS_USER_LOCAL, JSON.stringify({email}))
                resolve(false);
            }
            console.log('LOGIN ERROR: ', err);
            reject(I18n.get(err.message));
        });
    });
};

export const logout = () => {
    return new Promise((resolve, reject) => {
        Auth.signOut().then(res => {
            removeLocalData();
            resolve(true);
        }).catch(err => {
            console.log('LOGOUT ERROR: ', err);
            reject(I18n.get(err.message));
        });
    });
};

/**
 * Create new user on cognito
 * @author @leonard_lib
 * @date 05/08/2020
 * @param email
 * @param password
 * @param keep_in_local
 * @returns {Promise<unknown>}
 */
export const register = (email, password, keep_in_local = true) => {
    return new Promise((resolve, reject) => {
        Auth.signUp({
            username: email,
            password: password
        }).then(user => {
            if (keep_in_local) {
                localStorage.setItem(AWS_USER_LOCAL, JSON.stringify(user));
            }
            resolve(user);
        }).catch(err => {
            console.log('SIGNUP ERROR: ', err);
            reject(I18n.get(err.message));
        });
    });
};

/**
 * Confirm register on cognito
 * @author @leonard_lib
 * @date 05/08/2020
 * @param email
 * @param code
 * @returns {Promise<unknown>}
 */
export const confirmSignup = (email, code) => {
    return new Promise((resolve, reject) => {
        Auth.confirmSignUp(
            email,
            code
        ).then(() => {
            resolve(true);
        }).catch(err => {
            console.log('CONFIRM SIGNUP ERROR: ', err);
            reject(I18n.get(err.message));
        });
    });
};

/**
 * Request code for restore password
 * @author @leonard_lib
 * @date 29/07/2020
 * @param email
 * @returns {Promise<unknown>}
 */
export const forgotPassword = (email) => {
    return new Promise((resolve, reject) => {
        Auth.forgotPassword(
            email
        ).then(() => {
            resolve(true);
        }).catch(err => {
            console.log('FORGOT PASSWORD ERROR: ', err);
            reject(I18n.get(err.message));
        });
    });
};

/**
 * Restore user password
 * @author @leonard_lib
 * @date 29/07/2020
 * @param email
 * @param code
 * @param password
 * @returns {Promise<unknown>}
 */
export const restorePassword = (email, code, password) => {
    return new Promise((resolve, reject) => {
        Auth.forgotPasswordSubmit(
            email,
            code,
            password
        ).then(data => {
            resolve(true);
        }).catch(err => {
            console.log('RESTORE PASSWORD ERROR: ', err);
            reject(I18n.get(err.message));
        });
    });
};

/**
 * Get user data from local or from cognito
 * @author @leonard_lib
 * @date 29/07/2020
 * @param local
 * @returns {Promise<unknown>}
 */
export const getAuthenticatedUser = (local = true) => {
    if (local) {
        const data = localStorage.getItem(AWS_USER_LOCAL);

        if (data != null) {
            return JSON.parse(data);
        }

        return null;
    } else {
        return new Promise((resolve, reject) => {
            Auth.currentAuthenticatedUser().then(user => {
                resolve(user);
            }).catch(err => {
                console.log('GET CURRENT USER ERROR: ', err);
                reject(I18n.get(err.message));
            });
        });
    }
}

export const userIsAuthenticated = () => {
    const data = localStorage.getItem(AWS_USER_LOCAL);
    return data != null;
};

/**
 * Set new password for AWS console users
 * @author @leonard_lib
 * @date 29/07/2020
 * @param user
 * @param password
 * @returns {Promise<unknown>}
 */
export const completeNewPassword = (user, password) => {
    return new Promise((resolve, reject) => {
        Auth.completeNewPassword(
            user,
            password,
            {}
        ).then(user_two => {
            resolve(user_two);
        }).catch(err => {
            console.log('NEW PASS USER ERROR: ', err);
            reject(I18n.get(err.message));
        });
    });
}

/**
 * Check if password is valid
 * @author @leonard_lib
 * @date 29/07/2020
 * @param password
 * @returns {boolean | string[]}
 */
export const isValidPassword = (password) => {
    const schema = new PasswordValidator();
    schema.is().min(8)
        .has().uppercase()
        .has().lowercase()
        .has().digits()
        .has().symbols();
    return schema.validate(password);
};

/**
 * Check if user exists on database
 * @author @leonard_lib
 * @date 05/08/2020
 * @param email
 * @returns {Promise<unknown>}
 */
export const clientExists = (email) => {
    return new Promise((resolve, reject) => {
        API.graphql(graphqlOperation(userExists, {
            email: email
        })).then(res => {
            resolve(res.data.listClients.items.length > 0);
        }).catch(err => {
            console.log('USER EXISTS ERROR: ', err);
            reject(Strings.USER_EXISTS_ERROR);
        });
    });
};

/**
 * Keep client on local
 * @author @leonard_lib
 * @date 05/08/2020
 * @param client
 */
export const setClientData = (client) => {
		const currentClient = getClientData();
		if (currentClient){
			if (currentClient.afiservicios_form && !client.afiservicios_form) {
				client.afiservicios_form = currentClient.afiservicios_form;
			}

			if (currentClient.afisofom_credit && !client.afisofom_credit) {
				client.afisofom_credit = currentClient.afisofom_credit;
			}
		}
    client = JSON.stringify(client);
    localStorage.setItem(CLIENT_USER_LOCAL, client);
};

/**
 * Get client from local
 * @author @leonard_lib
 * @date 05/08/2020
 * @returns {null|any}
 */
export const getClientData = () => {
    const data = localStorage.getItem(CLIENT_USER_LOCAL);
    if (data != null) {
        return JSON.parse(data);
    }
    return null;
};

export const removeLocalData = () => {
    localStorage.removeItem(AWS_USER_LOCAL);
    localStorage.removeItem(CLIENT_USER_LOCAL);
};

/**
 * Add user to cognito group
 * @author @leonard_lib
 * @date 05/08/2020
 * @param email
 * @param group
 * @returns {Promise<unknown>}
 */
export const addUserToGroup = (email, group) => {
    return new Promise((resolve, reject) => {
        API.graphql(graphqlOperation(attachUserToGroup, {
            email: email,
            group: group
        })).then(res => {
            resolve(true);
        }).catch(err => {
            console.log('ADD USER TO GROUP ERROR: ', err);
            reject(Strings.ADD_USER_TO_GROUP_ERROR);
        });
    });
};

export const resendCode = async (email) => {
    return new Promise((resolve, reject) => {
        Auth.resendSignUp(
            email
        ).then(() => {
            resolve(true);
        }).catch(err => {
            console.log('RESEND CODE ERROR: ', err);
            reject(I18n.get(err.message));
        });
    });
};
