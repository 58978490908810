import React, { useState } from "react";
import MaterialTable from "material-table";
import PropTypes from "prop-types";
import {
  MdAdd,
  MdCheck,
  MdClear,
  MdDelete,
  MdChevronRight,
  MdEdit,
  MdSave,
  MdFilter,
  MdFirstPage,
  MdLastPage,
  MdChevronLeft,
  MdArrowDownward,
  MdRemove,
  MdViewColumn,
  MdSearch,
} from "react-icons/md";
import Strings from "../assets/strings";
import Colors from "../assets/colors";
import CustomCheckbox from "./checkbox";
import styled from "styled-components";
import Fonts from "../assets/fonts";
import CustomInput from "./input";
import { IconButton, InputAdornment } from "@material-ui/core";
import SmallCustomButton from "./small-button";

const tableIcons = {
  Add: React.forwardRef((props, ref) => <MdAdd {...props} ref={ref} />),
  Check: React.forwardRef((props, ref) => <MdCheck {...props} ref={ref} />),
  Clear: React.forwardRef((props, ref) => <MdClear {...props} ref={ref} />),
  Delete: React.forwardRef((props, ref) => <MdDelete {...props} ref={ref} />),
  DetailPanel: React.forwardRef((props, ref) => (
    <MdChevronRight {...props} ref={ref} />
  )),
  Edit: React.forwardRef((props, ref) => <MdEdit {...props} ref={ref} />),
  Export: React.forwardRef((props, ref) => <MdSave {...props} ref={ref} />),
  Filter: React.forwardRef((props, ref) => <MdFilter {...props} ref={ref} />),
  FirstPage: React.forwardRef((props, ref) => (
    <MdFirstPage {...props} ref={ref} />
  )),
  LastPage: React.forwardRef((props, ref) => (
    <MdLastPage {...props} ref={ref} />
  )),
  NextPage: React.forwardRef((props, ref) => (
    <MdChevronRight {...props} ref={ref} />
  )),
  PreviousPage: React.forwardRef((props, ref) => (
    <MdChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: React.forwardRef((props, ref) => (
    <MdClear {...props} ref={ref} />
  )),
  SortArrow: React.forwardRef((props, ref) => (
    <MdArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: React.forwardRef((props, ref) => (
    <MdRemove {...props} ref={ref} />
  )),
  ViewColumn: React.forwardRef((props, ref) => (
    <MdViewColumn {...props} ref={ref} />
  )),
};

const tableLocalization = {
  pagination: {
    labelDisplayedRows: Strings.TABLE_DATA_COUNT,
    labelRowsSelect: Strings.TABLE_ROWS,
    labelRowsPerPage: Strings.TABLE_ROWS_PER_PAGE,
    firstAriaLabel: Strings.TABLE_FIRST_PAGE,
    firstTooltip: Strings.TABLE_FIRST_PAGE,
    previousAriaLabel: Strings.TABLE_PREVIOUS_PAGE,
    previousTooltip: Strings.TABLE_PREVIOUS_PAGE,
    nextAriaLabel: Strings.TABLE_NEXT_PAGE,
    nextTooltip: Strings.TABLE_NEXT_PAGE,
    lastAriaLabel: Strings.TABLE_LAST_PAGE,
    lastTooltip: Strings.TABLE_LAST_PAGE,
  },
  body: {
    emptyDataSourceMessage: Strings.TABLE_NO_DATA,
  },
};

const GeneralTableWrapper = styled.div`
  max-width: 100% !important;

  .MuiTableCell-root {
    border-top: none;
    border-bottom: none;
  }

  .MuiCircularProgress-colorPrimary {
    color: ${Colors.DARK_GRAY} !important;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    max-width: calc(100vw - 60px) !important;
  }
`;

const ToolbarContainer = styled.div`
  padding: 0 20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    padding-top: 20px;
    grid-template-columns: 1fr;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    justify-content: flex-start;

    .search-w {
      width: 100%;
    }
  }
`;

const CheckboxLabel = styled.span`
  font-family: ${Fonts.MEDIUM};
  font-size: 0.9em;
  color: ${Colors.DARK_GRAY};
`;

const CustomToolbar = ({
  showCheckbox,
  showSearch,
  onChangeShowAll,
  onChangeSearch,
  showAllChecked,
  searchValue,
  onNewClick,
  showNewButton,
}) => {
  const [value, setValue] = useState(searchValue);

  const onKeyDown = (code) => {
    if (code === 13) {
      onChangeSearch(value);
    }
  };

  return (
    <ToolbarContainer>
      <LeftContainer>
        {showCheckbox && (
          <CustomCheckbox
            label={
              <CheckboxLabel>{Strings.SHOW_DECLINED_APPROVED}</CheckboxLabel>
            }
            checked={showAllChecked}
            name="show-declined-approved"
            background={Colors.BLACK}
            id="show-declined-approved"
            onChange={(checked) => onChangeShowAll(checked)}
          />
        )}
        {showNewButton && (
          <SmallCustomButton
            label={Strings.NEW}
            onClick={() => onNewClick()}
            color={Colors.WHITE}
            background={Colors.DARK_GRAY}
            font={Fonts.BOLD}
          />
        )}
      </LeftContainer>
      <RightContainer>
        {showSearch && (
          <CustomInput
            onChange={(val) => setValue(val)}
            color={Colors.BLACK}
            className="search-w"
            value={value}
            onKeyDown={(event) => onKeyDown(event.keyCode)}
            placeholder={Strings.SEARCH}
            onlyPlaceholder={true}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    tabIndex="-1"
                    onClick={() => onChangeSearch(value)}
                  >
                    <MdSearch />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            name="search-input"
            type="text"
            id="search-input"
          />
        )}
      </RightContainer>
    </ToolbarContainer>
  );
};

const headerStyle = {
  color: Colors.DARK_GRAY,
  fontSize: "0.9em",
  fontFamily: Fonts.BOLD,
  borderTop: "solid 2px " + Colors.LIGHT_GRAY,
  borderBottom: "solid 2px " + Colors.LIGHT_GRAY,
};

const rowStyleGray = {
  backgroundColor: Colors.LIGHT_GRAY,
  color: Colors.DARK_GRAY,
  fontSize: "0.9em",
  fontFamily: Fonts.MEDIUM,
};

const rowStyleWhite = {
  backgroundColor: Colors.WHITE,
  color: Colors.DARK_GRAY,
  fontSize: "0.9em",
  fontFamily: Fonts.MEDIUM,
};

const CustomFooter = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`;

const CustomLink = styled.a`
  font-family: ${Fonts.MEDIUM};
  font-size: 0.9em;
  color: ${Colors.DARK_GRAY};
  text-decoration: underline;
  cursor: pointer;
`;

const GeneralTable = ({
  columns,
  rows,
  title,
  showCheckbox,
  showSearch,
  onChangeShowAll,
  onChangeSearch,
  isLoading,
  maxHeight,
  onLoadMore,
  showLoadMore,
  showAllChecked,
  searchValue,
  showToolbar,
  onNewClick,
  showNewButton,
  headerBackground,
}) => {
  return (
    <GeneralTableWrapper>
      <MaterialTable
        icons={tableIcons}
        localization={tableLocalization}
        columns={columns}
        isLoading={isLoading}
        data={rows}
        title={title}
        options={{
          headerStyle: {
            ...headerStyle,
            backgroundColor: headerBackground,
          },
          rowStyle: (data, index) => {
            return index % 2 ? rowStyleGray : rowStyleWhite;
          },
          padding: "dense",
          paging: showLoadMore,
          thirdSortClick: false,
          toolbar: showToolbar,
          maxBodyHeight: maxHeight,
        }}
        components={{
          Toolbar: (props) => (
            <CustomToolbar
              showCheckbox={showCheckbox}
              showAllChecked={showAllChecked}
              searchValue={searchValue}
              onChangeSearch={onChangeSearch}
              onChangeShowAll={onChangeShowAll}
              showNewButton={showNewButton}
              onNewClick={onNewClick}
              showSearch={showSearch}
            />
          ),
          Pagination: (props) => (
            <CustomFooter>
              <CustomLink onClick={() => onLoadMore()}>
                {Strings.LOAD_MORE}
              </CustomLink>
            </CustomFooter>
          ),
        }}
      />
    </GeneralTableWrapper>
  );
};

GeneralTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  title: PropTypes.string,
  showCheckbox: PropTypes.bool,
  showSearch: PropTypes.bool,
  onChangeSearch: PropTypes.func,
  onChangeShowAll: PropTypes.func,
  isLoading: PropTypes.bool,
  maxHeight: PropTypes.number,
  showLoadMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
  showAllChecked: PropTypes.bool,
  showToolbar: PropTypes.bool,
  showNewButton: PropTypes.bool,
  searchValue: PropTypes.string,
  headerBackground: PropTypes.string,
  onNewClick: PropTypes.func,
};

GeneralTable.defaultProps = {
  title: "",
  searchValue: "",
  showCheckbox: true,
  showSearch: true,
  onChangeSearch: () => {},
  onChangeShowAll: () => {},
  isLoading: false,
  maxHeight: 500,
  showLoadMore: false,
  showAllChecked: false,
  showToolbar: true,
  showNewButton: false,
  headerBackground: Colors.WHITE,
  onLoadMore: () => {},
  onNewClick: () => {},
};

export default GeneralTable;
