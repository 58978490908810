import React from "react";
import "../assets/styles/global.css"
import Dashboard from "../components/dashboard";
import {getAuthenticatedUser, getClientData} from "../data/services/auth";

const GeneralDashboard = ({history}) => {
  const user = getAuthenticatedUser()
  const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
  const client = getClientData()

  if (groups && (groups.includes("admins") || groups.includes("advisers"))) {
    history.push('/admin/clientes')
  }
  else if (client.afisofom_credit && client.afisofom_credit.amount) {
    history.push('/afisofom')
  }
  else if (client.afiservicios_form) {
    history.push('/afiservicios')
  }
  return (
      <Dashboard />
  );
};

export default GeneralDashboard;
