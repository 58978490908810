import SmallCustomButton from "./small-button";
import Strings from "../assets/strings";
import Colors from "../assets/colors";
import Fonts from "../assets/fonts";
import React from "react";
import PropTypes from 'prop-types';

const ManageSmallButton = ({ onClick }) => (
    <SmallCustomButton label={Strings.MANAGE}
                       onClick={() => onClick()}
                       color={Colors.WHITE}
                       background={Colors.BLACK}
                       font={Fonts.BOLD} />
);

ManageSmallButton.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default ManageSmallButton;
