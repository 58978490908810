/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const attachUserToGroup = /* GraphQL */ `
  query AttachUserToGroup($email: String!, $group: String!) {
    attachUserToGroup(email: $email, group: $group) {
      success
      info
      error
    }
  }
`;
export const updateClientBasic = /* GraphQL */ `
  query UpdateClientBasic($client: BasicClientInput!, $update_email: Boolean) {
    updateClientBasic(client: $client, update_email: $update_email) {
      success
      info
      error
    }
  }
`;
export const updateSitesConfiguration = /* GraphQL */ `
  query UpdateSitesConfiguration($data: String!, $prod: Boolean) {
    updateSitesConfiguration(data: $data, prod: $prod) {
      success
      info
      error
    }
  }
`;
export const emailAdminsUserRegister = /* GraphQL */ `
  query EmailAdminsUserRegister(
    $isSofom: Boolean!
    $username: String!
    $user_email: String!
  ) {
    emailAdminsUserRegister(
      isSofom: $isSofom
      username: $username
      user_email: $user_email
    ) {
      success
      info
      error
    }
  }
`;
export const adminGetClients = /* GraphQL */ `
  query AdminGetClients(
    $from_afisofom: Boolean!
    $by_advisor: Boolean!
    $advisor_id: String
    $limit: Int
    $show_all: Boolean
    $search: String
  ) {
    adminGetClients(
      from_afisofom: $from_afisofom
      by_advisor: $by_advisor
      advisor_id: $advisor_id
      limit: $limit
      show_all: $show_all
      search: $search
    ) {
      success
      info
      error
    }
  }
`;
export const adminGetAdvisors = /* GraphQL */ `
  query AdminGetAdvisors($role: Int!, $limit: Int, $search: String) {
    adminGetAdvisors(role: $role, limit: $limit, search: $search) {
      success
      info
      error
    }
  }
`;
export const adminSendAdvisorInvite = /* GraphQL */ `
  query AdminSendAdvisorInvite($email: String!, $url: String!) {
    adminSendAdvisorInvite(email: $email, url: $url) {
      success
      info
      error
    }
  }
`;
export const afisofomSendAttachments = /* GraphQL */ `
  query AfisofomSendAttachments($email: String!) {
    afisofomSendAttachments(email: $email) {
      success
      info
      error
    }
  }
`;
export const afisofomUpdateCredit = /* GraphQL */ `
  query AfisofomUpdateCredit(
    $id: ID!
    $email: String!
    $afisofom_credit: AfisofomCreditInput
  ) {
    afisofomUpdateCredit(
      id: $id
      email: $email
      afisofom_credit: $afisofom_credit
    ) {
      success
      info
      error
    }
  }
`;
export const afiserviciosUpdateForm = /* GraphQL */ `
  query AfiserviciosUpdateForm(
    $id: ID!
    $email: String!
    $afiservicios_form: AfiserviciosFormInput
  ) {
    afiserviciosUpdateForm(
      id: $id
      email: $email
      afiservicios_form: $afiservicios_form
    ) {
      success
      info
      error
    }
  }
`;
export const afisofomEmailReqConfirm = /* GraphQL */ `
  query AfisofomEmailReqConfirm(
    $email_asesor: String!
    $username: String!
    $user_email: String!
  ) {
    afisofomEmailReqConfirm(
      email_asesor: $email_asesor
      username: $username
      user_email: $user_email
    ) {
      success
      info
      error
    }
  }
`;
export const chatSendEmail = /* GraphQL */ `
  query ChatSendEmail($from: String!, $to: String!, $message: String!) {
    chatSendEmail(from: $from, to: $to, message: $message) {
      success
      info
      error
    }
  }
`;
export const updateSearchTerm = /* GraphQL */ `
  query UpdateSearchTerm($d: String) {
    updateSearchTerm(d: $d) {
      success
      info
      error
    }
  }
`;
export const syncClients = /* GraphQL */ `
  query SyncClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        lastname
        mother_lastname
        phone
        email
        is_moral_person
        moral_person {
          business_name
        }
        address {
          street
          colony
          city
          state
          country
          zipcode
        }
        afisofom_credit {
          stage
          amount
          term
          type
          rateType
          interest
          advisor_id
          advisor
          chat_id
          request_date
          is_requested
          decline_reason
          afisofom_credit_form_key
          credit_buro_key
          photo_id_front_key
          photo_id_reverse_key
        }
        afiservicios_form {
          stage
          type
          advisor_id
          advisor
          chat_id
          comments
          is_requested
          request_date
        }
        admin {
          roles
        }
        search_term
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      name
      lastname
      mother_lastname
      phone
      email
      is_moral_person
      moral_person {
        business_name
      }
      address {
        street
        colony
        city
        state
        country
        zipcode
      }
      afisofom_credit {
        stage
        amount
        term
        type
        rateType
        interest
        advisor_id
        advisor
        chat_id
        request_date
        is_requested
        decline_reason
        annexes {
          key
          name
        }
        amortization_calendar {
          period
          amount
          payment_date
          completed
          file_key
        }
        afisofom_credit_form_key
        credit_buro_key
        photo_id_front_key
        photo_id_reverse_key
      }
      afiservicios_form {
        stage
        type
        advisor_id
        advisor
        chat_id
        comments
        is_requested
        request_date
      }
      admin {
        roles
      }
      search_term
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        lastname
        mother_lastname
        phone
        email
        is_moral_person
        moral_person {
          business_name
        }
        address {
          street
          colony
          city
          state
          country
          zipcode
        }
        afisofom_credit {
          stage
          amount
          term
          type
          rateType
          interest
          advisor_id
          advisor
          chat_id
          request_date
          is_requested
          decline_reason
          afisofom_credit_form_key
          credit_buro_key
          photo_id_front_key
          photo_id_reverse_key
        }
        afiservicios_form {
          stage
          type
          advisor_id
          advisor
          chat_id
          comments
          is_requested
          request_date
        }
        admin {
          roles
        }
        search_term
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncChats = /* GraphQL */ `
  query SyncChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        messages {
          advisor_id
          advisor
          client_id
          client
          text
          sent_date
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getChat = /* GraphQL */ `
  query GetChat($id: ID!) {
    getChat(id: $id) {
      id
      messages {
        advisor_id
        advisor
        client_id
        client
        text
        sent_date
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listChats = /* GraphQL */ `
  query ListChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messages {
          advisor_id
          advisor
          client_id
          client
          text
          sent_date
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const clientBySearchTerm = /* GraphQL */ `
  query ClientBySearchTerm(
    $search_term: String
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientBySearchTerm(
      search_term: $search_term
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        lastname
        mother_lastname
        phone
        email
        is_moral_person
        moral_person {
          business_name
        }
        address {
          street
          colony
          city
          state
          country
          zipcode
        }
        afisofom_credit {
          stage
          amount
          term
          type
          rateType
          interest
          advisor_id
          advisor
          chat_id
          request_date
          is_requested
          decline_reason
          afisofom_credit_form_key
          credit_buro_key
          photo_id_front_key
          photo_id_reverse_key
        }
        afiservicios_form {
          stage
          type
          advisor_id
          advisor
          chat_id
          comments
          is_requested
          request_date
        }
        admin {
          roles
        }
        search_term
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
