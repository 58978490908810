import React, {useEffect, useState} from "react";
import LayoutAdmins from "../../components/layout-admins";
import styled from "styled-components";
import ClientAfisofomCard from "../../components/client-afisofom-card";
import {DefaultText, InfoMsg, PageTitle} from "../../components/shared-components/text-styles";
import ClientAfiserviciosCard from "../../components/client-afiservicios-card";
import {
    useParams
} from 'react-router-dom';
import {getAfisofomClientData} from "../../data/services/afisofom/client";
import LoadingIndicator from "../../components/loading-indicator";
import Strings from "../../assets/strings";
import {getAfiserviciosClientData} from "../../data/services/afiservicios/client";
import {useChangeTitleFavicon} from "../../hooks/useChangeTitle";

const UserDataWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 10px;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }
`;

const UserDataWrapperLeft = styled.div`
    text-align: left;
`;

const UserDataWrapperRight = styled.div`
    text-align: right;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        text-align: left;
    }
`;

const ContentWrapper = styled.div`
    margin-top: 50px;
`;

const NoData = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 30px;
`;

const AdminsClientDetail = ({ history }) => {
    const { id } = useParams();
    const [clientAfisofom, setClientAfisofom] = useState(null);
    const [clientAfiservicios, setClientAfiservicios] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    // Get afisofom client by id
    const getSofomData = () => {
        setLoading(true);
        getAfisofomClientData(id).then(res => {
            setClientAfisofom(res);
            getServiciosData();
        }).catch(error => {
            setLoading(false);
            setError(error);
        });
    };

    // Get afiservicios client by id
    const getServiciosData = () => {
        setLoading(true);
        getAfiserviciosClientData(id).then(res => {
            setClientAfiservicios(res);
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            setError(error);
        })
    };

    // on mount
    useEffect(() => {
        getSofomData();
        // eslint-disable-next-line
    }, []);

    const onManage = () => {
        history.push(`/admin/clientes/${id}/gestion`);
    }
    const pageTitle = (clientAfisofom ? (clientAfisofom.is_moral_person ? clientAfisofom.moral_person.business_name :
        clientAfisofom.full_name) : "");
    useChangeTitleFavicon( pageTitle);

    return (
        <LayoutAdmins history={history}
                      showBack={true}
                      tabAdminSelected={0}>
            {
                loading &&
                <div className="container" style={{ marginTop: '130px' }}>
                    <LoadingIndicator />
                </div>
            }
            {
                error && !loading &&
                <div className="container" style={{ marginTop: '130px' }}>
                    <NoData>
                        <InfoMsg>{error}</InfoMsg>
                    </NoData>
                </div>
            }
            {
                !error && !loading && clientAfisofom &&
                <div className="container" style={{ marginTop: '130px' }}>
                    <PageTitle>
                        {
                            clientAfisofom.is_moral_person
                                ? clientAfisofom.moral_person.business_name
                                : clientAfisofom.full_name
                        }
                    </PageTitle>
                    <UserDataWrapper>
                        <UserDataWrapperLeft>
                            {
                                clientAfisofom.is_moral_person &&
                                <React.Fragment>
                                    <DefaultText>{clientAfisofom.full_name} ({Strings.LR_NAME})</DefaultText>
                                    <br/>
                                </React.Fragment>
                            }
                            <DefaultText>{clientAfisofom.email}</DefaultText>
                            <br/>
                            <DefaultText>{clientAfisofom.phone}</DefaultText>
                        </UserDataWrapperLeft>
                        <UserDataWrapperRight>
                            <DefaultText>
                                {clientAfisofom.address.string_line_1}
                            </DefaultText>
                            <br/>
                            <DefaultText>
                                {clientAfisofom.address.string_line_2}
                            </DefaultText>
                        </UserDataWrapperRight>
                    </UserDataWrapper>
                    <ContentWrapper>
                        {
                            clientAfisofom.afisofom_credit.is_requested &&
                            <ClientAfisofomCard client={clientAfisofom}
                                                onDeclined={() => getSofomData()}
                                                onApproved={() => getSofomData()}
                                                onManage={() => onManage()}
                                                fromAdmin={true}  />
                        }
                    </ContentWrapper>
                    <ContentWrapper>
                        {
                            clientAfiservicios.afiservicios_form.is_requested &&
                            <ClientAfiserviciosCard client={clientAfiservicios}
                                                    onTerminated={() => getServiciosData()}
                                                    onManage={() => onManage()}
                                                    fromAdmin={true}  />
                        }
                    </ContentWrapper>
                </div>
            }
        </LayoutAdmins>
    );
};

export default AdminsClientDetail;
