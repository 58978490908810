import React from "react";
import LayoutClients from "../../components/layout-clients";
import AfiserviciosConfirmSignupForm from "../../components/afiservicios/confirm-signup-form";
import {useChangeTitleFavicon} from "../../hooks/useChangeTitle";
import Strings from "../../assets/strings";

const AfiserviciosConfirmSignup = ({history}) => {
    useChangeTitleFavicon(Strings.TITLE_CONFIRM_SIGNUP, "afiservicios")
    return (
        <LayoutClients site="servicios"
                       history={history}
                       showPrincipalButton={false}>
            <div className="container" style={{marginTop: '0px'}}>
                <AfiserviciosConfirmSignupForm
                    onConfirm={
                        () => history.push('/afiservicios')
                    }
                    onInvalidUser={() => {
                        history.push('/afiservicios/iniciar-sesion')
                    }}
                />
            </div>
        </LayoutClients>
    );
}

export default AfiserviciosConfirmSignup;
