import React, {useEffect, useState} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
// Assets
import Fonts from "../assets/fonts";
import Colors from "../assets/colors";
import Strings from "../assets/strings";
// Components
import {DefaultText, PageTitle, ErrorText} from "./shared-components/text-styles";
import {WhiteButton} from "./shared-components/button-styles";
import BasicInfoForm from "./basic-info-form";
import {TabItem, TabsContainer, DivMargin} from "./shared-components/container-styles";
import ChatForm from "../components/chat-form";
// Models and data management
import AfiserviciosClient from "../data/models/afiservicios-client";
import AdminClient from "../data/models/admin";
import {updateClientBasicData} from "../data/services/client";
import {updateAfiserviciosForm} from "../data/services/afiservicios/client";


const CardWrapper = styled.div`
  border: solid 1px ${Colors.PRIMARY_AFI_SERVICIOS};
  padding: 30px;
  margin-top: 10px;
`;

const CardTitle = styled.span`
  font-family: ${Fonts.MEDIUM};
  font-size: 1.25em;
  color: ${Colors.PRIMARY_AFI_SERVICIOS};
  margin-top: 30px;
`;

const CardHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1em;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1em;
  }
`;

const CardHeaderBottom = styled(CardHeader) `
	padding-top: 1em;
`;

const CardHeaderItem = styled.div`
  text-align: ${props => props.textAlignment ? props.textAlignment : "center"};
  justify-self: ${props => props.gridJustify ? props.gridJustify : "inherit"};
`;

const UserAfiserviciosCard = ({client}) => {
	const [showServManage, setShowServManage] = useState(false);
	const [genInfoTabSelected, setGenInfoTabSelected] = useState(true);
	const [convoTabSelected, setConvoTabSelected] = useState(false);
	const [advisorID, setAdvisorID] = useState(null);
	const [advisorName, setAdvisorName] = useState(null);


  // on mount
	useEffect(() => {
		if (client.afiservicios_form.advisor_id){ 
			setAdvisorID(client.afiservicios_form.advisor_id);
			setAdvisorName(client.afiservicios_form.advisor);
		}
	}, [client.afiservicios_form.advisor_id, client.afiservicios_form.advisor]);


	const handleServFormSubmit = (params) => {
  	updateClientBasicData(params);
  	setShowServManage(false);
  }

  const manageServicios = () => {
  	setShowServManage(true);
  }

  const clickGenInfoTab = () => {
  	setGenInfoTabSelected(true);
  	setConvoTabSelected(false);
  }

  const clickConvoTab = () => {
  	setGenInfoTabSelected(false);
  	setConvoTabSelected(true);
  }

  const onChatCreated = async (chat_id) => {
  	client.afiservicios_form.chat_id = chat_id;
    await updateAfiserviciosForm(
        client.id,
        client.email,
        client.afiservicios_form
    );
	}

  const ChatFormPopulated = (advisorID, advisorName) => {
  	if (advisorID) {
  		const advisorInfo = { "id": advisorID,
  													"full_name": advisorName};
  		const advisor = new AdminClient(advisorInfo);

  		return(
  			<ChatForm
      		fromAdmin={false}
      		advisor={advisor}
      		client={client}
      		onChatCreated={chatId => onChatCreated(chatId)}
      		isFromAfisofom={false}
      		showTitle={true}
      		title={client.afiservicios_form.advisor}
      		/>)
  	} else {
  		return(
  			<DivMargin>
  				<ErrorText>{Strings.NO_ADVISOR_ASSIGNED_ERROR}</ErrorText>
  			</DivMargin>
  			)
  	}
  }

  return (
    <div>
      <CardTitle>{Strings.AFISERVICIOS}</CardTitle>
      <CardWrapper>
	      {showServManage &&
	      	<TabsContainer>
	      		<TabItem
	      			site="servicios"
	      			selected={genInfoTabSelected}
	      			onClick={clickGenInfoTab}>
	      				{Strings.GENERAL_INFO}
	      			</TabItem>
	      		<TabItem
	      			site="servicios"
	      			selected={convoTabSelected}
	      			onClick={clickConvoTab}>
	      				{Strings.CHAT}
	      			</TabItem>
	      	</TabsContainer>
	      }
      	
      	{showServManage &&
      		genInfoTabSelected &&
	      	<DivMargin>
		      	<BasicInfoForm
		      	onSubmit={handleServFormSubmit}
		      	canEditEmail={false}
		      	user={client} />
	      	</DivMargin>}
	      {showServManage &&
	      	convoTabSelected &&
	      	ChatFormPopulated(advisorID, advisorName)
	      	}

      	{!showServManage &&
	      	<div>
		        <CardHeader>
		          <CardHeaderItem gridJustify="left">
		            <PageTitle transform="none">{client.afiservicios_form.type}</PageTitle>
		            <br/>
		            <DefaultText>{Strings.AFISERVICIOS_TYPE}</DefaultText>
		          </CardHeaderItem>
		          
		          <CardHeaderItem gridJustify="right">
		            <PageTitle color={Colors.PRIMARY_AFI_SERVICIOS} transform="none">{client.afiservicios_form.stage}</PageTitle>
		          </CardHeaderItem>
		        </CardHeader>
		        <CardHeaderBottom>
		          <CardHeaderItem textAlignment="left">
		          	<DefaultText>{"Solicitud envíada el " + client.afiservicios_form.request_date}</DefaultText>
		          	<br/>
		            <DefaultText>{"Asesor: " + client.afiservicios_form.advisor}</DefaultText>
		          </CardHeaderItem>
		          <CardHeaderItem gridJustify="right">
		          	<WhiteButton
		          		onClick={manageServicios}
		          	>
		          		{Strings.MANAGE}
		          	</WhiteButton>
		          </CardHeaderItem>
		        </CardHeaderBottom>
	        </div>
      	}
      </CardWrapper>
    </div>
  );
};

UserAfiserviciosCard.propTypes = {
  client: PropTypes.instanceOf(AfiserviciosClient).isRequired
};

UserAfiserviciosCard.defaultProps = {
  onManage: () => {
  }
};

export default UserAfiserviciosCard;
