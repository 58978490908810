import React, { useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
// material ui
import Slider from "@material-ui/core/Slider";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
// assets and shared components
import Strings from "../../assets/strings";
import Fonts from "../../assets/fonts";
import Colors from "../../assets/colors";
import "../../assets/styles/global.css";
import { SectionTitle, ErrorText } from "../shared-components/text-styles";
import {
  SquareButton,
  DefaultButton,
} from "../shared-components/button-styles";
// file upload
import { uploadFile } from "../../data/services/s3";
import {
  updateAfisofomCredit,
  sendCreditEmail,
} from "../../data/services/afisofom/client";
import { getClientData, setClientData } from "../../data/services/auth";
import { AfisofomCredit } from "../../data/models/afisofom-client";
import CustomInput from "../input";
import crypto from "crypto";
import { FIXED_RATE, TableSection, VARIABLE_RATE } from "./form-one-main";
import creditTypeCatalog from "../../utils/credit-type-catalog";

// CONTAINER GENERAL /////////////////
const FormContainer = styled.div`
  @media only screen and (min-width: 767px) {
    widht: 100%;
    height: 100%;
    margin-top: -20px;
    padding: 1em 3em 2em;
  }
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2em;

  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

// LADO IZQUIERDO /////////////////
const SquareButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10%;
  grid-row-gap: 1em;
`;

const StyledButtonOne = styled(SquareButton)`
  grid-row: 2;
  grid-column: 1;
`;

const StyledButtonTwo = styled(SquareButton)`
  grid-row: 2;
  grid-column: 2;
`;

const MainControlGroup = styled.div`
  display: grid;
`;

const InputControlGroup = styled.div`
  @media only screen and (max-width: 767px) {
    grid-row: 1;
  }
`;

// SLIDER /////////////////
const PrettoSlider = withStyles({
  root: {
    color: "#dddddd",
    height: 8,
    gridColumn: "1/ span 2",
    gridRow: "2",
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: `3px solid ${Colors.PRIMARY_AFI_SOFOM}`,
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  track: {
    height: 0,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const SliderGroup = styled.div`
  margin-top: 1em;

  display: grid;
  grid-template-rows: 1fr 2fr 1fr;
  grid-template-columns: 1fr 1fr;
`;
const SliderInput = styled.input`
  grid-row: 1;
  grid-column: 2;
  justify-self: right;
  width: 35%;
  border-radius: 0;
  border: none;
  border-bottom: solid 1px black;
  text-align: right;
  font-family: inherit;
  font-size: inherit;

  @media only screen and (max-width: 540px) {
    width: 55%;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 55%;
  }
`;

const SliderLabel1 = styled.span`
  grid-row: 3;
  grid-column: 1;
`;

const SliderLabel2 = styled(SliderLabel1)`
  grid-column: 2;
  justify-self: right;
`;

// DROPDOWNS /////////////////
const StyledFormControl = styled(FormControl)`
  &.MuiFormControl-root {
    width: 100%;
    margin: 1em 0;
  }

  .MuiFormLabel-root {
    color: ${Colors.BLACK};
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${Colors.PRIMARY_AFI_SOFOM};
  }

  .MuiInput-underline::before {
    border-bottom: 1px solid ${Colors.BLACk} !important;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .MuiInput-underline:hover::before {
    border-bottom: 3px solid ${Colors.PRIMARY_AFI_SOFOM} !important;
  }
  .MuiInput-underline::after {
    border-bottom: 3px solid ${Colors.PRIMARY_AFI_SOFOM};
  }
`;
const StyledLabel = styled(InputLabel)`
  color: black;
`;

const StyledSelect = styled(Select)``;
const ErrorHelperText = styled(FormHelperText)`
  &.showHelperText {
    display: block !important;
  }

  &.MuiFormHelperText-root {
    display: none;
    color: red !important;
    font-family: ${Fonts.BOLD};
  }
`;

// TEXTO CON VÍNCULO /////////////////
const TextContainer = styled.div`
  margin: 1em 0;
`;

const BoldText = styled.span`
  font-size: 1em;
  font-family: ${Fonts.BOLD};
`;

// LADO DERECHO /////////////////
const RightHalf = styled.div`
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const Disclaimer = styled.p`
  font-size: 0.8em;
  color: ${Colors.GRAY};
  max-width: 95%;
  margin: 0.25em 0;
`;

// SECCION DE SUBIR DOCUMENTOS
const UpBtnTemplate = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const UploadButtonMain = styled(UpBtnTemplate)`
  grid-column-gap: 3em;

  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 0.5em;
    grid-row: 2;
  }
`;

const UploadButtonPair = styled(UpBtnTemplate)`
  grid-column-gap: 0.75em;
  @media only screen and (max-width: 767px) {
    grid-column-gap: 1.5em;
  }

  &.errorVisible {
    grid-template-rows: 3em 1fr;
  }

  &.errorVisible > :nth-child(1) {
    grid-column: 1/3;
    justify-self: center;
  }

  &.errorVisible > :nth-child(2) {
    grid-column: 1;
    grid-row: 2;
  }

  &.errorVisible > :nth-child(3) {
    grid-column: 2;
    grid-row: 2;
  }
`;

const wordFileType =
  ".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,";
const pdf = ".pdf,";
const images = "image/png,image/jpeg";

const fileDef = wordFileType + pdf + images;

const SolicitudFormOneMain = ({ history }) => {
  const [currentMax, setCurrentMax] = useState(2150000);
  const [currentMin, setCurrentMin] = useState(50000);
  const [showTable, toggleShowTable] = useReducer((v) => !v, false);
  const [paymentMonths, setPaymentMonths] = useState(6);
  const [creditType, setCreditType] = useState(creditTypeCatalog[0]);
  const [rateType, setRateType] = useState(FIXED_RATE);
  const [personaMoral, setPersonaMoral] = useState(false);
  const [loanAmount, setLoanAmount] = useState(1050000);
  const [wasBackspace, setWasBackspace] = useState(false);
  const [monthsError, setMonthsError] = useState(false);
  const [cTypeError, setCTypeError] = useState(false);
  const [position, setPosition] = useState(0);
  const [inputRef, setInputRef] = useState();
  const [rateTypeError] = useState(false);
  const [tiie, setTIIE] = useState({ day: "0/0/0", value: 0 });
  const [change, activateChange] = useReducer(() => true, false);
  const [upload1, setUpload1] = useState("");
  const [upload2, setUpload2] = useState("");
  const [upload3, setUpload3] = useState("");
  const [upload4, setUpload4] = useState("");
  const [uploadFileDef] = useState(fileDef);
  const [uploadErrorText1, setUploadErrorText1] = useState("");
  const [uploadErrorText2, setUploadErrorText2] = useState("");
  const [showError1, setShowError1] = useState("");
  const [showError2, setShowError2] = useState("");
  const [solCreditoUploaded, setSolCreditoUploaded] = useState(false);
  const [consultaBuroUploaded, setConsultaBuroUploaded] = useState(false);
  const [idFrontUploaded, setIdFrontUploaded] = useState(false);
  const [idBackUploaded, setIdBackUploaded] = useState(false);

  const formatCurrency = (value) => {
    let formatted = Intl.NumberFormat().format(value);
    formatted = "$" + formatted;
    return formatted;
  };

  const changeMonths = (event) => {
    setPaymentMonths(event.target.value);
    setMonthsError(false);
    activateChange();
  };

  const changeCredito = (event) => {
    setCreditType(
      creditTypeCatalog.find((ct) => ct.name === event.target.value)
    );
    activateChange();
  };

  const toggleBox = () => {
    toggleShowTable();
    killScroll();
  };

  const killScroll = () => {
    if (showTable) {
      document.body.style =
        "overflow-y:hidden; position: relative; margin-right: 15px;";
    } else {
      document.body.style =
        "overflow-y:visible; position: static; margin-right: 0;";
    }
  };

  const handleButtonPersona = (personaType) => {
    setPersonaMoral(personaType === "moral" ? true : false);
    activateChange();
  };

  const handleSliderChange = (event, value) => {
    setLoanAmount(Math.round(value / 10000) * 10000);
    activateChange();
  };

  const handleKeyDownSliderText = (event) => {
    // Se decide no utilizar setState aquí porque no requerimos
    // que el componente haga un render después de este cambio.
    if (event.key === "backspace") {
      // eslint-disable-next-line
      setWasBackspace(true);
      activateChange();
    }
  };

  const handleSliderTextChange = (event) => {
    if (wasBackspace) {
      setPosition(event.target.selectionStart - 1);
      setWasBackspace(false);
    } else {
      setPosition(event.target.selectionStart);
    }

    const text = event.target.value;
    const re = new RegExp(/[$,]/, "g");
    const textVal = text.replaceAll(re, "");

    if (!isNaN(textVal)) {
      let textInt = parseInt(textVal, 10);
      if (textInt > currentMax) {
        textInt = currentMax;
      }
      if (textInt < currentMin) {
        textInt = currentMin;
      }
      setLoanAmount(textInt);
      activateChange();
    }
  };

  const assignRef = (c) => {
    setInputRef(c);
  };

  const changeRateType = (e) => {
    setRateType(e.target.value);
    activateChange();
  };

  const generateTerms = () => {
    const items = [];
    for (
      let i = creditType?.minTerm;
      i <= creditType?.maxTerm;
      i += creditType?.termRange
    ) {
      items.push(
        <MenuItem key={i} value={i}>
          {i} Meses
        </MenuItem>
      );
    }
    return items;
  };

  const getTIIE = async () => {
    const res = await fetch(
      "https://afi-configuration-bucket.s3.us-east-2.amazonaws.com/indicators.json"
    );
    const values = await res.json();
    const {
      datos: [tiie],
    } = values.find((v) => v.idSerie === "SF43783");
    console.log({ day: tiie.fecha, value: parseFloat(tiie.dato) });
    setTIIE({ day: tiie.fecha, value: parseFloat(tiie.dato) });
  };

  const validateSubmit = async () => {
    const fileUploadError =
      "Es necesario que subas los documentos requeridos para continuar";

    if (!solCreditoUploaded) {
      setShowError1(true);
      setUploadErrorText1(fileUploadError);
    } else if (!consultaBuroUploaded) {
      setShowError1(true);
      setUploadErrorText1(fileUploadError);
    }

    if (!idFrontUploaded) {
      setShowError2(true);
      setUploadErrorText2(fileUploadError);
    } else if (!idBackUploaded) {
      setShowError2(true);
      setUploadErrorText2(fileUploadError);
    } else {
      let cData = getClientData();
      await sendCreditEmail(cData.name, cData.email);
      let credit = new AfisofomCredit(cData.afisofom_credit);
      credit.stage = AfisofomCredit.STAGE_PENDING;
      credit.amount = loanAmount;
      credit.term = paymentMonths;
      credit.type = creditType.name;
      credit.is_requested = true;
      credit.interest = 0;
      credit.rateType = rateType;
      credit.generateAmortizationCalendar(tiie);
      cData.afisofom_credit = credit;
      cData.afisofom_credit.stage = "Pendiente de revisión";
      setClientData(cData);
      await updateAfisofomCredit(cData.id, cData.email, credit);
      history.push("/afisofom/dashboard");
    }
  };

  const handleUpload = async (event) => {
    event.persist();

    const file = event.target.files[0];
    if (file) {
      const uploadType = event.target.getAttribute("name");
      let fileKey = "";
      let errorExists = false;
      let errorType = "";

      if (file.size > 2000000) {
        errorExists = true;
        errorType = "El tamaño del archivo no debe exceder 2MB";
      } else {
        errorExists = false;
        errorType = "";
        try {
          const { key } = await uploadFile(file, file.name);
          fileKey = key;
        } catch (error) {
          errorExists = true;
          errorType =
            "Hubo un error al subir el archivo, por favor intente de nuevo";
          console.log("handleUpload Error:" + error);
        }
      }

      if (uploadType === "solCredito" || uploadType === "consulBuro") {
        setShowError1(errorExists);
        setUploadErrorText1(errorType);
      } else {
        setShowError2(errorExists);
        setUploadErrorText2(errorType);
      }

      if (!errorExists) {
        let cData = getClientData();
        let credit = cData.afisofom_credit;
        switch (uploadType) {
          case "solCredito":
            credit.afisofom_credit_form_key = fileKey;
            setSolCreditoUploaded(true);

            break;
          case "consulBuro":
            credit.credit_buro_key = fileKey;
            setConsultaBuroUploaded(true);
            break;
          case "idFront":
            credit.photo_id_front_key = fileKey;
            setIdFrontUploaded(true);
            break;
          case "idBack":
            credit.photo_id_reverse_key = fileKey;
            setIdBackUploaded(true);
            break;
          default:
            break;
        }
        cData.afisofom_credit = credit;
        setClientData(cData, true);
        updateAfisofomCredit(cData.id, cData.email, credit);
      }
    }
  };

  useEffect(() => {
    const client = getClientData();
    console.log(client);
    if (client.is_moral_person) {
      setPersonaMoral(client.is_moral_person);
    }

    if (client.afisofom_credit.amount) {
      setLoanAmount(client.afisofom_credit.amount);
    }

    if (client.afisofom_credit.term) {
      setPaymentMonths(client.afisofom_credit.term);
    }

    if (client.afisofom_credit.type) {
      if (client.afisofom_credit.type !== "") {
        setCreditType(
          creditTypeCatalog.find(
            (ct) => ct.name === client.afisofom_credit.type
          )
        );
      }
    }
    if (client.afisofom_credit.rateType) {
      setRateType(client.afisofom_credit.rateType);
    }
  }, []);

  useEffect(() => {
    const index = position;
    if (inputRef) {
      inputRef.setSelectionRange(index, index);
    }
  });

  // //TODO: Revisar localStorage.
  // useEffect(() => {
  //   setCreditType(creditTypeCatalog[0]);
  // }, []);

  useEffect(() => {
    getTIIE();
  }, []);

  // Efecto llamado cuando se cambia el tipo de crédito
  useEffect(() => {
    const min = personaMoral ? creditType.minPMAmount : creditType.minPFAmount;
    const max = personaMoral ? creditType.maxPMAmount : creditType.maxPFAmount;
    setCurrentMin(min);
    setCurrentMax(max);
    if (change) setLoanAmount(loanAmount <= max ? loanAmount : max);
    setCTypeError(false);

    if (!creditType?.minVariableInterestRate) {
      setRateType(FIXED_RATE);
    }
    if (paymentMonths % !creditType?.termRange !== 0 && change) {
      setPaymentMonths(creditType?.minTerm);
    }
  }, [creditType, personaMoral]);

  //Use effect para guardar en localStorage
  // useEffect(() => {
  //   console.log(change);
  //   if (change) {
  //     localStorage.creditType = creditType.name;
  //     localStorage.loanAmount = loanAmount;
  //     localStorage.paymentMonths = paymentMonths;
  //     localStorage.personaMoral = personaMoral;
  //     localStorage.rateType = rateType;
  //   }
  // }, [change]);

  return (
    <FormContainer className="box-shadow">
      <SectionTitle>{Strings.AFISOFOM_CONFIRMFORM_TITLE}</SectionTitle>
      <p>{Strings.AFISOFOM_CONFIRMFORM_DESCRIPTION}</p>
      <FormGrid>
        <div>
          <MainControlGroup>
            <UploadButtonMain>
              <UploadButtonPair className={showError1 ? "errorVisible" : ""}>
                {showError1 && <ErrorText>{uploadErrorText1}</ErrorText>}
                <CustomInput
                  id={crypto.randomBytes(20).toString("hex")}
                  name="solCredito"
                  type="file"
                  buttonType="icon"
                  onChange={handleUpload}
                  accept={uploadFileDef}
                  uploaded={solCreditoUploaded}
                  label="Solicitud de crédito"
                />

                <CustomInput
                  id={crypto.randomBytes(20).toString("hex")}
                  name="consulBuro"
                  type="file"
                  buttonType="icon"
                  onChange={handleUpload}
                  accept={uploadFileDef}
                  uploaded={consultaBuroUploaded}
                  label="Consulta de buro de crédito"
                />
              </UploadButtonPair>

              <UploadButtonPair className={showError2 ? "errorVisible" : ""}>
                {showError2 && <ErrorText>{uploadErrorText2}</ErrorText>}
                <CustomInput
                  id={crypto.randomBytes(20).toString("hex")}
                  name="idFront"
                  type="file"
                  buttonType="icon"
                  onChange={handleUpload}
                  accept={uploadFileDef}
                  uploaded={idFrontUploaded}
                  isID
                  label="Identificación oficial (frente)"
                />

                <CustomInput
                  id={crypto.randomBytes(20).toString("hex")}
                  name="idBack"
                  type="file"
                  buttonType="icon"
                  onChange={handleUpload}
                  accept={uploadFileDef}
                  uploaded={idBackUploaded}
                  isID
                  label="Identificación oficial (reverso)"
                />
              </UploadButtonPair>
            </UploadButtonMain>
            <InputControlGroup>
              <SliderGroup>
                <span id="slider-cantidad-prestamo">
                  {Strings.AFISOFOM_CONFIRMFORM_LABEL_SLIDER}
                </span>
                <SliderInput
                  onKeyDown={handleKeyDownSliderText}
                  onChange={handleSliderTextChange}
                  value={formatCurrency(loanAmount)}
                  ref={assignRef}
                />
                <PrettoSlider
                  value={loanAmount}
                  onChange={handleSliderChange}
                  aria-labelledby="slider-cantidad-prestamo"
                  step={1000}
                  min={currentMin}
                  max={currentMax}
                  valueLabelDisplay="off"
                />
                <SliderLabel1>{formatCurrency(currentMin)}</SliderLabel1>
                <SliderLabel2>{formatCurrency(currentMax)}</SliderLabel2>
              </SliderGroup>
              <StyledFormControl>
                <StyledLabel id="label-plazo-select">Plazo </StyledLabel>
                <StyledSelect
                  labelId="label-plazo-select"
                  value={paymentMonths}
                  onChange={changeMonths}
                  error={monthsError}
                >
                  {generateTerms()}
                </StyledSelect>
                <ErrorHelperText
                  className={monthsError ? "showHelperText" : ""}
                >
                  Es necesario seleccionar un plazo.
                </ErrorHelperText>
              </StyledFormControl>
              {/* Tipo de crédito */}
              <StyledFormControl>
                <StyledLabel id="label-credito-select">
                  Tipo de crédito
                </StyledLabel>
                <StyledSelect
                  labelId="label-credito-select"
                  value={creditType?.name}
                  onChange={changeCredito}
                  error={cTypeError}
                >
                  {creditTypeCatalog.map(({ name }, key) => (
                    <MenuItem key={key} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </StyledSelect>
                <ErrorHelperText className={cTypeError ? "showHelperText" : ""}>
                  {Strings.AFISOFOM_FORMONE_ERROR_HELPER}
                </ErrorHelperText>
              </StyledFormControl>

              {/* Tipo de tasa */}
              <StyledFormControl>
                <StyledLabel id="label-credito-select">
                  Tipo de tasa
                </StyledLabel>
                <StyledSelect
                  labelId="label-tasa-select"
                  value={rateType}
                  onChange={changeRateType}
                  error={rateTypeError}
                >
                  <MenuItem value={FIXED_RATE}>Tasa fija</MenuItem>
                  {creditType?.minVariableInterestRate && (
                    <MenuItem value={VARIABLE_RATE}>Tasa variable</MenuItem>
                  )}
                </StyledSelect>
                <ErrorHelperText
                  className={rateTypeError ? "showHelperText" : ""}
                >
                  {Strings.AFISOFOM_FORMONE_ERROR_HELPER}
                </ErrorHelperText>
              </StyledFormControl>
            </InputControlGroup>
          </MainControlGroup>

          <DefaultButton
            dark="true"
            onClick={validateSubmit}
            variant="contained"
          >
            {Strings.AFISOFOM_CONFIRMFORM_SUBMIT_BTN}
          </DefaultButton>
        </div>
        <RightHalf>
          <TableSection
            loanAmount={loanAmount}
            paymentMonths={paymentMonths}
            toggleBox={toggleBox}
            creditType={creditType}
            rateType={rateType}
            tiie={tiie}
          />
        </RightHalf>
      </FormGrid>
    </FormContainer>
  );
};

SolicitudFormOneMain.propTypes = {
  history: PropTypes.any.isRequired,
};

export default SolicitudFormOneMain;
