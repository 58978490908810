import React, {Component} from "react";
import styled from "styled-components";
import Colors from "../../assets/colors";
import Fonts from "../../assets/fonts";
import Strings from "../../assets/strings";
import CustomInput from "../input";
import CustomButton from "../button";
import {isValidPassword, restorePassword} from "../../data/services/auth";
import PropTypes from 'prop-types';
import {InputAdornment, IconButton} from "@material-ui/core";
import {MdVisibility, MdVisibilityOff} from "react-icons/md";
import PasswordHint from "../password-hint";

const FormWrapper = styled.div`
    width: 100%;
    min-height: calc(100vh - 110px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ForgotContainer = styled.div`
    width: 50%;
    min-height: 500px;
    height: auto;
    box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.16);
    display: flex;
    background-color: ${Colors.WHITE};
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        width: 100%;
        box-shadow: none;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 100%;
    }
`;

const FormContainer = styled.div`
    padding: 30px 60px;
    margin: auto 0;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        padding: 0;
        margin-top: 50px;
        margin-bottom: 50px;
    }
`;

const FormTitle = styled.label`
    font-family: ${Fonts.BOLD};
    font-size: 1.5em;
    color: ${Colors.PRIMARY_AFI_SOFOM};
`;

const CenterContainer = styled.div`
    text-align: center;
`;

const FormError = styled.span`
    font-family: ${Fonts.MEDIUM};
    font-size: 0.8em;
    color: ${Colors.ERROR};
`;

const FormSubtitle = styled.p`
    font-family: ${Fonts.MEDIUM};
    font-size: 1em;
    color: ${Colors.BLACK};
`;

class SofomRestorePasswordForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            code: '',
            password: '',
            password_confirm: '',
            submitted: false,
            errors: {
                code: true,
                password: true,
                password_confirm: true,
            },
            isLoading: false,
            show_password: false,
            show_password_confirm: false,
            error: null,
            password_match: true,
            password_invalid: false,
        };
    }

    render() {
        return (
            <FormWrapper>
                <ForgotContainer>
                    <FormContainer>
                        <FormTitle>
                            {Strings.RESTORE_PASSWORD_TITLE}
                        </FormTitle>
                        <br/>
                        <FormSubtitle>
                            {Strings.RESTORE_PASSWORD_SUBTITLE}
                        </FormSubtitle>
                        <form noValidate autoComplete="off">
                            <CustomInput label={Strings.CODE + ' *'}
                                         name="code"
                                         id="code"
                                         required={true}
                                         value={this.state.code}
                                         color={Colors.PRIMARY_DARK_AFI_SOFOM}
                                         submitted={this.state.submitted}
                                         invalid={this.state.errors['code']}
                                         onChange={(val, v) => this.updateState('code', val, v)}
                                         maxLength={6}
                                         minLength={6}
                                         type="number"/>
                            <br/>
                            <CustomInput label={Strings.PASSWORD + ' *'}
                                         name="password"
                                         id="password"
                                         value={this.state.password}
                                         required={true}
                                         color={Colors.PRIMARY_DARK_AFI_SOFOM}
                                         submitted={this.state.submitted}
                                         invalid={this.state.errors['password']}
                                         onChange={(val, v) => this.updateState('password', val, v)}
                                         inputProps={{
                                             endAdornment: (
                                                 <InputAdornment position="end">
                                                     <IconButton tabIndex="-1" onClick={() => this.setState({
                                                         show_password: !this.state.show_password
                                                     })}>
                                                         {
                                                             this.state.show_password
                                                                 ? <MdVisibility/>
                                                                 : <MdVisibilityOff/>
                                                         }
                                                     </IconButton>
                                                 </InputAdornment>
                                             )
                                         }}
                                         type={this.state.show_password ? 'text' : 'password'}/>
                            <br/>
                            <CustomInput label={Strings.PASSWORD_CONFIRM + ' *'}
                                         name="password_confirm"
                                         id="password_confirm"
                                         value={this.state.password_confirm}
                                         required={true}
                                         color={Colors.PRIMARY_DARK_AFI_SOFOM}
                                         submitted={this.state.submitted}
                                         invalid={this.state.errors['password_confirm']}
                                         onChange={(val, v) => this.updateState('password_confirm', val, v)}
                                         inputProps={{
                                             endAdornment: (
                                                 <InputAdornment position="end">
                                                     <IconButton tabIndex="-1" onClick={() => this.setState({
                                                         show_password_confirm: !this.state.show_password_confirm
                                                     })}>
                                                         {
                                                             this.state.show_password_confirm
                                                                 ? <MdVisibility/>
                                                                 : <MdVisibilityOff/>
                                                         }
                                                     </IconButton>
                                                 </InputAdornment>
                                             )
                                         }}
                                         type={this.state.show_password_confirm ? 'text' : 'password'}/>
                            <br/>
                            <br/>
                            {
                                this.state.password_invalid &&
                                <div>
                                    <PasswordHint/>
                                    <br/>
                                    <br/>
                                </div>
                            }
                            <CenterContainer>
                                <CustomButton label={Strings.RECOVER}
                                              align="center"
                                              onClick={() => this.handleSubmit()}
                                              color={Colors.WHITE}
                                              isLoading={this.state.isLoading}
                                              fullWidth={false}
                                              background={Colors.PRIMARY_DARK_AFI_SOFOM}
                                              font={Fonts.BOLD}/>
                                {
                                    this.state.error &&
                                    <FormError>
                                        <br/>
                                        {this.state.error}
                                    </FormError>
                                }
                            </CenterContainer>
                        </form>
                    </FormContainer>
                </ForgotContainer>
            </FormWrapper>
        );
    }

    /**
     * Update state vars
     * @author @leonard_lib
     * @date 29/07/2020
     * @param prop
     * @param value
     * @param valid
     */
    updateState(prop, value, valid) {
        const state = this.state;
        const errors = state.errors;

        state[prop] = value;
        errors[prop] = !valid;
        state.errors = errors;

        this.setState(state, () => {
            this.checkPassword();
        });
    };

    /**
     * Check if password match
     * @author @leonard_lib
     * @date 29/07/2020
     */
    checkPassword() {
        const invalid_password = !isValidPassword(this.state.password);

        if (this.state.password !== this.state.password_confirm) {
            this.setState({
                password_match: false,
                password_invalid: invalid_password,
                error: Strings.PASSWORD_NOT_MATCH
            });
        } else {
            this.setState({
                password_match: true,
                password_invalid: invalid_password,
                error: null
            });
        }
    };

    /**
     * Check if data is completed
     * @author @leonard_lib
     * @date 29/07/2020
     * @returns {boolean}
     */
    checkCompleted() {
        this.checkPassword();
        return !this.state.errors['code']
            && !this.state.errors['password']
            && !this.state.errors['password_confirm']
            && this.state.password_match;
    }

    /**
     * Handle submit button click
     * @author @leonard_lib
     * @date 29/07/2020
     */
    handleSubmit() {
        this.setState({
            submitted: true,
            error: null
        }, () => {
            if (this.checkCompleted()) {
                this.setState({
                    isLoading: true
                }, () => {
                    this.makeCall();
                })
            }
        });
    };

    /**
     * Make request to reset password
     * @author @leonard_lib
     * @date 29/07/2020
     */
    makeCall() {
        const params = new URLSearchParams(this.props.location.search);
        const email = params.get('email');

        restorePassword(
            email,
            this.state.code,
            this.state.password
        ).then(() => {
            this.props.onRestore();
        }).catch(error => {
            this.setState({
                isLoading: false,
                error: error
            });
        });
    }
}

SofomRestorePasswordForm.propTypes = {
    onRestore: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
};

export default SofomRestorePasswordForm;
