/**
 * Check if user exists on database
 * @author @leonard_lib
 * @date 05/08/2020
 * @type {string}
 */
export const userExists = `
    query userExists($email: String!) {
        listClients(filter: {email: {eq: $email}}) {
            items {
                email
            }
        }
    }
`;
