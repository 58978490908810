import React from 'react';
import styled from "styled-components";
import Fonts from "../assets/fonts";
import PropTypes from 'prop-types';

const MessageBubble = styled.div`
    background-color: ${props => props.background};
    border-radius: 30px;
    color: ${props => props.color};
    font-family: ${Fonts.MEDIUM};
    font-size: 1em;
    padding: 15px 30px;
    max-width: 70%;
    margin: 30px 0;
    opacity: ${props => props.isSending ? '0.75' : '1'};
    margin-left: ${props => props.align === 'left' ? '0' : 'auto'};
`;

const MessageInfoContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: ${props => props.secondaryColor};
    font-family: ${Fonts.MEDIUM};
    font-size: 0.9em;
    margin-top: 15px;
`;

const MessageToLeft = styled.div`
    text-align: left;
`;

const MessageToRight = styled.div`
    text-align: right;
`;

const ChatMessageComponent = ({ message, from, time, background, color, secondaryColor, align, isSending }) => (
    <MessageBubble background={background}
                   align={align}
                   isSending={isSending}
                   color={color}>
        <div>{message}</div>
        <MessageInfoContainer secondaryColor={secondaryColor}>
            <MessageToLeft>{from}</MessageToLeft>
            <MessageToRight>{time}</MessageToRight>
        </MessageInfoContainer>
    </MessageBubble>
);

ChatMessageComponent.propTypes = {
    message: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    isSending: PropTypes.bool.isRequired,
    secondaryColor: PropTypes.string.isRequired,
    align: PropTypes.oneOf([
        'left',
        'right'
    ])
};

ChatMessageComponent.defaultProps = {
    align: 'left'
};

export default ChatMessageComponent;
