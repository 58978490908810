import React, {Component} from "react";
import styled from "styled-components";
import Colors from "../../assets/colors";
import Fonts from "../../assets/fonts";
import Strings from "../../assets/strings";
import CustomInput from "../input";
import CustomButton from "../button";
import PropTypes from 'prop-types';
import {
    addUserToGroup,
    confirmSignup,
    forgotPassword, getAuthenticatedUser,
    resendCode,
} from "../../data/services/auth";
import {DefaultLink} from "../shared-components/text-styles";
import {Alert} from "@material-ui/lab";
import {Snackbar} from "@material-ui/core";
import {getAfisofomClientDataByEmail} from "../../data/services/afisofom/client";
import AfisofomClient from "../../data/models/afisofom-client";
import AdminClient from "../../data/models/admin";

const FormWrapper = styled.div`
    width: 100%;
    min-height: calc(100vh - 110px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ForgotContainer = styled.div`
    width: 50%;
    min-height: 400px;
    height: auto;
    box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.16);
    display: flex;
    background-color: ${Colors.WHITE};
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        width: 100%;
        box-shadow: none;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 100%;
    }
`;

const FormContainer = styled.div`
    padding: 30px 60px;
    margin: auto 0;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        padding: 0;
        margin-top: 50px;
        margin-bottom: 50px;
    }
`;

const FormTitle = styled.label`
    font-family: ${Fonts.BOLD};
    font-size: 1.5em;
    color: ${Colors.DARK_GRAY};
`;

const CenterContainer = styled.div`
    text-align: center;
`;

const FormError = styled.span`
    font-family: ${Fonts.MEDIUM};
    font-size: 0.8em;
    color: ${Colors.ERROR};
`;

const FormSubtitle = styled.p`
    font-family: ${Fonts.MEDIUM};
    font-size: 1em;
    color: ${Colors.BLACK};
`;

class AdminConfirmSignupForm extends Component {
    constructor(props) {
        super(props);

        const email = props.email ? props.email : getAuthenticatedUser(true).email

        this.state = {
            code: '',
            submitted: false,
            password: '',
            errors: {
                code: true,
            },
            isLoading: false,
            error: null,
            openSnackbar: false,
            showResendLink: true,
            resends: 0,
            client: new AdminClient({email})
        }

        this.handleClose = this.handleClose.bind(this);
    }

    render() {
        return (
            <FormWrapper>
                <ForgotContainer>
                    <FormContainer>
                        <FormTitle>
                            {Strings.CONFIRM_SIGNUP_TITLE}
                        </FormTitle>
                        <br />
                        <FormSubtitle>
                            {Strings.CONFIRM_SIGNUP_SUBTITLE}
                        </FormSubtitle>
                        <form noValidate autoComplete="off">
                            <CustomInput label={Strings.CODE + ' *'}
                                         name="code"
                                         id="code"
                                         required={true}
                                         value={this.state.code}
                                         color={Colors.BLACK}
                                         submitted={this.state.submitted}
                                         invalid={this.state.errors['code']}
                                         onChange={(val, v) => this.updateState('code', val, v)}
                                         maxLength={6}
                                         minLength={6}
                                         type="number" />
                            <br />
                            <br />
                            {this.state.resends < 3 && !this.state.isLoading && (<>
                                    <DefaultLink style={{color:Colors.DARK_GRAY}} onClick={() => this.resendCode()}> Reenvíar código</DefaultLink>
                                    <br/>
                                    <br/>
                                </>
                            )
                            }
                            <CenterContainer>
                                <CustomButton label={Strings.CONFIRM_SIGNUP}
                                              align="center"
                                              onClick={() => this.handleSubmit()}
                                              color={Colors.WHITE}
                                              isLoading={this.state.isLoading}
                                              fullWidth={false}
                                              background={Colors.BLACK}
                                              font={Fonts.BOLD} />
                                {
                                    this.state.error &&
                                    <FormError>
                                        <br/>
                                        {this.state.error}
                                    </FormError>
                                }
                            </CenterContainer>
                        </form>
                    </FormContainer>
                </ForgotContainer>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    onClose={this.handleClose}
                    open={this.state.openSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert severity={"success"}>El código fue enviado nuevamente</Alert>
                </Snackbar>
            </FormWrapper>
        );
    }

    handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({openSnackbar: false});
    }
    /**
     * Update state vars
     * @author @leonard_lib
     * @date 29/07/2020
     * @param prop
     * @param value
     * @param valid
     */
    updateState(prop, value, valid) {
        const state = this.state;
        const errors = state.errors;

        state[prop] = value;
        errors[prop] = !valid;
        state.errors = errors;

        this.setState(state);
    };

    /**
     * Check if data is completed
     * @author @leonard_lib
     * @date 29/07/2020
     * @returns {boolean}
     */
    checkCompleted() {
        return !this.state.errors['code'];
    }

    /**
     * Handle submit button click
     * @author @leonard_lib
     * @date 29/07/2020
     */
    handleSubmit() {
        this.setState({
            submitted: true,
            error: null
        }, () => {
            if (this.checkCompleted()) {
                this.setState({
                    isLoading: true
                }, () => {
                    this.makeCall();
                })
            }
        });
    };

    resendCode() {
        this.setState({
            resends: this.state.resends++,
            isLoading: true,
            openSnackbar: false
        })
        resendCode(this.state.client.email)
            .then(() => {
                this.setState({
                    isLoading: false,
                    openSnackbar: true
                });
            }). catch(error => {
                this.setState({
                    isLoading: false,
                    error
                })
            }
        )
    }

    /**
     * Make request to reset password
     * @author @leonard_lib
     * @date 29/07/2020
     */
    makeCall() {
        confirmSignup(
            this.state.client.email,
            this.state.code
        ).then(() => {
            this.attachToAdvisorsClientsGroup();
        }).catch(error => {
            this.setState({
                isLoading: false,
                error: error
            });
        });
    }

    /**
     * Set user as advisor client
     * @author @leonard_lib
     * @date 05/08/2020
     */
    attachToAdvisorsClientsGroup() {
        addUserToGroup(
            this.state.client.email,
            'advisers'
        ).then(() => {
            this.makeCallToChangePassword();
        }).catch(error => {
            console.log(error);
            this.makeCallToChangePassword();
        });
    }

    makeCallToChangePassword() {
        forgotPassword(
            this.state.client.email
        ).then(() => {
            this.props.onConfirm(this.state.client.email)
        }).catch(error => {
            this.setState({
                isLoading: false,
                error: error
            });
        });
    }

}

AdminConfirmSignupForm.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired
};

export default AdminConfirmSignupForm;
