import React from "react";
import LayoutClients from "../../components/layout-clients";
import SofomRestorePasswordForm from "../../components/afisofom/restore-password-form";
import {useChangeTitleFavicon} from "../../hooks/useChangeTitle";
import Strings from "../../assets/strings";

const AfisofomRestorePassword = ({ history, location }) => {
    useChangeTitleFavicon(Strings.TITLE_RESTORE_PASSWORD, "afisofom");

    return (
        <LayoutClients site="sofom" history={history}>
            <div className="container">
                <SofomRestorePasswordForm
                    location={location}
                    onRestore={() =>
                        history.push('/afisofom/iniciar-sesion')
                    }
                />
            </div>
        </LayoutClients>
    );
}

export default AfisofomRestorePassword;
