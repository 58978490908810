import {DefaultText, ErrorMsg, PageTitle} from "../shared-components/text-styles";
import Strings from "../../assets/strings";
import CustomModal from "../custom-modal";
import React, {useState} from "react";
import PropTypes from 'prop-types';
import Colors from "../../assets/colors";
import CustomButton from "../button";
import Fonts from "../../assets/fonts";
import styled from "styled-components";
import {format} from "../../utils/string";
import {sitesConfigurationUpdate} from "../../data/services/admins/sites";

const ButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    margin-top: 50px;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }
`;

const ErrorWrapper = styled.div`
    margin-top: 30px;
    text-align: center;
`;

const DeployConfirmation = ({ isOpen, onClose, data, prod, onDeployed }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const env = prod ? "producción": "prueba"

    const deploy = async () => {
        try {
            setLoading(true)
            const d = await sitesConfigurationUpdate(JSON.stringify(data), prod);
            setLoading(false)
            if(d.data.updateSitesConfiguration.error !== "") {
                setError(d.data.updateSitesConfiguration.error)
            } else {
                onDeployed()
                setError(null)
            }

        } catch (e) {
            setLoading(false)
            setError(Strings.DEPLOY_ERROR)
        }
    }

    return (
        <CustomModal onClose={() => onClose()}
                     isOpen={isOpen}>
            <PageTitle>
                {format(Strings.DEPLOY_CONFIRM, [env, data.title])}
            </PageTitle>
            <br/>
            <br/>
            <DefaultText dangerouslySetInnerHTML={{__html: format(Strings.DEPLOY_CONFIRM_DESCRIPTION,
                    [prod ? data.siteURL : data.devSiteURL, prod ? data.siteURL : data.devSiteURL])}}/>
            <form noValidate autoComplete="off">
                <ButtonsWrapper>
                    <CustomButton label={Strings.CANCEL}
                                  onClick={() => onClose()}
                                  align="center"
                                  color={Colors.DARK_GRAY}
                                  background={Colors.WHITE}
                                  font={Fonts.BOLD}
                                  fullWidth={false} />
                    <CustomButton label={Strings.DEPLOY}
                                  onClick={deploy}
                                  align="center"
                                  isLoading={loading}
                                  color={Colors.WHITE}
                                  background={Colors.DARK_GRAY}
                                  font={Fonts.BOLD}
                                  fullWidth={false} />
                </ButtonsWrapper>
            </form>
            {
                error &&
                <ErrorWrapper>
                    <ErrorMsg>
                        {error}
                    </ErrorMsg>
                </ErrorWrapper>
            }
        </CustomModal>
    );
};

DeployConfirmation.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    data: PropTypes.object,
    prod: PropTypes.bool
};

export default DeployConfirmation;
