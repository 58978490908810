import React from "react";

import Dashboard from "../../components/dashboard";
import LayoutClients from "../../components/layout-clients";

const AfiserviciosDashboard = ({history}) => {

  return (
    <LayoutClients site="servicios" showPrincipalButton={false} history={history}>
      <Dashboard />
    </LayoutClients>
  );
};

export default AfiserviciosDashboard;
