import React from "react";
import LayoutClients from "../../components/layout-clients";
import SolicitudRequerida from "../../components/afisofom/required-form-main";
import {useChangeTitleFavicon} from "../../hooks/useChangeTitle";
import Strings from "../../assets/strings";

const AfisofomFormOne = ({history}) => {
    useChangeTitleFavicon(Strings.TITLE_REQUIRED_FORM_AFISOFOM, "afisofom");

    return (
        <LayoutClients site="sofom" history={history} showPrincipalButton={false}>
            <div className="container" style={{ marginTop: '130px' }}>
                <SolicitudRequerida />
            </div>
        </LayoutClients>
    )
}

export default AfisofomFormOne;
