import styled from "styled-components";

import Fonts from "../../assets/fonts";
import Colors from "../../assets/colors";

import Button from '@material-ui/core/Button';


export const SquareButton = styled(Button) `
  color: ${Colors.WHITE} !important;
  background-color: ${props => props.bgcolor ? props.bgcolor : Colors.PRIMARY_AFI_SOFOM} !important;
  padding: 10px auto !important;
  font-family: ${Fonts.MEDIUM} !important;
  font-size: 1em !important;
  border-radius: 0 !important;
  text-transform: none !important;

  &.inactive {
    color: ${Colors.BLACK} !important;
    background-color: ${Colors.WHITE} !important;
  }
`

export const DefaultButton = styled(Button) `
    color: ${Colors.WHITE} !important;
    background-color: ${props => props.dark ? Colors.PRIMARY_DARK_AFI_SOFOM : Colors.PRIMARY_AFI_SOFOM}!important;
    padding: 10px 30px !important;
    font-family: ${Fonts.BOLD} !important;
    font-size: 1em !important;
    border-radius: 50px !important;
    text-transform: none !important;

    &.hideOnBigScreen {
        display: none;
        @media only screen and (max-width: 767px) {
            display: block;
        }
    }

    @media only screen and (max-width: 767px) {
        width: 100%;
        margin: .5em 0 !important;
    }
`

export const LightButton = styled(DefaultButton) `
    color: ${Colors.PRIMARY_AFI_SOFOM} !important;
    background-color: ${Colors.WHITE} !important;
`


export const WhiteButton = styled(DefaultButton) `
  padding: 5px 30px !important;
	color: ${Colors.DARK_GRAY} !important;
	background-color: ${Colors.WHITE} !important;
	filter: drop-shadow(0 1px 1px ${Colors.GRAY});
`;