import React, { useEffect, useState } from "react";
import {
  ErrorMsg,
  InfoMsg,
  SectionTitleSmall,
} from "../shared-components/text-styles";
import Strings from "../../assets/strings";
import IconButton from "@material-ui/core/IconButton";
import { MdEdit, MdLock } from "react-icons/md/index";
import styled from "styled-components";
import PropTypes from "prop-types";
import CustomSlider from "../slider";
import Colors from "../../assets/colors";
import CustomSelect from "../select";
import {
  getAfisofomCreditMonths,
  getAfisofomCreditTypes,
  getFileTypes,
} from "../../data/services/utils";
import CustomInput from "../input";
import InputAdornment from "@material-ui/core/InputAdornment";
import { getAdvisors } from "../../data/services/admins/clients";
import CustomInputFileLarge from "../input-file";
import { DivMargin } from "../shared-components/container-styles";
import CustomButton from "../button";
import Fonts from "../../assets/fonts";
import Table from "../afisofom/form-one-table_DEPRECATED";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";

const TitleIconContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
`;

const AfisofomRequestBasicData = ({
  showEditButton,
  onClickEdit,
  disabled,
  client,
  onUpdated,
  isLoadingButton,
  success,
  error,
}) => {
  const [user, setUser] = useState(client);
  const [maxAmount, setMaxAmount] = useState(2150000);
  const [submitted, setSubmitted] = useState(false);
  const [term, setTerm] = useState(client.afisofom_credit.term);
  const [amount, setAmount] = useState(client.afisofom_credit.amount);
  const [type, setType] = useState(client.afisofom_credit.type);
  const [advisorId, setAdvisorId] = useState(client.afisofom_credit.advisor_id);
  const [isUploadedCreditBuro, setIsUploadedCreditBuro] = useState(
    client.afisofom_credit.credit_buro_key !== null
  );
  const [isUploadedFormKey, setIsUploadedFormKey] = useState(
    client.afisofom_credit.afisofom_credit_form_key !== null
  );
  const [isUploadedIDFrontKey, setIsUploadedIDFrontKey] = useState(
    client.afisofom_credit.photo_id_front_key !== null
  );
  const [isUploadedIDReverseKey, setIsUploadedIDReverseKey] = useState(
    client.afisofom_credit.photo_id_reverse_key !== null
  );
  const [interest, setInterest] = useState(
    client.afisofom_credit.interest || 18
  );
  const [advisors, setAdvisors] = useState([]);
  const [errors, setErrors] = useState({
    term: client.afisofom_credit.term < 1,
    type:
      client.afisofom_credit.type === null ||
      client.afisofom_credit.type === "",
    interest: false,
  });
  const minAmount = 50000;
  const terms = getAfisofomCreditMonths();
  const types = getAfisofomCreditTypes();
  const fileTypes = getFileTypes();

  // on mount
  useEffect(() => {
    getAdvisorsData();
    checkUserLimits();
    // eslint-disable-next-line
  }, []);

  const checkUserLimits = () => {
    if (user.is_moral_person) {
      setMaxAmount(4300000);
    } else {
      setMaxAmount(2150000);
    }
  };

  const getAdvisorsData = async () => {
    try {
      const res = await getAdvisors(true);
      setAdvisors(res.advisors);
    } catch (error) {
      console.log(error);
      setAdvisors([]);
    }
  };

  const updateState = (prop, value, valid) => {
    const auxUser = user;

    // eslint-disable-next-line
    switch (prop) {
      case "amount":
        setAmount(value);
        auxUser.afisofom_credit.amount = value;
        setUser(auxUser);
        break;
      case "term":
        setTerm(value);
        auxUser.afisofom_credit.term = value;
        setUser(auxUser);
        break;
      case "type":
        setType(value);
        auxUser.afisofom_credit.type = value;
        setUser(auxUser);
        break;
      case "interest":
        setInterest(value);
        auxUser.afisofom_credit.interest = Number(value);
        setUser(auxUser);
        break;
      case "advisor_id":
        setAdvisorId(value);
        auxUser.afisofom_credit.advisor_id = value;
        auxUser.afisofom_credit.advisor = getAdvisorName(value);
        setUser(auxUser);
        break;
      case "credit_buro_key":
        setIsUploadedCreditBuro(true);
        auxUser.afisofom_credit.credit_buro_key = value;
        onUpdated(auxUser);
        break;
      case "afisofom_credit_form_key":
        setIsUploadedFormKey(true);
        auxUser.afisofom_credit.afisofom_credit_form_key = value;
        onUpdated(auxUser);
        break;
      case "photo_id_front_key":
        setIsUploadedIDFrontKey(true);
        auxUser.afisofom_credit.photo_id_front_key = value;
        onUpdated(auxUser);
        break;
      case "photo_id_reverse_key":
        setIsUploadedIDReverseKey(true);
        auxUser.afisofom_credit.photo_id_reverse_key = value;
        onUpdated(auxUser);
        break;
    }

    const auxErrors = errors;
    errors[prop] = !valid;
    setErrors(auxErrors);
  };

  const verifyData = () => {
    return !errors["term"] && !errors["type"] && !errors["interest"];
  };

  const onSave = () => {
    setSubmitted(true);

    if (verifyData()) {
      onUpdated(user);
    }
  };

  const getAdvisorName = (id) => {
    let name = "";

    advisors.forEach((item) => {
      if (item.id === id) {
        name = item.full_name;
      }
    });

    return name;
  };

  return (
    <form noValidate autoComplete="off">
      <ContentContainer>
        <div>
          <TitleIconContainer>
            <TitleContainer>
              <SectionTitleSmall>
                {Strings.AFISOFOM_REQUEST_2}
              </SectionTitleSmall>
            </TitleContainer>
            <IconContainer>
              {showEditButton && (
                <IconButton onClick={() => onClickEdit()}>
                  {disabled ? <MdEdit /> : <MdLock />}
                </IconButton>
              )}
            </IconContainer>
          </TitleIconContainer>
          <CustomSlider
            step={1000}
            id="slider-amount"
            disabled={disabled}
            onChange={(value) => updateState("amount", value, true)}
            initialValue={amount}
            currentMin={minAmount}
            fromAdmin={true}
            currentMax={maxAmount}
          />
          <CustomSelect
            label={Strings.TERM}
            name="term"
            id="term"
            color={Colors.BLACK}
            value={term + ""}
            required={true}
            disabled={disabled}
            useNestedProps={true}
            valueProp="value"
            labelProp="label"
            submitted={submitted}
            invalid={errors["term"]}
            onChange={(val, v) => updateState("term", val, v)}
            options={terms}
          />
          <CustomSelect
            label={Strings.AFISOFOM_CREDIT_TYPE}
            name="type"
            id="type"
            color={Colors.BLACK}
            value={type}
            required={true}
            disabled={disabled}
            submitted={submitted}
            invalid={errors["type"]}
            onChange={(val, v) => updateState("type", val, v)}
            options={types}
          />
          <CustomInput
            label={Strings.AFISOFOM_ANNUAL_INTEREST}
            name="interest"
            id="interest"
            disabled={disabled}
            color={Colors.BLACK}
            value={interest + ""}
            required={true}
            submitted={submitted}
            invalid={errors["interest"]}
            onChange={(val, v) => updateState("interest", val, v)}
            inputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            type="number"
          />
          <CustomSelect
            label={Strings.AFISOFOM_ADVISOR}
            name="advisor"
            id="advisor"
            color={Colors.BLACK}
            value={advisorId || ""}
            required={false}
            disabled={advisors.length < 1 || disabled}
            submitted={submitted}
            invalid={false}
            useNestedProps={true}
            valueProp="id"
            labelProp="full_name"
            isClearable={advisorId?.length > 0}
            onClear={() => updateState("advisor_id", null, true)}
            onChange={(val, v) => updateState("advisor_id", val, v)}
            options={advisors}
          />
          <DivMargin>
            <TitleContainer>
              <SectionTitleSmall>{Strings.AFISOFOM_DOCS}</SectionTitleSmall>
            </TitleContainer>
          </DivMargin>
          <DivMargin>
            <CustomInputFileLarge
              isUploaded={isUploadedCreditBuro}
              fileKey={user.afisofom_credit.credit_buro_key}
              background="transparent"
              id="file-credit-buro"
              disabled={disabled}
              white={true}
              accept={fileTypes}
              onChangeKey={(key) => updateState("credit_buro_key", key, true)}
              label={Strings.AFISOFOM_CREDIT_BURO}
            />
            <CustomInputFileLarge
              isUploaded={isUploadedFormKey}
              fileKey={user.afisofom_credit.afisofom_credit_form_key}
              background={Colors.LIGHT_GRAY}
              id="file-credit-form"
              accept={fileTypes}
              white={true}
              disabled={disabled}
              onChangeKey={(key) =>
                updateState("afisofom_credit_form_key", key, true)
              }
              label={Strings.AFISOFOM_REQUEST_2}
            />
            <CustomInputFileLarge
              isUploaded={isUploadedIDFrontKey}
              fileKey={user.afisofom_credit.photo_id_front_key}
              background="transparent"
              id="file-photo-id-front"
              isID={true}
              accept={fileTypes}
              white={true}
              disabled={disabled}
              onChangeKey={(key) =>
                updateState("photo_id_front_key", key, true)
              }
              label={Strings.AFISOFOM_PHOTO_ID_FRONT}
            />
            <CustomInputFileLarge
              isUploaded={isUploadedIDReverseKey}
              fileKey={user.afisofom_credit.photo_id_reverse_key}
              background={Colors.LIGHT_GRAY}
              id="file-photo-id-reverse"
              accept={fileTypes}
              white={true}
              isID={true}
              disabled={disabled}
              onChangeKey={(key) =>
                updateState("photo_id_reverse_key", key, true)
              }
              label={Strings.AFISOFOM_PHOTO_ID_REVERSE}
            />
          </DivMargin>
          <DivMargin>
            {success && (
              <InfoMsg>
                {success}
                <br />
                <br />
              </InfoMsg>
            )}
            {error && (
              <ErrorMsg>
                {error}
                <br />
                <br />
              </ErrorMsg>
            )}
            <CustomButton
              label={Strings.UPDATE}
              onClick={() => onSave()}
              color={Colors.WHITE}
              background={Colors.DARK_GRAY}
              font={Fonts.BOLD}
              disabled={disabled}
              isLoading={isLoadingButton}
              fullWidth={false}
              fullWidthMobile={true}
            />
          </DivMargin>
        </div>
        <div>
          <DivMargin>
            <TitleContainer>
              <SectionTitleSmall>{Strings.AFISOFOM_TABLE}</SectionTitleSmall>
            </TitleContainer>
          </DivMargin>
          <DivMargin>
            <Table
              loanAmount={amount}
              paymentMonths={term}
              interestRate={interest}
              headerBackground={Colors.DARK_GRAY}
            />
          </DivMargin>
        </div>
      </ContentContainer>
    </form>
  );
};

AfisofomRequestBasicData.propTypes = {
  showEditButton: PropTypes.bool,
  onClickEdit: PropTypes.func,
  disabled: PropTypes.bool,
  isLoadingButton: PropTypes.bool,
  client: PropTypes.object.isRequired,
  onUpdated: PropTypes.func.isRequired,
  error: PropTypes.string,
  success: PropTypes.string,
};

AfisofomRequestBasicData.defaultProps = {
  showEditButton: false,
  disabled: false,
  isLoadingButton: false,
  error: null,
  success: null,
  onClickEdit: () => {},
};

export default AfisofomRequestBasicData;
