import React, {useState} from "react";
import styled from "styled-components";
import Fonts from "../assets/fonts";
import Colors from "../assets/colors";
import Strings from "../assets/strings";
import {DefaultText, PageTitle} from "./shared-components/text-styles";
import CustomButton from "./button";
import CloseAdvisoryRequest from "./admins/close-advisory";
import PropTypes from "prop-types";
import AfiserviciosClient, {AfiserviciosForm} from "../data/models/afiservicios-client";

const CardWrapper = styled.div`
    border: solid 1px ${Colors.PRIMARY_AFI_SERVICIOS};
    padding: 30px;
    margin-top: 10px;
`;

const CardTitle = styled.span`
    font-family: ${Fonts.MEDIUM};
    font-size: 1.25em;
    color: ${Colors.PRIMARY_AFI_SERVICIOS};
`;

const CardHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-row-gap: 1em;
    }
`;

const CardBody = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-top: 30px;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-row-gap: 2em;
    }
`;

const CardLeft = styled.div`
    text-align: left;
`;

const CardRight = styled.div`
    text-align: right;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        text-align: left;
    }
`;

const CardBodyRight = styled.div`
    display: grid;
    grid-template-columns: ${props => 'repeat('+ props.elements +', 1fr)'};
    grid-gap: 1em;
    justify-content: flex-end;
    align-items: flex-end;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        justify-content: center;
        grid-template-columns: 1fr;
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        justify-content: center;
    }
`;

const ClientAfiserviciosCard = ({ client, fromAdmin, onManage, onTerminated }) => {
    const [closeAdvisoryModalOpen, setCloseAdvisoryModalOpen] = useState(false);

    const onTerminatedInCard = () => {
        setCloseAdvisoryModalOpen(false);
        onTerminated();
    };

    return (
        <div>
            <CardTitle>{Strings.AFISERVICIOS}</CardTitle>
            <CardWrapper>
                <CardHeader>
                    <CardLeft>
                        <PageTitle transform="none">
                            {client.afiservicios_form.type}
                        </PageTitle>
                        <br/>
                        <DefaultText>{Strings.AFISERVICIOS_REQUESTED_SERVICES}</DefaultText>
                    </CardLeft>
                    <CardRight>
                        <PageTitle transform="none"
                                   color={Colors.PRIMARY_AFI_SERVICIOS}>
                            {client.afiservicios_form.stage}
                        </PageTitle>
                    </CardRight>
                </CardHeader>
                <CardBody>
                    <CardLeft>
                        <DefaultText>{Strings.AFISOFOM_REQUEST_DATE_2} {client.afiservicios_form.request_date}</DefaultText>
                        <br/>
                        <DefaultText color={!client.afiservicios_form.advisor_id ? Colors.ERROR : Colors.DARK_GRAY}>
                            {
                                !client.afiservicios_form.advisor_id
                                    ? Strings.NO_ADVISOR
                                    : Strings.ADVISOR + ': ' + client.afiservicios_form.advisor
                            }
                        </DefaultText>
                    </CardLeft>
                    {
                        fromAdmin &&
                        <CardBodyRight elements={client.afiservicios_form.stage === AfiserviciosForm.STAGE_IN_PROCESS ? 2 : 1}>
                            {
                                client.afiservicios_form.stage === AfiserviciosForm.STAGE_IN_PROCESS &&
                                <CustomButton label={Strings.AFISERVICIOS_DONE}
                                              onClick={() => setCloseAdvisoryModalOpen(true)}
                                              color={Colors.WHITE}
                                              align="right"
                                              background={Colors.DARK_GRAY}
                                              font={Fonts.BOLD}
                                              fullWidth={false} />
                            }
                            <CustomButton label={Strings.MANAGE}
                                          onClick={() => onManage()}
                                          align="right"
                                          color={Colors.DARK_GRAY}
                                          background={Colors.WHITE}
                                          font={Fonts.BOLD}
                                          fullWidth={false} />
                        </CardBodyRight>
                    }
                </CardBody>
            </CardWrapper>
            <CloseAdvisoryRequest client={client}
                                  onTerminated={() => onTerminatedInCard()}
                                  onClose={() => setCloseAdvisoryModalOpen(false)}
                                  isOpen={closeAdvisoryModalOpen} />
        </div>
    );
};

ClientAfiserviciosCard.propTypes = {
    client: PropTypes.instanceOf(AfiserviciosClient).isRequired,
    fromAdmin: PropTypes.bool.isRequired,
    onManage: PropTypes.func,
    onTerminated: PropTypes.func.isRequired
};

ClientAfiserviciosCard.defaultProps = {
    onManage: () => {}
};

export default ClientAfiserviciosCard;
