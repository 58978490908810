/**
 * Get Admin or Advisor client
 * @author @leonard_lib
 * @date 05/08/2020
 * @type {string}
 */
export const getAdminClient = `
    query getAdminClient($email: String!) {
        listClients(filter: {email: {eq: $email}}) {
            items {
                id
                email
                admin {
                    roles
                }
                address {
                    city
                    colony
                    country
                    street
                    state
                    zipcode
                }
                is_moral_person
                lastname
                moral_person {
                    business_name
                }
                mother_lastname
                name
                phone
            }
        }
    }
`;

/**
 * Get Admin or Advisor client by ID
 * @author @leonard_lib
 * @date 05/08/2020
 * @type {string}
 */
export const getAdminClientByID = `
    query getAdminClient($id: ID!) {
        listClients(filter: {id: {eq: $id}}) {
            items {
                id
                email
                admin {
                    roles
                }
                address {
                    city
                    colony
                    country
                    street
                    state
                    zipcode
                }
                is_moral_person
                lastname
                moral_person {
                    business_name
                }
                mother_lastname
                name
                phone
            }
        }
    }
`;

/**
 * Insert new advisor client in database
 * @author @leonard_lib
 * @date 04/08/2020
 * @type {string}
 */
export const createAdvisorClient = `
    mutation CreateClient(
        $input: CreateClientInput!
        $condition: ModelClientConditionInput
    ) {
        createClient(input: $input, condition: $condition) {
            id
            name
            lastname
            mother_lastname
            phone
            email
            address {
                street
                colony
                city
                state
                country
                zipcode
            }
            admin {
                roles
            }
            createdAt
            updatedAt
        }
    }
`;
