import React from "react";
import LayoutAdmins from "../../components/layout-admins";
import AdminDashboardLayout from "../../components/admins/dashboard-layout";
import ClientsList from "../../components/admins/clients-list";
import {useChangeTitleFavicon} from "../../hooks/useChangeTitle";
import Strings from "../../assets/strings";

const AdminsClients = ({ history }) => {
    useChangeTitleFavicon(Strings.TITLE_CLIENTS_ADMIN)
    return (
        <LayoutAdmins history={history} tabAdminSelected={0}>
            <AdminDashboardLayout selected={0} >
                <ClientsList history={history} />
            </AdminDashboardLayout>
        </LayoutAdmins>
    );
}

export default AdminsClients;
