import {DefaultText, PageTitle} from "../shared-components/text-styles";
import Strings from "../../assets/strings";
import CustomModal from "../custom-modal";
import React from "react";
import PropTypes from 'prop-types';
import AfisofomClient from "../../data/models/afisofom-client";
import Colors from "../../assets/colors";
import CustomButton from "../button";
import Fonts from "../../assets/fonts";
import styled from "styled-components";

const ButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    margin-top: 50px;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }
`;

const ClientWrapper = styled.span`
    text-transform: uppercase;
    font-family: ${Fonts.BOLD};
`;

const AfisofomModalEdit = ({ client, isOpen, onClose, onAccept }) => {
    return (
        <CustomModal onClose={() => onClose()}
                     isOpen={isOpen}>
            <PageTitle>
                {Strings.AFISOFOM_EDIT_INFO}
            </PageTitle>
            <br/>
            <br/>
            <DefaultText>
                {Strings.AFISOFOM_EDIT_INFO_2} <ClientWrapper>{client.full_name}</ClientWrapper>?
            </DefaultText>
            <form noValidate autoComplete="off">
                <ButtonsWrapper>
                    <CustomButton label={Strings.CANCEL}
                                  onClick={() => onClose()}
                                  align="center"
                                  color={Colors.DARK_GRAY}
                                  background={Colors.WHITE}
                                  font={Fonts.BOLD}
                                  fullWidth={false} />
                    <CustomButton label={Strings.ACCEPT}
                                  onClick={() => onAccept()}
                                  align="center"
                                  color={Colors.WHITE}
                                  background={Colors.DARK_GRAY}
                                  font={Fonts.BOLD}
                                  fullWidth={false} />
                </ButtonsWrapper>
            </form>
        </CustomModal>
    );
};

AfisofomModalEdit.propTypes = {
    client: PropTypes.instanceOf(AfisofomClient).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
};

export default AfisofomModalEdit;
