import moment from "moment";
import Strings from "../../assets/strings";

export class ChatMessageGraphql {
    advisor_id;
    advisor;
    client_id;
    client;
    text;
    sent_date;

    constructor(json = {}) {
        this.advisor_id = json.advisor_id ? json.advisor_id : null;
        this.client_id = json.client_id ? json.client_id : null;
        this.advisor = json.advisor ? json.advisor : null;
        this.client = json.client ? json.client : null;
        this.text = json.text ? json.text : '';
        this.sent_date = json.sent_date ? json.sent_date : null;
    }

    static fromMultiple(array) {
        let items = [];

        if (array && array.length > 0) {
            array.forEach(item => {
                items.push(new ChatMessageGraphql(item));
            });
        }

        return items;
    }
}

export class ChatMessage {
    advisor_id;
    advisor;
    client_id;
    client;
    text;
    sent_date;
    sent_date_text;

    constructor(json = {}) {
        this.advisor_id = json.advisor_id ? json.advisor_id : null;
        this.client_id = json.client_id ? json.client_id : null;
        this.advisor = json.advisor ? json.advisor : null;
        this.client = json.client ? json.client : null;
        this.text = json.text ? json.text : '';
        this.sent_date = json.sent_date ? json.sent_date : null;
        this.sent_date_text = json.sent_date ? moment(json.sent_date, Strings.FORMAT_DATE_CHAT).calendar() : null;
    }

    static fromMultiple(array) {
        let items = [];

        if (array && array.length > 0) {
            array.forEach(item => {
                items.push(new ChatMessage(item));
            });
        }

        return items;
    }
}

class Chat {
    id;
    messages;

    constructor(json = {}, parse_date = true) {
        const chat_messages = parse_date
            ? ChatMessage.fromMultiple(json.messages)
            : ChatMessageGraphql.fromMultiple(json.messages);

        this.id = json.id ? json.id : null;
        this.messages = json.messages ? chat_messages : [];
    }
}

export default Chat;
