import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import "./assets/styles/global.css";
import Routes from "./routes";
import Amplify, { I18n } from "aws-amplify";
import awsconfig from "./aws-exports";
import lang from "./assets/aws-vocabularies";
import moment from "moment";
import "moment/locale/es";

Amplify.configure({
  ...awsconfig,
});
I18n.putVocabularies(lang);
I18n.setLanguage("es");
moment.locale("es");

ReactDOM.render(Routes, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
