import React, {useEffect, useState} from "react";
import LayoutAdmins from "../../components/layout-admins";
import {DefaultText, PageTitle, SectionTitleSmall} from "../../components/shared-components/text-styles";
import Strings from "../../assets/strings";
import {DivMargin, TabItem, TabsContainer} from "../../components/shared-components/container-styles";
import BasicInfoForm from "../../components/basic-info-form";
import {getAdvisorClientData} from "../../data/services/admins/clients";
import {
    useParams
} from 'react-router-dom';
import {updateClientBasicData} from "../../data/services/client";
import styled from 'styled-components';
import ClientsOfAdvisorTable from "../../components/admins/clients-of-advisor-table";

const InfoContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2em;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        grid-template-columns: 1fr;
    }
`;

const AdminsAdvisorDetail = ({ history }) => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState(null);
    const [requestError, setRequestError] = useState(null);
    const [infoMsg, setInfoMsg] = useState(null);
    const [formDisabled, setFormDisabled] = useState(false);

    // Get advisor client by id
    const getAdvisorData = async () => {
        try {
            setLoading(true);
            const res = await getAdvisorClientData(id);
            setClient(res);
            setFormDisabled(true);
            setLoading(false);
            document.title = `${res.full_name}`;
        } catch (error) {
            setLoading(false);
            setRequestError(error);
        }
    };

    // on mount
    useEffect(() => {
        getAdvisorData();
        // eslint-disable-next-line
    }, []);

    const onSubmitted = (user) => {
        setLoading(true);
        setRequestError(null);
        setInfoMsg(null);
        setClient(user);
        makeUpdate();
    };

    const makeUpdate = async () => {
        try {
            await updateClientBasicData(client);
            setInfoMsg(Strings.UPDATED_CLIENT_BASIC_DATA)
            setLoading(false);

            setTimeout(() => {
                setInfoMsg(null);
            }, 5000);
        } catch (error) {
            setLoading(false);
            setRequestError(error);
        }
    }

    return (
        <LayoutAdmins history={history}
                      showBack={true}
                      tabAdminSelected={1}>
            <div className="container" style={{ marginTop: '130px' }}>
                <PageTitle>
                    {Strings.MANAGE_ADVISOR}
                </PageTitle>
                <DivMargin>
                    <DefaultText>
                        {Strings.MANAGE_ADVISOR_2}
                    </DefaultText>
                </DivMargin>
                <DivMargin>
                    <TabsContainer>
                        <TabItem site="admin"
                                 onClick={() => {}}
                                 selected={true}>
                            <span>{Strings.GENERAL_INFO}</span>
                        </TabItem>
                    </TabsContainer>
                </DivMargin>
                <DivMargin>
                    {
                        client &&
                        <InfoContainer>
                            <div>
                                <BasicInfoForm isLoadingButton={loading}
                                               error={requestError}
                                               success={infoMsg}
                                               user={client}
                                               disabled={formDisabled}
                                               showEditButton={true}
                                               onClickEdit={() => setFormDisabled(!formDisabled)}
                                               halfOnDesktop={true}
                                               onSubmit={(data, v) => onSubmitted(data, v)} />
                            </div>
                            <div>
                                <SectionTitleSmall>
                                    {Strings.CLIENTS}
                                </SectionTitleSmall>
                                <br/>
                                <br/>
                                <ClientsOfAdvisorTable id={client.id} />
                            </div>
                        </InfoContainer>
                    }
                </DivMargin>
            </div>
        </LayoutAdmins>
    );
};

export default AdminsAdvisorDetail;
