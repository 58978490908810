import React, {useState} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import NavbarAdmins from "./navbar-admins";
import MobileNavbar from "./admins/mobile-navbar";
import "../assets/styles/global.css"

const LayoutWrapper = styled.div`
    padding-bottom: 100px;
`;

const LayoutAdmins = ({ children, location, history, tabAdminSelected, showBack }) => {
    const [openMobile, setOpenMobile] = useState(false);
    const [mobileIconWidth, setMobileIconWidth] = useState('100%');

    /**
     * Show or hide mobile menu
     * @author @leonard_lib
     * @date 29/07/2020
     */
    const toggleMobileMenu = () => {
        setOpenMobile(!openMobile);
        const newWidth = !openMobile ? '40%' : '100%';
        setMobileIconWidth(newWidth);
    };

    return (
        <LayoutWrapper>
            <NavbarAdmins location={location}
                          mobileIconWidth={mobileIconWidth}
                          history={history}
                          showBack={showBack}
                          onClickMobile={() => toggleMobileMenu()} />
            <div style={{ marginTop: '80px', backgroundColor:"white !important" }}>
                <main>{children}</main>
            </div>
            <MobileNavbar isOpen={openMobile}
                          location={location}
                          history={history}
                          selected={tabAdminSelected}
                          onBackdropClick={() => toggleMobileMenu()} />
        </LayoutWrapper>
    );
};

/**
 * Property types of Layout
 * @author @leonard_lib
 * @date 02/07/2020
 * @type Object
 */
LayoutAdmins.propTypes = {
    children: PropTypes.node.isRequired,
    history: PropTypes.any.isRequired,
    location: PropTypes.string,
    tabAdminSelected: PropTypes.number
};

/**
 * Default property values of Layout
 * @author @leonard_lib
 * @date 02/07/2020
 * @type Object
 */
LayoutAdmins.defaultProps = {
    location: '/',
    tabAdminSelected: 0
};

export default LayoutAdmins;
