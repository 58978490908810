import axios from 'axios';
import Strings from "../../assets/strings";
import {isValidPassword} from "./auth";
import crypto from 'crypto';
import {AfisofomCredit} from "../models/afisofom-client";

/**
 * Assign value to property of object via string
 * like obj['client.address.street'] = 'test'
 * @author @leonard_lib
 * @date 05/08/2020
 * @param obj
 * @param prop
 * @param value
 */
export const assign = (obj, prop, value) => {
    if (typeof prop === "string") {
        prop = prop.split(".");
    }

    if (prop.length > 1) {
        const e = prop.shift();
        obj[e] = Object.prototype.toString.call(obj[e]) === "[object Object]" ? obj[e] : {};
        assign(obj[e], prop, value);
    } else {
        obj[prop[0]] = value;
    }
}

/**
 * Get address data from zipcode
 * @author @leonard_lib
 * @date 05/08/2020
 * @param zipcode
 * @returns {Promise<unknown>}
 */
export const getDataByZipcode = (zipcode) => {
    return new Promise((resolve, reject) => {
        const path = 'https://rocktech-codigos-postales.herokuapp.com/codigo_postal/' + zipcode;

        axios.get(path).then(res => {
            if (res && res.data && res.data.length > 0) {
                const colonies = [];

                res.data.forEach(item => {
                    colonies.push(item.colonia);
                });

                const new_data = {
                    codigo_postal: res.data[0].codigo_postal,
                    municipio: res.data[0].municipio,
                    estado: res.data[0].estado,
                    colonias: colonies
                };

                resolve(new_data);
            } else {
                resolve(null);
            }
        }).catch(() => {
            reject(Strings.ZIPCODE_ERROR);
        });
    });
};

export const formatCurrency = (value) => {
    let formatted = Intl.NumberFormat('es-MX', {style: 'currency', currency: 'MXN'}).format(value);
    return formatted;
}

export const randomString = (length, output = 'utf8') => {
    const buffer = crypto.randomBytes(length);
    return buffer.toString(output);
}

export const randomPassword = () => {
    let result = randomString(8);
    let isValid = isValidPassword(result);

    while (!isValid) {
        result = randomString(8);
        isValid = isValidPassword(result);
    }

    return result;
}

export const getAfisofomCreditMonths = () => {
    return [{
        value: 6,
        label: Strings.MONTHS_6
    }, {
        value: 12,
        label: Strings.MONTHS_12
    }, {
        value: 18,
        label: Strings.MONTHS_18
    }, {
        value: 24,
        label: Strings.MONTHS_24
    }, {
        value: 30,
        label: Strings.MONTHS_30
    }, {
        value: 36,
        label: Strings.MONTHS_36
    }];
};

export const getAfisofomCreditTypes = () => {
    return [
        AfisofomCredit.TYPE_AVIO,
        AfisofomCredit.TYPE_REFACT,
        AfisofomCredit.TYPE_SIMPLE,
        AfisofomCredit.TYPE_ACCOUNT,
        AfisofomCredit.TYPE_CAPITAL,
        AfisofomCredit.TYPE_FACT,
    ];
};

export const getFileTypes = () => {
    const wordFileType = ".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,";
    const pdf = '.pdf,';
    const images = 'image/png,image/jpeg';
    return wordFileType + pdf + images;
}
