import {API, graphqlOperation} from "aws-amplify";
import Strings from "../../assets/strings";
import Chat from "../models/chat";
import {createChatCustom, getChatCustom, updateChatCustom} from "../queries/chat";
import {chatSendEmail} from "../../graphql/queries";

export const createChatCall = (chat) => {
    return new Promise((resolve, reject) => {
        const new_chat = new Chat(chat, false);
        API.graphql(graphqlOperation(createChatCustom, {
            input: new_chat
        })).then(res => {
            const chat = new Chat(res.data.createChat);
            resolve(chat);
        }).catch(err => {
            console.log('CHAT CREATION ERROR: ', err);
            reject(Strings.CHAT_CREATION_ERROR);
        });
    });
};

export const getChatCall = (id) => {
    return new Promise((resolve, reject) => {
        API.graphql(graphqlOperation(getChatCustom, {
            id: id
        })).then(res => {
            let chat = new Chat();

            if (res.data.getChat) {
                chat = new Chat(res.data.getChat);
            }

            resolve(chat);
        }).catch(err => {
            console.log('GET CHAT ERROR: ', err);
            reject(Strings.GET_CHAT_ERROR);
        });
    });
};

export const updateChatCall = (chat) => {
    return new Promise((resolve, reject) => {
        const new_chat = new Chat(chat, false);
        API.graphql(graphqlOperation(updateChatCustom, {
            input: new_chat
        })).then(() => {
            resolve(true);
        }).catch(err => {
            console.log('CHAT UPDATE ERROR: ', err);
            reject(Strings.CHAT_UPDATE_ERROR);
        });
    });
};

export const sendEmailCall = async (from, to, message) => {
    try{
        return await API.graphql(graphqlOperation(chatSendEmail, {
            from, to, message
        }));
    } catch(err) {
        console.log("CHAT_EMAIL_ERROR", err);
        return Strings.CHAT_UPDATE_ERROR
    }

}
