import {DefaultText, ErrorMsg, PageTitle} from "../shared-components/text-styles";
import Strings from "../../assets/strings";
import CustomModal from "../custom-modal";
import React, {useState} from "react";
import PropTypes from 'prop-types';
import AfisofomClient, {AfisofomCredit} from "../../data/models/afisofom-client";
import Colors from "../../assets/colors";
import CustomButton from "../button";
import Fonts from "../../assets/fonts";
import styled from "styled-components";
import {updateAfisofomCredit} from "../../data/services/afisofom/client";

const ButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    margin-top: 50px;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }
`;

const ErrorWrapper = styled.div`
    margin-top: 30px;
    text-align: center;
`;

const ApproveAfisofomRequest = ({ client, isOpen, onClose, onApproved }) => {
    const [loading, setLoading] = useState(false);
    const [requestError, setRequestError] = useState(null);

    const onApprove = () => {
        setLoading(true);
        client.afisofom_credit.stage = AfisofomCredit.STAGE_APPROVED;
        client.afisofom_credit.generateAmortizationCalendar();

        updateAfisofomCredit(
            client.id,
            client.email,
            client.afisofom_credit
        ).then(() => {
            setLoading(false);
            onApproved();
        }).catch(error => {
            setLoading(false);
            setRequestError(error);
        })
    };

    return (
        <CustomModal onClose={() => onClose()}
                     isOpen={isOpen}>
            <PageTitle>
                {Strings.APPROVE_REQUEST} {client.name}
            </PageTitle>
            <br/>
            <br/>
            <DefaultText>
                {Strings.APPROVE_REQUEST_MSG}
            </DefaultText>
            <form noValidate autoComplete="off">
                <ButtonsWrapper>
                    <CustomButton label={Strings.CANCEL}
                                  onClick={() => onClose()}
                                  align="center"
                                  color={Colors.DARK_GRAY}
                                  background={Colors.WHITE}
                                  font={Fonts.BOLD}
                                  fullWidth={false} />
                    <CustomButton label={Strings.APPROVE}
                                  onClick={() => onApprove()}
                                  align="center"
                                  isLoading={loading}
                                  color={Colors.WHITE}
                                  background={Colors.DARK_GRAY}
                                  font={Fonts.BOLD}
                                  fullWidth={false} />
                </ButtonsWrapper>
            </form>
            {
                requestError &&
                <ErrorWrapper>
                    <ErrorMsg>
                        {requestError}
                    </ErrorMsg>
                </ErrorWrapper>
            }
        </CustomModal>
    );
};

ApproveAfisofomRequest.propTypes = {
    client: PropTypes.instanceOf(AfisofomClient).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onApproved: PropTypes.func.isRequired,
};

export default ApproveAfisofomRequest;
