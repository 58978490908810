import React, {useState} from 'react';
import styled from 'styled-components';
import {ReactComponent as IdentitySVG} from "../assets/svg/identity.svg";
import {ReactComponent as EmptyFileSVG} from "../assets/svg/metro-file-empty.svg";
import PropTypes from 'prop-types';
import Colors from "../assets/colors";
import {DefaultText} from "./shared-components/text-styles";
import Strings from "../assets/strings";
import {getUrl, uploadFile} from "../data/services/s3";
import {randomString} from "../data/services/utils";
import LoadingIndicator from "./loading-indicator";
import {ReactComponent as UploadSVG} from "../assets/svg/feather-upload.svg";
import {ReactComponent as UploadSVGWhite} from "../assets/svg/feather-upload-white.svg";

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 4fr 2fr;
    grid-gap: 1em;
    min-height: 80px;
    background-color: ${props => props.background};
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        grid-gap: 0.5em;
    }
`;

const IconWrapper = styled.div`
    width: 80px;
    min-height: 80px;
    background-color: ${Colors.DARK_GRAY};
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
        width: 40px;
        height: 40px;
    }
`;

const TextContainerLeft = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em 0;
`;

const TextContainerRight = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1em;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        display: block;
        text-align: right;
        
        .space {
            margin-top: 10px;
        }
    }
`;

const CustomLink = styled(DefaultText)`
    cursor: pointer;
    text-decoration: underline;
    color: ${Colors.DARK_GRAY};
`;

const CustomLinkLabel = styled.label`
    cursor: pointer;
    text-decoration: underline;
    color: ${Colors.DARK_GRAY};
`;

const CustomInputFileLarge = ({ isUploaded, label, background, fileKey, accept, id, onChangeKey, disabled, isID, white }) => {
    const [key, setKey] = useState(fileKey);
    const [isLoading, setIsLoading] = useState(false);

    const onDownload = async () => {
        setIsLoading(true);

        try {
            const res = await getUrl(key);
            window.open(res);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    const onChange = async (e) => {
        setIsLoading(true);
        const file = e.target.files[0];
        const file_name = randomString(8, 'hex') + '_' + file.name;

        try {
            const res = await uploadFile(file, file_name);
            setKey(res.key);
            setIsLoading(false);
            onChangeKey(res.key);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    return (
        <div>
            <Wrapper background={background}
                     isUploaded={isUploaded}
                     disabled={disabled} >
                <IconWrapper>
                    {
                        !isUploaded
                            ? (white ? <UploadSVGWhite className="icon" style={{stroke: "white"}} /> : <UploadSVG className="icon" style={{stroke: "white"}} />)
                            : (isID ? <IdentitySVG className="icon" /> : <EmptyFileSVG className="icon" />)
                    }
                </IconWrapper>
                <TextContainerLeft>
                    <DefaultText>{label}</DefaultText>
                </TextContainerLeft>
                <TextContainerRight>
                    {
                        isUploaded &&
                        <CustomLink onClick={() => onDownload()}>
                            {Strings.AFISOFOM_DOCS_DOWNLOAD}
                        </CustomLink>
                    }
                    {
                        !disabled &&
                        <input
                            onChange={event => onChange(event)}
                            accept={accept}
                            style={{ display: 'none' }}
                            type="file"
                            id={id}
                        />
                    }
                    {
                        !isLoading && !disabled &&
                        <div className="space">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <CustomLinkLabel htmlFor={id}>
                                {Strings.AFISOFOM_DOCS_UPDATE}
                            </CustomLinkLabel>
                        </div>
                    }
                </TextContainerRight>
            </Wrapper>
            {
                isLoading &&
                <LoadingIndicator />
            }
        </div>
    );
};

CustomInputFileLarge.propTypes = {
    isUploaded: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    background: PropTypes.string,
    fileKey: PropTypes.string,
    accept: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onChangeKey: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isID: PropTypes.bool
};

CustomInputFileLarge.defaultProps = {
    background: Colors.LIGHT_GRAY,
    disabled: false,
    isID: false
};

export default CustomInputFileLarge;
