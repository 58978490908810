import React, {Component} from "react";
import styled from "styled-components";
import Colors from "../../assets/colors";
import Fonts from "../../assets/fonts";
import Strings from "../../assets/strings";
import CustomInput from "../input";
import CustomButton from "../button";
import {forgotPassword} from "../../data/services/auth";
import PropTypes from 'prop-types';

const FormWrapper = styled.div`
    width: 100%;
    min-height: calc(100vh - 110px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ForgotContainer = styled.div`
    width: 50%;
    min-height: 500px;
    height: auto;
    box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.16);
    display: flex;
    background-color: ${Colors.WHITE};
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        width: 100%;
        box-shadow: none;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 100%;
    }
`;

const FormContainer = styled.div`
    padding: 30px 60px;
    margin: auto 0;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        padding: 0;
        margin-top: 50px;
        margin-bottom: 50px;
    }
`;

const FormTitle = styled.label`
    font-family: ${Fonts.BOLD};
    font-size: 1.5em;
    color: ${Colors.BLACK};
`;

const CenterContainer = styled.div`
    text-align: center;
`;

const FormError = styled.span`
    font-family: ${Fonts.MEDIUM};
    font-size: 0.8em;
    color: ${Colors.ERROR};
`;

const FormSubtitle = styled.p`
    font-family: ${Fonts.MEDIUM};
    font-size: 1em;
    color: ${Colors.BLACK};
`;

class AdminsForgotPasswordForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            submitted: false,
            errors: {
                email: true,
            },
            isLoading: false,
            error: null
        };
    }

    render() {
        return (
            <FormWrapper>
                <ForgotContainer>
                    <FormContainer>
                        <FormTitle>
                            {Strings.FORGOT_PASSWORD_TITLE}
                        </FormTitle>
                        <br />
                        <FormSubtitle>
                            {Strings.FORGOT_PASSWORD_SUBTITLE}
                        </FormSubtitle>
                        <br />
                        <form noValidate autoComplete="off">
                            <CustomInput label={Strings.EMAIL + ' *'}
                                         name="email"
                                         id="email"
                                         value={this.state.email}
                                         color={Colors.BLACK}
                                         required={true}
                                         submitted={this.state.submitted}
                                         invalid={this.state.errors['email']}
                                         onChange={(val, v) => this.updateState('email', val, v)}
                                         type="email" />
                            <br/>
                            <br/>
                            <CenterContainer>
                                <CustomButton label={Strings.RECOVER}
                                              align="center"
                                              onClick={() => this.handleSubmit()}
                                              color={Colors.WHITE}
                                              isLoading={this.state.isLoading}
                                              fullWidth={false}
                                              background={Colors.BLACK}
                                              font={Fonts.BOLD} />
                                {
                                    this.state.error &&
                                    <FormError>
                                        <br/>
                                        {this.state.error}
                                    </FormError>
                                }
                            </CenterContainer>
                        </form>
                    </FormContainer>
                </ForgotContainer>
            </FormWrapper>
        );
    }

    /**
     * Update state vars
     * @author @leonard_lib
     * @date 29/07/2020
     * @param prop
     * @param value
     * @param valid
     */
    updateState(prop, value, valid) {
        const state = this.state;
        const errors = state.errors;

        state[prop] = value;
        errors[prop] = !valid;
        state.errors = errors;

        this.setState(state);
    };

    /**
     * Check if email is filled
     * @author @leonard_lib
     * @date 29/07/2020
     * @returns {boolean}
     */
    checkCompleted() {
        return !this.state.errors['email'];
    }

    /**
     * Handle submit button click
     * @author @leonard_lib
     * @date 29/07/2020
     */
    handleSubmit() {
        this.setState({
            submitted: true,
            error: null
        }, () => {
            if (this.checkCompleted()) {
                this.setState({
                    isLoading: true
                }, () => {
                    this.makeCall();
                })
            }
        });
    };

    /**
     * Make call to request code
     * @author @leonard_lib
     * @date 29/07/2020
     */
    makeCall() {
        forgotPassword(
            this.state.email
        ).then(() => {
            this.props.onRecover(this.state.email);
        }).catch(error => {
            this.setState({
                isLoading: false,
                error: error
            });
        });
    }
}

AdminsForgotPasswordForm.propTypes = {
    onRecover: PropTypes.func.isRequired
};

export default AdminsForgotPasswordForm;
