import React from "react";
import LayoutAdmins from "../../components/layout-admins";
import AdminDashboardLayout from "../../components/admins/dashboard-layout";
import AdvisorsList from "../../components/admins/advisors-list";
import {useChangeTitleFavicon} from "../../hooks/useChangeTitle";
import Strings from "../../assets/strings";

const AdminsAdvisors = ({ history }) => {
    useChangeTitleFavicon(Strings.TITLE_ADVISORS_ADMIN);
    return (
        <LayoutAdmins history={history}
                      tabAdminSelected={1}>
            <AdminDashboardLayout selected={1} >
                <AdvisorsList history={history} />
            </AdminDashboardLayout>
        </LayoutAdmins>
    );
}

export default AdminsAdvisors;
