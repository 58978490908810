import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types"

import { SquareButton } from "./shared-components/button-styles";

const ButtonGroup = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10%;
  grid-row-gap: 1em;
`

const StyledButtonOne = styled(SquareButton) `
  grid-row: 2;
  grid-column: 1;
`

const StyledButtonTwo = styled(SquareButton) `
  grid-row: 2;
  grid-column: 2;
`

class PMoralButtonSwitch extends Component {

  handleButtonPersona(tipoPersona) {
    this.props.onChange(tipoPersona);
  }

	render () {
    return (
      <ButtonGroup>
        <span>{this.props.groupLabel}</span>
        <StyledButtonOne
          onClick={() => this.handleButtonPersona("fisica")}
          variant="contained"
          className={ this.props.personaMoral ? "inactive": ""}
          bgcolor={this.props.bgColor}
          >
            { this.props.pFisicaLabel }
        </StyledButtonOne>
        <StyledButtonTwo
          onClick={() => this.handleButtonPersona("moral")}
          variant="contained"
          className={ this.props.personaMoral ? "": "inactive"}
          bgcolor={this.props.bgColor}
          >
            { this.props.pMoralLabel }
        </StyledButtonTwo>
      </ButtonGroup>
    )
  }
}


PMoralButtonSwitch.propTypes = {
    groupLabel: PropTypes.string,
    personaMoral: PropTypes.bool.isRequired,
    pFisicaLabel: PropTypes.string,
    pMoralLabel: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    bgcolor: PropTypes.string
};

PMoralButtonSwitch.defaultProps = {
    groupLabel: "Solicitar como:",
    pFisicaLabel: "Persona física",
    pMoralLabel: "Persona moral",
};

export default PMoralButtonSwitch;