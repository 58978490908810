/**
 * Strings in the app
 * @author @leonard_lib
 * @date 29/07/2020
 */
export default class Strings {
  static AFISOFOM = "Afisofom";
  static AFISOFOM_PERIOD = "Parcialidad";
  static AFISOFOM_LIMIT_DATE = "Fecha límite";
  static AFISOFOM_DATE = "Fecha";
  static AFISOFOM_AMOUNT = "Cantidad";
  static AFISOFOM_EDIT_INFO = "Editar información";
  static AFISOFOM_GENERAL_INFO = "Detalles generales";
  static AFISOFOM_NO_ANNEXES = "No se encontraron anexos para el cliente.";
  static AFISOFOM_ANNEXES_ADD = "Agregar anexo";
  static AFISOFOM_ANNEXES = "Anexos";
  static AFISOFOM_REGISTER_PAYMENT = "Registrar pago";
  static AFISOFOM_REGISTER_ANNEXE = "Agregar anexo";
  static FILE_NAME = "Nombre del anexo";
  static AFISOFOM_PAYMENTS_NOT_COMPLETED = "Completa la información del pago";
  static AFISOFOM_ANNEXE_NOT_COMPLETED = "Completa la información del anexo";
  static AFISOFOM_PAYMENTS_COMPLETED = "Se registró el pago";
  static AFISOFOM_PAYMENTS_FILE = "Comprobante de pago";
  static AFISOFOM_EDIT_INFO_2 =
    "¿Seguro que deseas editar la información del cliente";
  static UPDATE = "Actualizar";
  static AFISOFOM_DOCS = "Documentos";
  static AFISOFOM_DOCS_DOWNLOAD = "Descargar";
  static AFISOFOM_CREDIT_BURO = "Consulta de buró de crédito";
  static AFISOFOM_DOCS_UPDATE = "Actualizar";
  static AFISOFOM_PHOTO_ID_FRONT = "Identificación (frente)";
  static AFISOFOM_PHOTO_ID_REVERSE = "Identificación (reverso)";
  static NEW = "Nuevo";
  static AFISOFOM_REQUEST = "Solicitar crédito";
  static AFISOFOM_REQUEST_2 = "Solicitud de crédito";
  static AFISOFOM_REQUEST_DATE = "Fecha de solicitud";
  static AFISOFOM_REQUEST_DATE_2 = "Solicitud enviada el";
  static AFISOFOM_REQUEST_AMOUNT = "Cantidad solicitada";
  static AFISOFOM_REMAINING_AMOUNT = "Adeudo";
  static AFISOFOM_PENDING_PAYMENTS = "Pagos pendientes";
  static AFISOFOM_DONE_PAYMENTS = "Pagos realizados";
  static AFISOFOM_ON_TIME = "A tiempo";
  static AFISOFOM_OVERTIME = "Retrasado";
  static AFISOFOM_PAYMENTS_STATUS = "Estatus de pagos";
  static AFISOFOM_CREDIT_TYPE = "Tipo de crédito";
  static AFISOFOM_CREDIT_INFO = "Información del crédito";
  static AFISOFOM_ANNUAL_INTEREST = "Tasa de interés anual";
  static AFISOFOM_SHOW_TABLES = "Mostrar tablas de pagos";
  static QTY = "Cantidad";
  static TERM = "Plazo";
  static ADVISOR = "Asesor";
  static STATUS = "Estatus";
  static CLEAR = "Limpiar";
  static ACTION = "Acción";
  static AFISOFOM_LOGIN_TITLE = "Accede para dar seguimiento a tu crédito";
  static AFISOFOM_LOGIN_IMAGE =
    "Somos una empresa experta en financiamientos estructurados y comerciales";
  static AFISOFOM_LOGIN_SUB =
    "Si no has solicitado un crédito, puedes hacerlo ";
  static AFISOFOM_LOGIN_SUB_2 = "aquí.";
  static MONTHS = "meses";
  static GET_AFISOFOM_DATA_ERROR = "No se pudo obtener el usuario de afisofom.";
  static UPDATE_AFISOFOM_CREDIT_ERROR =
    "No se pudo actualizar el crédito del usuario.";
  static DECLINE_REASON = "Motivo de rechazo";
  static DECLINE_REQUEST = "Rechazar solicitud de";
  static APPROVE_REQUEST = "Aprobar solicitud de";
  static APPROVE_REQUEST_MSG =
    "Al aprobar la solicitud de crédito estás conforme con la información definida actualmente: cantidad requerida, plazo, tipo de crédito, tasa de interés. Aprueba solamente si estás seguro de que la información del usuario es correcta.";
  static DECLINE_REQUEST_MSG_1 = "Para rechazar la solicitud de";
  static DECLINE_REQUEST_MSG_2 =
    "escribe los motivos del rechazo, y en el siguiente cuadro de texto escribe la palabra";
  static DECLINE_REQUEST_MSG_3 =
    ". Inmediatamente le notificaremos al usuario que su crédito ha sido rechazado.";
  static DECLINE_REQUEST_REASON = "Razón de rechazo";

  // AFISERVICIOS
  static AFISERVICIOS = "Afiservicios";
  static AFISERVICIOS_ADVISOR = "Asesor Afiservicios";
  static AFISERVICIOS_REQUEST = "Solicitar asesoría";
  static AFISERVICIOS_REQUESTED_SERVICES = "Servicio requerido";
  static AFISERVICIOS_LOGIN_TITLE = "Accede para seguimiento de asesoría";
  static AFISERVICIOS_LOGIN_IMAGE =
    "APOYAMOS EL DESARROLLO DE CONJUNTOS INMOBILIARIOS, YA SEA POR LA VÍA DEL CAPITAL Y/O FINANCIAMIENTO";
  static AFISERVICIOS_LOGIN_SUB =
    "Si no has solicitado una asesoría, puedes hacerlo ";
  static AFISERVICIOS_LOGIN_SUB_2 = "aquí.";
  static AFISERVICIOS_DONE = "Terminar";
  static CLOSE_ADVISORY = "Terminar seguimiento de asesoría";
  static CLOSE_ADVISORY_MSG =
    "¿Estás seguro que deseas cerrar el seguimiento de la asesoría del cliente";
  static CLOSE_ADVISORY_MSG_2 = "?";
  static COMMENTS = "Comentarios";
  static GET_AFISERVICIOS_DATA_ERROR =
    "No se pudo obtener el usuario de afiservicios.";
  static UPDATE_AFISERVICIOS_FORM_ERROR =
    "No se pudo actualizar el usuario de afiservicios.";
  static NO_ADVISOR_ASSIGNED_ERROR =
    "No cuentas con un asesor asignado, estaremos en contacto contigo pronto.";

  // REGISTRO
  static AFISOFOM_REGISTER_SUBTITLE =
    "Para continuar con la solicitud, es necesario que te registres en nuestra plataforma, también podrás consultar tus estados de cuenta y el estatus de tu crédito.";
  static AFISOFOM_REGISTER_ERROR =
    "Ocurrió un error al registrar tus datos, intenta de nuevo.";
  static AFISERVICIOS_REGISTER_ERROR =
    "Ocurrió un error al registrar tus datos, intenta de nuevo.";

  // SOLICITUD UNO / FORM ONE
  static AFISOFOM_FORMONE_TITLE = "SIMULADOR DE CRÉDITO";
  static AFISOFOM_FORMONE_DESCRIPTION =
    "Para iniciar con el proceso de tu solicitud, solo requerimos la siguiente información.";
  static AFISOFOM_FORMONE_BTN_PFISICA = "Persona física";
  static AFISOFOM_FORMONE_BTN_PMORAL = "Persona moral";
  static AFISOFOM_FORMONE_LABEL_SLIDER = "Monto";
  static AFISOFOM_FORMONE_BTNGROUP_TEXT = "Solicitar como:";
  static AFISOFOM_FORMONE_TABLETITLE = "Calendario de amortización.";
  static AFISOFOM_TABLE = "Calendario de amortización";
  static AFISOFOM_FORMONE_DISCLAIMER_2 =
    "**Las cantidades mostradas aquí representan el crédito con una tasa de interés del 18%, este monto está sujeto a la revisión del comité.";
  static AFISOFOM_FORMONE_DISCLAIMER_1 =
    "*La información puede cambiar de acuerdo al proceso de crédito.";
  static AFISOFOM_FORMONE_ERROR_HELPER =
    "Es necesario seleccionar un tipo de crédito.";
  static AFISOFOM_FORMONE_INICIO_SESS_PROMPT =
    "Si ya tienes un crédito activo, puedes acceder a los detalles ";
  static AFISOFOM_FORMONE_INICIO_SESS_LINK_TEXT = "iniciando sesión.";
  static AFISOFOM_FORMONE_CAL_BTN = "CALENDARIO DE AMORTIZACIÓN";
  static AFISOFOM_FORMONE_SUBMIT_BTN = "SOLICITAR CRÉDITO";
  static AFISOFOM_ADVISOR = "Asesor Afisofom";
  static APPROVE = "Aprobar";
  static DECLINE = "Rechazar";
  static CANCEL = "Cancelar";
  static ACCEPT = "Aceptar";
  static DECLINE_2 = "rechazar";
  static MONTHS_6 = "6 meses";
  static MONTHS_12 = "12 meses";
  static MONTHS_18 = "18 meses";
  static MONTHS_24 = "24 meses";
  static MONTHS_30 = "30 meses";
  static MONTHS_36 = "36 meses";

  // SOLICITUD REQUERIDA
  static AFISOFOM_SOLREQ_POP_TITLE = "En un momento comenzará la descarga";
  static AFISOFOM_SOLREQ_POP_P1 =
    "El archivo a descargar contiene algunos de los documentos que deben ser llenados con la información requerida para la solicitud, en el siguiente paso te solicitaremos los siguentes documentos:";
  static AFISOFOM_SOLREQ_POP_LI1 = "Solicitud de crédito llenada";
  static AFISOFOM_SOLREQ_POP_LI2 = "Consulta de buró de crédito llenada";
  static AFISOFOM_SOLREQ_POP_LI3 = "Identificación oficial (por los dos lados)";
  static AFISOFOM_SOLREQ_POP_P2 =
    "A los documentos se anexa el Aviso de privacidad para tu conocimiento.";
  static AFISOFOM_SOLREQ_POP_BTN = "CERRAR";
  static AFISOFOM_SOLREQ_MAIN_TITLE = "SOLICITUD DE CRÉDITO";
  static AFISOFOM_SOLREQ_WELCOME_1 = "¡Bienvenido ";
  static AFISOFOM_SOLREQ_WELCOME_2 =
    "! Ahora estás más cerca de completar la solicitud para tu crédito";
  static AFISOFOM_SOLREQ_MAIN_TXT_1 =
    "Te hemos enviado por correo electrónico las instrucciones y la lista de documentos necesarios para continuar con el proceso, una vez que tengas todoa  la mano, da click en el botón ";
  static AFISOFOM_SOLREQ_MAIN_LINK_1 = "Continuar";
  static AFISOFOM_SOLREQ_MAIN_TXT_2 =
    ". Si requieres tiempo para el llenado, puedes realizarlo posteriormente accediendo a ";
  static AFISOFOM_SOLREQ_MAIN_LINK_2 = "clientes.afisofom.com";
  static AFISOFOM_SOLREQ_MAIN_TXT_3 =
    " e iniciando sesión con tu correo y contraseña, de igual forma los documentos pueden ser enviados a hgomez@afisofom.com";
  static AFISOFOM_SOLREQ_SUB_BTN = "CONTINUAR";
  static AFISOFOM_SOLREQ_BOTTOM_LINK = "No recibi los formatos";

  // CONFIRMAR SOLICITUD
  static AFISOFOM_CONFIRMFORM_TITLE = "CONFIRMAR SOLICITUD";
  static AFISOFOM_CONFIRMFORM_DESCRIPTION =
    "Para continuar con el proceso de tu solicitud, solo requerimos que subas los siguientes documentos y verifiques que la información de tu solicitud es la correcta.";
  static AFISOFOM_CONFIRMFORM_BTN_PFISICA = "Persona física";
  static AFISOFOM_CONFIRMFORM_BTN_PMORAL = "Persona moral";
  static AFISOFOM_CONFIRMFORM_LABEL_SLIDER = "Monto";
  static AFISOFOM_CONFIRMFORM_BTNGROUP_TEXT = "Solicitar como:";
  static AFISOFOM_CONFIRMFORM_TABLETITLE = "Calendario de amortización.";
  static AFISOFOM_CONFIRMFORM_DISCLAIMER_2 =
    "**Las cantidades mostradas aquí representan el crédito con una tasa de interés del 18%, este monto está sujeto a la revisión del comité.";
  static AFISOFOM_CONFIRMFORM_DISCLAIMER_1 =
    "*La información puede cambiar de acuerdo al proceso de crédito.";
  static AFISOFOM_CONFIRMFORM_ERROR_HELPER =
    "Es necesario seleccionar un tipo de crédito.";
  static AFISOFOM_CONFIRMFORM_CAL_BTN = "CALENDARIO DE AMORTIZACIÓN";
  static AFISOFOM_CONFIRMFORM_SUBMIT_BTN = "SOLICITAR CRÉDITO";

  // MULTI LAYER PROPERTIES
  static MLP = "MLP";

  static DEPLOY_CONFIRM = "Desplegar sitio de {1} de {2}";
  static DEPLOY_CONFIRM_DESCRIPTION = `¿Estás seguro de realizar el despliegue? Los cambios serán visibles en el sitio <b><a target="_blank" href="{1}">{2}</a></b>.<br>
                   Es recomendable revisar la <b><a target="_blank" href="https://us-east-2.console.aws.amazon.com/amplify/home?region=us-east-2#/">consola de AWS</a></b> para revisar el estado de los despliegues.                         
`;
  static DEPLOY_DEV = "Desplegar sitio de prueba";
  static DEPLOY_SITE = "Desplegar sitio";
  static DEPLOY = "Desplegar";
  static DEPLOY_ERROR = "Hubo un error al desplegar el sitio";

  static ADMINS_LOGIN_TITLE = "Acceso para administradores";
  static CLIENTS = "Clientes";
  static GET_ADMIN_CLIENTS_ERROR = "No se pudo obtener los clientes.";
  static GET_ADMIN_ADVISORS_ERROR =
    "Hubo un error al mandar el correo de registro a los administradores.";
  static GET_ADMINS_ERROR = "No se pudo obtener los administradores.";
  static CLIENTS_SUBTITLE =
    "Seguimiento para los clientes de las plataformas Afisofom y Afiservicios.";
  static ADVISORS_SUBTITLE =
    "Administra a los ejecutivos que tendrán acceso y podrán ver información de los clientes.";
  static ADVISORS = "Asesores";
  static SITES = "Sitios";
  static SITES_SUBTITLE =
    "Administración de los sitios de Afisofom, Afiservicios y MLP.";
  static SHOW_DECLINED_APPROVED = "Mostrar rechazados y terminados";
  static SEARCH = "Buscar";
  static MANAGE = "Gestionar";
  static CREATE_ADVISOR = "Crear asesor";
  static MANAGE_ADVISOR = "Gestionar asesor";
  static MANAGE_CLIENT = "Gestionar cliente";
  static MANAGE_ADVISOR_2 = "Modifica los datos personales del asesor";
  static MANAGE_CLIENT_2 =
    "Modifica los datos personales del cliente y la solicitud de su crédito para la aprobación del mismo";
  static GENERAL_INFO = "Información general";
  static PERSONAL_INFO = "Datos personales";
  static CHAT = "Conversación";
  static SAVE = "Guardar";
  static ADVISOR_REGISTER_ERROR =
    "Ocurrió un error al registrar el asesor, intenta de nuevo.";
  static SEND_ADVISOR_INVITE_ERROR =
    "Ocurrió un error al enviar la invitación al asesor, intenta de nuevo.";
  static ADVISOR_INVITED =
    "Se envió correctamente la invitación por correo al asesor.";
  static GET_ADVISOR_DATA_ERROR = "No se pudo obtener el usuario de afisofom.";
  static UPDATE_CLIENT_BASIC_DATA_ERROR = "No se pudo actualizar el usuario.";
  static UPDATED_CLIENT_BASIC_DATA = "Se actualizó la información del usuario";

  static ADDRESS_1 = "Minerva 56,";
  static ADDRESS_2 = "Col. Crédito Constructor";
  static ADDRESS_3 = "C.P. 03940 CDMX";
  static PHONE_1 = "Tel. 1: (55) 26140920";
  static PHONE_2 = "Tel. 2: 5273 3929";
  static MAP_URL = "https://goo.gl/maps/cgWQy6Y7CH6VwGH9A";
  static REMEMBER_USER = "Recordar usuario";
  static LOGOUT = "Cerrar sesión";
  static REGISTER = "Registro";
  static REGISTER_2 = "Registrar";
  static REGISTER_ME = "Registrarme";
  static NAME = "Nombre";
  static NAME_2 = "Nombre (representante legal)";
  static LR_NAME = "representante legal";
  static BUSINESS_NAME = "Razón social";
  static ADDRESS = "Dirección";
  static LASTNAME = "Apellido paterno";
  static LASTNAME_2 = "Apellido paterno (representante legal)";
  static STREET_LINE = "Calle y número";
  static COLONY = "Colonia";
  static CITY = "Ciudad";
  static STATE = "Estado";
  static POLICY = "aviso de privacidad";
  static POLICY_AGREE = "Estoy de acuerdo con el";
  static ZIPCODE = "Código postal";
  static MOTHER_LASTNAME = "Apellido materno";
  static MOTHER_LASTNAME_2 = "Apellido materno (representante legal)";
  static PHONE = "Teléfono";
  static SIGNIN = "Ingresar";
  static FORGOT_PASSWORD = "¿Olvidaste tu contraseña?";
  static EMAIL = "Correo electrónico";
  static AFISERVICIOS_TYPE = "Servicio requerido";
  static PASSWORD = "Contraseña";
  static PASSWORD_CONFIRM = "Confirmar contraseña";
  static FIELD_REQUIRED = "Este campo es obligatorio";
  static INVALID_EMAIL = "Ingresa un email valido";
  static FIELD_OVERLOAD_SIZE = "Ingresa un valor correcto";
  static INVALID_VALUE = "El valor es incorrecto";
  static RECOVER = "Recuperar";
  static FORGOT_PASSWORD_TITLE = "Recuperación de contraseña";
  static RESTORE_PASSWORD_TITLE = "Restaurar contraseña";
  static FORGOT_PASSWORD_SUBTITLE =
    "Ingresa tu correo electrónico para recuperar tu contraseña. Te enviaremos un mensaje con el código para restablecer tu contraseña.";
  static RESTORE_PASSWORD_SUBTITLE =
    "Por favor introduce el código que enviamos a tu correo electrónico y tu nueva contraseña.";
  static CODE = "Código";
  static PASSWORD_NOT_MATCH = "Las contraseñas no coinciden";
  static PASSWORD_HINT =
    "NOTA: Para continuar, asegúrate que la contraseña contenga:";
  static PASSWORD_LENGTH = "Mínimo 8 caracteres";
  static PASSWORD_UPPER = "Al menos una letra mayúscula";
  static PASSWORD_LOWER = "Al menos una letra minúscula";
  static PASSWORD_SYMBOL = "Al menos un caracter simbólico";
  static ZIPCODE_ERROR =
    "Hubo un error al obtener la información del código postal";
  static USER_EXISTS_ERROR =
    "No se pudo verificar si el usuario existe, intenta de nuevo.";
  static GET_ADMIN_DATA_ERROR =
    "No se pudo obtener la información del usuario.";
  static ALREADY_REGISTER = "El usuario ya está registrado";
  static ALREADY_REGISTER_INFO =
    "Detectamos que ya tienes una cuenta registrada en nuestras plataformas (AFISofom o AFIServicios), te invitamos a ";
  static ALREADY_REGISTER_INFO_2 = "iniciar sesión";
  static ALREADY_REGISTER_INFO_3 =
    " para continuar con el proceso, o registrar un correo electrónico diferente.";
  static CONFIRM_SIGNUP_TITLE = "Confirmar registro";
  static CONFIRM_SIGNUP = "Confirmar";
  static CONFIRM_SIGNUP_SUBTITLE =
    "Por favor introduce el código que enviamos a tu correo electrónico para confirmar tu registro.";
  static CONFIRM_SIGNUP_SUBTITLE_2 =
    "Por favor introduce tu nueva contraseña y el código que enviamos a tu correo electrónico para confirmar tu registro.";
  static SEND_ATTACHMENTS_ERROR =
    "Ocurrió un error al enviar los archivos, intenta de nuevo.";
  static ADD_USER_TO_GROUP_ERROR =
    "Ocurrió un error al agregar el usuario al grupo.";
  static SEND_CONFIRM_CREDIT_ERROR =
    "Ocurrión un error al envíar el correo al asesor.";

  static TABLE_NO_DATA = "No hay información para mostrar";
  static TABLE_DATA_COUNT = "{from}-{to} de {count}";
  static TABLE_ROWS = "registros";
  static TABLE_ROWS_PER_PAGE = "registros por página";
  static TABLE_FIRST_PAGE = "Primera página";
  static TABLE_LAST_PAGE = "Última página";
  static TABLE_NEXT_PAGE = "Siguiente página";
  static TABLE_PREVIOUS_PAGE = "Página anterior";
  static LOAD_MORE = "Cargar más";
  static NO_ROLE =
    "No tienes los permisos necesarios para acceder a esta funcionalidad.";

  static STAGE_OPEN = "En captura";

  static NO_ADVISOR = "Asesor no asignado";

  static SITE_AFISOFOM = "afisofom";
  static SITE_AFISERVICIOS = "afiservicios";
  static SITE_MLP = "mlp";

  static NEW_MESSAGE = "Mensaje nuevo";
  static FORMAT_DATE_CHAT = "YYYY-MM-DD HH:mm:ss";
  static CHAT_CREATION_ERROR = "Error al crear el chat";
  static CHAT_UPDATE_ERROR = "Error al actualizar el chat";
  static GET_CHAT_ERROR = "Error al obtener el chat";

  //404 Not Found
  static NOT_FOUND = "Página no encontrada";
  static NOT_FOUND_DESC = `La página que estás buscando no se encuentra disponible, <a href="/">regresa al inicio</a> si deseas visitar otra página.`;

  //Títulos
  static TITLE_LOGIN = "Iniciar sesión";
  static TITLE_CONFIRM_SIGNUP = "Confirmar registro";
  static TITLE_CONFIRM_FORM_AFISOFOM = "Confirmar solicitud";
  static TITLE_FORM_ONE_AFISOFOM = "Solicitud de crédito";
  static TITLE_DASHBOARD = "";
  static TITLE_FORGOT_PASSWORD = "Recuperar contraseña";
  static TITLE_REGISTER = "Registro";
  static TITLE_REQUIRED_FORM_AFISOFOM = "Solicitud requerida";
  static TITLE_RESTORE_PASSWORD = "Restaurar contraseña";

  static TITLE_CLIENTS_ADMIN = "Clientes";
  static TITLE_CREATE_ADVISOR_ADMIN = "Crear asesor";
  static TITLE_SITES_ADMIN = "Sitios";
  static TITLE_ADVISORS_ADMIN = "Asesores";
}
