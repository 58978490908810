import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Drawer from '@material-ui/core/Drawer';
import {
    FaMap,
    FaPhone
} from "react-icons/fa"
import Colors from "../../assets/colors";
import Fonts from "../../assets/fonts";
import Strings from "../../assets/strings";
import AdminDashboardMenuItem from "./dashboard-menu-item";
import {ReactComponent as UserIcon} from "../../assets/icons/user.svg";
import {Link} from "react-router-dom";
import {ReactComponent as QuestionIcon} from "../../assets/icons/question.svg";
import {ReactComponent as WorldIcon} from "../../assets/icons/world.svg";
import {getClientData, userIsAuthenticated} from "../../data/services/auth";
import AdminNavbarMenu from "./navbar-menu";
import AdminClient from "../../data/models/admin";

const paperProps = {
    style: {
        width: '60%'
    }
};

const MenuMobileContent = styled.div`
  display: block;
  height: 100vh;
`;

const MenuMobileList = styled.div`
  width: 100%;
  display: block;
  height: 65vh;
  overflow-y: scroll;
  background-color: ${Colors.LIGHT_GRAY};
  
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        height: 80vh;
    }
`;

const MenuMobileFooter = styled.div`
  background-color: ${Colors.DARK_GRAY};
  padding: 0 30px;
  padding-bottom: 15px;
  display: grid;
  height: 35vh;
  justify-content: center;
  align-items: center;
  
  /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        height: 18.5vh;
    }
`;

const MenuMobileIconLegend = styled.div`
  display: grid;
  grid-template-columns: 2fr 8fr;
  justify-content: flex-start;
  align-items: center;
  padding-top: 15px;
  
  .footer-icon {
    font-size: 1.5em;
    color: ${Colors.WHITE};
  }
`;

const MenuMobileFooterText = styled.p`
  font-family: ${Fonts.MEDIUM};
  font-size: 1em;
  color: ${Colors.WHITE};
  margin: 0;
`;

const NavbarLink = styled.div`
    padding: 30px;
    min-height: 40px;
    display: flex;
    justify-content: start;
    align-items: center;
    
    .link {
        font-family: ${Fonts.MEDIUM};
        font-size: 1.5em;
        cursor: pointer;
        text-decoration: none;
        color: ${props => props.site === 'sofom' ? Colors.PRIMARY_AFI_SOFOM : (props.site === 'mlp' ? Colors.PRIMARY_MLP : Colors.PRIMARY_AFI_SERVICIOS)};
    }
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        padding: 10px 30px;
        min-height: 40px;
    
        .link {
            font-size: 1em;
        }
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        padding: 15px 30px;
        min-height: 70px;
    
        .link {
            font-size: 1em;
        }
    }
`;

const MenuUser = styled.div`
    padding: 30px;
    min-height: 40px;
    justify-content: start;
    align-items: center;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        display: flex;
        padding: 10px 30px;
        min-height: 40px;
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        display: flex;
        padding: 15px 30px;
        min-height: 70px;
    }
`;

const MobileNavbar = ({ isOpen, onBackdropClick, selected, history }) => {
    const [isAuth, setIsAuth] = useState(userIsAuthenticated());
    const [client, setClient] = useState(null);

    // on mount
    useEffect(() => {
        const data = getClientData();
        if(data) {
            setClient(new AdminClient(data));
        }
    }, []);
    const onLogout = () => {
        setIsAuth(userIsAuthenticated());
        history.push('/admin/iniciar-sesion');
    };

    return (
        <Drawer PaperProps={paperProps}
                open={isOpen}
                onBackdropClick={() => onBackdropClick()}
                anchor="right">
            <div>
                <MenuMobileContent>
                    <MenuMobileList>
                        {
                            isAuth &&
                            <React.Fragment>
                                <Link to={"/admin/clientes"}>
                                <AdminDashboardMenuItem selected={selected === 0}
                                                   icon={<UserIcon />}
                                                   label={Strings.CLIENTS} />
                                </Link>
                                {
                                    client && !client.isAdvisor &&
                                    <Link to={"/admin/asesores"}>
                                    <AdminDashboardMenuItem selected={selected === 1}
                                                            icon={<QuestionIcon/>}
                                                            label={Strings.ADVISORS}/>
                                    </Link>
                                }
                                {
                                    client && client.isSuperAdmin &&

                                    <Link to={"/admin/sitios"}>
                                    <AdminDashboardMenuItem selected={selected === 2}
                                                            icon={<WorldIcon/>}
                                                            label={Strings.SITES}/>
                                    </Link>
                                }
                            </React.Fragment>
                        }
                        {
                            !isAuth &&
                            <React.Fragment>
                                <NavbarLink site="servicios">
                                    <Link to="/" className="link">
                                        {Strings.AFISERVICIOS}
                                    </Link>
                                </NavbarLink>
                                <NavbarLink site="sofom">
                                    <Link to="/" className="link">
                                        {Strings.AFISOFOM}
                                    </Link>
                                </NavbarLink>
                                <NavbarLink site="mlp">
                                    <Link to="/" className="link">
                                        {Strings.MLP}
                                    </Link>
                                </NavbarLink>
                            </React.Fragment>
                        }
                        {
                            isAuth &&
                            <MenuUser>
                                <AdminNavbarMenu onLogoutUser={() => onLogout()} />
                            </MenuUser>
                        }
                    </MenuMobileList>
                    <MenuMobileFooter>
                        <MenuMobileIconLegend>
                            <FaMap className="footer-icon"/>
                            <MenuMobileFooterText onClick={() => openMap()}>
                                {Strings.ADDRESS_1}
                                <br/>
                                {Strings.ADDRESS_2}
                                <br/>
                                {Strings.ADDRESS_3}
                            </MenuMobileFooterText>
                        </MenuMobileIconLegend>
                        <MenuMobileIconLegend>
                            <FaPhone className="footer-icon"/>
                            <MenuMobileFooterText>
                                {Strings.PHONE_1}
                                <br/>
                                {Strings.PHONE_2}
                                <br/>
                            </MenuMobileFooterText>
                        </MenuMobileIconLegend>
                    </MenuMobileFooter>
                </MenuMobileContent>
            </div>
        </Drawer>
    )
};

/**
 * Open address in new tab
 * @author @leonard_lib
 * @date 29/07/2020
 */
const openMap = () => {
    const url = Strings.MAP_URL;
    window.open(url, '_blank');
};

MobileNavbar.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onBackdropClick: PropTypes.func.isRequired,
    selected: PropTypes.number,
    history: PropTypes.any.isRequired,
};

export default MobileNavbar;
