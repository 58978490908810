/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:a3388746-7fad-4a60-8c57-0a0f35df4435",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_TLraAwfCr",
    "aws_user_pools_web_client_id": "1cuf3888o1lsjk7c0mkdpl6vr9",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://tf3qfsf5abehjdkesd6gsofha4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-xwqgyzhjdbcxbbhryucmcowism",
    "aws_user_files_s3_bucket": "afi-web-s3-bucket132438-develop",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
