import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import NavbarClients from "./navbar-clients";
import "../assets/styles/global.css"


const LayoutWrapper = styled.div``;

const LayoutClients = ({ children, location, history, site, showPrincipalButton }) => (
    <LayoutWrapper>
        <NavbarClients site={site}
                       showPrincipalButton={showPrincipalButton}
                       history={history}
                       location={location} />
        <div style={{ marginTop: '80px' }}>
            <main>{children}</main>
        </div>
    </LayoutWrapper>
);

/**
 * Property types of Layout
 * @author @leonard_lib
 * @date 02/07/2020
 * @type Object
 */
LayoutClients.propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.string,
    site: PropTypes.oneOf([
        'sofom',
        'servicios'
    ]).isRequired,
    showPrincipalButton: PropTypes.bool
};

/**
 * Default property values of Layout
 * @author @leonard_lib
 * @date 02/07/2020
 * @type Object
 */
LayoutClients.defaultProps = {
    location: '/',
    showPrincipalButton: true
};

export default LayoutClients;
