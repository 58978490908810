import React from "react";
import LayoutAdmins from "../../components/layout-admins";
import AdminsForgotPasswordForm from "../../components/admins/forgot-password-form";
import {useChangeTitleFavicon} from "../../hooks/useChangeTitle";
import Strings from "../../assets/strings";

const AdminsForgotPassword = ({ history }) => {

    useChangeTitleFavicon( Strings.TITLE_FORGOT_PASSWORD);
    return (
        <LayoutAdmins history={history} >
            <div className="container">
                <AdminsForgotPasswordForm
                    onRecover={email =>
                        history.push('/admin/restaurar-contrasena?email=' + email)
                    }
                />
            </div>
        </LayoutAdmins>
    );
}

export default AdminsForgotPassword;
