import React, { useState } from "react";
import styled from "styled-components";
import Fonts from "../assets/fonts";
import Colors from "../assets/colors";
import Strings from "../assets/strings";
import { DefaultText, PageTitle } from "./shared-components/text-styles";
import CustomButton from "./button";
import PropTypes from "prop-types";
import { formatCurrency } from "../data/services/utils";
import AfisofomClient, { AfisofomCredit } from "../data/models/afisofom-client";
import DeclineAfisofomRequest from "./admins/decline-request";
import ApproveAfisofomRequest from "./admins/approve-request";
import creditTypeCatalog from "../utils/credit-type-catalog";
import { VARIABLE_RATE } from "./afisofom/form-one-main";

const CardWrapper = styled.div`
  border: solid 1px ${Colors.PRIMARY_AFI_SOFOM};
  padding: 30px;
  margin-top: 10px;
`;

const CardTitle = styled.span`
  font-family: ${Fonts.MEDIUM};
  font-size: 1.25em;
  color: ${Colors.PRIMARY_AFI_SOFOM};
`;

const CardHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1em;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1em;
  }
`;

const CardBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 30px;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-row-gap: 2em;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr;
    grid-row-gap: 2em;
  }
`;

const CardBodyLeft = styled.div`
  text-align: left;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    text-align: center;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    text-align: center;
  }
`;

const CardBodyRight = styled.div`
  display: grid;
  grid-template-columns: ${(props) => "repeat(" + props.elements + ", 1fr)"};
  grid-gap: 1em;
  justify-content: flex-end;
  align-items: flex-end;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    justify-content: center;
    grid-template-columns: 1fr;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    justify-content: center;
  }
`;

const CardHeaderItem = styled.div`
  text-align: center;
`;

const ClientAfisofomCard = ({
  client,
  fromAdmin,
  onManage,
  onDeclined,
  onApproved,
}) => {
  const [declineModalOpen, setDeclineModalOpen] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);

  const onDeclinedInCard = () => {
    setDeclineModalOpen(false);
    onDeclined();
  };

  const onApprovedInCard = () => {
    setApproveModalOpen(false);
    onApproved();
  };
  const getInterestRate = () => {
    const c = creditTypeCatalog.find(
      (v) => v.name === client.afisofom_credit.type
    );
    if (c) {
      if (client.afisofom_credit.rateType === VARIABLE_RATE) {
        return `TIIE + ${Math.floor(
          c.minVariableInterestRate * 100
        )} p.p. a  ${Math.floor(c.maxVariableInterestRate * 100)} p.p.`;
      } else {
        return `${Math.floor(c.minVariableInterestRate * 100)}% - ${Math.floor(
          c.maxVariableInterestRate * 100
        )}`;
      }
    } else {
      return client.afisofom_credit.interest + "%";
    }
  };

  return (
    <div>
      <CardTitle>{Strings.AFISOFOM}</CardTitle>
      <CardWrapper>
        <CardHeader>
          <CardHeaderItem>
            <PageTitle>
              {formatCurrency(client.afisofom_credit.amount)}
            </PageTitle>
            <br />
            <DefaultText>{Strings.AFISOFOM_REQUEST_AMOUNT}</DefaultText>
          </CardHeaderItem>
          <CardHeaderItem>
            <PageTitle transform="none">
              {client.afisofom_credit.term} {Strings.MONTHS}
            </PageTitle>
            <br />
            <DefaultText>{Strings.TERM}</DefaultText>
          </CardHeaderItem>
          <CardHeaderItem>
            <PageTitle transform="none">
              {client.afisofom_credit.type}
            </PageTitle>
            <br />
            <DefaultText>{Strings.AFISOFOM_CREDIT_TYPE}</DefaultText>
          </CardHeaderItem>
          <CardHeaderItem>
            <PageTitle>{getInterestRate()}%</PageTitle>
            <br />
            <DefaultText>{Strings.AFISOFOM_ANNUAL_INTEREST}</DefaultText>
          </CardHeaderItem>
        </CardHeader>
        <CardBody>
          <CardBodyLeft>
            <PageTitle transform="none" color={Colors.PRIMARY_AFI_SOFOM}>
              {client.afisofom_credit.stage}
            </PageTitle>
            <br />
            <DefaultText>
              {Strings.AFISOFOM_REQUEST_DATE_2}{" "}
              {client.afisofom_credit.request_date}
            </DefaultText>
            <br />
            <DefaultText
              color={
                !client.afisofom_credit.advisor_id
                  ? Colors.ERROR
                  : Colors.DARK_GRAY
              }
            >
              {!client.afisofom_credit.advisor_id
                ? Strings.NO_ADVISOR
                : Strings.ADVISOR + ": " + client.afisofom_credit.advisor}
            </DefaultText>
          </CardBodyLeft>
          {fromAdmin && (
            <CardBodyRight
              elements={
                client.afisofom_credit.stage === AfisofomCredit.STAGE_PENDING
                  ? 3
                  : 1
              }
            >
              {client.afisofom_credit.stage ===
                AfisofomCredit.STAGE_PENDING && (
                <CustomButton
                  label={Strings.APPROVE}
                  onClick={() => setApproveModalOpen(true)}
                  align="right"
                  color={Colors.WHITE}
                  background={Colors.DARK_GRAY}
                  font={Fonts.BOLD}
                  fullWidth={false}
                />
              )}
              <CustomButton
                label={Strings.MANAGE}
                onClick={() => onManage()}
                align="right"
                color={Colors.DARK_GRAY}
                background={Colors.WHITE}
                font={Fonts.BOLD}
                fullWidth={false}
              />
              {client.afisofom_credit.stage ===
                AfisofomCredit.STAGE_PENDING && (
                <CustomButton
                  label={Strings.DECLINE}
                  onClick={() => setDeclineModalOpen(true)}
                  align="right"
                  color={Colors.WHITE}
                  background={Colors.PRIMARY_AFI_SOFOM}
                  font={Fonts.BOLD}
                  fullWidth={false}
                />
              )}
            </CardBodyRight>
          )}
        </CardBody>
      </CardWrapper>
      <DeclineAfisofomRequest
        client={client}
        onDeclined={() => onDeclinedInCard()}
        onClose={() => setDeclineModalOpen(false)}
        isOpen={declineModalOpen}
      />
      <ApproveAfisofomRequest
        client={client}
        onApproved={() => onApprovedInCard()}
        onClose={() => setApproveModalOpen(false)}
        isOpen={approveModalOpen}
      />
    </div>
  );
};

ClientAfisofomCard.propTypes = {
  client: PropTypes.instanceOf(AfisofomClient).isRequired,
  fromAdmin: PropTypes.bool.isRequired,
  onManage: PropTypes.func,
  onDeclined: PropTypes.func.isRequired,
  onApproved: PropTypes.func.isRequired,
};

ClientAfisofomCard.defaultProps = {
  onManage: () => {},
};

export default ClientAfisofomCard;
