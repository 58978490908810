import React, {useState} from "react";
import Colors from "../assets/colors";
import CustomInput from "./input";
import {Editor} from "@toast-ui/react-editor";
import crypto from "crypto";
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import {getUrl, uploadFile} from "../data/services/s3";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab"
import CustomButton from "./button";
import Fonts from "../assets/fonts";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {MdClose} from "react-icons/md/index";

const SiteTitleElement = ({element, updateState}) => (
    <div style={{marginTop: "60px"}}>
        <h3>{element.name}</h3>
        {element.data.map((element, key) => (<SiteElement key={key} updateState={updateState} element={element}/>))}
    </div>)

const SiteTextElement = ({element, updateState, closable, removeElement}) => {
    let timeout = null;
    const onChange = (val, v) => {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            element.data = val;
            updateState()
        }, 800);
    }
    return (
        <div>
            <CustomInput label={`${element.name} ${(element.position ? element.position : "")}`}
                         name={crypto.randomBytes(20).toString('hex')}
                         id={crypto.randomBytes(20).toString('hex')}
                         color={Colors.BLACK}
                         required={true}
                         defaultValue={element.data}
                         onChange={onChange}
                         inputProps={{
                             endAdornment: closable ? (
                                 <InputAdornment position="end">
                                     <IconButton tabIndex="-1" onClick={() => removeElement(element)}>
                                         {
                                                 <MdClose/>
                                         }
                                     </IconButton>
                                 </InputAdornment>
                             ): null
                         }}
                         type="text"/>
        </div>
    )
}

const SiteArrayElement = ({element, updateState}) => {
    const addElement = () => {
        element.data.push(
            {
                name: "Etiqueta",
                position: element.data.length + 1,
                type: "text",
                data: "Escribe algo..."
            }
        )
        updateState();
    }

    const removeElement = (rEl) => {
        element.data = element.data.filter ((elem) => {
            return elem !== rEl;
        });
        updateState();
    }

    return (
        <div>
            <h4>{element.name}</h4>
            {element.data.map((el, key) => (
                <SiteElement
                    key={key}
                    updateState={updateState}
                    element={el}
                        {...{ closable:key !== 0,
                            removeElement: key !== 0 ?
                                removeElement :
                                null
                        }
                    }/>
                ))}
            {
                element.appendable && (
                    <div style={{
                        marginTop: "30px",
                        marginBottom: "40px"
                    }}>
                        <CustomButton
                            label={"Añadir"}
                            onClick={addElement}
                            color={Colors.DARK_GRAY}
                            background={Colors.WHITE}
                            containerStyle={{marginTop: "300px !important"}}
                            fullWidth={false} font={Fonts.MEDIUM}/>
                    </div>
                )
            }
        </div>
    )
}
const SiteHTMLElement = ({element, updateState}) => {
    const editorRef = React.createRef();
    let timeout = null;
    const onChange = () => {
        const value = editorRef.current.getInstance().getMarkdown();
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            element.data = value;
            updateState()
        }, 800);
    }
    return (
        <div>
            <h4>{element.name}</h4>
            <Editor
                initialValue={element.data}
                initialEditType={"wysiwyg"}
                useCommandShortcut={true}
                onChange={onChange}
                ref={editorRef}
            />
        </div>
    )
}

const SiteImageElement = ({element, updateState}) => {
    const [open, setOpen] = useState();
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const onChange = async (event) => {
        try {
            const file = event.target.files[0];
            if (file.size < 2e+6) {
                const {key} = await uploadFile(file, file.name, "public");
                const url = await getUrl(key, {});
                element.data = url;
                updateState()
            } else {
                setOpen(true);
            }

        } catch (e) {
        }
    }
    return (
        <div style={{paddingTop: "20px"}}>
            <h4>{element.name}</h4>
            <img style={{
                maxWidth: "calc(100vw - 80px)",
                width: "450px"
            }} src={element.data} alt=""/>
            <CustomInput label={element.name}
                         name={crypto.randomBytes(20).toString('hex')}
                         id={crypto.randomBytes(20).toString('hex')}
                         color={Colors.BLACK}
                         required={true}
                         onChange={onChange}
                         buttonType={"text"}
                         accept={".jpg,.png,.jpeg"}
                         type="file"/>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={handleClose}
                open={open}
                autoHideDuration={6000}
            >
                <Alert severity={"error"}>El tamaño del archivo debe ser menor a 2MB.</Alert>
            </Snackbar>
        </div>
    )
}


const SiteElement = ({element, updateState, closable, removeElement}) => {
    return (
        element.type === "title" ?
            (<SiteTitleElement element={element} updateState={updateState}/>) :
            element.type === "html" ?
                (<SiteHTMLElement element={element} updateState={updateState}/>) :
                element.type === "array" ?
                    (<SiteArrayElement element={element} updateState={updateState}/>) :
                    element.type === "text" ?
                        (<SiteTextElement element={element} closable={closable} updateState={updateState} removeElement={removeElement}/>) :
                        element.type.match(/jpg|png/) ?
                            (<SiteImageElement element={element} updateState={updateState}/>) :
                            null
    )
}

export default SiteElement;




