import React, { Component } from "react";
import styled from "styled-components";
import Fonts from "../../assets/fonts";
import Colors from "../../assets/colors";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
//
const StyledTableContainer = styled(TableContainer)`
  height: 55vh;

  @media only screen and (max-width: 767px) {
    height: 55vh;
  }

  .MuiTableCell-root {
    color: ${Colors.BLACK};
    font-family: ${Fonts.REGULAR};
    font-size: 1em;

    --table-padding-updown: 6.5px;
    --table-padding-sides: 10px;
    padding-top: var(--table-padding-updown);
    padding-bottom: var(--table-padding-updown);
    padding-left: var(--table-padding-sides);
    padding-right: var(--table-padding-sides);
    border-bottom: none;

    @media only screen and (max-width: 470px) {
      --table-padding-sides: 3.5px;
      --table-padding-updown: 7px;
    }
  }

  .MuiTableCell-root.MuiTableCell-body {
    @media only screen and (max-width: 767px) {
      background-color: ${Colors.WHITE};
    }
  }
`;

const StyledTableHead = styled(TableHead)`
  .MuiTableRow-head {
    background-color: ${(props) => props.background};
  }

  .MuiTableCell-head {
    font-family: ${Fonts.BOLD};
    color: ${Colors.WHITE};
  }
`;

function createData(parcialidad, fechaEst, cantidad) {
  return { parcialidad, fechaEst, cantidad };
}

class FormOneTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headers: ["Parcialidad", "Fecha estimada*", "Cantidad*"],
    };
  }

  // Se utilizó la formula en el siguiente vínculo:
  // https://www.vertex42.com/ExcelArticles/amortization-calculation.html
  calculateAmortization(loanAmount, interestRate, payments) {
    let paymentAmmount;
    interestRate = interestRate / 100;
    const interestRatePOne = interestRate + 1;

    const topHalfEq = interestRate * Math.pow(interestRatePOne, payments);
    const bottomHalfEq = Math.pow(interestRatePOne, payments) - 1;
    const divisionAmmnt = topHalfEq / bottomHalfEq;
    paymentAmmount = loanAmount * divisionAmmnt;

    paymentAmmount = Math.round(paymentAmmount * 100) / 100;

    return paymentAmmount;
  }

  generateTableData() {
    const dateTable = {
      0: "enero",
      1: "febrero",
      2: "marzo",
      3: "abril",
      4: "mayo",
      5: "junio",
      6: "julio",
      7: "agosto",
      8: "septiembre",
      9: "octubre",
      10: "noviembre",
      11: "diciembre",
    };

    let paymentMonths = this.props.paymentMonths;
    if (paymentMonths === "") {
      paymentMonths = 6;
    }
    let tableRows = [];
    let row, dateString;
    const value = this.calculateAmortization(
      this.props.loanAmount,
      this.props.interestRate,
      paymentMonths
    );
    const calculatedPayment = this.formatCurrency(value);

    for (let i = 1; i <= paymentMonths; i++) {
      const currentDate = new Date();
      const paymentDate = new Date(
        currentDate.setMonth(currentDate.getMonth() + (i - 1))
      );
      const day = paymentDate.getDate();
      const month = dateTable[paymentDate.getMonth()];
      const year = paymentDate.getFullYear();

      dateString = day + " " + month + " " + year;
      row = createData(i, dateString, calculatedPayment);
      tableRows.push(row);
    }

    return tableRows;
  }

  formatCurrency(value) {
    let formatted = Intl.NumberFormat().format(value);
    formatted = "$" + formatted;
    return formatted;
  }

  render() {
    const tableData = this.generateTableData();

    return (
      <StyledTableContainer>
        <Table size="small">
          <StyledTableHead
            background={
              this.props.headerBackground || Colors.PRIMARY_DARK_AFI_SOFOM
            }
          >
            <TableRow>
              <TableCell align="center">{this.state.headers[0]}</TableCell>
              <TableCell align="center">{this.state.headers[1]}</TableCell>
              <TableCell align="center">{this.state.headers[2]}</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {tableData.map((row) => (
              <TableRow key={row.parcialidad}>
                <TableCell align="center">{row.parcialidad}</TableCell>
                <TableCell align="center">{row.fechaEst}</TableCell>
                <TableCell align="center">{row.cantidad}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    );
  }
}

export default FormOneTable;
