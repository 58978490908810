import React from "react";
import AdminDashboardLayout from "../../components/admins/dashboard-layout";
import LayoutAdmins from "../../components/layout-admins";
import SiteContent from "../../components/site-content";

const AdminSiteDetail = ({history, match}) => {
    return (
        <LayoutAdmins history={history} showBack={true}>
            <AdminDashboardLayout selected={2} >
                <SiteContent history={history} params={match.params} />
            </AdminDashboardLayout>
        </LayoutAdmins>
    );
}

export default AdminSiteDetail
