import React, {Component} from "react";
import styled from "styled-components";
import Fonts from "../../assets/fonts";
import Colors from "../../assets/colors";
import Strings from "../../assets/strings";
import PropTypes from 'prop-types';
import {getClientData} from "../../data/services/auth";
import AdminClient from "../../data/models/admin";
import {
    InfoMsg
} from '../shared-components/text-styles';
import {DivMargin, TabItem, TabsContainer} from "../shared-components/container-styles";
import AdminClientsTable from "./admin-clients-table";
import "../../assets/styles/global.css";

const ClientsListWrapper = styled.div`
    padding: 15px 0;
`;

const PageTitle = styled.span`
    font-family: ${Fonts.BRANDON_BOLD};
    font-size: 2em;
    color: ${Colors.DARK_GRAY};
    text-transform: uppercase;
`;

const PageSubtitle = styled.span`
    font-family: ${Fonts.MEDIUM};
    font-size: 1em;
    color: ${Colors.DARK_GRAY};
`;

const ListContainer = styled.div``;

const NoRole = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 30px;
`;

class ClientsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTab: 0,
            per_page: 1,
            show_afisofom_tab: false,
            show_afiservicios_tab: true,
            by_advisor: false,
            advisor_id: null
        };
    }

    componentDidMount() {
        this.verifyAdminRole();
    }

    render() {
        return (
            <ClientsListWrapper>
                <div>
                    <PageTitle>
                        {Strings.CLIENTS}
                    </PageTitle>
                </div>
                <DivMargin>
                    <PageSubtitle>
                        {Strings.CLIENTS_SUBTITLE}
                    </PageSubtitle>
                </DivMargin>
                <DivMargin>
                    <TabsContainer>
                        {
                            this.state.show_afisofom_tab &&
                            <TabItem site="sofom"
                                     onClick={() => this.setState({ selectedTab: 0 })}
                                     selected={this.state.selectedTab === 0}>
                                <span>{Strings.AFISOFOM}</span>
                            </TabItem>
                        }
                        {
                            this.state.show_afiservicios_tab &&
                            <TabItem site="servicios"
                                     onClick={() => this.setState({ selectedTab: 1 })}
                                     selected={this.state.selectedTab === 1}>
                                <span>{Strings.AFISERVICIOS}</span>
                            </TabItem>
                        }
                    </TabsContainer>
                </DivMargin>
                <ListContainer>
                    {
                        this.state.show_afisofom_tab && this.state.selectedTab === 0 &&
                         <AdminClientsTable byAdvisor={this.state.by_advisor}
                                            advisorId={this.state.advisor_id}
                                            fromAfisofom={true}
                                            goTo={id => this.goToDetail(id)}
                                            perPageGeneral={this.state.per_page} />
                    }
                    {
                        this.state.show_afiservicios_tab && this.state.selectedTab === 1 &&
                        <AdminClientsTable byAdvisor={this.state.by_advisor}
                                           advisorId={this.state.advisor_id}
                                           fromAfisofom={false}
                                           goTo={id => this.goToDetail(id)}
                                           perPageGeneral={this.state.per_page} />
                    }
                    {
                        !this.state.show_afisofom_tab && !this.state.show_afiservicios_tab &&
                        <NoRole>
                            <InfoMsg>{Strings.NO_ROLE}</InfoMsg>
                        </NoRole>
                    }
                </ListContainer>
            </ClientsListWrapper>
        );
    }

    goToDetail(client_id) {
        this.props.history.push('/admin/clientes/' + client_id);
    };

    verifyAdminRole() {
        let client = getClientData();

        if (client != null) {
            client = new AdminClient(getClientData());
            let show_sofom_tab = client.isAfisofomAdminOrAdvisor;
            let by_advisor = client.isAdvisor;
            let advisor_id = by_advisor ? client.id : null;
            let show_servicios_tab = client.isAfiserviciosAdminOrAdvisor;
            let selectedTab = show_sofom_tab ? 0 : 1;

            this.setState({
                show_afisofom_tab: show_sofom_tab,
                by_advisor: by_advisor,
                advisor_id: advisor_id,
                selectedTab: selectedTab,
                show_afiservicios_tab: show_servicios_tab
            });
        }
    }
}

ClientsList.propTypes = {
    history: PropTypes.any.isRequired
};

export default ClientsList;
