/**
 * Fonts in the app
 * @author @leonard_lib
 * @date 29/07/2020
 */
export default class Fonts {
    static REGULAR = '"Raleway Regular", sans-serif';
    static MEDIUM = '"Raleway Medium", sans-serif';
    static BOLD = '"Raleway Bold", sans-serif';
    static SEMIBOLD = '"Raleway SemiBold", sans-serif';
    static BRANDON_BOLD = "'Brandon Bold', sans-serif";
}
