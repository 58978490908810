import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import moment from "moment";
// Assets
import Fonts from "../assets/fonts";
import Colors from "../assets/colors";
import Strings from "../assets/strings";
// Components
import {
  DefaultText,
  PageTitle,
  ErrorText,
} from "./shared-components/text-styles";
import {
  TabItem,
  TabsContainer,
  DivMargin,
} from "./shared-components/container-styles";
import { WhiteButton } from "./shared-components/button-styles";
import LoadingIndicator from "./loading-indicator";
import ChatForm from "../components/chat-form";
import BasicInfoForm from "./basic-info-form";
// Models and data management
import AfisofomClient, { AfisofomCredit } from "../data/models/afisofom-client";
import { formatCurrency } from "../data/services/utils";
import { getAdvisors, getAllAdmins } from "../data/services/admins/clients";
import { updateAfisofomCredit } from "../data/services/afisofom/client";
import { updateClientBasicData } from "../data/services/client";
import AdminClient from "../data/models/admin";
import creditTypeCatalog from "../utils/credit-type-catalog";
import { VARIABLE_RATE } from "./afisofom/form-one-main";

const CardWrapper = styled.div`
  border: solid 1px ${Colors.PRIMARY_AFI_SOFOM};
  padding: 30px;
  margin-top: 10px;
`;

const CardTitle = styled.span`
  font-family: ${Fonts.MEDIUM};
  font-size: 1.25em;
  color: ${Colors.PRIMARY_AFI_SOFOM};
`;

const CardHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1em;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1em;
  }
`;

const CardHeaderBottom = styled(CardHeader)`
  padding-top: 1em;
`;

const CardBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 30px;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-row-gap: 2em;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr;
    grid-row-gap: 2em;
  }
`;

const CardBodyLeft = styled.div`
  text-align: left;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    text-align: center;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    text-align: center;
  }
`;

const CardBodyRight = styled.div`
  display: grid;
  grid-template-columns: ${(props) => "repeat(" + props.elements + ", 1fr)"};
  grid-gap: 1em;
  justify-content: flex-end;
  align-items: flex-end;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    justify-content: center;
    grid-template-columns: 1fr;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    justify-content: center;
  }
`;

const CardHeaderItem = styled.div`
  text-align: center;
`;

const DeclineText = styled(DefaultText)`
  text-align: right;
  padding-right: 10%;
`;

const TitleSmallHeight = styled(PageTitle)`
  line-height: 0.8em;
`;

const ApprovedBtnContainer = styled.div`
  display: grid;
  align-items: right;
  margin-top: 10px;
`;

const UserAfisofomCard = ({ client }) => {
  const [clientAdvisor, setClientAdvisor] = useState(false);
  const [advisorFullName, setAdvisorFullName] = useState("");
  const [advisorName, setAdvisorName] = useState("");
  const [advisorID, setAdvisorID] = useState("");
  const [advisorEmail, setAdvisorEmail] = useState("");
  const [cAdvisorIsLoading, setCAdvisorIsLoading] = useState(false);
  const [wasDelined, setWasDelined] = useState(false);
  const [wasApproved, setWasApproved] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const [currentDebt, setCurrentDebt] = useState(0);
  const [dueMonths, setDueMonths] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [cType, setCType] = useState("");

  const [credInfoTabSelected, setCredInfoTabSelected] = useState(false);
  const [genInfoTabSelected, setGenInfoTabSelected] = useState(true);
  const [convoTabSelected, setConvoTabSelected] = useState(false);
  const [showManage, setShowManage] = useState(false);

  const clickCredInfoTab = () => {
    setCredInfoTabSelected(true);
    setGenInfoTabSelected(false);
    setConvoTabSelected(false);
  };

  const clickGenInfoTab = () => {
    setGenInfoTabSelected(true);
    setCredInfoTabSelected(false);
    setConvoTabSelected(false);
  };

  const clickConvoTab = () => {
    setConvoTabSelected(true);
    setCredInfoTabSelected(false);
    setGenInfoTabSelected(false);
  };

  const manageSOFOM = () => {
    setShowManage(true);
  };

  const onChatCreated = async (chat_id) => {
    client.afisofom_credit.chat_id = chat_id;
    await updateAfisofomCredit(client.id, client.email, client.afisofom_credit);
  };

  const handleSOFOMFormSubmit = (params) => {
    updateClientBasicData(params);
    setShowManage(false);
  };

  const ChatFormPopulated = (advisorID, advisorEmail, advisorName) => {
    if (advisorID) {
      const advisorInfo = {
        id: advisorID,
        email: advisorEmail,
        full_name: advisorFullName,
      };
      const advisor = new AdminClient(advisorInfo);

      return (
        <ChatForm
          fromAdmin={false}
          advisor={advisor}
          client={client}
          onChatCreated={(chatId) => onChatCreated(chatId)}
          isFromAfisofom={true}
          showTitle={true}
          title={client.afisofom_credit.advisor}
        />
      );
    } else {
      return (
        <DivMargin>
          <ErrorText>{Strings.NO_ADVISOR_ASSIGNED_ERROR}</ErrorText>
        </DivMargin>
      );
    }
  };

  const getInterestRate = () => {
    const c = creditTypeCatalog.find(
      (v) => v.name === client.afisofom_credit.type
    );
    if (c) {
      if (client.afisofom_credit.rateType === VARIABLE_RATE) {
        return `TIIE + ${Math.floor(
          c.minVariableInterestRate * 100
        )} p.p. a  ${Math.floor(c.maxVariableInterestRate * 100)} p.p.`;
      } else {
        return `${Math.floor(c.minVariableInterestRate * 100)}% - ${Math.floor(
          c.maxVariableInterestRate * 100
        )}`;
      }
    } else {
      return client.afisofom_credit.interest + "s%";
    }
  };

  // on mount
  useEffect(() => {
    async function setAdvisor() {
      setCAdvisorIsLoading(true);
      let advisors = await getAdvisors();
      advisors.advisors.forEach((advisor) => {
        if (advisor.id === client.afisofom_credit.advisor_id) {
          setClientAdvisor(advisor);
          setAdvisorFullName(
            advisor.name +
              " " +
              advisor.lastname +
              " " +
              advisor.mother_lastname
          );
          setAdvisorName(advisor.name);
          setAdvisorID(advisor.id);
          setAdvisorEmail(advisor.email);
          setCAdvisorIsLoading(false);
          console.log(advisor);
        }
      });
    }

    if (
      client.afisofom_credit.stage === AfisofomCredit.STAGE_DECLINED &&
      client.afisofom_credit.decline_reason
    ) {
      setWasDelined(true);
      setDeclineReason(client.afisofom_credit.decline_reason);
    }

    // Only shows loan info to customers with an approved loan
    if (client.afisofom_credit.stage === AfisofomCredit.STAGE_APPROVED) {
      setWasApproved(true);
      let debtCounter = 0;
      let monthCounter = 0;
      let isLate = false;
      const currentDate = moment();
      client.afisofom_credit.amortization_calendar.forEach((stage) => {
        if (!stage.completed) {
          debtCounter += stage.amount;
          monthCounter += 1;
          const stageDate = moment(stage.payment_date);
          if (currentDate.isAfter(stageDate)) {
            isLate = true;
          }
        }
      });
      setCurrentDebt(debtCounter);
      setDueMonths(monthCounter);
      if (isLate) {
        setPaymentStatus(AfisofomCredit.PAYMENTS_LATE);
      } else {
        setPaymentStatus(AfisofomCredit.PAYMENTS_ON_TIME);
      }
      if (client.afisofom_credit.advisor_id) {
        setAdvisor();
      }
    } else {
      // We only need the advisor info if the loan hasn't been approved
      if (client.afisofom_credit.advisor_id) {
        setAdvisor();
      }
    }
  }, [
    client.afisofom_credit.advisor_id,
    client.afisofom_credit.decline_reason,
    client.afisofom_credit.stage,
    client.afisofom_credit.amortization_calendar,
  ]);

  useEffect(() => {
    console.log(client.afisofom_credit);
    try {
      setCType(JSON.parse(client.afisofom_credit.type).name);
    } catch (e) {
      setCType(client.afisofom_credit.type);
    }
  }, []);

  return (
    <div>
      <CardTitle>{Strings.AFISOFOM}</CardTitle>
      <CardWrapper>
        {showManage && (
          <TabsContainer>
            <TabItem
              site="sofom"
              selected={credInfoTabSelected}
              onClick={clickCredInfoTab}
            >
              {Strings.AFISOFOM_CREDIT_INFO}
            </TabItem>
            <TabItem
              site="sofom"
              selected={genInfoTabSelected}
              onClick={clickGenInfoTab}
            >
              {Strings.GENERAL_INFO}
            </TabItem>
            <TabItem
              site="sofom"
              selected={convoTabSelected}
              onClick={clickConvoTab}
            >
              {Strings.CHAT}
            </TabItem>
          </TabsContainer>
        )}
        {(!showManage || credInfoTabSelected) && (
          <CardHeader>
            <CardHeaderItem>
              <PageTitle>
                {formatCurrency(client.afisofom_credit.amount)}
              </PageTitle>
              <br />
              <DefaultText>{Strings.AFISOFOM_REQUEST_AMOUNT}</DefaultText>
            </CardHeaderItem>
            <CardHeaderItem>
              <PageTitle transform="none">
                {client.afisofom_credit.term} {Strings.MONTHS}
              </PageTitle>
              <br />
              <DefaultText>{Strings.TERM}</DefaultText>
            </CardHeaderItem>
            <CardHeaderItem>
              <TitleSmallHeight transform="none">{cType}</TitleSmallHeight>
              <br />
              <DefaultText>{Strings.AFISOFOM_CREDIT_TYPE}</DefaultText>
            </CardHeaderItem>
            <CardHeaderItem>
              <PageTitle>{getInterestRate()}%</PageTitle>
              <br />
              <DefaultText>{Strings.AFISOFOM_ANNUAL_INTEREST}</DefaultText>
            </CardHeaderItem>
          </CardHeader>
        )}
        {(!showManage || credInfoTabSelected) && wasApproved && (
          <div>
            <CardHeaderBottom>
              <CardHeaderItem>
                <PageTitle>{formatCurrency(currentDebt)}</PageTitle>
                <br />
                <DefaultText>{Strings.AFISOFOM_REMAINING_AMOUNT}</DefaultText>
              </CardHeaderItem>
              <CardHeaderItem>
                <PageTitle transform="none">{dueMonths}</PageTitle>
                <br />
                <DefaultText>{Strings.AFISOFOM_PENDING_PAYMENTS}</DefaultText>
              </CardHeaderItem>
              <CardHeaderItem>
                <PageTitle
                  transform="none"
                  color={
                    paymentStatus === AfisofomCredit.PAYMENTS_LATE
                      ? Colors.PRIMARY_AFI_SOFOM
                      : Colors.PRIMARY_DARK_AFI_SERVICIOS
                  }
                >
                  {paymentStatus}
                </PageTitle>
                <br />
                <DefaultText>{Strings.AFISOFOM_PAYMENTS_STATUS}</DefaultText>
              </CardHeaderItem>
              <CardHeaderItem>
                <TitleSmallHeight transform={"none"}>
                  {client.afisofom_credit.advisor}
                </TitleSmallHeight>
                <br />
                <DefaultText>{Strings.ADVISOR}</DefaultText>
              </CardHeaderItem>
            </CardHeaderBottom>
            <ApprovedBtnContainer>
              {!showManage && (
                <WhiteButton
                  style={{ margin: "0 0 0 auto" }}
                  onClick={manageSOFOM}
                >
                  {Strings.MANAGE}
                </WhiteButton>
              )}
            </ApprovedBtnContainer>
          </div>
        )}
        {(!showManage || credInfoTabSelected) && !wasApproved && (
          <CardBody>
            <CardBodyLeft>
              <PageTitle transform="none" color={Colors.PRIMARY_AFI_SOFOM}>
                {client.afisofom_credit.stage}
              </PageTitle>
              <br />
              <DefaultText>
                {Strings.AFISOFOM_REQUEST_DATE_2}{" "}
                {client.afisofom_credit.request_date}
              </DefaultText>
              <br />
              <DefaultText
                color={
                  !client.afisofom_credit.advisor_id
                    ? Colors.ERROR
                    : Colors.DARK_GRAY
                }
              >
                {!client.afisofom_credit.advisor_id
                  ? Strings.NO_ADVISOR
                  : Strings.ADVISOR + ": " + advisorFullName}
              </DefaultText>
              {clientAdvisor && (
                <div>
                  <DefaultText color={Colors.DARK_GRAY}>
                    {Strings.EMAIL + ": " + clientAdvisor.email}
                  </DefaultText>
                  <br />
                  <DefaultText color={Colors.DARK_GRAY}>
                    {Strings.PHONE + ": " + clientAdvisor.phone}
                  </DefaultText>
                </div>
              )}
              {cAdvisorIsLoading && (
                <div>
                  <br />
                  <LoadingIndicator
                    color={Colors.PRIMARY_AFI_SOFOM}
                    background={Colors.WHITE}
                    width={"80%"}
                  />
                </div>
              )}
            </CardBodyLeft>
            {
              <CardBodyRight elements={1}>
                <WhiteButton onClick={manageSOFOM}>
                  {Strings.MANAGE}
                </WhiteButton>
                {wasDelined && (
                  <DeclineText color={Colors.DARK_GRAY}>
                    {Strings.DECLINE_REASON + ": " + declineReason}
                  </DeclineText>
                )}
              </CardBodyRight>
            }
          </CardBody>
        )}
        {showManage && genInfoTabSelected && (
          <DivMargin>
            <BasicInfoForm
              onSubmit={handleSOFOMFormSubmit}
              canEditEmail={false}
              user={client}
            />
          </DivMargin>
        )}
        {showManage &&
          convoTabSelected &&
          ChatFormPopulated(advisorID, advisorEmail, advisorName)}
      </CardWrapper>
    </div>
  );
};

UserAfisofomCard.propTypes = {
  client: PropTypes.instanceOf(AfisofomClient).isRequired,
};

export default UserAfisofomCard;
