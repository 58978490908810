const creditTypeCatalog = [
  {
    id: 1,
    name: "Avío",
    minFixedInterestRate: 0.15,
    maxFixedInterestRate: 0.18,
    minVariableInterestRate: 0.1,
    maxVariableInterestRate: 0.125,
    liquidWarrant: 0.1,
    minMonitoringFee: 0.01,
    maxMonitoringFee: 0.02,
    minDisposalFee: 0.015,
    maxDisposalFee: 0.03,
    minCat: 0.2348,
    maxCat: 0.2773,
    minPFAmount: 50000,
    maxPFAmount: 5000000,
    minPMAmount: 50000,
    maxPMAmount: 10000000,
    minTerm: 6,
    maxTerm: 11,
    termRange: 1,
  },
  {
    id: 2,
    name: "Cuenta Corriente",
    minFixedInterestRate: 0.15,
    maxFixedInterestRate: 0.18,
    minVariableInterestRate: 0.1,
    maxVariableInterestRate: 0.125,
    liquidWarrant: 0.1,
    minMonitoringFee: 0.01,
    maxMonitoringFee: 0.02,
    minDisposalFee: 0.015,
    maxDisposalFee: 0.03,
    minCat: 0.2348,
    maxCat: 0.2865,
    minPFAmount: 50000,
    maxPFAmount: 5000000,
    minPMAmount: 50000,
    maxPMAmount: 10000000,
    minTerm: 6,
    maxTerm: 10,
    termRange: 1,
  },
  {
    id: 3,
    name: "Multiciclo",
    minFixedInterestRate: 0.15,
    maxFixedInterestRate: 0.18,
    minVariableInterestRate: 0.1,
    maxVariableInterestRate: 0.125,
    liquidWarrant: 0.1,
    minMonitoringFee: 0.01,
    maxMonitoringFee: 0.02,
    minDisposalFee: 0.015,
    maxDisposalFee: 0.03,
    minCat: 0.2348,
    maxCat: 0.2773,
    minPFAmount: 50000,
    maxPFAmount: 5000000,
    minPMAmount: 50000,
    maxPMAmount: 10000000,
    minTerm: 6,
    maxTerm: 11,
    termRange: 1,
  },
  {
    id: 4,
    name: "Simple",
    minFixedInterestRate: 0.15,
    maxFixedInterestRate: 0.2,
    minVariableInterestRate: 0.1,
    maxVariableInterestRate: 0.14,
    liquidWarrant: 0.1,
    minMonitoringFee: 0.01,
    maxMonitoringFee: 0.02,
    minDisposalFee: 0.015,
    maxDisposalFee: 0.03,
    minCat: 0.1728,
    maxCat: 0.2179,
    minPFAmount: 50000,
    maxPFAmount: 5000000,
    minPMAmount: 50000,
    maxPMAmount: 10000000,
    minTerm: 24,
    maxTerm: 60,
    termRange: 6,
  },
  {
    id: 5,
    name: "Refaccionario",
    minFixedInterestRate: 0.15,
    maxFixedInterestRate: 0.18,
    minVariableInterestRate: 0.1,
    maxVariableInterestRate: 0.125,
    liquidWarrant: 0.1,
    minMonitoringFee: 0.01,
    maxMonitoringFee: 0.02,
    minDisposalFee: 0.015,
    maxDisposalFee: 0.03,
    minCat: 0.1728,
    maxCat: 0.1979,
    minPFAmount: 50000,
    maxPFAmount: 5000000,
    minPMAmount: 50000,
    maxPMAmount: 10000000,
    minTerm: 24,
    maxTerm: 60,
    termRange: 6,
  },
  {
    id: 6,
    name: "Simple - Recursos propios",
    minFixedInterestRate: 0.24,
    maxFixedInterestRate: 0.36,
    minDisposalFee: 0.02,
    maxDisposalFee: 0.03,
    minCat: 0.3653,
    maxCat: 0.3847,
    minPFAmount: 10000,
    maxPFAmount: 5000000,
    minPMAmount: 10000,
    maxPMAmount: 10000000,
    minTerm: 3,
    maxTerm: 36,
    termRange: 3,
  },
];

export default creditTypeCatalog;
