import React, {useEffect, useState} from "react";
import styled from "styled-components";
// ASSETS
import Strings from "../assets/strings";
// DATA
import {getAfisofomClientData} from "../data/services/afisofom/client";
import {getAfiserviciosClientData} from "../data/services/afiservicios/client";
import { getClientData, setClientData } from "../data/services/auth";
import {AfisofomCredit} from "../data/models/afisofom-client";

import {DefaultText, InfoMsg, PageTitle} from "../components/shared-components/text-styles";
import {PopupHolder, BGDiv, DivMargin} from "../components/shared-components/container-styles";
import { DefaultButton } from "../components/shared-components/button-styles";
import LoadingIndicator from "../components/loading-indicator";
import UserAfisofomCard from "../components/user-afisofom-card";
import UserAfiserviciosCard from "../components/user-afiservicios-card";
import PaymentTables from "../components/afisofom/payments-tables";
import {useChangeTitleFavicon} from "../hooks/useChangeTitle";

const UserDataWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
  grid-template-columns: 1fr;
  }
`;

const UserDataWrapperLeft = styled.div`
  text-align: left;
`;

const UserDataWrapperRight = styled.div`
  text-align: right;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
  text-align: left;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 50px;
`;

const NoData = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 30px;
`;

const LargeScreenTable = styled(PaymentTables) `
  @media only screen and (max-width: 767px) {
  	display: none;
  }
`;

const PopupTables = styled(PaymentTables) `
  @media only screen and (max-width: 500px) {
  		.MuiTableCell-root {
	  		width: 20px !important;
	  		padding: 6px 8px 6px 8px !important;
	  	}
  }
`;


const Dashboard = ({type}) => {
  const {id} = getClientData();
  const [clientAfisofom, setClientAfisofom] = useState(null);
  const [isClientSofom, setIsClientSofom] = useState(false);

  const [clientAfiservicios, setClientAfiservicios] = useState(null);
  const [isClientServicios, setIsClientServicios] = useState(false);
  
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showTables, setShowTables] = useState(false);
  const [loanApproved, setLoanApproved] = useState(false);

  // Get afisofom client by id
  const getSofomData = () => {
    setLoading(true);
    getAfisofomClientData(id).then(res => {
      setClientAfisofom(res);
      setClientData(res)
    	getServiciosData();
      setLoading(false);
      if (res.afisofom_credit.amount){
      	setIsClientSofom(true);
      }
	    if(res.afisofom_credit.stage === AfisofomCredit.STAGE_APPROVED) {
	    	setLoanApproved(true);
	    }
    }).catch(error => {
      setLoading(false);
      setError(error);
    })
  };

  // Get afiservicios client by id
  const getServiciosData = () => {
    getAfiserviciosClientData(id).then(res => {
      setClientAfiservicios(res);
      setIsClientServicios(res.afiservicios_form.is_requested);
      setClientData(res);
    }).catch(error => {
    	console.log("No afiservicios data");
      setError(error);
    })
  };

  // on mount
  useEffect(() => {
    getSofomData();

    handleDisplayTables(showTables);
    // eslint-disable-next-line
  }, [showTables]);


  const handleDisplayTables = (showTables) => {
  	if (showTables) {
      document.body.style =
        "overflow-y:hidden; position: relative; margin-right: 15px;"
    } else {
      document.body.style =
        "overflow-y:visible; position: static; margin-right: 0;"
    }
  }

  const toggleTables = () => {
    setShowTables(!showTables);
  }
  const pageTitle = (clientAfisofom ? (clientAfisofom.is_moral_person ? clientAfisofom.moral_person.business_name :
      clientAfisofom.full_name) : "");
  useChangeTitleFavicon( pageTitle, "afisofom");
  return (
      <div className="container" style={{marginTop: '130px'}}>
        {
          loading &&
          <div className="container" style={{marginTop: '130px'}}>
            <LoadingIndicator/>
          </div>
        }
        {
          error && !loading &&
          <div className="container" style={{marginTop: '130px'}}>
            <NoData>
              <InfoMsg>{error}</InfoMsg>
            </NoData>
          </div>
        }
        {
        	// Client information
          !error && !loading && clientAfisofom &&
          <div className="container" style={{marginTop: '130px'}}>
            <PageTitle>
              {
                clientAfisofom.is_moral_person
                  ? clientAfisofom.moral_person.business_name
                  : clientAfisofom.full_name
              }
            </PageTitle>
            <UserDataWrapper>
              <UserDataWrapperLeft>
                {
                  clientAfisofom.is_moral_person &&
                  <React.Fragment>
                    <DefaultText>{clientAfisofom.full_name} ({Strings.LR_NAME})</DefaultText>
                    <br/>
                  </React.Fragment>
                }
                <DefaultText>{clientAfisofom.email}</DefaultText>
                <br/>
                <DefaultText>{clientAfisofom.phone}</DefaultText>
              </UserDataWrapperLeft>
              <UserDataWrapperRight>
                <DefaultText>
                  {clientAfisofom.address.string_line_1}
                </DefaultText>
                <br/>
                <DefaultText>
                  {clientAfisofom.address.string_line_2}
                </DefaultText>
              </UserDataWrapperRight>
            </UserDataWrapper>
            <ContentWrapper>
              {
		            isClientServicios &&
		            <UserAfiserviciosCard client={clientAfiservicios}/>
              }
              <DivMargin />
              {
                isClientSofom &&
                <UserAfisofomCard client={clientAfisofom}
                  onDeclined={() => getSofomData()}
                  onApproved={() => getSofomData()}
                  fromAdmin={false}/>
              }
              
            </ContentWrapper>
            <br />

            { loanApproved &&
            	<div>
		            <LargeScreenTable client={clientAfisofom}/>
		            <DefaultButton
		                dark="true"
		                className='hideOnBigScreen'
		                variant="contained"
		                onClick={toggleTables}>{Strings.AFISOFOM_SHOW_TABLES}
		            </DefaultButton>
	            </div>
	          }
            {loanApproved && showTables &&
            	<div>
		            <PopupHolder>
		            	<PopupTables client={clientAfisofom}/>
		            	<br />
		            	<DefaultButton
		                dark="true"
		                variant="contained"
		                onClick={toggleTables}>{Strings.AFISOFOM_SOLREQ_POP_BTN}
			            </DefaultButton>
		            </PopupHolder>
	            	<BGDiv onClick={toggleTables}/>
            	</div>
	          }
          </div>
        }
      </div>
  );
};

export default Dashboard;
