/**
 * Insert new afisofom client in database
 * @author @leonard_lib
 * @date 04/08/2020
 * @type {string}
 */
export const createClient = `
    mutation CreateClient(
        $input: CreateClientInput!
        $condition: ModelClientConditionInput
    ) {
        createClient(input: $input, condition: $condition) {
            id
            name
            lastname
            mother_lastname
            phone
            email
            is_moral_person
            moral_person {
                business_name
            }
            address {
                street
                colony
                city
                state
                country
                zipcode
            }
            afisofom_credit {
                stage
                amount
                term
                rateType
                type
                decline_reason
                interest
                advisor_id
                advisor
                chat_id
                request_date
                is_requested
                decline_reason
                annexes {
                    key
                    name
                }
                amortization_calendar {
                  period
                  amount
                  payment_date
                  completed
                  file_key
                }
                afisofom_credit_form_key
                credit_buro_key
                photo_id_front_key
                photo_id_reverse_key
            }
            createdAt
            updatedAt
        }
    }
`;

/**
 * Get Afisofom client by ID
 * @author @leonard_lib
 * @date 05/08/2020
 * @type {string}
 */
export const getAfisofomClient = `
    query getAfisofomClient($id: ID!) {
        listClients(filter: {id: {eq: $id}}) {
            items {
                id
                name
                lastname
                mother_lastname
                phone
                email
                is_moral_person
                moral_person {
                    business_name
                }
                address {
                    street
                    colony
                    city
                    state
                    country
                    zipcode
                }
                afisofom_credit {
                    stage
                    amount
                    term
                    type
                    rateType
                    decline_reason
                    interest
                    advisor_id
                    advisor
                    chat_id
                    request_date
                    is_requested
                    decline_reason
                    annexes {
                        key
                        name
                    }
                    amortization_calendar {
                      period
                      amount
                      payment_date
                      completed
                      file_key
                    }
                    afisofom_credit_form_key
                    credit_buro_key
                    photo_id_front_key
                    photo_id_reverse_key
                }
            }
        }
    }
`;

/**
 * Get Afisofom client by email
 * @author @leonard_lib
 * @date 05/08/2020
 * @type {string}
 */
export const getAfisofomClientByEmail = `
    query getAfisofomClientByEmail($email: String!) {
        listClients(filter: {email: {eq: $email}}) {
            items {
                id
                name
                lastname
                mother_lastname
                phone
                email
                admin {
                    roles
                }
                is_moral_person
                moral_person {
                    business_name
                }
                address {
                    street
                    colony
                    city
                    state
                    country
                    zipcode
                }
                afisofom_credit {
                    stage
                		decline_reason
                    amount
                    term
                    type
                    rateType
                    interest
                    advisor_id
                    advisor
                    chat_id
                    request_date
                    is_requested
                    decline_reason
                    annexes {
                        key
                        name
                    }
                    amortization_calendar {
                      period
                      amount
                      payment_date
                      completed
                      file_key
                    }
                    afisofom_credit_form_key
                    credit_buro_key
                    photo_id_front_key
                    photo_id_reverse_key
                }
            }
        }
    }
`;
