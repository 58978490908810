/**
 * Colors in the app
 * @author @leonard_lib
 * @date 29/07/2020
 */
export default class Colors {
    static PRIMARY_AFI_SERVICIOS = '#53A38F';
    static PRIMARY_DARK_AFI_SERVICIOS = '#3C786A';
    static PRIMARY_LIGHT_AFI_SERVICIOS = '#D5E4DD';

    static PRIMARY_AFI_SOFOM = '#DB1A16';
    static PRIMARY_LIGHT_AFI_SOFOM = '#F1C8BA';
    static PRIMARY_DARK_AFI_SOFOM = '#BC2F2C';
    static SECONDARY_AFI_SOFOM_BUTTON = '#F1C8BA';

    static PRIMARY_MLP = '#0038AE';

    static WHITE = '#FFFFFF';
    static BLACK = '#000000';
    static DARK_GRAY = '#212322';
    static GRAY = '#707070';
    static LIGHT_GRAY = '#F2F2F2';
    static ERROR = '#f44336';
    static SMOKE = '#FCF9F8';
}
