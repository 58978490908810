import React, {Component} from "react";
import styled from "styled-components";
import Strings from "../../assets/strings";
import GeneralTable from "../general-table";
import PropTypes from 'prop-types';
import {getAdvisors} from "../../data/services/admins/clients";
import {getClientData} from "../../data/services/auth";
import AdminClient from "../../data/models/admin";
import {
    DefaultText,
    InfoMsg, PageTitle
} from '../shared-components/text-styles';
import {DivMargin, TabItem, TabsContainer} from "../shared-components/container-styles";
import ManageSmallButton from "../manage-small-button";

const AdvisorsListWrapper = styled.div`
    padding: 15px 0;
`;

const NoRole = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 30px;
`;

class AdvisorsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rows_sofom: [],
            rows_servicios: [],
            columns: [],
            selectedTab: 0,
            per_page: 20,
            per_page_afisofom: 20,
            per_page_afiservicios: 20,
            search_afisofom: '',
            search_afiservicios: '',
            is_loading_afisofom: false,
            is_loading_afiservicios: false,
            show_load_more_afisofom: true,
            show_load_more_afiservicios: true,
            show_afisofom_tab: false,
            show_afiservicios_tab: true,
            by_advisor: false,
            advisor_id: null
        };
    }

    componentDidMount() {
        this.verifyAdminRole(() => {
            this.getAfisofomAdvisorsList();
            this.getAfiserviciosAdvisorsList();
        });
    }

    render() {
        return (
            <AdvisorsListWrapper>
                <div>
                    <PageTitle>
                        {Strings.ADVISORS}
                    </PageTitle>
                </div>
                <DivMargin>
                    <DefaultText>
                        {Strings.ADVISORS_SUBTITLE}
                    </DefaultText>
                </DivMargin>
                <DivMargin>
                    <TabsContainer>
                        {
                            this.state.show_afisofom_tab &&
                            <TabItem site="sofom"
                                     onClick={() => this.setState({ selectedTab: 0 })}
                                     selected={this.state.selectedTab === 0}>
                                <span>{Strings.AFISOFOM}</span>
                            </TabItem>
                        }
                        {
                            this.state.show_afiservicios_tab &&
                            <TabItem site="servicios"
                                     onClick={() => this.setState({ selectedTab: 1 })}
                                     selected={this.state.selectedTab === 1}>
                                <span>{Strings.AFISERVICIOS}</span>
                            </TabItem>
                        }
                    </TabsContainer>
                </DivMargin>
                <div>
                    {
                        this.state.selectedTab === 0 && this.state.show_afisofom_tab &&
                        <GeneralTable rows={this.state.rows_sofom}
                                      columns={this.state.columns}
                                      isLoading={this.state.is_loading_afisofom}
                                      showLoadMore={this.state.show_load_more_afisofom}
                                      onLoadMore={() => this.onLoadMoreAfisofom()}
                                      showCheckbox={false}
                                      showNewButton={true}
                                      searchValue={this.state.search_afisofom}
                                      onNewClick={() => this.onNewAfisofom()}
                                      onChangeSearch={value => this.onChangeSearchAfisofom(value)} />
                    }
                    {
                        this.state.selectedTab === 1 && this.state.show_afiservicios_tab &&
                        <GeneralTable rows={this.state.rows_servicios}
                                      columns={this.state.columns}
                                      isLoading={this.state.is_loading_afiservicios}
                                      showLoadMore={this.state.show_load_more_afiservicios}
                                      onLoadMore={() => this.onLoadMoreAfiservicios()}
                                      showCheckbox={false}
                                      showNewButton={true}
                                      onNewClick={() => this.onNewAfiservicios()}
                                      searchValue={this.state.search_afiservicios}
                                      onChangeSearch={value => this.onChangeSearchAfiservicios(value)} />
                    }
                    {
                        !this.state.show_afisofom_tab && !this.state.show_afiservicios_tab &&
                        <NoRole>
                            <InfoMsg>{Strings.NO_ROLE}</InfoMsg>
                        </NoRole>
                    }
                </div>
            </AdvisorsListWrapper>
        );
    }

    getAfisofomAdvisorsList() {
        this.setState({
            is_loading_afisofom: true
        }, async () => {
            const res = await getAdvisors(
                true,
                this.state.per_page_afisofom,
                this.state.search_afisofom
            );
            this.initData(true, res.advisors, res.load_more);
        });
    }

    getAfiserviciosAdvisorsList() {
        this.setState({
            is_loading_afiservicios: true
        }, async () => {
            const res = await getAdvisors(
                false,
                this.state.per_page_afiservicios,
                this.state.search_afiservicios
            );
            this.initData(false, res.advisors, res.load_more);
        });
    }

    initData(afisofom, advisors, load_more) {
        const columns = [
            { title: "#", field: "index" },
            { title: Strings.NAME, field: "name" },
            { title: Strings.EMAIL, field: "email" },
            { title: Strings.PHONE, field: "phone" },
            {
                title: Strings.ACTION,
                field: "action",
                render: data => <ManageSmallButton onClick={() => this.goToDetail(data.id)} />
            },
        ];

        if (afisofom) {
            const rows_sofom = [];

            if (advisors.length > 0) {
                advisors.forEach((client, index) => {
                    rows_sofom.push({
                        index: index + 1,
                        id: client.id,
                        name: client.full_name,
                        email: client.email,
                        phone: client.phone,
                        action: "Gestionar"
                    });
                });
            }

            this.setState({
                rows_sofom: rows_sofom,
                columns: columns,
                show_load_more_afisofom: load_more,
                is_loading_afisofom: false
            });
        } else {
            const rows_servicios = [];

            if (advisors.length > 0) {
                advisors.forEach((client, index) => {
                    rows_servicios.push({
                        index: index + 1,
                        id: client.id,
                        name: client.full_name,
                        email: client.email,
                        phone: client.phone,
                        action: "Gestionar"
                    });
                });
            }

            this.setState({
                rows_servicios: rows_servicios,
                columns: columns,
                show_load_more_afiservicios: load_more,
                is_loading_afiservicios: false
            });
        }
    }

    goToDetail(advisor_id) {
        this.props.history.push('/admin/asesores/' + advisor_id);
    };

    onChangeSearchAfisofom(value) {
        this.setState({
            search_afisofom: value
        }, () => {
            this.getAfisofomAdvisorsList();
        });
    }

    onChangeSearchAfiservicios(value) {
        this.setState({
            search_afiservicios: value
        }, () => {
            this.getAfiserviciosAdvisorsList();
        });
    }

    onLoadMoreAfisofom() {
        const per_page = this.state.per_page_afisofom + this.state.per_page;
        this.setState({
            per_page_afisofom: per_page
        }, () => {
            this.getAfisofomAdvisorsList();
        });
    }

    onLoadMoreAfiservicios() {
        const per_page = this.state.per_page_afiservicios + this.state.per_page;
        this.setState({
            per_page_afiservicios: per_page
        }, () => {
            this.getAfiserviciosAdvisorsList();
        });
    }

    verifyAdminRole(callback) {
        let client = getClientData();

        if (client != null) {
            client = new AdminClient(getClientData());
            let show_sofom_tab = client.isAfisofomAdminOrAdvisor;
            let show_servicios_tab = client.isAfiserviciosAdminOrAdvisor;
            let selectedTab = show_sofom_tab ? 0 : 1;

            this.setState({
                show_afisofom_tab: show_sofom_tab,
                selectedTab: selectedTab,
                show_afiservicios_tab: show_servicios_tab
            }, () => {
                callback();
            });
        }
    }

    onNewAfiservicios() {
        this.props.history.push('/admin/asesores/nuevo/afiservicios');
    }

    onNewAfisofom() {
        this.props.history.push('/admin/asesores/nuevo/afisofom');
    }
}

AdvisorsList.propTypes = {
    history: PropTypes.any.isRequired
};

export default AdvisorsList;
