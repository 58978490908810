import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";

const SmallCustomButtonWrapper = styled.div`
    text-align: ${props => props.align};

    .custom-indicator {
        color: var(${props => props.indicatorColor}) !important;
    }
    
    .custom-button {
        color: ${props => props.color} !important;
        background-color: ${props => props.background} !important;
        padding: 3px 20px !important;
        border-radius: 30px !important;
        font-family: ${props => props.font} !important;
        font-size: 1em !important;
    }
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        .custom-button {
            padding: 3px 10px !important;
            font-size: 0.8em !important;
        }
    }
`;

const SmallCustomButton = ({ color, background, font, label, onClick, isLoading, indicatorColor, containerStyle, fullWidth, align }) => (
    <SmallCustomButtonWrapper style={containerStyle}
                              indicatorColor={indicatorColor}
                              color={color}
                              background={background}
                              font={font}
                              align={align}>
        <Button variant="contained"
                fullWidth={fullWidth}
                className="custom-button"
                onClick={onClick}
                disabled={isLoading}>
            {
                isLoading &&
                <CircularProgress size={24}
                                  className="custom-indicator" />
            }
            {
                !isLoading && label
            }
        </Button>
    </SmallCustomButtonWrapper>
);

/**
 * Property types of CustomButton
 * @author @leonard_lib
 * @date 02/07/2020
 * @type Object
 */
SmallCustomButton.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    view: PropTypes.oneOf([
      'small-primary-button',
      'small-secondary-button'
    ]),
    indicatorColor: PropTypes.string,
    containerStyle: PropTypes.object,
    fullWidth: PropTypes.bool,
    align: PropTypes.oneOf([
        'center',
        'left',
        'right'
    ]),
    color: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
    font: PropTypes.string.isRequired
};

/**
 * Default property values for CustomButton
 * @author @leonard_lib
 * @date 02/07/2020
 * @type Object
 */
SmallCustomButton.defaultProps = {
    isLoading: false,
    view: 'small-primary-button',
    indicatorColor: '--four-color',
    containerStyle: {},
    fullWidth: true,
    align: 'left'
};

export default SmallCustomButton;
