import React, {Component} from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Select from "@material-ui/core/Select"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import MenuItem from "@material-ui/core/MenuItem"
import Strings from "../assets/strings";
import Fonts from "../assets/fonts";
import Colors from "../assets/colors";
import {SmallLink} from "./shared-components/text-styles";

const CustomSelectWrapper = styled.div`
  margin-top: 20px;
    
    .MuiFormLabel-root {
        font-family: ${Fonts.MEDIUM} !important;
        font-size: 1em !important;
        color: ${Colors.BLACK} !important;
    }
    
    .MuiButton-root {
        font-family: ${Fonts.BOLD} !important;
        font-size: 1em !important;
    }
    
    .MuiInputBase-input {
        font-family: ${Fonts.MEDIUM} !important;
        font-size: 1em !important;
    }
    
    .MuiFormLabel-root {
        color: ${Colors.BLACK} !important;
    }
    
    .MuiFormLabel-root.Mui-focused {
        color: ${props => props.color} !important;
    }
    
    .MuiInput-underline:before {
        border-bottom: 1px solid ${Colors.GRAY} !important;
    }
    
    .MuiInput-underline:after {
        border-bottom: 2px solid ${props => props.color} !important;
    }
    
    .MuiFormLabel-root.Mui-error {
        color: ${Colors.ERROR} !important;
    }
    
    .MuiInput-underline.Mui-error:before {
        border-bottom: 1px solid ${Colors.ERROR} !important;
    }
    
    .MuiInput-underline.Mui-error:after {
        border-bottom: 2px solid ${Colors.ERROR} !important;
    }
    
    .MuiFormHelperText-root.Mui-error {
        font-family: ${Fonts.MEDIUM} !important;
        font-size: 1em !important;
    }
    
    .MuiTypography-body1 {
        font-family: ${Fonts.MEDIUM} !important;
        font-size: 1em !important;
    }
    
    .MuiCheckbox-colorPrimary.Mui-checked {
        color: ${props => props.color} !important;
    }
    
    .MuiPaper-root {
        background-color: var(--primary-color) !important;
        color: ${Colors.WHITE} !important;
    }
`;

class CustomSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMsg: Strings.FIELD_REQUIRED
        };
    }

    render() {
        const label = `label-${this.props.id}`;

        return (
            <CustomSelectWrapper style={this.props.style} color={this.props.color}>
                <FormControl error={this.props.invalid && this.props.submitted}
                             disabled={this.props.disabled}
                             fullWidth>
                    <InputLabel id={label}>
                        {this.props.label}
                    </InputLabel>
                    <Select labelId={label}
                            inputProps={this.props.inputProps}
                            value={this.props.value}
                            onChange={(e) => this.onChange(e)}
                            id={this.props.id}>
                        {
                            !this.props.useNestedProps && this.props.options.map((option, key) =>
                                <MenuItem value={option} key={key}>
                                    {option}
                                </MenuItem>
                            )
                        }
                        {
                            this.props.useNestedProps && this.props.options.map((option, key) =>
                                <MenuItem value={option[this.props.valueProp]} key={key}>
                                    {option[this.props.labelProp]}
                                </MenuItem>
                            )
                        }
                    </Select>
                    <FormHelperText>
                        {
                            this.props.invalid && this.props.submitted
                                ? this.state.errorMsg
                                : this.props.hint
                        }
                    </FormHelperText>
                    {
                        this.props.isClearable && !this.props.disabled &&
                        <SmallLink onClick={() => this.props.onClear()} style={{ float: 'right' }}>
                            {Strings.CLEAR}
                        </SmallLink>
                    }
                </FormControl>
            </CustomSelectWrapper>
        );
    }

    onChange(event) {
        const value = event.target.value;
        const valid = this.checkValid(value);
        return this.props.onChange(value, valid);
    };

    checkValid(value) {
        let valid = true;
        let msg = null;

        if (this.props.required) {
            if (!(value != null && value !== '')) {
                valid = false;
                msg = Strings.FIELD_REQUIRED;
            }
        }

        this.setState({
            errorMsg: msg
        });
        return valid;
    }
}

CustomSelect.propTypes = {
    options: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onClear: PropTypes.func,
    required: PropTypes.bool,
    invalid: PropTypes.bool,
    submitted: PropTypes.bool,
    style: PropTypes.object,
    hint: PropTypes.string,
    value: PropTypes.string,
    valueProp: PropTypes.string,
    labelProp: PropTypes.string,
    inputProps: PropTypes.object,
    disabled: PropTypes.bool,
    isClearable: PropTypes.bool,
    useNestedProps: PropTypes.bool,
    color: PropTypes.string.isRequired
};

CustomSelect.defaultProps = {
    required: false,
    invalid: false,
    submitted: false,
    style: {},
    inputProps: {},
    hint: '',
    value: '',
    valueProp: '',
    labelProp: '',
    disabled: false,
    isClearable: false,
    useNestedProps: false,
    onClear: () => {}
};

export default CustomSelect;
