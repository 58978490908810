import {API, graphqlOperation} from "aws-amplify";
import {createClient, getAfisofomClient, getAfisofomClientByEmail} from "../../queries/afisofom/client";
import Strings from "../../../assets/strings";
import {afisofomSendAttachments, afisofomUpdateCredit, afisofomEmailReqConfirm} from "../../../graphql/queries";
import AfisofomClient from "../../models/afisofom-client";
import AfiserviciosClient from "../../models/afiservicios-client";

/**
 * Create new afisofom client
 * @author @leonard_lib
 * @date 05/08/2020
 * @param client
 * @returns {Promise<unknown>}
 */
export const registerAfisofom = (client) => {
    client.afisofom_credit.is_requested = true;
    client.afisofom_credit.interest = 18;
    client.search_term = `${client.name} ${client.lastname} ${client.mother_lastname} ${client.phone} ${client.email} ${client.is_moral_person ? client.moral_person.business_name : ""}`;
    client.search_term = client.search_term.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    return new Promise((resolve, reject) => {
        API.graphql(graphqlOperation(createClient, {
            input: client
        })).then(user => {
            resolve(user);
        }).catch(err => {
            console.log('REGISTER AFISOFOM ERROR: ', err);
            reject(Strings.AFISOFOM_REGISTER_ERROR);
        });
    });
};

/**
 * Send attachment for afisofom client
 * @author @leonard_lib
 * @date 05/08/2020
 * @param email
 * @returns {Promise<unknown>}
 */
export const sendAttachments = (email) => {
    return new Promise((resolve, reject) => {
        API.graphql(graphqlOperation(afisofomSendAttachments, {
            email: email
        })).then(data => {
            resolve(true);
        }).catch(err => {
            console.log('SEND ATTACHMENTS ERROR: ', err);
            reject(Strings.SEND_ATTACHMENTS_ERROR);
        });
    });
};

export const sendCreditEmail = (username, user_email) => {
    const correoAsesor = "carneggiemelon@yopmail.com";

    return new Promise((resolve, reject) => {
        API.graphql(graphqlOperation(afisofomEmailReqConfirm, {
            email_asesor: correoAsesor,
            username: username,
            user_email: user_email
        })).then(data => {
            resolve(true);
        }).catch(err => {
            console.log('SEND CREDIT EMAIL ERROR: ', err);
            // TODO: CHANGE VALUE
            reject(Strings.SEND_CONFIRM_CREDIT_ERROR);
        });
    });
};

export const getAfisofomClientData = (id) => {
    return new Promise((resolve, reject) => {
        API.graphql(graphqlOperation(getAfisofomClient, {
            id: id
        })).then(res => {
            if (res.data.listClients.items.length > 0) {
                const client = new AfisofomClient(res.data.listClients.items[0]);
                resolve(client);
            } else {
                console.log('GET AFISOFOM DATA ERROR');
                reject(Strings.GET_AFISOFOM_DATA_ERROR);
            }
        }).catch(err => {
            console.log('GET AFISOFOM DATA ERROR: ', err);
            reject(Strings.GET_AFISOFOM_DATA_ERROR);
        });
    });
};

export const getAfisofomClientDataByEmail = (email) => {
    return new Promise((resolve, reject) => {
        API.graphql(graphqlOperation(getAfisofomClientByEmail, {
            email: email
        })).then(res => {
            if (res.data.listClients.items.length > 0) {
                let client;
                if(res.data.listClients.items[0].afisofom_credit) {
                    client = new AfisofomClient(res.data.listClients.items[0]);
                }
                else {
                    client = new AfiserviciosClient(res.data.listClients.items[0])
                }
                resolve(client);
            } else {
                console.log('GET AFISOFOM DATA ERROR');
                reject(Strings.GET_AFISOFOM_DATA_ERROR);
            }
        }).catch(err => {
            console.log('GET AFISOFOM DATA ERROR: ', err);
            reject(Strings.GET_AFISOFOM_DATA_ERROR);
        });
    });
};

export const updateAfisofomCredit = (id, email, afisofom_credit) => {
    return new Promise((resolve, reject) => {
        API.graphql(graphqlOperation(afisofomUpdateCredit, {
            id: id,
            email: email,
            afisofom_credit: afisofom_credit
        })).then(res => {
            if (res.data.afisofomUpdateCredit.success) {
                resolve(true);
            } else {
                console.log('UPDATE AFISOFOM CREDIT ERROR');
                reject(Strings.UPDATE_AFISOFOM_CREDIT_ERROR);
            }
        }).catch(err => {
            console.log('UPDATE AFISOFOM CREDIT ERROR: ', err);
            reject(Strings.UPDATE_AFISOFOM_CREDIT_ERROR);
        });
    });
};
