import React from "react";
import AdminsRestorePasswordForm from "../../components/admins/restore-password-form";
import LayoutAdmins from "../../components/layout-admins";
import {useChangeTitleFavicon} from "../../hooks/useChangeTitle";
import Strings from "../../assets/strings";

const AdminsRestorePassword = ({ history, location }) => {
    useChangeTitleFavicon(Strings.TITLE_RESTORE_PASSWORD);
    return (
        <LayoutAdmins history={history} >
            <div className="container">
                <AdminsRestorePasswordForm
                    location={location}
                    onRestore={() =>
                        history.push('/admin/iniciar-sesion')
                    }
                />
            </div>
        </LayoutAdmins>
    );
}

export default AdminsRestorePassword;
