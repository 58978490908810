import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {DefaultText, SectionTitleSmall} from "../shared-components/text-styles";
import Strings from "../../assets/strings";
import {DivMargin} from "../shared-components/container-styles";
import GeneralTable from "../general-table";
import {formatCurrency} from "../../data/services/utils";
import PropTypes from 'prop-types';
import Colors from "../../assets/colors";
import AfisofomClient from "../../data/models/afisofom-client";
import moment from "moment";

const TablesWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2em;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        grid-template-columns: 1fr;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const PaymentsTables = ({ client, className }) => {
    const columnPeriod = {
        title: Strings.AFISOFOM_PERIOD,
        field: "period"
    };
    const columnDatePending = {
        title: Strings.AFISOFOM_LIMIT_DATE,
        field: "payment_date",
        render: data => <DefaultText>{moment(data.payment_date).format('DD/MM/YYYY')}</DefaultText>
    };
    const columnDateDone = {
        title: Strings.AFISOFOM_DATE,
        field: "payment_date",
        render: data => <DefaultText>{moment(data.payment_date).format('DD/MM/YYYY')}</DefaultText>
    };
    const columnAmount = {
        title: Strings.AFISOFOM_AMOUNT,
        field: "amount",
        render: data => <DefaultText>{formatCurrency(data.amount)}</DefaultText>
    }
    const columnsPending = [
        columnPeriod,
        columnDatePending,
        columnAmount
    ];
    const columnsDone = [
        columnPeriod,
        columnDateDone,
        columnAmount
    ];
    const [pendingPayments, setPendingPayments] = useState([]);
    const [donePayments, setDonePayments] = useState([]);

    // on client info change
    useEffect(() => {
        setPendingPayments(client.afisofom_credit.payments_data.pending_payments_info);
        setDonePayments(client.afisofom_credit.payments_data.done_payments_info);
    }, [client]);

    return (
        <TablesWrapper className={className}>
            <div>
                <TitleContainer>
                    <SectionTitleSmall>
                        {Strings.AFISOFOM_PENDING_PAYMENTS}
                    </SectionTitleSmall>
                </TitleContainer>
                <DivMargin>
                    <GeneralTable rows={pendingPayments}
                                  showToolbar={false}
                                  headerBackground={Colors.PRIMARY_LIGHT_AFI_SOFOM}
                                  columns={columnsPending}
                                  isLoading={false}/>
                </DivMargin>
            </div>
            <div>
                <TitleContainer>
                    <SectionTitleSmall>
                        {Strings.AFISOFOM_DONE_PAYMENTS}
                    </SectionTitleSmall>
                </TitleContainer>
                <DivMargin>
                    <GeneralTable rows={donePayments}
                                  showToolbar={false}
                                  headerBackground={Colors.PRIMARY_LIGHT_AFI_SOFOM}
                                  columns={columnsDone}
                                  isLoading={false}/>
                </DivMargin>
            </div>
        </TablesWrapper>
    );
};

PaymentsTables.propTypes = {
    client: PropTypes.instanceOf(AfisofomClient).isRequired,
    className: PropTypes.string
};

export default PaymentsTables;
