import {API, graphqlOperation} from "aws-amplify";
import Strings from "../../../assets/strings";
import {adminGetClients, adminSendAdvisorInvite, adminGetAdvisors, emailAdminsUserRegister} from "../../../graphql/queries";
import AfisofomClient from "../../models/afisofom-client";
import AfiserviciosClient from "../../models/afiservicios-client";
import {createAdvisorClient, getAdminClient, getAdminClientByID} from "../../queries/admins/client";
import AdminClient from "../../models/admin";
import Client from "../../models/client";

/**
 * get clients for admin or advisor
 * @author @leonard_lib
 * @date 05/08/2020
 * @param by_advisor
 * @param advisor_id
 * @param afisofom
 * @param show_all
 * @param search
 * @param limit
 * @returns {Promise<unknown>}
 */
export const getClients = (
    afisofom = true,
    limit = 0,
    show_all = false,
    search = '',
    by_advisor = false,
    advisor_id = null,
) => {
    return new Promise((resolve, reject) => {
        API.graphql(graphqlOperation(adminGetClients, {
            from_afisofom: afisofom,
            limit: limit,
            show_all: show_all,
            search: search,
            by_advisor: by_advisor,
            advisor_id: advisor_id
        })).then(res => {
            const info = res.data.adminGetClients.success ? JSON.parse(res.data.adminGetClients.info) : {};
            const clients = afisofom
                ? AfisofomClient.fromMultiple(info.items)
                : AfiserviciosClient.fromMultiple(info.items);

            resolve({
                clients: clients,
                load_more: info.there_are_more
            });
        }).catch(err => {
            console.log('GET ADMIN CLIENTS ERROR: ', err);
            reject(Strings.GET_ADMIN_CLIENTS_ERROR);
        });
    });
};

export const getAdvisors = (
    afisofom = true,
    limit = 0,
    search = ''
) => {
    return new Promise((resolve, reject) => {
        API.graphql(graphqlOperation(adminGetAdvisors, {
            role: afisofom ? AdminClient.ROLE_AFISOFOM_ADVISOR : AdminClient.ROLE_AFISERVICIOS_ADVISOR,
            limit: limit,
            search: search
        })).then(res => {
            const info = res.data.adminGetAdvisors.success ? JSON.parse(res.data.adminGetAdvisors.info) : {};
            const advisors = afisofom
                ? AfisofomClient.fromMultiple(info.items)
                : AfiserviciosClient.fromMultiple(info.items);

            resolve({
                advisors: advisors,
                load_more: info.there_are_more
            });
        }).catch(err => {
            console.log('GET ADMIN ADVISORS ERROR: ', err);
            reject(Strings.GET_ADMIN_ADVISORS_ERROR);
        });
    });
};

export const sendMailAdminsUserReg = (isSofom, username, user_email) => {
    return new Promise((resolve, reject) => {
        API.graphql(graphqlOperation(emailAdminsUserRegister, {
        	isSofom: isSofom,
        	username: username,
        	user_email: user_email
        })).then(res => {
            const info = res.data.emailAdminsUserRegister.success;
            if (info){
            	resolve({
                success: info
            	});
            } else {
            	reject(Strings.GET_ADMIN_ADVISORS_ERROR)
            }
            
        }).catch(err => {
            console.log('GET ADMINS ERROR: ', err);
            reject(Strings.GET_ADMIN_ADVISORS_ERROR);
        });
    });
};

export const getAdminClientData = (email) => {
    return new Promise((resolve, reject) => {
        API.graphql(graphqlOperation(getAdminClient, {
            email: email
        })).then(res => {
            if (res.data.listClients.items.length > 0) {
                const admin = new AdminClient(res.data.listClients.items[0]);
                resolve(admin);
            } else {
                console.log('GET ADMIN DATA ERROR');
                reject(Strings.GET_ADMIN_DATA_ERROR);
            }
        }).catch(err => {
            console.log('GET ADMIN DATA ERROR: ', err);
            reject(Strings.USER_EXISTS_ERROR);
        });
    });
};

/**
 * Create new advisor client
 * @author @leonard_lib
 * @date 05/08/2020
 * @param client
 * @returns {Promise<unknown>}
 */
export const registerAdvisor = (client) => {
    const format_client = new Client(client)
    return new Promise((resolve, reject) => {
        API.graphql(graphqlOperation(createAdvisorClient, {
            input: format_client
        })).then(user => {
            resolve(user);
        }).catch(err => {
            console.log('REGISTER ADVISOR ERROR: ', err);
            reject(Strings.ADVISOR_REGISTER_ERROR);
        });
    });
};

export const sendAdminAdvisorInvite = (email) => {
    return new Promise((resolve, reject) => {
        const url = `https://clientes.afisofom.com/admin/asesores/confirmar?email=${email}`;

        API.graphql(graphqlOperation(adminSendAdvisorInvite, {
            email: email,
            url: url
        })).then(res => {
            if (res.data.adminSendAdvisorInvite.success) {
                resolve(true);
            } else {
                console.log('SEND ADVISOR INVITE ERROR');
                reject(Strings.SEND_ADVISOR_INVITE_ERROR);
            }
        }).catch(err => {
            console.log('SEND ADVISOR INVITE ERROR: ', err);
            reject(Strings.SEND_ADVISOR_INVITE_ERROR);
        });
    });
};

export const getAdvisorClientData = (id) => {
    return new Promise((resolve, reject) => {
        API.graphql(graphqlOperation(getAdminClientByID, {
            id: id
        })).then(res => {
            if (res.data.listClients.items.length > 0) {
                const client = new AdminClient(res.data.listClients.items[0]);
                resolve(client);
            } else {
                console.log('GET ADVISOR DATA ERROR');
                reject(Strings.GET_ADVISOR_DATA_ERROR);
            }
        }).catch(err => {
            console.log('GET ADVISOR DATA ERROR: ', err);
            reject(Strings.GET_ADVISOR_DATA_ERROR);
        });
    });
};
