import React from "react";
import LayoutClients from "../../components/layout-clients";
import SolicitudFormOneMain from "../../components/afisofom/form-one-main";
import {useChangeTitleFavicon} from "../../hooks/useChangeTitle";
import Strings from "../../assets/strings";

const AfisofomFormOne = ({ history }) => {
    useChangeTitleFavicon(Strings.TITLE_FORM_ONE_AFISOFOM, "afisofom");
    return (
        <LayoutClients site="sofom"
                       history={history}
                       showPrincipalButton={false}>
            <div className="container"
                 style={{ marginTop: '130px' }}>
                <SolicitudFormOneMain history={history} />
            </div>
        </LayoutClients>
    )
}

export default AfisofomFormOne;
