import React, {useState} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Colors from "../assets/colors";
import logo from '../assets/images/logo.png';
import {FaArrowRight, FaBars} from "react-icons/fa";
import {userIsAuthenticated} from "../data/services/auth";
import AdminNavbarMenu from "./admins/navbar-menu";
import {ReactComponent as LeftArrow} from "../assets/svg/arrow-left.svg";

const NavbarWrapper = styled.header`
    width: 100%;
    height: 80px;
    height: auto;
    position: fixed;
    top: 0;
    z-index: 999999999;
    box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.16);
    background-color: ${Colors.WHITE};
    max-width: ${props => props.mobileIconWidth};
`;

const NavbarContainer = styled.div`
    display: grid;
    grid-template-columns: 0.1fr 1fr 0.4fr;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        grid-template-columns: 0.1fr 1fr;
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        grid-template-columns: 0.1fr 1fr;
    }
`;

const NavbarLeft = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const NavbarRight = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        display: none;
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        display: none;
    }
`;

const NavbarMobileWrapper = styled.div`
  display: none;
  justify-content: flex-end;
  align-items: center;
  
  .menu-mobile-icon {
    font-size: 2em;
    color: ${Colors.BLACK};
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    display: flex;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    display: flex;
  }
`;

const MenuUser = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        display: none;
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        display: none;
    }
`;

const NavbarAdmins = ({location, mobileIconWidth, onClickMobile, history, showBack}) => {
    const [isAuth, setIsAuth] = useState(userIsAuthenticated());

    const onLogout = () => {
        setIsAuth(userIsAuthenticated());
        history.push('/admin/iniciar-sesion');
    };

    const onBack = () => {
        //TODO: Revisar cómo modificar esto con el history.
        window.history.back();
    }

    return (
        <NavbarWrapper mobileIconWidth={mobileIconWidth}>
            <div className="container">
                <NavbarContainer>
                    <NavbarLeft>
                        {showBack && (
                            <LeftArrow
                                style={{marginRight: "30px"}}
                                onClick={onBack}
                            />
                        )}
                        <img src={logo}
                             className="img-logo"
                             width="50"
                             height="50"
                             alt="logo"/>
                    </NavbarLeft>
                    <NavbarRight/>
                    {
                        isAuth &&
                        <MenuUser>
                            <AdminNavbarMenu onLogoutUser={() => onLogout()}/>
                        </MenuUser>
                    }
                    <NavbarMobileWrapper>
                        {
                            mobileIconWidth === '40%'
                                ? <FaArrowRight className="menu-mobile-icon"
                                                onClick={() => onClickMobile()}/>
                                : <FaBars className="menu-mobile-icon"
                                          onClick={() => onClickMobile()}/>
                        }
                    </NavbarMobileWrapper>
                </NavbarContainer>
            </div>
        </NavbarWrapper>
    );
};

NavbarAdmins.propTypes = {
    location: PropTypes.string.isRequired,
    onClickMobile: PropTypes.func.isRequired,
    mobileIconWidth: PropTypes.string.isRequired
};

export default NavbarAdmins;
