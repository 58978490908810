import React, {useState} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Colors from "../assets/colors";
import Fonts from "../assets/fonts";
import SmallCustomButton from "./small-button";
import Strings from "../assets/strings";
import AdminNavbarMenu from "./admins/navbar-menu";
import {userIsAuthenticated} from "../data/services/auth";

const NavbarWrapper = styled.header`
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    z-index: 999999999;
    box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.16);
    background-color: ${props => props.site === 'sofom' ? Colors.WHITE : Colors.PRIMARY_LIGHT_AFI_SERVICIOS}
`;

const NavbarContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`;

const NavbarLeft = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const NavbarRight = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const NavbarTitle = styled.label`
    color: ${props => props.site === 'sofom' ? Colors.PRIMARY_AFI_SOFOM : Colors.PRIMARY_AFI_SERVICIOS };
    font-family: ${Fonts.MEDIUM};
    font-size: 1.5em;
    text-decoration: none;
    cursor: pointer;
`;

const MenuUser = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        display: none;
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        display: none;
    }
`;


const NavbarClients = ({ history, site, showPrincipalButton }) => {
    const [isAuth, setIsAuth] = useState(userIsAuthenticated());
    const button_color = Colors.WHITE;
    const button_bg = site === 'sofom' ? Colors.PRIMARY_AFI_SOFOM : Colors.PRIMARY_AFI_SERVICIOS;
    const button_font = Fonts.BOLD;
    const button_label = site === 'sofom' ? Strings.AFISOFOM_REQUEST : Strings.AFISERVICIOS_REQUEST;
    const title = site === 'sofom' ? Strings.AFISOFOM : Strings.AFISERVICIOS;

    const onLogout = () => {
        setIsAuth(userIsAuthenticated());
        if(site === "sofom") {
            history.push('/afisofom/iniciar-sesion');
        }
        else {
            history.push('/afiservicios/iniciar-sesion')
        }
    };

    const click = () => {
        if (site === "sofom") {
            history.push('/afisofom/solicitud')
        }
    }
    return (
        <NavbarWrapper site={site}>
            <div className="container">
                <NavbarContainer>
                    <NavbarLeft>
                        <NavbarTitle site={site}>{title}</NavbarTitle>
                    </NavbarLeft>
                    <NavbarRight>
                        {
                            showPrincipalButton && history &&
                            <SmallCustomButton label={button_label}
                                               onClick={click}
                                               color={button_color}
                                               background={button_bg}
                                               font={button_font} />
                        }
                        {
                            isAuth &&
                            <MenuUser>
                                <AdminNavbarMenu onLogoutUser={() => onLogout()}/>
                            </MenuUser>
                        }
                    </NavbarRight>
                </NavbarContainer>
            </div>
        </NavbarWrapper>
    );
};

NavbarClients.propTypes = {
    location: PropTypes.string.isRequired,
    site: PropTypes.oneOf([
        'sofom',
        'servicios',
        'admin'
    ]).isRequired,
    showPrincipalButton: PropTypes.bool
};

NavbarClients.defaultProps = {
    showPrincipalButton: true
};

export default NavbarClients;
