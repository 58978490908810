import React, {useState} from 'react';
import styled from "styled-components";
import {withStyles} from "@material-ui/core/styles";
import Colors from "../assets/colors";
import Slider from "@material-ui/core/Slider";
import {formatCurrency} from "../data/services/utils";
import PropTypes from 'prop-types';
import CurrencyTextInput from "./currency-text-input";

const SliderGroup = styled.div`
    margin-top: 1em;
    display: grid;
    grid-template-rows: 1fr 2fr 1fr;
    grid-template-columns: 1fr 1fr;
`;

const SliderInput = styled(CurrencyTextInput)`
    grid-row: 1;
    background-color: transparent;
    grid-column: 2;
    justify-self: right;
    width: 35%;
    border-radius: 0;
    border: none;
    border-bottom: solid 1px black;
    text-align: right;
    font-family: inherit;
    font-size: inherit;
    
    @media only screen and (max-width: 540px) {
        width: 55%;
    }
    
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 55%;
    }
`;

const PrettoSlider = withStyles({
    root: {
        color: '#dddddd',
        height: 8,
        gridColumn: "1/ span 2",
        gridRow: "2"
    },
    thumb: {
        height: '24px !important',
        width: '24px !important',
        backgroundColor: '#fff',
        border: `3px solid ${Colors.PRIMARY_AFI_SOFOM}`,
        marginTop: '-8px !important',
        marginLeft: '-12px !important',
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        }
    },
    active: {},
    track: {
        height: 0,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

const PrettoSliderAdmin = withStyles({
    root: {
        color: '#dddddd',
        height: 8,
        gridColumn: "1/ span 2",
        gridRow: "2"
    },
    thumb: {
        height: '24px !important',
        width: '24px !important',
        backgroundColor: '#fff',
        border: `3px solid ${Colors.DARK_GRAY}`,
        marginTop: '-8px !important',
        marginLeft: '-12px !important',
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        }
    },
    active: {},
    track: {
        height: 0,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

const SliderLabel1 = styled.span`
    grid-row: 3;
    grid-column: 1;
`

const SliderLabel2 = styled(SliderLabel1)`
    grid-column: 2;
    justify-self: right;
`;

const CustomSlider = ({ id, step, currentMin, currentMax, initialValue, disabled, onChange, fromAdmin }) => {
    const [loanAmount, setLoanAmount] = useState(initialValue);

    const onChangeValue = (value) => {
        setLoanAmount(value);
        onChange(value);
    };

    return (
        <SliderGroup>
            <SliderInput
                value={loanAmount}
                currencySymbol="$"
                decimalPlaces={0}
                disabled={disabled}
                outputFormat="number"
                symbol={"$"}
                onChange={value => onChangeValue(value)}
            />
            {
                fromAdmin &&
                <PrettoSliderAdmin
                    value={loanAmount}
                    onChange={(event, value) => onChangeValue(value)}
                    aria-labelledby={id}
                    step={step}
                    disabled={disabled}
                    min={currentMin}
                    max={currentMax}
                    valueLabelDisplay="off"
                />
            }
            {
                !fromAdmin &&
                <PrettoSlider
                    value={loanAmount}
                    onChange={(event, value) => onChangeValue(value)}
                    aria-labelledby={id}
                    step={step}
                    disabled={disabled}
                    min={currentMin}
                    max={currentMax}
                    valueLabelDisplay="off"
                />
            }
            <SliderLabel1>{formatCurrency(currentMin)}</SliderLabel1>
            <SliderLabel2>{formatCurrency(currentMax)}</SliderLabel2>
        </SliderGroup>
    );
};

CustomSlider.propTypes = {
    id: PropTypes.string.isRequired,
    step: PropTypes.number.isRequired,
    currentMin: PropTypes.number.isRequired,
    currentMax: PropTypes.number.isRequired,
    initialValue: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    fromAdmin: PropTypes.bool
};

CustomSlider.defaultProps = {
    fromAdmin: false
};

export default CustomSlider;
