import React from "react";
import LayoutClients from "../../components/layout-clients";
import ConfirmarSolicitud from "../../components/afisofom/confirm-form-main";
import { useChangeTitleFavicon } from "../../hooks/useChangeTitle";
import Strings from "../../assets/strings";

const AfisofomConfirmForm = ({ history }) => {
  useChangeTitleFavicon(Strings.TITLE_CONFIRM_FORM_AFISOFOM, "afisofom");
  return (
    <LayoutClients site="sofom" showPrincipalButton={false} history={history}>
      <div className="container" style={{ marginTop: "130px" }}>
        <ConfirmarSolicitud history={history} />
      </div>
    </LayoutClients>
  );
};

export default AfisofomConfirmForm;
