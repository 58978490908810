import styled from "styled-components";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import Fonts from "../assets/fonts";
import Colors from "../assets/colors";
import React from "react";
import PropTypes from 'prop-types';

const SliderInput = styled(CurrencyTextField)`
    .MuiFormLabel-root {
        font-family: ${Fonts.MEDIUM} !important;
        font-size: 1em !important;
        color: ${Colors.BLACK} !important;
    }
    
    .MuiButton-root {
        font-family: ${Fonts.BOLD} !important;
        font-size: 1em !important;
    }
    
    .MuiInputBase-input {
        font-family: ${Fonts.MEDIUM} !important;
        font-size: 1em !important;
    }
    
    .MuiFormLabel-root {
        color: ${Colors.BLACK} !important;
    }
    
    .MuiFormLabel-root.Mui-focused {
        color: ${props => props.color} !important;
    }
    
    .MuiInput-underline:before {
        border-bottom: 1px solid ${Colors.GRAY} !important;
    }
    
    .MuiInput-underline:after {
        border-bottom: 2px solid ${props => props.color} !important;
    }
    
    .MuiFormLabel-root.Mui-error {
        color: ${Colors.ERROR} !important;
    }
    
    .MuiInput-underline.Mui-error:before {
        border-bottom: 1px solid ${Colors.ERROR} !important;
    }
    
    .MuiInput-underline.Mui-error:after {
        border-bottom: 2px solid ${Colors.ERROR} !important;
    }
    
    .MuiFormHelperText-root.Mui-error {
        font-family: ${Fonts.MEDIUM} !important;
        font-size: 1em !important;
    }
    
    .MuiTypography-body1 {
        font-family: ${Fonts.MEDIUM} !important;
        font-size: 1em !important;
        color: ${Colors.BLACK};
    }
    
    .MuiCheckbox-colorPrimary.Mui-checked {
        color: ${props => props.color} !important;
    }
    
    .MuiPaper-root {
        background-color: var(--primary-color) !important;
        color: ${Colors.WHITE} !important;
    }
`;

const CurrencyTextInput = ({ value, symbol, decimalPlaces, disabled, outputFormat, onChange, color }) => (
    <SliderInput
        value={value}
        color={color}
        currencySymbol={symbol}
        decimalPlaces={decimalPlaces}
        disabled={disabled}
        outputFormat={outputFormat}
        onChange={(event, value) => onChange(value)}
    />
);

CurrencyTextInput.propTypes = {
    value: PropTypes.number.isRequired,
    symbol: PropTypes.string.isRequired,
    decimalPlaces: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
    outputFormat: PropTypes.oneOf([
        'string',
        'number'
    ]),
    onChange: PropTypes.func.isRequired,
    color: PropTypes.string
};

CurrencyTextInput.defaultProps = {
    color: Colors.DARK_GRAY
};

export default CurrencyTextInput;
