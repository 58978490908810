import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import Fonts from "../../assets/fonts";
import Colors from "../../assets/colors";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { FIXED_RATE, VARIABLE_RATE } from "./form-one-main";
import { pmt } from "../../utils/finance";
//
const StyledTableContainer = styled(TableContainer)`
  height: 55vh;

  @media only screen and (max-width: 767px) {
    height: 55vh;
  }

  .MuiTableCell-root {
    color: ${Colors.BLACK};
    font-family: ${Fonts.REGULAR};
    font-size: 1em;

    --table-padding-updown: 6.5px;
    --table-padding-sides: 10px;
    padding-top: var(--table-padding-updown);
    padding-bottom: var(--table-padding-updown);
    padding-left: var(--table-padding-sides);
    padding-right: var(--table-padding-sides);
    border-bottom: none;

    @media only screen and (max-width: 470px) {
      --table-padding-sides: 3.5px;
      --table-padding-updown: 7px;
    }
  }

  .MuiTableCell-root.MuiTableCell-body {
    @media only screen and (max-width: 767px) {
      background-color: ${Colors.WHITE};
    }
  }
`;

const StyledTableHead = styled(TableHead)`
  .MuiTableRow-head {
    background-color: ${(props) => props.background};
  }

  .MuiTableCell-head {
    font-family: ${Fonts.BOLD};
    color: ${Colors.WHITE};
  }
`;

function createData(parcialidad, fechaEst, min, max) {
  return { parcialidad, fechaEst, min, max };
}

const FormOneTable = ({
  paymentMonths,
  loanAmount,
  interestRate,
  headerBackground,
  creditType,
  rateType,
  tiie,
}) => {
  const [headers] = useState([
    "Parcialidad",
    "Fecha estimada*",
    "Pago",
    "Pago",
  ]);
  const [tableData, setTableData] = useState();

  // Se utilizó la formula en el siguiente vínculo:
  // https://www.vertex42.com/ExcelArticles/amortization-calculation.html
  const calculateAmortization = (loanAmount, interestRate, payments) => {
    return pmt(interestRate / 12, payments, loanAmount);
  };

  const generateTableData = () => {
    const dateTable = {
      0: "enero",
      1: "febrero",
      2: "marzo",
      3: "abril",
      4: "mayo",
      5: "junio",
      6: "julio",
      7: "agosto",
      8: "septiembre",
      9: "octubre",
      10: "noviembre",
      11: "diciembre",
    };

    if (paymentMonths === "") {
      paymentMonths = 6;
    }
    let tableRows = [];
    let row, dateString;

    const minInterest =
      rateType === FIXED_RATE
        ? creditType?.minFixedInterestRate
        : tiie.value / 100 + creditType?.minVariableInterestRate;
    const maxInterest =
      rateType === FIXED_RATE
        ? creditType?.maxFixedInterestRate
        : tiie.value / 100 + creditType?.maxVariableInterestRate;

    const minValue = calculateAmortization(
      loanAmount,
      minInterest,
      paymentMonths
    );
    const maxValue = calculateAmortization(
      loanAmount,
      maxInterest,
      paymentMonths
    );
    const minPayment = formatCurrency(Math.abs(minValue));
    const maxPayment = formatCurrency(Math.abs(maxValue));

    for (let i = 1; i <= paymentMonths; i++) {
      const currentDate = new Date();
      const paymentDate = new Date(
        currentDate.setMonth(currentDate.getMonth() + (i - 1))
      );
      const day = paymentDate.getDate();
      const month = dateTable[paymentDate.getMonth()];
      const year = paymentDate.getFullYear();

      dateString = day + " " + month + " " + year;
      row = createData(i, dateString, minPayment, maxPayment);
      tableRows.push(row);
    }

    return tableRows;
  };

  const formatCurrency = (value) => {
    return Intl.NumberFormat("us", {
      style: "currency",
      currency: "MXN",
      currencyDisplay: "symbol",
    }).format(value);
  };

  useEffect(() => {
    setTableData(generateTableData());
  }, [paymentMonths, loanAmount, interestRate, headerBackground, rateType]);

  return (
    <StyledTableContainer>
      <Table size="small">
        <StyledTableHead
          background={headerBackground || Colors.PRIMARY_DARK_AFI_SOFOM}
        >
          <TableRow>
            <TableCell align="center">{headers[0]}</TableCell>
            <TableCell align="center">{headers[1]}</TableCell>
            {rateType === FIXED_RATE && (
              <>
                <TableCell align="center">
                  {headers[2] +
                    " con tasa del " +
                    Math.floor(creditType?.minFixedInterestRate * 100)}
                  %
                </TableCell>
                <TableCell align="center">
                  {headers[3] +
                    " con tasa del " +
                    Math.floor(creditType?.maxFixedInterestRate * 100)}
                  %
                </TableCell>
              </>
            )}
            {rateType === VARIABLE_RATE && (
              <>
                <TableCell align="center">
                  {headers[2] +
                    " con tasa mínima (TIIE + " +
                    Math.floor(creditType?.minVariableInterestRate * 100)}
                  {" p.p.)**"}
                </TableCell>
                <TableCell align="center">
                  {headers[2] +
                    " con tasa máxima (TIIE + " +
                    Math.floor(creditType?.maxVariableInterestRate * 100)}
                  {" p.p.)**"}
                </TableCell>
              </>
            )}
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {tableData?.map((row) => (
            <TableRow key={row.parcialidad}>
              <TableCell align="center">{row.parcialidad}</TableCell>
              <TableCell align="center">{row.fechaEst}</TableCell>
              <TableCell align="center">{row.min}</TableCell>
              <TableCell align="center">{row.max}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default FormOneTable;
