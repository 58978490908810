import GeneralTable from "../general-table";
import React, {useEffect, useState} from "react";
import Strings from "../../assets/strings";
import {formatCurrency} from "../../data/services/utils";
import {getClients} from "../../data/services/admins/clients";
import PropTypes from 'prop-types';
import SmallCustomButton from "../small-button";
import Colors from "../../assets/colors";
import Fonts from "../../assets/fonts";
import {AfisofomCredit} from "../../data/models/afisofom-client";
import styled from "styled-components";

const LabelStatus = styled.span`
    font-family: ${Fonts.MEDIUM};
    font-size: 1em;
    color: ${props => props.status === AfisofomCredit.STAGE_APPROVED 
        ? Colors.PRIMARY_AFI_SERVICIOS 
        : (props.status === AfisofomCredit.STAGE_DECLINED ? Colors.ERROR : Colors.DARK_GRAY)
    };
`;

const DetailButton = ({ onClick }) => (
    <SmallCustomButton label={Strings.MANAGE}
                       onClick={() => onClick()}
                       color={Colors.WHITE}
                       background={Colors.BLACK}
                       font={Fonts.BOLD} />
);

const AdminClientsTable = ({ perPageGeneral, byAdvisor, advisorId, fromAfisofom, goTo }) => {
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showLoadMore, setShowLoadMore] = useState(true);
    const [showAll, setShowAll] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [perPage, setPerPage] = useState(0);
    const columnsAfisofom = [
        { title: "#", field: "index" },
        { title: Strings.NAME, field: "name" },
        { title: Strings.AFISOFOM_REQUEST_DATE, field: "request_date" },
        {
            title: Strings.QTY,
            field: "qty",
            render: data => <span>{formatCurrency(data.qty)}</span>
        },
        {
            title: Strings.TERM,
            field: "term",
            render: data => <span>{data.term} {Strings.MONTHS}</span>
        },
        { title: Strings.ADVISOR, field: "advisor" },
        {
            title: Strings.STATUS,
            field: "status",
            render: data => <LabelStatus status={data.status}>{data.status}</LabelStatus>
        },
        {
            title: Strings.ACTION,
            field: "action",
            render: data => <DetailButton onClick={() => goTo(data.id)} />
        },
    ];
    const columnsAfiservicios = [
        { title: "#", field: "index" },
        { title: Strings.NAME, field: "name" },
        { title: Strings.AFISOFOM_REQUEST_DATE, field: "request_date" },
        { title: Strings.AFISERVICIOS_REQUESTED_SERVICES, field: "service" },
        { title: Strings.ADVISOR, field: "advisor" },
        { title: Strings.STATUS, field: "status" },
        {
            title: Strings.ACTION,
            field: "action",
            render: data => <DetailButton onClick={() => goTo(data.id)} />
        },
    ];
    const columns = fromAfisofom ? columnsAfisofom : columnsAfiservicios;

    useEffect(() => {
        getClientsList();
        // eslint-disable-next-line
    }, []);

    const onLoadMore = () => {
        const newPerPage = perPage + perPageGeneral;
        setPerPage(newPerPage);
        getClientsList();
    }

    const getClientsList = async (show_all, search_text) => {
        setIsLoading(true);

        try {
            const res = await getClients(
                fromAfisofom,
                perPage,
                show_all,
                search_text,
                byAdvisor,
                advisorId
            );
            initData(res.clients, res.load_more);
        } catch (error) {
            console.log(error);
        }
    }

    const initData = (clients, load_more) => {
        const auxRows = [];

        if (clients.length > 0) {
            clients.forEach((client, index) => {
                if (fromAfisofom && client?.afisofom_credit?.is_requested) {
                    auxRows.push({
                        index: index + 1,
                        id: client.id,
                        name: client.is_moral_person ? client.moral_person.business_name : client.full_name,
                        request_date: client.afisofom_credit.request_date,
                        qty: client.afisofom_credit.amount,
                        term: client.afisofom_credit.term,
                        advisor: client.afisofom_credit.advisor,
                        status: client.afisofom_credit.stage,
                        action: "Gestionar"
                    });
                } else if (client?.afiservicios_form?.is_requested) {
                    auxRows.push({
                        index: index + 1,
                        id: client.id,
                        name:  client.is_moral_person ? client.moral_person.business_name : client.full_name,
                        request_date: client.afiservicios_form.request_date,
                        service: client.afiservicios_form.type,
                        advisor: client.afiservicios_form.advisor,
                        status: client.afiservicios_form.stage,
                        action: "Gestionar"
                    });
                }
            });
        }

        setRows(auxRows);
        setShowLoadMore(load_more);
        setIsLoading(false);
    }

    const onCheckShowAll = (show) => {
        setShowAll(show);
        getClientsList(show, searchText);
    }

    const onChangeSearch = (value) => {
        setSearchText(value);
        getClientsList(showAll, value);
    }

    return (
        <GeneralTable rows={rows}
                      columns={columns}
                      isLoading={isLoading}
                      showLoadMore={showLoadMore}
                      onLoadMore={() => onLoadMore()}
                      showAllChecked={showAll}
                      searchValue={searchText}
                      onChangeShowAll={checked => onCheckShowAll(checked)}
                      onChangeSearch={value => onChangeSearch(value)} />
    );
};

AdminClientsTable.propTypes = {
    perPageGeneral: PropTypes.number.isRequired,
    byAdvisor: PropTypes.bool.isRequired,
    fromAfisofom: PropTypes.bool.isRequired,
    advisorId: PropTypes.string,
    goTo: PropTypes.func.isRequired
};

export default AdminClientsTable;
