import React, {Component} from "react";
import styled from "styled-components";
import Fonts from "../../assets/fonts";
import Colors from "../../assets/colors";
import Strings from "../../assets/strings";
import SiteFrame from "../site-frame";

const SitesWrapper = styled.div`
  padding: 15px 15px 0 0;
`

const PageTitle = styled.span`
    font-family: ${Fonts.BRANDON_BOLD};
    font-size: 2em;
    color: ${Colors.DARK_GRAY};
    text-transform: uppercase;
`;

const PageSubtitle = styled.span`
    font-family: ${Fonts.MEDIUM};
    font-size: 1em;
    color: ${Colors.DARK_GRAY};
`;

const DivMargin = styled.div`
    margin-top: 30px;
`;

const FrameContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    
    /* Tablet */
    @media only screen and (max-width: 992px) and (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
    
    /* Móvil */
    @media only screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    } 
`;


/**
 * Clase Sites
 */
class Sites extends Component {

    /**
     * render
     * @returns {JSX.Element}
     */
    render() {
        const sites = [
            Strings.SITE_AFISOFOM,
            Strings.SITE_AFISERVICIOS,
            Strings.SITE_MLP,
        ];
        return (
            <SitesWrapper>
                <div>
                    <PageTitle>
                        {Strings.SITES}
                    </PageTitle>
                </div>
                <DivMargin>
                    <PageSubtitle>
                        {Strings.CLIENTS_SUBTITLE}
                    </PageSubtitle>
                </DivMargin>
                <DivMargin>
                    <FrameContainer>
                        {sites.map((site, key) => (
                            <SiteFrame site={site} key={key}/>
                        ))}
                    </FrameContainer>
                </DivMargin>
            </SitesWrapper>
        )
    }
}

export default Sites;
