import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import AfisofomLogin from "./pages/afisofom/login";
import AfiserviciosLogin from "./pages/afiservicios/login";
import Dashboard from "./pages/dashboard";
import AfisofomDashboard from "./pages/afisofom/dashboard";
import AfiserviciosDashboard from "./pages/afiservicios/dashboard";
import AfiserviciosRegister from "./pages/afiservicios/register";
import AdminsLogin from "./pages/admins/login";
import AdminsClients from "./pages/admins/clients";
import AdminsSites from "./pages/admins/sites";
import AfisofomForgotPassword from "./pages/afisofom/forgot-password";
import AfisofomRestorePassword from "./pages/afisofom/restore-password";
import AfiserviciosForgotPassword from "./pages/afiservicios/forgot-password";
import AfiserviciosRestorePassword from "./pages/afiservicios/restore-password";
import AdminsRestorePassword from "./pages/admins/restore-password";
import AdminsForgotPassword from "./pages/admins/forgot-password";
import AfisofomRegister from "./pages/afisofom/register";
import AfisofomConfirmSignUp from "./pages/afisofom/confirm-signup";
import {GuardedRoute, GuardProvider} from "react-router-guards";
import {isAlreadyConfirmed, isAuthenticated, isNotAuthenticated} from "./guards/auth";
import {isAfisofomClient, isAfisofomClientAllowNoSession, isAfisofomClientCompletedForm} from "./guards/afisofom/client";
import {isAdminClient, isAdminOrAdvisorClient, isNotAdminClient} from "./guards/admins/client";
import AfisofomFormOne from "./pages/afisofom/form-one";
import AdminSiteDetail from "./pages/admins/site-detail";
import AdminsClientDetail from "./pages/admins/client-detail";
import AdminsCreateAdvisor from "./pages/admins/create-advisor";
import AdminConfirmSignUp from "./pages/admins/confirm-signup";
import SolicitudRequerida from "./pages/afisofom/required-form";
import ConfirmarSolicitud from "./pages/afisofom/confirm-form";
import AdminsAdvisors from "./pages/admins/advisors";
import AdminsAdvisorDetail from "./pages/admins/advisor-detail";
import AdminsManageClient from "./pages/admins/manage-client";
import NotFoundPage from "./pages/not-found-page";
import AfiserviciosConfirmSignup from "./pages/afiservicios/confirm-signup";

/**
 * Application routes
 * @author @leonard_lib
 * @date 29/07/2020
 */
const Routes = (
    <Router>
        <div>
            <GuardProvider guards={[
                isAuthenticated,
                isNotAuthenticated,
                isNotAdminClient,
                isAdminClient,
                isAlreadyConfirmed,
                isAdminOrAdvisorClient,
                isAfisofomClient,
                isAfisofomClientCompletedForm,
                isAfisofomClientAllowNoSession
            ]}>
                <Switch>
                		{
                			// DASHBOARD GENERAL
                		}
                		<GuardedRoute exact path="/"
                									component={Dashboard}
                									meta={{
	                                    check_for_auth: true,
                                        path_not_confirmed:'/afisofom/confirmar-registro',
	                                    redirect: '/afisofom/iniciar-sesion'
                                  }} />
                    {
                        // Afisofom ROUTES
                    }
                    <GuardedRoute exact path="/afisofom/registro"
                                  component={AfisofomRegister}
                                  meta={{
                                      check_for_is_not_auth: true,
                                      path_not_confirmed:'/afisofom/confirmar-registro',
                                      redirect: '/afisofom/dashboard'
                                  }} />
                    <GuardedRoute exact path="/afisofom/iniciar-sesion"
                                  component={AfisofomLogin}
                                  meta={{
                                      check_for_is_not_auth: true,
                                      path_not_confirmed:'/afisofom/confirmar-registro',
                                      redirect: '/afisofom/dashboard'
                                  }} />
                    <GuardedRoute exact path="/afisofom/recuperar-contrasena"
                                  component={AfisofomForgotPassword}
                                  meta={{
                                      check_for_is_not_auth: true,
                                      path_not_confirmed:'/afisofom/confirmar-registro',
                                      redirect: '/afisofom/dashboard'
                                  }} />
                    <GuardedRoute exact path="/afisofom/restaurar-contrasena"
                                  component={AfisofomRestorePassword}
                                  meta={{
                                      check_for_is_not_auth: true,
                                      path_not_confirmed:'/afisofom/confirmar-registro',
                                      redirect: '/afisofom/dashboard'
                                  }} />
                    <GuardedRoute exact path="/afisofom/confirmar-registro"
                                  component={AfisofomConfirmSignUp}
                                  meta={{
                                      check_for_already_confirmed: true,
                                      path_confirmed:'/afisofom/',
                                      redirect: '/afisofom/iniciar-sesion'
                                  }} />
                    <GuardedRoute exact path="/afisofom/dashboard">
                        <Redirect to="/afisofom" />
                    </GuardedRoute>
                    <GuardedRoute exact path="/afisofom/"
                                  component={AfisofomDashboard}
                                  meta={{
                                      check_for_auth: true,
                                      check_for_completed_form: true,
                                      redirect: '/afisofom/iniciar-sesion',
                                      check_for_is_not_admin_or_advisor: true,
                                      path_admin: '/admin/clientes',
                                      path_not_confirmed:'/afisofom/confirmar-registro',
                                      redirect_no_completed_form: '/afisofom/solicitud-requerida'
                                  }} />
                    <GuardedRoute exact path="/afisofom/confirmar-solicitud"
                                  component={ConfirmarSolicitud}
                                  meta={{
                                      check_for_auth: true,
                                      check_for_completed_form: true,
                                      check_for_is_not_admin_or_advisor: true,
                                      path_admin: '/admin/clientes',
                                      path_not_confirmed:'/afisofom/confirmar-registro',
                                      redirect: '/afisofom/iniciar-sesion',
                                      redirect_completed_form: '/afisofom/'
                                  }} />
                    <GuardedRoute exact path="/afisofom/solicitud"
                                  component ={AfisofomFormOne}
                                  meta={{
                                    check_for_is_afisofom_allow_nosess: true,
                                      check_for_is_not_admin_or_advisor: true,
                                      path_admin: '/admin/clientes',
                                    redirect_allow_nosess: '/afisofom/dashboard'
                                  }} />
                    <GuardedRoute exact path="/afisofom/solicitud-requerida"
                                   component={SolicitudRequerida}
                                   meta={{
                                       check_for_auth: true,
                                       check_for_completed_form: true,
                                       check_for_is_not_admin_or_advisor: true,
                                       path_admin: '/admin/clientes',
                                       path_not_confirmed:'/afisofom/confirmar-registro',
                                       redirect: '/afisofom/solicitud',
                                       redirect_completed_form: '/afisofom/'
                                   }} />

                    {
                        // Afiservicios ROUTES
                    }
                    <GuardedRoute exact path="/afiservicios/iniciar-sesion"
                                  component={AfiserviciosLogin}
                                  meta={{
                                      check_for_is_not_auth: true,
                                      path_not_confirmed:'/afiservicios/confirmar-registro',
                                      redirect: '/afiservicios/dashboard'
                                  }} />
                    <GuardedRoute exact path="/afiservicios/recuperar-contrasena"
                                  component={AfiserviciosForgotPassword}
                                  meta={{
                                      check_for_is_not_auth: true,
                                      path_not_confirmed:'/afiservicios/confirmar-registro',
                                      redirect: '/afiservicios/dashboard'
                                  }} />
                    <GuardedRoute exact path="/afiservicios/restaurar-contrasena"
                                  component={AfiserviciosRestorePassword}
                                  meta={{
                                      check_for_is_not_auth: true,
                                      path_not_confirmed:'/afiservicios/confirmar-registro',
                                      redirect: '/afiservicios/dashboard'
                                  }} />
                    <GuardedRoute exact path="/afiservicios/confirmar-registro"
                                  component={AfiserviciosConfirmSignup}
                                  meta={{
                                      check_for_already_confirmed: true,
                                      path_confirmed:'/afiservicios/',
                                      redirect: '/afiservicios/iniciar-sesion'
                                  }} />
                    <GuardedRoute exact path="/afiservicios/dashboard"
                                  component={AfiserviciosDashboard}
                                  meta={{
                                      check_for_auth: true,
                                      check_for_is_not_admin_or_advisor: true,
                                      path_admin: '/admin/clientes',
                                      path_not_confirmed:'/afiservicios/confirmar-registro',
                                      redirect: '/afiservicios/iniciar-sesion'
                                  }} />
                    <GuardedRoute exact path="/afiservicios/"
                                  component={AfiserviciosDashboard}
                                  meta={{
                                      check_for_auth: true,
                                      check_for_is_not_admin_or_advisor: true,
                                      path_admin: '/admin/clientes',
                                      path_not_confirmed:'/afiservicios/confirmar-registro',
                                      redirect: '/afiservicios/iniciar-sesion'
                                  }} />
                    <GuardedRoute exact path="/afiservicios/registro"
                                  component={AfiserviciosRegister}
                                  meta={{
                                      check_for_is_not_auth: true,
                                      path_not_confirmed: '/afiservicios/confirmar-registro',
                                      redirect: '/afiservicios'
                                  }} />

                    {
                        // ADMIN ROUTES
                    }
                    <GuardedRoute exact path="/admin/iniciar-sesion"
                                  component={AdminsLogin}
                                  meta={{
                                      check_for_is_not_auth: true,
                                      redirect: '/admin'
                                  }} />
                    <GuardedRoute exact path="/admin/recuperar-contrasena"
                                  component={AdminsForgotPassword}
                                  meta={{
                                      check_for_is_not_auth: true,
                                      redirect: '/admin'
                                  }} />
                    <GuardedRoute exact path="/admin/restaurar-contrasena"
                                  component={AdminsRestorePassword}
                                  meta={{
                                      check_for_is_not_auth: true,
                                      redirect: '/admin' }} />
                    <GuardedRoute exact path="/admin">
                        <Redirect to="/admin/clientes" />
                    </GuardedRoute>
                    <GuardedRoute exact path="/admin/clientes"
                                  component={AdminsClients}
                                  meta={{
                                      check_for_auth: true,
                                      check_for_is_admin_or_advisor: true,
                                      path_not_confirmed: '/admin/asesores/confirmar',
                                      redirect: '/admin/iniciar-sesion'
                                  }} />
                    <GuardedRoute exact path="/admin/clientes/:id"
                                  component={AdminsClientDetail}
                                  meta={{
                                      check_for_auth: true,
                                      check_for_is_admin_or_advisor: true,
                                      path_not_confirmed: '/admin/asesores/confirmar',
                                      redirect: '/admin/clientes'
                                  }} />
                    <GuardedRoute exact path="/admin/clientes/:id/gestion"
                                  component={AdminsManageClient}
                                  meta={{
                                      check_for_auth: true,
                                      check_for_is_admin_or_advisor: true,
                                      path_not_confirmed: '/admin/asesores/confirmar',
                                      redirect: '/admin/clientes'
                                  }} />
                    <GuardedRoute exact path="/admin/asesores"
                                  component={AdminsAdvisors}
                                  meta={{
                                      check_for_auth: true,
                                      check_for_is_admin: true,
                                      path_not_confirmed: '/admin/asesores/confirmar',
                                      redirect: '/admin/iniciar-sesion'
                                  }} />
                    <GuardedRoute exact path="/admin/asesores/confirmar"
                                  component={AdminConfirmSignUp}
                                  meta={{
                                      check_for_is_not_auth: true,
                                      redirect: '/admin/asesores'
                                  }} />
                    <GuardedRoute exact path="/admin/asesores/:id"
                                  component={AdminsAdvisorDetail}
                                  meta={{
                                      check_for_auth: true,
                                      check_for_is_admin: true,
                                      path_not_confirmed: '/admin/asesores/confirmar',
                                      redirect: '/admin/asesores'
                                  }} />
                    <GuardedRoute exact path="/admin/asesores/nuevo/:type?"
                                  component={AdminsCreateAdvisor}
                                  meta={{
                                      check_for_auth: true,
                                      check_for_is_admin: true,
                                      path_not_confirmed: '/admin/asesores/confirmar',
                                      redirect: '/admin/asesores'
                                  }} />
                    <GuardedRoute exact path="/admin/sitios"
                                  component={AdminsSites}
                                  meta={{
                                      check_for_auth: true,
                                      check_for_is_admin: true,
                                      path_not_confirmed: '/admin/asesores/confirmar',
                                      redirect: '/admin/iniciar-sesion'
                                  }} />
                    <GuardedRoute exact path="/admin/sitios/:name"
                                  component={AdminSiteDetail}
                                  meta={{
                                      check_for_auth: true,
                                      check_for_is_admin: true,
                                      path_not_confirmed: '/admin/asesores/confirmar',
                                      redirect: '/admin/iniciar-sesion'
                                  }} />

                    <Route path="*" component={NotFoundPage}/>
                </Switch>
            </GuardProvider>

        </div>
    </Router>
);

export default Routes;
