import React from "react"
import styled from "styled-components"
import Strings from "../assets/strings";

const PasswordHintWrapper = styled.div`
    padding: 15px 30px;
    box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.16);
`;

const PasswordHintSub = styled.p``;

const PasswordHintList = styled.ul``;

const PasswordHintItem = styled.li``;

const PasswordHint = () => (
    <PasswordHintWrapper>
        <PasswordHintSub>
            {Strings.PASSWORD_HINT}
        </PasswordHintSub>
        <PasswordHintList>
            <PasswordHintItem>{Strings.PASSWORD_LENGTH}</PasswordHintItem>
            <PasswordHintItem>{Strings.PASSWORD_UPPER}</PasswordHintItem>
            <PasswordHintItem>{Strings.PASSWORD_LOWER}</PasswordHintItem>
            <PasswordHintItem>{Strings.PASSWORD_SYMBOL}</PasswordHintItem>
        </PasswordHintList>
    </PasswordHintWrapper>
);

export default PasswordHint;
