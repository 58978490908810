import React, {useEffect} from "react";

export const useChangeTitleFavicon = (title, favicon) => {
    useEffect(() => {
        async function setTitle(title) {
            document.title = title
        }
        function getFaviconEl() {
            return document.querySelector("#favicon");
        }
        async function setFavicon(favicon) {
            switch (favicon) {
                case "afisofom":
                    getFaviconEl().href="/favicon-afisofom.ico"
                    break;
                case "afiservicios":
                    getFaviconEl().href="/favicon-afiservicios.ico"
                    break;
                default:
                    getFaviconEl().href="/favicon.ico"
                    break
            }
        }
        setTitle(title)
        setFavicon(favicon)
    });
}
