import React, {useState} from "react";
import LayoutAdmins from "../../components/layout-admins";
import {PageTitle} from "../../components/shared-components/text-styles";
import Strings from "../../assets/strings";
import {DivMargin, TabItem, TabsContainer} from "../../components/shared-components/container-styles";
import BasicInfoForm from "../../components/basic-info-form";
import {clientExists, register} from "../../data/services/auth";
import AdminClient from "../../data/models/admin";
import {registerAdvisor, sendAdminAdvisorInvite} from "../../data/services/admins/clients";
import {
    useParams
} from 'react-router-dom';
import {randomPassword} from "../../data/services/utils";
import {useChangeTitleFavicon} from "../../hooks/useChangeTitle";

const AdminsCreateAdvisor = ({ history }) => {
    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState(new AdminClient());
    const [token] = useState(randomPassword());
    const [requestError, setRequestError] = useState(null);
    const [infoMsg, setInfoMsg] = useState(null);
    const { type } = useParams();

    const onSubmitted = (user) => {
        setLoading(true);
        setRequestError(null);
        setInfoMsg(null);
        setClient(user);
        isRegistered();
    };

    const isRegistered = async () => {
        try {
            const exists = await clientExists(client.email);

            if (!exists) {
                registerOnCognito();
            } else {
                setLoading(false);
                setRequestError(Strings.ALREADY_REGISTER);
            }
        } catch (error) {
            setLoading(false);
            setRequestError(error);
        }
    }

    const registerOnCognito = async () => {
        try {
            await register(client.email, token, false);
            makeCallToRegisterInDB();
        } catch (error) {
            setLoading(false);
            setRequestError(error);
        }
    }

    const makeCallToRegisterInDB = async () => {
        if (type === 'afisofom') {
            client.addRole(AdminClient.ROLE_AFISOFOM_ADVISOR);
        } else {
            client.addRole(AdminClient.ROLE_AFISERVICIOS_ADVISOR);
        }

        try {
            await registerAdvisor(client);
            sendEmailToAdvisor();
        } catch (error) {
            setLoading(false);
            setRequestError(error);
        }
    }

    const sendEmailToAdvisor = async () => {
        try {
            await sendAdminAdvisorInvite(client.email);
            setLoading(false);
            setInfoMsg(Strings.ADVISOR_INVITED);
            history.push('/admin/asesores')
        } catch (error) {
            setLoading(false);
            setRequestError(error);
        }
    };
    useChangeTitleFavicon( Strings.TITLE_CREATE_ADVISOR_ADMIN);
    return (
        <LayoutAdmins history={history}
                      showBack={true}
                      tabAdminSelected={1}>
            <div className="container" style={{ marginTop: '130px' }}>
                <PageTitle>
                    {Strings.CREATE_ADVISOR}
                </PageTitle>
                <DivMargin>
                    <TabsContainer>
                        <TabItem site="admin"
                                 onClick={() => {}}
                                 selected={true}>
                            <span>{Strings.GENERAL_INFO}</span>
                        </TabItem>
                    </TabsContainer>
                </DivMargin>
                <DivMargin>
                    {
                        client &&
                        <BasicInfoForm isLoadingButton={loading}
                                       error={requestError}
                                       success={infoMsg}
                                       user={client}
                                       onSubmit={(data, v) => onSubmitted(data, v)} />
                    }
                </DivMargin>
            </div>
        </LayoutAdmins>
    );
};

export default AdminsCreateAdvisor;
