import React from "react";
import LayoutAdmins from "../../components/layout-admins";
import AdminsLoginForm from "../../components/admins/login-form";
import {useChangeTitleFavicon} from "../../hooks/useChangeTitle";
import Strings from "../../assets/strings";

const AdminsLogin = ({ history }) => {
    useChangeTitleFavicon( Strings.TITLE_LOGIN);
    return (
        <LayoutAdmins history={history} >
            <div className="container">
                <AdminsLoginForm
                    onUserNotConfirmed={email => history.push('/admin/asesores/confirmar?email=' + email)}
                    onForgotPassword={() =>
                        history.push('/admin/recuperar-contrasena')
                    }
                    onLogin={() =>
                        history.push('/admin')
                    }
                />
            </div>
        </LayoutAdmins>
    );
}

export default AdminsLogin;
