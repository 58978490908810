import {ErrorMsg, PageTitle} from "../shared-components/text-styles";
import Strings from "../../assets/strings";
import CustomModal from "../custom-modal";
import React, {useState} from "react";
import PropTypes from 'prop-types';
import AfisofomClient, {AfisofomCreditAnnexes} from "../../data/models/afisofom-client";
import Colors from "../../assets/colors";
import CustomButton from "../button";
import Fonts from "../../assets/fonts";
import styled from "styled-components";
import {DivMargin} from "../shared-components/container-styles";
import CustomInput from "../input";
import {getFileTypes} from "../../data/services/utils";
import {uploadFile} from "../../data/services/s3";
import {updateAfisofomCredit} from "../../data/services/afisofom/client";
import LoadingIndicator from "../loading-indicator";

const ButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    margin-top: 50px;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }
`;

const InputWrapper = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 2em;
    justify-content: center;
    align-items: center;
`;

const ErrorWrapper = styled.div`
    margin-top: 30px;
    text-align: center;
`;

const AfisofomAddAnnexe = ({ client, isOpen, onClose, onRegistered }) => {
    const [loading, setLoading] = useState(false);
    const [isLoadingFile, setIsLoadingFile] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [requestError, setRequestError] = useState(null);
    const [annexeName, setAnnexeName] = useState('');
    const [formError, setFormError] = useState(true);
    const [fileKey, setFileKey] = useState(null);
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const fileTypes = getFileTypes();

    const onRegister = async () => {
        setLoading(true);
        setSubmitted(true);
        setRequestError(null);

        if (verifyData()) {
            try {
                const auxAnnexes = client.afisofom_credit.annexes || [];
                auxAnnexes.push(new AfisofomCreditAnnexes({
                    key: fileKey,
                    name: annexeName
                }));
                client.afisofom_credit.annexes = auxAnnexes;

                await updateAfisofomCredit(
                    client.id,
                    client.email,
                    client.afisofom_credit
                );
                setLoading(false);
                onRegistered();
            } catch (error) {
                setLoading(false);
                setRequestError(error);
            }
        } else {
            setLoading(false);
            setRequestError(Strings.AFISOFOM_PAYMENTS_NOT_COMPLETED);
        }
    };

    const verifyData = () => {
        return !formError && fileKey != null;
    };

    const handleUpload = async (event) => {
        setIsLoadingFile(true);
        const file = event.target.files[0];
        const file_name = 'annexe-file-' + file.name;

        try {
            const { key } = await uploadFile(file, file_name);
            setFileKey(key);
            setIsFileUploaded(true);
            setIsLoadingFile(false);
        } catch (error) {
            setRequestError(error);
            setIsLoadingFile(false);
        }
    };

    return (
        <CustomModal onClose={() => onClose()}
                     isOpen={isOpen}>
            <PageTitle>
                {Strings.AFISOFOM_REGISTER_ANNEXE}
            </PageTitle>
            <DivMargin>
                <form noValidate autoComplete="off">
                    <InputWrapper>
                        <CustomInput label={Strings.FILE_NAME}
                                     name="name"
                                     id="name"
                                     value={annexeName}
                                     color={Colors.BLACK}
                                     required={true}
                                     submitted={submitted}
                                     invalid={formError}
                                     onChange={(val, v) => {
                                         setAnnexeName(val);
                                         setFormError(!v);
                                     }}
                                     type="text"/>
                        <div>
                            <CustomInput id="file-key-payment"
                                         name="file-key-payment"
                                         type="file"
                                         inputFileDark={true}
                                         buttonType="icon"
                                         onChange={event => handleUpload(event)}
                                         accept={fileTypes}
                                         uploaded={isFileUploaded}
                                         isID={false}
                                         label="" />
                            {
                                isLoadingFile &&
                                <LoadingIndicator />
                            }
                        </div>
                    </InputWrapper>
                    <ButtonsWrapper>
                        <CustomButton label={Strings.CANCEL}
                                      onClick={() => onClose()}
                                      align="center"
                                      color={Colors.DARK_GRAY}
                                      background={Colors.WHITE}
                                      font={Fonts.BOLD}
                                      fullWidth={false} />
                        <CustomButton label={Strings.REGISTER_2}
                                      onClick={() => onRegister()}
                                      align="center"
                                      isLoading={loading}
                                      color={Colors.WHITE}
                                      background={Colors.DARK_GRAY}
                                      font={Fonts.BOLD}
                                      fullWidth={false} />
                    </ButtonsWrapper>
                </form>
            </DivMargin>
            {
                requestError &&
                <ErrorWrapper>
                    <ErrorMsg>
                        {requestError}
                    </ErrorMsg>
                </ErrorWrapper>
            }
        </CustomModal>
    );
};

AfisofomAddAnnexe.propTypes = {
    client: PropTypes.instanceOf(AfisofomClient).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onRegistered: PropTypes.func.isRequired,
};

export default AfisofomAddAnnexe;
