import React, {useEffect, useState} from "react";
import LayoutAdmins from "../../components/layout-admins";
import {DefaultText, PageTitle} from "../../components/shared-components/text-styles";
import Strings from "../../assets/strings";
import {DivMargin, TabItem, TabsContainer} from "../../components/shared-components/container-styles";
import BasicInfoForm from "../../components/basic-info-form";
import {
    useParams
} from 'react-router-dom';
import {updateClientBasicData} from "../../data/services/client";
import {getAfisofomClientData, updateAfisofomCredit} from "../../data/services/afisofom/client";
import {getAfiserviciosClientData, updateAfiserviciosForm} from "../../data/services/afiservicios/client";
import AfiserviciosClient from "../../data/models/afiservicios-client";
import AfisofomClient, {AfisofomCredit} from "../../data/models/afisofom-client";
import LoadingIndicator from "../../components/loading-indicator";
import ChatForm from "../../components/chat-form";
import {getClientData} from "../../data/services/auth";
import AdminClient from "../../data/models/admin";
import styled from "styled-components";
import AfisofomRequestBasicData from "../../components/admins/afisofom-request-basic-data";
import AfisofomModalEdit from "../../components/admins/afisofom-modal-edit";
import CreditInfo from "../../components/afisofom/credit-info";
import {Alert} from "@material-ui/lab";
import {Snackbar} from "@material-ui/core";
import {useChangeTitleFavicon} from "../../hooks/useChangeTitle";

const MultipleChatContainer = styled.div`
    display: grid;
    grid-template-columns: ${props => props.isFromBoth ? '1fr 1fr' : '1fr'};
    grid-gap: ${props => props.isFromBoth ? '2em' : '0'};
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        grid-template-columns: 1fr;
    }
`;

const AdminsManageClient = ({ history }) => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [clientAfisofom, setClientAfisofom] = useState(null);
    const [clientAfiservicios, setClientAfiservicios] = useState(null);
    const [requestError, setRequestError] = useState(null);
    const [infoMsg, setInfoMsg] = useState(null);
    const [formDisabled, setFormDisabled] = useState(true);
    const [formAfisofomDisabled, setFormAfisofomDisabled] = useState(true);
    const [selectedTab, setSelectedTab] = useState(0);
    const [adminClient, setAdminClient] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    // Get afisofom client by id
    const getSofomData = async () => {
        try {
            setLoading(true);
            const res = await getAfisofomClientData(id);
            setClientAfisofom(res);

            if (res?.afisofom_credit?.is_requested) {
                setSelectedTab(0);
            } else {
                setSelectedTab(1);
            }

            getServiciosData();
        } catch (error) {
            setLoading(false);
            setRequestError(error);
        }
    };

    // Get afiservicios client by id
    const getServiciosData = async () => {
        try {
            setLoading(true);
            const res = await getAfiserviciosClientData(id);
            setClientAfiservicios(res);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setRequestError(error);
        }
    };

    const getAdminData = () => {
        const data = getClientData();
        setAdminClient(new AdminClient(data));
    };

    // on mount
    useEffect(() => {
        getAdminData();
        getSofomData();
        // eslint-disable-next-line
    }, []);

    const onChatCreated = (chat_id, from_afisofom) => {
        if (from_afisofom) {
            const auxClient = clientAfisofom;
            auxClient.afisofom_credit.chat_id = chat_id;
            onSubmitted(auxClient, clientAfiservicios);
        } else {
            const auxClient = clientAfiservicios;
            auxClient.afiservicios_form.chat_id = chat_id;
            onSubmitted(clientAfisofom, auxClient);
        }
    };

    const onSubmitted = (sofom, servicios) => {
        setLoadingButton(true);
        setRequestError(null);
        setInfoMsg(null);
        setClientAfisofom(sofom);
        setClientAfiservicios(servicios);
        makeUpdate();
    };

    const makeUpdate = async () => {
        try {
            await updateClientBasicData(clientAfisofom);
            await updateAfisofomCredit(
                clientAfisofom.id,
                clientAfisofom.email,
                clientAfisofom.afisofom_credit
            );
            await updateAfiserviciosForm(
                clientAfiservicios.id,
                clientAfiservicios.email,
                clientAfiservicios.afiservicios_form
            );

            setInfoMsg(Strings.UPDATED_CLIENT_BASIC_DATA)
            setOpenSnackbar(true)
            setLoadingButton(false);

            setTimeout(() => {
                setInfoMsg(null);
            }, 5000);
        } catch (error) {
            setLoadingButton(false);
            setRequestError(error);
        }
    }

    const onClickEdition = (from) => {
        const open = from === 0 ? formAfisofomDisabled : formDisabled;
        setOpenModalEdit(open);

        if (!open) {
            if (from === 0) {
                setFormAfisofomDisabled(true);
            } else {
                setFormDisabled(true);
            }
        }
    }

    const onAcceptEdition = () => {
        setFormAfisofomDisabled(false);
        setFormDisabled(false);
        setOpenModalEdit(false);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    }

    const pageTitle = (clientAfisofom ? (clientAfisofom.is_moral_person ? clientAfisofom.moral_person.business_name :
        clientAfisofom.full_name) : "");
    useChangeTitleFavicon( pageTitle);
    return (
        <LayoutAdmins history={history}
                      showBack={true}
                      tabAdminSelected={1}>
            {
                loading &&
                <div className="container" style={{ marginTop: '130px' }}>
                    <LoadingIndicator />
                </div>
            }
            {
                !loading &&
                <div className="container" style={{ marginTop: '130px' }}>
                    <PageTitle>
                        {Strings.MANAGE_CLIENT}
                    </PageTitle>
                    <DivMargin>
                        <DefaultText>
                            {Strings.MANAGE_CLIENT_2}
                        </DefaultText>
                    </DivMargin>
                    <DivMargin>
                        <TabsContainer>
                            {
                                clientAfisofom?.afisofom_credit?.is_requested &&
                                clientAfisofom?.afisofom_credit?.stage !== AfisofomCredit.STAGE_DECLINED &&
                                <TabItem site="admin"
                                         onClick={() => setSelectedTab(0)}
                                         selected={selectedTab === 0}>
                                    <span>{Strings.AFISOFOM_CREDIT_INFO}</span>
                                </TabItem>
                            }
                            <TabItem site="admin"
                                     onClick={() => setSelectedTab(1)}
                                     selected={selectedTab === 1}>
                                <span>{Strings.GENERAL_INFO}</span>
                            </TabItem>
                            <TabItem site="admin"
                                     onClick={() => setSelectedTab(2)}
                                     selected={selectedTab === 2}>
                                <span>{Strings.CHAT}</span>
                            </TabItem>
                        </TabsContainer>
                    </DivMargin>
                    <DivMargin>
                        {
                            clientAfisofom?.afisofom_credit?.is_open && selectedTab === 0 &&
                            <AfisofomRequestBasicData showEditButton={true}
                                                      error={requestError}
                                                      client={clientAfisofom}
                                                      onClickEdit={() => onClickEdition(0)}
                                                      onUpdated={sofom => onSubmitted(sofom, clientAfiservicios)}
                                                      isLoadingButton={loadingButton}
                                                      disabled={formAfisofomDisabled} />
                        }
                        {
                            clientAfisofom?.afisofom_credit?.is_approved && selectedTab === 0 &&
                            <CreditInfo client={clientAfisofom} />
                        }
                        {
                            (clientAfisofom || clientAfiservicios) && selectedTab === 1 &&
                            <div>
                                <BasicInfoForm isLoadingButton={loadingButton}
                                               error={requestError}
                                               success={infoMsg}
                                               user={clientAfisofom || new AfisofomClient()}
                                               userAfiservicios={clientAfiservicios || new AfiserviciosClient()}
                                               disabled={formDisabled}
                                               showEditButton={true}
                                               canEditEmail={false}
                                               canEditAdvisor={false}
                                               canEditAdvisorAfiservicios={clientAfiservicios.afiservicios_form.is_requested}
                                               isFromAfisofom={clientAfisofom.afisofom_credit.is_requested}
                                               isFromAfiservicios={clientAfiservicios.afiservicios_form.is_requested}
                                               onClickEdit={() => onClickEdition(1)}
                                               onSubmit={(sofom, servicios) => onSubmitted(sofom, servicios)} />
                            </div>
                        }
                        <MultipleChatContainer isFromBoth={
                            clientAfisofom?.afisofom_credit?.is_requested
                            && clientAfiservicios?.afiservicios_form?.is_requested
                        }>
                            {
                                clientAfisofom?.afisofom_credit?.is_requested
                                && adminClient && selectedTab === 2 &&
                                <ChatForm fromAdmin={true}
                                          isFromAfisofom={true}
                                          title={Strings.AFISOFOM}
                                          showTitle={true}
                                          client={clientAfisofom}
                                          onChatCreated={chatId => onChatCreated(chatId, true)}
                                          advisor={adminClient} />
                            }
                            {
                                clientAfiservicios?.afiservicios_form?.is_requested &&
                                adminClient && selectedTab === 2 &&
                                <ChatForm fromAdmin={true}
                                          isFromAfisofom={false}
                                          title={Strings.AFISERVICIOS}
                                          showTitle={true}
                                          client={clientAfiservicios}
                                          onChatCreated={chatId => onChatCreated(chatId, false)}
                                          advisor={adminClient} />
                            }
                        </MultipleChatContainer>
                    </DivMargin>
                </div>
            }
            {
                clientAfisofom &&
                <AfisofomModalEdit client={clientAfisofom}
                                   onAccept={() => onAcceptEdition()}
                                   onClose={() => setOpenModalEdit(false)}
                                   isOpen={openModalEdit} />
            }
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={handleCloseSnackbar}
                open={openSnackbar}
                autoHideDuration={6000}
            >
                <Alert severity={"success"}> {infoMsg} </Alert>
            </Snackbar>
        </LayoutAdmins>
    );
};

export default AdminsManageClient;
