import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Drawer from "@material-ui/core/Drawer";
import PropTypes from "prop-types";
import Colors from "../../assets/colors";
import {ReactComponent as UserIcon} from "../../assets/icons/user.svg";
import {ReactComponent as QuestionIcon} from "../../assets/icons/question.svg";
import {ReactComponent as WorldIcon} from "../../assets/icons/world.svg";
import AdminDashboardMenuItem from "./dashboard-menu-item";
import Strings from "../../assets/strings";
import {Link} from "react-router-dom";
import {getClientData} from "../../data/services/auth";
import AdminClient from "../../data/models/admin";

const paperProps = {
    style: {
        width: '20%'
    }
};

const DashboardLayoutWrapper = styled.div`
    background-color: ${Colors.WHITE};
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        .menu-drawer-admin {
            display: none;
        }
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .menu-drawer-admin {
            display: none;
        }
    }
`;

const ContentContainer = styled.div`
    padding: 30px 0;
    max-width: 85%;
    margin-left: auto;
    min-height: calc(100vh - 130px);
    padding-bottom: 140px;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        margin-left: 0;
        max-width: 100%;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        margin-left: 0;
        max-width: 100%;
    }
`;

const MenuContainer = styled.div`
    margin-top: 100px;
    width: 100%;
    min-height: calc(100vh - 100px);
    overflow-y: scroll;
    background-color: ${Colors.LIGHT_GRAY};
    
    .link {
        text-decoration: none;
    }
`;

const AdminDashboardLayout = ({children, selected, hideDrawer = false}) => {

    const [client, setClient] = useState(null);

    // on mount
    useEffect(() => {
        const data = getClientData();
        if(data) {
            setClient(new AdminClient(data));
        }
    }, []);

    return (
        <DashboardLayoutWrapper>
            {!hideDrawer &&
            <Drawer PaperProps={paperProps}
                    variant="permanent"
                    className="menu-drawer-admin"
                    anchor="left">
                <MenuContainer>
                    <Link to={'/admin/clientes'} className="link">
                        <AdminDashboardMenuItem selected={selected === 0}
                                                icon={<UserIcon/>}
                                                label={Strings.CLIENTS}/>
                    </Link>
                    {
                        client && !client.isAdvisor &&
                        <Link to={'/admin/asesores'} className="link">
                            <AdminDashboardMenuItem selected={selected === 1}
                                                    icon={<QuestionIcon/>}
                                                    label={Strings.ADVISORS}/>
                        </Link>
                    }
                    {
                        client && client.isSuperAdmin &&
                        <Link to={'/admin/sitios'} className="link">
                            <AdminDashboardMenuItem selected={selected === 2}
                                                    icon={<WorldIcon/>}
                                                    label={Strings.SITES}/>
                        </Link>
                    }
                </MenuContainer>
            </Drawer>
            }

            <div className="container">
                <ContentContainer>
                    {children}
                </ContentContainer>
            </div>
        </DashboardLayoutWrapper>
    )
};


AdminDashboardLayout.propTypes = {
    children: PropTypes.element.isRequired,
    selected: PropTypes.number.isRequired
};

export default AdminDashboardLayout;
