import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Fonts from "../assets/fonts";
import Colors from "../assets/colors";
import Strings from "../assets/strings";
import {contentStructure} from "../utils/content-element";
import SiteElement from "./site-element";
import CustomButton from "./button";
import DeployConfirmation from "./admins/deploy-confirmation";
import {Alert} from "@material-ui/lab";
import {Snackbar} from "@material-ui/core";
import {useChangeTitleFavicon} from "../hooks/useChangeTitle";

const SiteContentWrapper = styled.div`
  padding: 15px 15px 0 0;
  height: 100%;
`

const PageTitle = styled.span`
    font-family: ${Fonts.BRANDON_BOLD};
    font-size: 2em;
    color: ${Colors.DARK_GRAY};
    text-transform: uppercase;
`;

const PageSubtitle = styled.span`
    font-family: ${Fonts.MEDIUM};
    font-size: 1em;
    color: ${Colors.DARK_GRAY};
`;

const DivMargin = styled.div`
    margin-top: 30px;
    
    .bottom-padding {
      padding-bottom: 100px;
    }
`;

const BottomContainer = styled.div`
    position: fixed;
    z-index: 1399;
    bottom: 0;
    left: 0;
    float: right;
    width: 100vw;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0 -3px 6px 0px rgba(0,0,0,0.16);
    background-color: ${Colors.WHITE};
    elevation: higher;
    display: flex;
    justify-content: flex-end !important;
    padding-right: 50px;
    box-sizing: border-box;
    
    @media only screen and (max-width: 992px) {
      justify-content: center !important;
      padding-left: 30px;
      padding-right: 30px;
      button {
        margin-bottom: 10px !important;
      }
    }
`;


const SiteContent = ({params}) => {
    const [data, setData] = useState({elements: []})
    const [openDeployConfirmation, setOpenDeployConfirmation] = useState(false);
    const [prod, setProd] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    useEffect(() => {
        const getStructure = async () => {
            const structure = await contentStructure(params.name);
            setData({
                elements: structure.data.elements,
                id: structure.data.id,
                title: structure.title,
                siteURL: structure.siteURL,
                devSiteURL: structure.devSiteURL
            });
        }
            getStructure();
            // eslint-disable-next-line
        }, [])


    const updateState = () => {
        setData({
            ...data
        })
    }

    const updateFile = async (e) => {
        setProd(e.currentTarget.id === "prod");
        setOpenDeployConfirmation(true);
    }

    const onDeployed = async () => {
        setOpenDeployConfirmation(false)
        setOpenSnackbar(true)
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    }
    const pageTitle = data.title ? data.title : "";
    useChangeTitleFavicon(pageTitle)
    return (
        <SiteContentWrapper>
            <div>
                <PageTitle>
                    {data.title}
                </PageTitle>
            </div>
            <DivMargin className={"bottom-padding"}>
                <PageSubtitle>
                    {Strings.CLIENTS_SUBTITLE}
                </PageSubtitle>
            </DivMargin>
            <DivMargin>
                {data.elements.map((elem, key) => (
                    <SiteElement element={elem} key={key} updateState={updateState}/>
                ))}
            </DivMargin>
            <BottomContainer>
                <div>
                    <CustomButton
                        label={Strings.DEPLOY_DEV}
                        onClick={updateFile}
                        id={"dev"}
                        color={Colors.DARK_GRAY}
                        background={Colors.WHITE}
                        fullWidth={false}
                        containerStyle={{display: "inline", marginRight: "20px"}}
                        font={Fonts.BOLD}/>

                    <CustomButton
                        label={Strings.DEPLOY_SITE}
                        onClick={updateFile}
                        id={"prod"}
                        color={Colors.WHITE}
                        background={Colors.DARK_GRAY}
                        containerStyle={{display: "inline"}}
                        fullWidth={false}
                        font={Fonts.BOLD}/>
                </div>
            </BottomContainer>
            <DeployConfirmation
                isOpen={openDeployConfirmation}
                onClose={() => setOpenDeployConfirmation(false)}
                onDeployed={onDeployed}
                data={data}
                prod={prod}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={handleCloseSnackbar}
                open={openSnackbar}
                autoHideDuration={6000}
            >
                <Alert severity={"success"}>El despliegue se ha iniciado con éxito, revisa la consola de AWS para más
                    detalles. </Alert>
            </Snackbar>
        </SiteContentWrapper>
    )
}

export default SiteContent
