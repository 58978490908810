import {API, graphqlOperation} from "aws-amplify";
import {updateSitesConfiguration} from "../../../graphql/queries";

export const sitesConfigurationUpdate = async (data, prod) => {
    return API.graphql(graphqlOperation(updateSitesConfiguration, {
        data: data,
        prod: prod
    }));
}


