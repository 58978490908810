import {DefaultText, ErrorMsg, PageTitle} from "../shared-components/text-styles";
import Strings from "../../assets/strings";
import CustomModal from "../custom-modal";
import React, {useState} from "react";
import PropTypes from 'prop-types';
import Colors from "../../assets/colors";
import CustomButton from "../button";
import Fonts from "../../assets/fonts";
import styled from "styled-components";
import AfiserviciosClient, {AfiserviciosForm} from "../../data/models/afiservicios-client";
import CustomInput from "../input";
import {updateAfiserviciosForm} from "../../data/services/afiservicios/client";

const ButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    margin-top: 50px;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }
`;

const ErrorWrapper = styled.div`
    margin-top: 30px;
    text-align: center;
`;

const CloseAdvisoryRequest = ({ client, isOpen, onClose, onTerminated }) => {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [comments, setComments] = useState('');
    const [requestError, setRequestError] = useState(null);
    const [errors, setErrors] = useState({
        comments: true
    });

    const updateState = (prop, value, valid) => {
        const auxErrors = errors;
        auxErrors[prop] = !valid;
        setErrors(auxErrors);
        setComments(value);
    };

    const verifyData = () => {
        return !errors['comments'];
    };

    const onDone = () => {
        setSubmitted(true);
        setLoading(true);

        if (verifyData()) {
            client.afiservicios_form.stage = AfiserviciosForm.STAGE_DONE;
            client.afiservicios_form.comments = comments;

            updateAfiserviciosForm(
                client.id,
                client.email,
                client.afiservicios_form
            ).then(() => {
                setLoading(false);
                onTerminated();
            }).catch(error => {
                setLoading(false);
                setRequestError(error);
            })
        } else {
            setLoading(false);
        }
    };

    return (
        <CustomModal onClose={() => onClose()}
                     isOpen={isOpen}>
            <PageTitle>
                {Strings.CLOSE_ADVISORY}
            </PageTitle>
            <br/>
            <br/>
            <DefaultText>
                {Strings.CLOSE_ADVISORY_MSG} <strong>{client.full_name}</strong>{Strings.CLOSE_ADVISORY_MSG_2}
            </DefaultText>
            <form noValidate autoComplete="off">
                <CustomInput label={Strings.COMMENTS + ' *'}
                             name="comments"
                             id="comments"
                             value={comments}
                             color={Colors.BLACK}
                             required={true}
                             rowsMax={5}
                             submitted={submitted}
                             invalid={errors['comments']}
                             onChange={(val, v) => updateState('comments', val, v)}
                             type="textarea" />
                <ButtonsWrapper>
                    <CustomButton label={Strings.CANCEL}
                                  onClick={() => onClose()}
                                  align="center"
                                  color={Colors.DARK_GRAY}
                                  background={Colors.WHITE}
                                  font={Fonts.BOLD}
                                  fullWidth={false} />
                    <CustomButton label={Strings.AFISERVICIOS_DONE}
                                  onClick={() => onDone()}
                                  align="center"
                                  isLoading={loading}
                                  color={Colors.WHITE}
                                  background={Colors.DARK_GRAY}
                                  font={Fonts.BOLD}
                                  fullWidth={false} />
                </ButtonsWrapper>
            </form>
            {
                requestError &&
                <ErrorWrapper>
                    <ErrorMsg>
                        {requestError}
                    </ErrorMsg>
                </ErrorWrapper>
            }
        </CustomModal>
    );
};

CloseAdvisoryRequest.propTypes = {
    client: PropTypes.instanceOf(AfiserviciosClient).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onTerminated: PropTypes.func.isRequired,
};

export default CloseAdvisoryRequest;
