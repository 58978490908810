import React, {Component} from 'react';
import styled from 'styled-components';
import bgImage from '../../assets/images/afisofom-logo-big.png';

import Fonts from "../../assets/fonts"
import Strings from "../../assets/strings"

import { getClientData } from "../../data/services/auth";
import { SectionTitle, SectionSubtitle, DefaultParagraph, DefaultLink } from "../shared-components/text-styles";
import { DefaultButton } from "../shared-components/button-styles";
import { PopupHolder, BGDiv } from "../shared-components/container-styles";


import "../../assets/styles/global.css";

const SolicitudContainer = styled.div `
  @media only screen and (min-width: 768px) {
    height: 80vh;
    padding-top: 1em;
    padding-right: 4em;
    padding-left: 3em;
    background: url(${bgImage}) bottom right no-repeat;
    background-size: 300px 300px;
    position: relative;

  }
`

const SRButton = styled(DefaultButton) `
  margin-top: .5em !important;
  padding: 3px 50px !important;
`

const SRPopUpBtn = styled(SRButton) `
  margin-top: 3em !important;
  @media only screen and (min-width: 768px) {
    float: right;
  }
  
  @media only screen and (max-width: 767px) {
    float: none;
    width: 200px !important;
    display: block !important;
    margin: auto !important;
    margin-top: 2em !important;
    padding: 5px 50px !important;
  }
`

const BottomLink = styled(DefaultLink) `
  cursor: pointer;
  
  @media only screen and (min-width: 768px) {
    position: absolute;
    bottom: 30px;
    left: 30px;
  }

  @media only screen and (max-width: 767px) {
    margin-top: 1em;
    margin-bottom: 1em;
    display: block;
  }

`

const PLink = styled(DefaultLink) `
  text-decoration: none;
`

const DashList = styled.ul `
  list-style-type: none;
  padding-left: 0;
  margin-top: -12px;
  margin-bottom: 30px;
  font-family: ${Fonts.REGULAR};

  & > li::before {
    content: "- ";
  }
`

const SRPopupHolder = styled(PopupHolder)`
  @media only screen and (max-width: 767px) {
    top: 15vh ;
  }
`


class SolicitudRequerida extends Component {
  constructor(props) {
    super(props);
    let username = getClientData();
    if (username && username.is_moral_person) {
      username = username.moral_person.business_name;
    } else if (username && !username.is_moral_person) {
      username = username.name
    } else {
      username = "Usuario";
    }
    
    this.state= {
      username : username,
      showPopup: false
    }

    this.startDownload = this.startDownload.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  startDownload() {

    this.setState({
        showPopup: true
    })
    setTimeout(() => {
      const response = {
        file: 'https://afi-configuration-bucket.s3.us-east-2.amazonaws.com/documentos.zip',
      };
      window.open(response.file);
      }, 2000)

  }

  closeModal() {
    this.setState({
        showPopup: false
    })
  }

  render() {
    // TODO: Asignar username desde el local storage
    const username = this.state.username;
    const showPopup = this.state.showPopup
    return (
      <SolicitudContainer className="box-shadow">
        {showPopup && (
          <div>
            <SRPopupHolder
                width="50vw"
                left="25vw"
                top="25vh"
                padding="1em 2em">
              <SectionSubtitle>{Strings.AFISOFOM_SOLREQ_POP_TITLE}</SectionSubtitle>
              <DefaultParagraph>{Strings.AFISOFOM_SOLREQ_POP_P1}</DefaultParagraph>
              <DashList>
                <li>{Strings.AFISOFOM_SOLREQ_POP_LI1}</li>
                <li>{Strings.AFISOFOM_SOLREQ_POP_LI2}</li>
                <li>{Strings.AFISOFOM_SOLREQ_POP_LI3}</li>
              </DashList>
              <SRPopUpBtn
                onClick={this.closeModal}
              >{Strings.AFISOFOM_SOLREQ_POP_BTN}</SRPopUpBtn>
            </SRPopupHolder>
            <BGDiv onClick={this.closeModal}/>
          </div>
        )}


        <SectionTitle> {Strings.AFISOFOM_SOLREQ_MAIN_TITLE} </SectionTitle>
        <SectionSubtitle>{Strings.AFISOFOM_SOLREQ_WELCOME_1 + username + Strings.AFISOFOM_SOLREQ_WELCOME_2}</SectionSubtitle>
        <DefaultParagraph> {Strings.AFISOFOM_SOLREQ_MAIN_TXT_1} <PLink href="/afisofom/confirmar-solicitud">{Strings.AFISOFOM_SOLREQ_MAIN_LINK_1}</PLink>{Strings.AFISOFOM_SOLREQ_MAIN_TXT_2}<PLink href="clientes.afisofom.com">{Strings.AFISOFOM_SOLREQ_MAIN_LINK_2}</PLink>{Strings.AFISOFOM_SOLREQ_MAIN_TXT_3}</DefaultParagraph>
        <SRButton href="/afisofom/confirmar-solicitud"> {Strings.AFISOFOM_SOLREQ_SUB_BTN} </SRButton>
        <BottomLink onClick={this.startDownload}>{Strings.AFISOFOM_SOLREQ_BOTTOM_LINK}</BottomLink>
      </SolicitudContainer>
    )
  }
}

export default SolicitudRequerida;
