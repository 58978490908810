import React from "react";
import styled from "styled-components";
import Colors from "../../assets/colors";
import Fonts from "../../assets/fonts";
import PropTypes from "prop-types";

const MenuItemWrapper = styled.div`
    min-height: 40px;
    height: auto;
    transition: background-color 0.15s ease;
    background-color: ${props => props.selected ? Colors.DARK_GRAY : Colors.LIGHT_GRAY};
    padding: 30px;
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    
    .label {
        font-family: ${Fonts.MEDIUM};
        font-size: 1.25em;
        margin-left: 10px;
        color: ${props => props.selected ? Colors.WHITE : Colors.DARK_GRAY};
    }
    
    svg {
        width: 32px;
        fill: ${props => props.selected ? Colors.WHITE : Colors.DARK_GRAY};
    }
    
    :hover {
        background-color: ${Colors.DARK_GRAY};
        
        .label {
            color: ${Colors.WHITE};
        }
        
        svg {
            fill: ${Colors.WHITE};
        }
    }
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        min-height: 40px;
        padding: 10px 30px;
    
        .label {
            font-size: 1em;
        }
        
        svg {
            width: 25px;
        }
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        min-height: 70px;
        padding: 15px 30px;
    
        .label {
            font-size: 1em;
        }
    }
`;

const AdminDashboardMenuItem = ({ icon, label, selected }) => (
    <MenuItemWrapper selected={selected}>
        {icon}
        <span className="label">
            {label}
        </span>
    </MenuItemWrapper>
);

AdminDashboardMenuItem.propTypes = {
    icon: PropTypes.element.isRequired,
    label: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired
};

export default AdminDashboardMenuItem;
