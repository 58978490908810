import axios from 'axios'
const AFISOFOM = "afisofom";
const AFISERVICIOS = "afiservicios";
const MLP = "mlp";

export const contentStructure = async (site) => {
    let structureURL, devSiteURL, siteURL, title = "";
    switch(site) {
        case AFISOFOM:
            structureURL = "https://afi-configuration-bucket.s3.us-east-2.amazonaws.com/afisofom-content.json";
            title = "Afisofom";
            devSiteURL = "https://dev.afisofom.com";
            siteURL = "https://afisofom.com";
            break;
        case AFISERVICIOS:
            structureURL = "https://afi-configuration-bucket.s3.us-east-2.amazonaws.com/afiservicios-content.json"
            title = "Afiservicios";
            devSiteURL = "https://dev.afiservicios.net";
            siteURL = "https://afiservicios.net";
            break;
        case MLP:
            structureURL = "https://afi-configuration-bucket.s3.us-east-2.amazonaws.com/mlp-content.json"
            title = "MLP";
            devSiteURL = "https://dev.multilayerproperties.com";
            siteURL = "https://multilayerproperties.com";
            break;
        default:
            break;
    }
    const data =  await getStructureFile(structureURL)
    return {...data, title, devSiteURL, siteURL}

}

const getStructureFile = async (url) => {
    return await axios.get(`${url}?timestamp=${new Date().getTime()}`)
}
