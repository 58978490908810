import React from "react";
import LayoutClients from "../../components/layout-clients";
import AfiserviciosRegisterForm from "../../components/afiservicios/register-form";
import {useChangeTitleFavicon} from "../../hooks/useChangeTitle";
import Strings from "../../assets/strings";

const AfiserviciosRegister = ({ history }) => {
    useChangeTitleFavicon(Strings.TITLE_REGISTER, "afiservicios");
    return (
        <LayoutClients site="servicios"
                       history={history}
                       showPrincipalButton={false}>
            <div className="container" style={{ marginTop: '130px' }}>
                <AfiserviciosRegisterForm onRegister={
                    () => history.push('/afiservicios/')
                } />
            </div>
        </LayoutClients>
    );
}

export default AfiserviciosRegister;
