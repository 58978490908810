import {DefaultText, ErrorMsg, PageTitle} from "../shared-components/text-styles";
import Strings from "../../assets/strings";
import CustomModal from "../custom-modal";
import React, {useState} from "react";
import PropTypes from 'prop-types';
import AfisofomClient, {AfisofomCredit} from "../../data/models/afisofom-client";
import Colors from "../../assets/colors";
import CustomInput from "../input";
import CustomButton from "../button";
import Fonts from "../../assets/fonts";
import styled from "styled-components";
import {updateAfisofomCredit} from "../../data/services/afisofom/client";

const ButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    margin-top: 50px;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }
`;

const ErrorWrapper = styled.div`
    margin-top: 30px;
    text-align: center;
`;

const DeclineAfisofomRequest = ({ client, isOpen, onClose, onDeclined }) => {
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState('');
    const [decline, setDecline] = useState('');
    const [requestError, setRequestError] = useState(null);
    const [errors, setErrors] = useState({
        reason: true,
        decline: true
    });

    const updateState = (prop, value, valid) => {
        const auxErrors = errors;
        auxErrors[prop] = !valid;
        setErrors(auxErrors);

        if (prop === 'reason') {
            setReason(value);
        }

        if (prop === 'decline') {
            setDecline(value);
        }
    };

    const verifyData = () => {
        return !errors['reason']
            && !errors['decline'];
    };

    const onDecline = () => {
        setSubmitted(true);
        setLoading(true);

        if (verifyData()) {
            client.afisofom_credit.stage = AfisofomCredit.STAGE_DECLINED;
            client.afisofom_credit.decline_reason = reason;

            updateAfisofomCredit(
                client.id,
                client.email,
                client.afisofom_credit
            ).then(() => {
                setLoading(false);
                onDeclined();
            }).catch(error => {
                setLoading(false);
                setRequestError(error);
            })
        } else {
            setLoading(false);
        }
    };

    return (
        <CustomModal onClose={() => onClose()}
                     isOpen={isOpen}>
            <PageTitle>
                {Strings.DECLINE_REQUEST} {client.name}
            </PageTitle>
            <br/>
            <br/>
            <DefaultText>
                {Strings.DECLINE_REQUEST_MSG_1}&nbsp;
                <strong>{client.full_name}</strong> {Strings.DECLINE_REQUEST_MSG_2}&nbsp;
                <strong>{Strings.DECLINE_2}</strong> {Strings.DECLINE_REQUEST_MSG_3}
            </DefaultText>
            <form noValidate autoComplete="off">
                <CustomInput label={Strings.DECLINE_REQUEST_REASON + ' *'}
                             name="reason"
                             id="reason"
                             value={reason}
                             color={Colors.BLACK}
                             required={true}
                             rowsMax={5}
                             submitted={submitted}
                             invalid={errors['reason']}
                             onChange={(val, v) => updateState('reason', val, v)}
                             type="textarea" />
                <CustomInput placeholder={Strings.DECLINE_2}
                             onlyPlaceholder={true}
                             name="decline"
                             id="decline"
                             value={decline}
                             sameAs={Strings.DECLINE_2}
                             color={Colors.BLACK}
                             required={true}
                             submitted={submitted}
                             invalid={errors['decline']}
                             onChange={(val, v) => updateState('decline', val, v)}
                             type="text" />
                <ButtonsWrapper>
                    <CustomButton label={Strings.CANCEL}
                                  onClick={() => onClose()}
                                  align="center"
                                  color={Colors.DARK_GRAY}
                                  background={Colors.WHITE}
                                  font={Fonts.BOLD}
                                  fullWidth={false} />
                    <CustomButton label={Strings.DECLINE}
                                  onClick={() => onDecline()}
                                  align="center"
                                  isLoading={loading}
                                  color={Colors.WHITE}
                                  background={Colors.PRIMARY_AFI_SOFOM}
                                  font={Fonts.BOLD}
                                  fullWidth={false} />
                </ButtonsWrapper>
            </form>
            {
                requestError &&
                <ErrorWrapper>
                    <ErrorMsg>
                        {requestError}
                    </ErrorMsg>
                </ErrorWrapper>
            }
        </CustomModal>
    );
};

DeclineAfisofomRequest.propTypes = {
    client: PropTypes.instanceOf(AfisofomClient).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDeclined: PropTypes.func.isRequired,
};

export default DeclineAfisofomRequest;
