import {getAuthenticatedUser, getClientData, removeLocalData} from "../../data/services/auth";

/**
 * Check if user is in afisofomClients group
 * @author @leonard_lib
 * @date 05/08/2020
 * @param to
 * @param from
 * @param next
 */
export const isAfisofomClient = (to, from, next) => {
    const path = to.meta.redirect;
    const check = to.meta.check_for_is_afisofom;

    if (check) {
        const user = getAuthenticatedUser();

        if (user) {
            const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
            if (groups.includes("afisofomClients")) {
                next();
            } else {
            }
        } else {
            removeLocalData();
            next.redirect(path);
        }
    } else {
        next();
    }
};


export const isAfisofomClientAllowNoSession = (to, from, next) => {
    const path = to.meta.redirect_allow_nosess;
    const check = to.meta.check_for_is_afisofom_allow_nosess;

    if (check) {
        const user = getAuthenticatedUser();

        if (user) {
            const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
            if (groups.includes("afisofomClients")) {
                next.redirect(path);
            } else {
                next();
            }
        } else {
            next();
        }
    } else {
        next();
    }
}

export const isAfisofomClientNotCompletedForm = (to, from, next) => {
    const path = to.meta.redirect_no_completed_form;
    const check = to.meta.check_for_no_completed_form;

    if (check) {
        const user = getClientData();
        if (user.afisofom_credit && user.afisofom_credit.stage === "En captura") {
            next.redirect(path);
        }
    } else {
        next()
    }
}

export const isAfisofomClientCompletedForm = (to, from, next) => {
    const path_not_completed = to.meta.redirect_no_completed_form;
    const path_completed = to.meta.redirect_completed_form;
    const check = to.meta.check_for_completed_form;
    if (check) {
        const user = getClientData();
        if (path_not_completed && user.afisofom_credit && user.afisofom_credit.stage === "En captura") {
            next.redirect(path_not_completed);
        } else if (path_completed && user.afisofom_credit && user.afisofom_credit.stage !== "En captura") {
            next.redirect(path_completed)
        } else {
            next()
        }
    } else {
        next()
    }
}

