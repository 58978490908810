import React from "react";
import PropTypes from "prop-types";
import Dialog from '@material-ui/core/Dialog';

const CustomModal = ({ isOpen, onClose, children }) => {
    return (
        <Dialog open={isOpen}
                onBackdropClick={() => onClose()}
                onClose={() => onClose()} >
            <div className="container">
                {children}
            </div>
        </Dialog>
    );
};

CustomModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default CustomModal;
