import Client from "./client";
import Strings from "../../assets/strings";
import moment from "moment";

export class AfiserviciosForm {
    static STAGE_PENDING = "Asesoría requerida";
    static STAGE_IN_PROCESS = "En seguimiento";
    static STAGE_DONE = "Terminado";

    static TYPE_1 = "Asesoría financiera";
    static TYPE_2 = "Estudios Socio-económicos";
    static TYPE_3 = "Estructuración de financiamientos";

    stage;
    comments;
    type;
    advisor_id;
    advisor;
    chat_id;
    request_date;
    is_requested;

    constructor(json = {}) {
        this.stage = json.stage ? json.stage : AfiserviciosForm.STAGE_PENDING;
        this.type = json.type ? json.type : AfiserviciosForm.TYPE_1;
        this.comments = json.comments ? json.comments : null;
        this.advisor_id = json.advisor_id ? json.advisor_id : null;
        this.chat_id = json.chat_id ? json.chat_id : null;
        this.advisor = json.advisor ? json.advisor : Strings.NO_ADVISOR;
        this.is_requested = !!json.is_requested;
        this.request_date = json.request_date ? json.request_date : moment().format('DD/MM/YYYY');
    }
}

/**
 * Afiservicios client model
 * @author @leonard_lib
 * @date 05/08/2020
 */
class AfiserviciosClient extends Client {
    afiservicios_form;

    constructor(json = {}) {
        super(json);
        this.afiservicios_form = json.afiservicios_form ? new AfiserviciosForm(json.afiservicios_form) : new AfiserviciosForm();
    }

    static fromMultiple(array) {
        let items = [];

        if (array && array.length > 0) {
            array.forEach(item => {
                items.push(new AfiserviciosClient(item));
            });
        }

        return items;
    }
}

export default AfiserviciosClient;
