import React from "react";
import styled from "styled-components";
import Colors from "../assets/colors";
import Strings from "../assets/strings";
import {ReactComponent as AfisofomLogo} from "../assets/svg/logo-afisofom.svg"
import {ReactComponent as AfiserviciosLogo} from "../assets/svg/logo-afiservicios.svg"
import {ReactComponent as MLPLogo} from "../assets/svg/logo-mlp.svg"
import {Link} from "react-router-dom";

const Wrapper = styled.div`
  display: inline;
`

const Frame = styled.div`
  background-color: ${Colors.LIGHT_GRAY};
  border: 1px solid ${Colors.LIGHT_GRAY};
  width: 300px;
  height: 300px;
  box-sizing: border-box;
  transition: 0.5s background-color;
  
  &:hover {
    background-color: ${Colors.WHITE};
  }
  
  & > * {
    width: 300px;
    height: 300px;
  }
`

const SiteFrame = ({site}) => {
    let Logo = null,
        route = null;
    switch (site) {
        case Strings.SITE_AFISERVICIOS:
            Logo = (<AfiserviciosLogo/>);
            route = "/admin/sitios/afiservicios";
            break;
        case Strings.SITE_AFISOFOM:
            Logo = (<AfisofomLogo/>);
            route = "/admin/sitios/afisofom";
            break;
        case Strings.SITE_MLP:
            Logo = (<MLPLogo/>);
            route = "/admin/sitios/mlp";
            break;
        default:
            break;
    }

    return (
        <Wrapper>
            <Link to={route}>
                <Frame>
                    {Logo}
                </Frame>
            </Link>
        </Wrapper>
    )
}

export default SiteFrame;

