import {Storage} from 'aws-amplify';

/**
 * Upload file
 * @author @leonard_lib
 * @date 29/07/2020
 * @param file
 * @param filename
 * @param level
 * @returns {Promise<unknown>}
 */
export const uploadFile = async (file, filename, level = 'public') => {
    return Storage.put(filename, file, {
        level: level,
        contentType: file.type,
        region: 'us-east-1',
        progressCallback(progress) {
            // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        }
    });
};

/**
 * Get file URL
 * @author @leonard_lib
 * @date 29/07/2020
 * @param key
 * @param config
 * @returns {Promise<unknown>}
 */
export const getUrl = async (key, config =  { expires: 60 }) => {
    return Storage.get(key, config);
};
