import React, {Component} from "react";
import styled from "styled-components";
import Colors from "../../assets/colors";
import Fonts from "../../assets/fonts";
import Strings from "../../assets/strings";
import CustomInput from "../input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {MdVisibility, MdVisibilityOff} from "react-icons/md";
import CustomCheckbox from "../checkbox";
import CustomButton from "../button";
import {clientExists, isValidPassword, register, setClientData} from "../../data/services/auth";
import PropTypes from 'prop-types';
import AfiserviciosClient from "../../data/models/afiservicios-client";
import PasswordHint from "../password-hint";
import {assign, getDataByZipcode} from "../../data/services/utils";
import CustomSelect from "../select";
import {registerAfiservicios} from "../../data/services/afiservicios/client";
import {sendMailAdminsUserReg} from "../../data/services/admins/clients";
import {Link} from 'react-router-dom';
import {ErrorMsg} from "../shared-components/text-styles";
import PMoralButtonSwitch from "../button-switch";
import {withRouter} from "react-router";

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 60%;
  min-height: 500px;
  height: auto;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: ${Colors.WHITE};
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    box-shadow: none;
    width: 100%;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 100%;
  }
`;

const FormContainer = styled.div`
  padding: 30px 60px;
  margin: auto 0;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    padding: 0;
    margin-top: 50px;
    margin-bottom: 50px;
  }
`;

const FormTitle = styled.label`
  font-family: ${Fonts.BOLD};
  font-size: 1.5em;
  color: ${Colors.PRIMARY_AFI_SERVICIOS};
`;

const FormCheckbox = styled.span`
  font-family: ${Fonts.BOLD};
  font-size: 1em;
  color: ${Colors.BLACK};
`;

const ForgotPasswordContainer = styled.div`
  text-align: center;
`;

const FormLink = styled(Link)`
  font-family: ${Fonts.BOLD};
  font-size: 1em;
  color: ${Colors.PRIMARY_AFI_SERVICIOS};
  text-decoration: underline;
  cursor: pointer;
`;

const FormInfo = styled.span`
  font-family: ${Fonts.MEDIUM};
  font-size: 1em;
  color: ${Colors.BLACK};
`;

const FormSubtitle = styled.p`
  font-family: ${Fonts.BOLD};
  font-size: 1em;
  color: ${Colors.BLACK};
`;

const PartialContainer = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1.5fr;
  grid-gap: 1em;
`;

class AfiserviciosRegisterForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            client: new AfiserviciosClient(),
            password: '',
            password_confirm: '',
            submitted: false,
            errors: {
                'client.name': true,
                'client.lastname': true,
                'client.mother_lastname': true,
                'client.phone': true,
                'client.email': true,
                'client.afiservicios_form.type': false,
                password: true,
                password_confirm: true,
                'client.address.street': true,
                'client.address.colony': true,
                'client.address.city': true,
                'client.address.state': true,
                'client.address.zipcode': true,
                'client.moral_person.business_name': true,
                agree: true,
            },
            isLoading: false,
            show_password: false,
            show_password_confirm: false,
            error: null,
            already_registered: false,
            password_invalid: false,
            agree: false,
            colonies: [],
            setted_by_colony: false
        };

        this.handleIsMoralBool = this.handleIsMoralBool.bind(this);
    }

    componentDidMount() {
        let errors = this.state.errors;
        const local_client = new AfiserviciosClient();

        this.setState({
            client: local_client
        }, () => {
            this.onChangeZipcode();
        });

        const state = this.state;
        const queryString = require('query-string');
        const parsed = queryString.parse(this.props.location.search);

        const name = parsed.name;
        const business = parsed.business;
        let phone = parsed.phone;
        const email = parsed.email;
        const service = parsed.service;

        if (name) {
            let names = name.split(' ');
            if (names.length === 1) {
                assign(state, 'client.name', name);
                errors['client.name'] = false;
                this.setState(state);
            } else if (names.length === 2) {
                assign(state, 'client.name', names[0]);
                errors['client.name'] = false;

                assign(state, 'client.lastname', names[1]);
                errors['client.lastname'] = false;
                this.setState(state);
            } else if (names.length === 3) {
                assign(state, 'client.name', names[0]);
                errors['client.name'] = false;

                assign(state, 'client.lastname', names[1]);
                errors['client.lastname'] = false;

                assign(state, 'client.mother_lastname', names[2]);
                errors['client.mother_lastname'] = false;
                this.setState(state);
            } else if (names.length >= 4) {
                const mother_lastname = names.pop();
                const lastname = names.pop();
                const firstNames = names.join(' ');

                assign(state, 'client.name', firstNames);
                errors['error.client.name'] = false;
                assign(state, 'client.lastname', lastname);
                errors['error.client.lastname'] = false;
                assign(state, 'client.mother_lastname', mother_lastname);
                errors['error.client.mother_lastname'] = false;
                this.setState(state);
            }
        }

        if (business) {
            assign(state, 'client.is_moral_person', true);
            assign(state, 'client.moral_person.business_name', business);

            errors['client.is_moral_person'] = false;
            errors['client.moral_person.business_name'] = false;
            this.setState(state);
        }

        if (phone) {
            phone = phone.replaceAll('-', '');
            assign(state, 'client.phone', phone);
            errors['client.phone'] = false;
            this.setState(state);
        }

        if (email) {
            assign(state, 'client.email', email);
            errors['client.email'] = false;
            this.setState(state);
        }

        if (service) {
            let services = service.split(',');
            assign(state, 'client.afiservicios_form.type', services[0]);
            errors['client.afiservicios_form.type'] = false;
            this.setState(state);
        }

        this.setState({
            errors: errors,
        });
    }

    render() {
        const field_name_label = this.state.client.is_moral_person ? Strings.NAME_2 : Strings.NAME;
        const field_lastname_label = this.state.client.is_moral_person ? Strings.LASTNAME_2 : Strings.LASTNAME;
        const field_mother_lastname_label = this.state.client.is_moral_person ? Strings.MOTHER_LASTNAME_2 : Strings.MOTHER_LASTNAME;

        return (
            <FormWrapper>
                <Container>
                    <FormContainer>
                        <FormTitle>
                            {Strings.REGISTER}
                        </FormTitle>
                        <br/>
                        <FormSubtitle>
                            {Strings.AFISOFOM_REGISTER_SUBTITLE}
                        </FormSubtitle>
                        <form noValidate autoComplete="off">
                            <PMoralButtonSwitch
                                onChange={this.handleIsMoralBool}
                                personaMoral={this.state.client.is_moral_person}
                                bgColor={Colors.PRIMARY_AFI_SERVICIOS}
                            />
                            {
                                this.state.client.is_moral_person &&
                                <CustomInput label={Strings.BUSINESS_NAME + ' *'}
                                             name="business_name"
                                             id="business_name"
                                             value={this.state.client.moral_person.business_name}
                                             color={Colors.BLACK}
                                             required={true}
                                             submitted={this.state.submitted}
                                             invalid={this.state.errors['client.moral_person.business_name']}
                                             onChange={(val, v) => this.updateState('client.moral_person.business_name', val, v)}
                                             type="text"/>
                            }
                            <CustomInput label={field_name_label + ' *'}
                                         name="name"
                                         id="name"
                                         value={this.state.client.name}
                                         color={Colors.BLACK}
                                         required={true}
                                         submitted={this.state.submitted}
                                         invalid={this.state.errors['client.name']}
                                         onChange={(val, v) => this.updateState('client.name', val, v)}
                                         type="text"/>
                            <CustomInput label={field_lastname_label + ' *'}
                                         name="lastname"
                                         id="lastname"
                                         value={this.state.client.lastname}
                                         color={Colors.BLACK}
                                         required={true}
                                         submitted={this.state.submitted}
                                         invalid={this.state.errors['client.lastname']}
                                         onChange={(val, v) => this.updateState('client.lastname', val, v)}
                                         type="text"/>
                            <CustomInput label={field_mother_lastname_label + ' *'}
                                         name="mother_lastname"
                                         id="mother_lastname"
                                         value={this.state.client.mother_lastname}
                                         color={Colors.BLACK}
                                         required={true}
                                         submitted={this.state.submitted}
                                         invalid={this.state.errors['client.mother_lastname']}
                                         onChange={(val, v) => this.updateState('client.mother_lastname', val, v)}
                                         type="text"/>
                            <CustomInput label={Strings.PHONE + ' *'}
                                         name="phone"
                                         id="phone"
                                         color={Colors.BLACK}
                                         value={this.state.client.phone}
                                         required={true}
                                         submitted={this.state.submitted}
                                         invalid={this.state.errors['client.phone']}
                                         onChange={(val, v) => this.updateState('client.phone', val, v)}
                                         minLength={10}
                                         maxLength={10}
                                         type="number"/>
                            <CustomInput label={Strings.EMAIL + ' *'}
                                         name="email"
                                         id="email"
                                         color={Colors.BLACK}
                                         required={true}
                                         value={this.state.client.email}
                                         submitted={this.state.submitted}
                                         invalid={this.state.errors['client.email']}
                                         onChange={(val, v) => this.updateState('client.email', val, v)}
                                         type="email"/>
                            <CustomSelect label={Strings.AFISERVICIOS_TYPE + ' *'}
                                          name="type"
                                          id="type"
                                          color={Colors.BLACK}
                                          value={this.state.client.afiservicios_form.type}
                                          required={true}
                                          submitted={this.state.submitted}
                                          invalid={this.state.errors['client.afiservicios_form.type']}
                                          onChange={(val, v) => this.updateState('client.afiservicios_form.type', val, v)}
                                          options={["Asesoría financiera", "Estudios Socio-económicos", "Estructuración de financiamientos"]}/>
                            <CustomInput label={Strings.PASSWORD + ' *'}
                                         name="password"
                                         id="password"
                                         required={true}
                                         color={Colors.BLACK}
                                         value={this.state.password}
                                         submitted={this.state.submitted}
                                         invalid={this.state.errors['password']}
                                         onChange={(val, v) => this.updateState('password', val, v)}
                                         inputProps={{
                                             endAdornment: (
                                                 <InputAdornment position="end">
                                                     <IconButton tabIndex="-1" onClick={() => this.setState({
                                                         show_password: !this.state.show_password
                                                     })}>
                                                         {
                                                             this.state.show_password
                                                                 ? <MdVisibility/>
                                                                 : <MdVisibilityOff/>
                                                         }
                                                     </IconButton>
                                                 </InputAdornment>
                                             )
                                         }}
                                         type={this.state.show_password ? 'text' : 'password'}/>
                            <CustomInput label={Strings.PASSWORD_CONFIRM + ' *'}
                                         name="password_confirm"
                                         id="password_confirm"
                                         required={true}
                                         value={this.state.password_confirm}
                                         color={Colors.BLACK}
                                         submitted={this.state.submitted}
                                         invalid={this.state.errors['password_confirm']}
                                         onChange={(val, v) => this.updateState('password_confirm', val, v)}
                                         inputProps={{
                                             endAdornment: (
                                                 <InputAdornment position="end">
                                                     <IconButton tabIndex="-1" onClick={() => this.setState({
                                                         show_password_confirm: !this.state.show_password_confirm
                                                     })}>
                                                         {
                                                             this.state.show_password_confirm
                                                                 ? <MdVisibility/>
                                                                 : <MdVisibilityOff/>
                                                         }
                                                     </IconButton>
                                                 </InputAdornment>
                                             )
                                         }}
                                         type={this.state.show_password_confirm ? 'text' : 'password'}/>
                            <br/>
                            <br/>
                            {
                                this.state.password_invalid &&
                                <div>
                                    <PasswordHint/>
                                    <br/>
                                    <br/>
                                </div>
                            }
                            <FormSubtitle>
                                {Strings.ADDRESS}
                            </FormSubtitle>
                            <CustomInput label={Strings.STREET_LINE + ' *'}
                                         name="street"
                                         id="street"
                                         value={this.state.client.address.street}
                                         color={Colors.BLACK}
                                         required={true}
                                         submitted={this.state.submitted}
                                         invalid={this.state.errors['client.address.street']}
                                         onChange={(val, v) => this.updateState('client.address.street', val, v)}
                                         type="text"/>
                            <PartialContainer>
                                <CustomInput label={Strings.ZIPCODE + ' *'}
                                             name="zipcode"
                                             id="zipcode"
                                             color={Colors.BLACK}
                                             required={true}
                                             value={this.state.client.address.zipcode}
                                             submitted={this.state.submitted}
                                             invalid={this.state.errors['client.address.zipcode']}
                                             onChange={(val, v) => this.updateState('client.address.zipcode', val, v)}
                                             minLength={5}
                                             maxLength={5}
                                             type="number"/>
                                <CustomSelect label={Strings.COLONY + ' *'}
                                              name="colony"
                                              id="colony"
                                              color={Colors.BLACK}
                                              value={this.state.client.address.colony}
                                              required={true}
                                              disabled={this.state.colonies.length < 1}
                                              submitted={this.state.submitted}
                                              invalid={this.state.errors['client.address.colony']}
                                              onChange={(val, v) => this.updateState('client.address.colony', val, v)}
                                              options={this.state.colonies}/>
                            </PartialContainer>
                            <CustomInput label={Strings.CITY + ' *'}
                                         name="city"
                                         id="city"
                                         color={Colors.BLACK}
                                         value={this.state.client.address.city}
                                         required={true}
                                         disabled={this.state.setted_by_colony}
                                         submitted={this.state.submitted}
                                         invalid={this.state.errors['client.address.city']}
                                         onChange={(val, v) => this.updateState('client.address.city', val, v)}
                                         type="text"/>
                            <CustomInput label={Strings.STATE + ' *'}
                                         name="state"
                                         id="state"
                                         disabled={this.state.setted_by_colony}
                                         value={this.state.client.address.state}
                                         color={Colors.BLACK}
                                         required={true}
                                         submitted={this.state.submitted}
                                         invalid={this.state.errors['client.address.state']}
                                         onChange={(val, v) => this.updateState('client.address.state', val, v)}
                                         type="text"/>
                            <br/>
                            <br/>
                            <CustomCheckbox name="agree"
                                            required={true}
                                            submitted={this.state.submitted}
                                            background={Colors.PRIMARY_AFI_SERVICIOS}
                                            errorMsg={Strings.FIELD_REQUIRED}
                                            onChange={(v) => this.updateState("agree", v, v)}
                                            label={
                                                <FormCheckbox>
                                                    {Strings.POLICY_AGREE} <FormLink to="/">{Strings.POLICY}</FormLink>
                                                </FormCheckbox>
                                            }
                                            id="agree"/>
                            <br/>
                            <br/>
                            <ForgotPasswordContainer>
                                <CustomButton label={Strings.REGISTER_ME}
                                              onClick={() => this.handleSignup()}
                                              align="center"
                                              color={Colors.WHITE}
                                              isLoading={this.state.isLoading}
                                              fullWidth={false}
                                              background={Colors.PRIMARY_AFI_SERVICIOS}
                                              font={Fonts.BOLD}/>
                                {
                                    this.state.error &&
                                    <ErrorMsg>
                                        <br/>
                                        {this.state.error}
                                    </ErrorMsg>
                                }
                                {
                                    this.state.already_registered &&
                                    <FormInfo>
                                        <br/>
                                        {Strings.ALREADY_REGISTER_INFO}
                                        <FormLink to="/afiservicios/iniciar-sesion">
                                            {Strings.ALREADY_REGISTER_INFO_2}
                                        </FormLink>
                                        {Strings.ALREADY_REGISTER_INFO_3}
                                    </FormInfo>
                                }
                            </ForgotPasswordContainer>
                        </form>
                    </FormContainer>
                </Container>
            </FormWrapper>
        );
    }

    /**
     * Update state vars
     * @author @leonard_lib
     * @date 29/07/2020
     * @param prop
     * @param value
     * @param valid
     */
    updateState(prop, value, valid) {
        const state = this.state;
        const errors = state.errors;

        assign(state, prop, value);
        errors[prop] = !valid;
        state.errors = errors;

        if (prop === 'client.address.zipcode') {
            assign(state, 'client.address.colony', '');
            this.onChangeZipcode();
        }

        localStorage.setItem('aux_password', state.password);
        this.setState(state, () => {
            this.checkPassword();
        });
    };

    onChangeZipcode() {
        getDataByZipcode(this.state.client.address.zipcode).then(res => {
            if (res != null) {
                const client = this.state.client;
                const errors = this.state.errors;
                client.address.colony = res.colonias[0];
                client.address.city = res.municipio;
                client.address.state = res.estado;
                errors['client.address.city'] = false;
                errors['client.address.state'] = false;
                errors['client.address.colony'] = false;

                this.setState({
                    colonies: res.colonias,
                    client: client,
                    errors: errors,
                    setted_by_colony: true
                });
            } else {
                this.setState({
                    colonies: [],
                    setted_by_colony: false
                });
            }
        }).catch(err => {
            this.setState({
                error: err
            });
        });
    }

    handleIsMoralBool(personType) {
        let value = false;
        if (personType === "moral") {
            value = true;
        }
        this.updateState('client.is_moral_person', value, true);
        this.updateState('client.moral_person', '', true);
    }

    /**
     * Check if data is completed
     * @author @leonard_lib
     * @date 29/07/2020
     * @returns {boolean|boolean}
     */
    checkCompleted() {
        let valid_moral_person_name = this.state.client.is_moral_person
            ? !this.state.errors['client.moral_person.business_name']
            : true;

        this.checkPassword()

        return !this.state.errors['client.name']
            && !this.state.errors['client.lastname']
            && !this.state.errors['client.mother_lastname']
            && !this.state.errors['client.phone']
            && !this.state.errors['client.email']
            && !this.state.errors['client.address.street']
            && !this.state.errors['client.address.colony']
            && !this.state.errors['client.address.state']
            && !this.state.errors['client.address.city']
            && !this.state.errors['client.address.zipcode']
            && !this.state.errors['client.afiservicios.type']
            && !this.state.errors['password']
            && !this.state.errors['password_confirm']
            && !this.state.errors['agree']
            && this.state.password_match
            && !this.state.password_invalid
            && valid_moral_person_name;
    }

    /**
     * Check if password match
     * @author @leonard_lib
     * @date 29/07/2020
     */
    checkPassword() {
        const invalid_password = !isValidPassword(this.state.password);

        if (this.state.password !== this.state.password_confirm) {
            this.setState({
                password_match: false,
                password_invalid: invalid_password,
                error: Strings.PASSWORD_NOT_MATCH
            });

            let errors = this.state.errors;
            this.setState({
                errors: errors
            });
        } else {
            this.setState({
                password_match: true,
                password_invalid: invalid_password,
                error: null
            });

            let errors = this.state.errors;
            this.setState({
                errors: errors
            });
        }
    };

    /**
     * Handle signup button click
     * @author @leonard_lib
     * @date 29/07/2020
     */
    handleSignup() {
        this.setState({
            submitted: true,
            error: null,
            already_registered: null,
        }, () => {
            if (this.checkCompleted()) {
                this.setState({
                    isLoading: true
                }, () => {
                    this.isRegistered();
                })
            }
        });
    };

    /**
     * Check if user exists
     * @author @leonard_lib
     * @date 05/08/2020
     */
    isRegistered() {
        clientExists(this.state.client.email).then(exists => {
            if (!exists) {
                this.registerOnCognito();
            } else {
                this.setState({
                    isLoading: false,
                    already_registered: true
                });
            }
        }).catch(error => {
            this.setState({
                isLoading: false,
                error: error
            });
        });
    }

    /**
     * Register on cognito first
     * @author @leonard_lib
     * @date 05/08/2020
     */
    registerOnCognito() {
        sendMailAdminsUserReg(
            false,
            this.state.client.name,
            this.state.client.email);
        register(
            this.state.client.email,
            this.state.password
        ).then(() => {
            this.makeCallToRegister();
        }).catch(error => {
            this.setState({
                isLoading: false,
                error: error
            });
        });
    }

    /**
     * Make call to register
     * @author @leonard_lib
     * @date 29/07/2020
     */
    makeCallToRegister() {
        registerAfiservicios(
            this.state.client
        ).then(() => {
            setClientData(this.state.client);
            this.props.onRegister();
        }).catch(error => {
            this.setState({
                isLoading: false,
                error: error
            });
        });
    }
}

AfiserviciosRegisterForm.propTypes = {
    onRegister: PropTypes.func.isRequired
};

export default withRouter(AfiserviciosRegisterForm);
