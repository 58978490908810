import React from "react";
import LayoutClients from "../../components/layout-clients";
import SofomLoginForm from "../../components/afisofom/login-form";
import {useChangeTitleFavicon} from "../../hooks/useChangeTitle";
import Strings from "../../assets/strings";

const AfisofomLogin = ({ history }) => {
    useChangeTitleFavicon(Strings.TITLE_LOGIN, "afisofom");
    return (
    <LayoutClients history={history} site="sofom">
        <div className="container">
            <SofomLoginForm
                onForgotPassword={() =>
                    history.push('/afisofom/recuperar-contrasena')
                }
                onLogin={() =>
                    history.push('/afisofom/dashboard')
                }
                onUserNotConfirmed={email => history.push('/afisofom/confirmar-registro?email=' + email)}
            />
        </div>
    </LayoutClients>
)};

export default AfisofomLogin;
