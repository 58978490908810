import React from "react";
import LayoutClients from "../../components/layout-clients";
import AfisofomConfirmSignupForm from "../../components/afisofom/confirm-signup-form";
import {useChangeTitleFavicon} from "../../hooks/useChangeTitle";
import Strings from "../../assets/strings";

const AfisofomConfirmSignUp = ({ history }) => {
    useChangeTitleFavicon(Strings.TITLE_CONFIRM_SIGNUP, "afisofom");
    return (
        <LayoutClients site="sofom"
                       history={history}
                       showPrincipalButton={false}>
            <div className="container" style={{ marginTop: '0px' }}>
                <AfisofomConfirmSignupForm onConfirm={
                    () => history.push('/afisofom/solicitud-requerida')
                }
                                           onInvalidUser={() => {
                                               history.push('/afisofom/iniciar-sesion')
                                           }}
                />
            </div>
        </LayoutClients>
    );
}

export default AfisofomConfirmSignUp;
