import React from "react";
import LayoutAdmins from "../../components/layout-admins";
import AdminDashboardLayout from "../../components/admins/dashboard-layout";
import Sites from "../../components/admins/sites";
import {useChangeTitleFavicon} from "../../hooks/useChangeTitle";
import Strings from "../../assets/strings";

const AdminsSites = ({ history }) => {
    useChangeTitleFavicon(Strings.TITLE_SITES_ADMIN);
    return (
        <LayoutAdmins tabAdminSelected={2} history={history} >
            <AdminDashboardLayout tabAdminSelected={2} selected={2} >
                <Sites history={history} />
            </AdminDashboardLayout>
        </LayoutAdmins>
    );
}

export default AdminsSites;
