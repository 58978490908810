import React, { useEffect, useState, useReducer } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";

import Slider from "@material-ui/core/Slider";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";

import Strings from "../../assets/strings";
import Fonts from "../../assets/fonts";
import Colors from "../../assets/colors";
import { SectionTitle } from "../shared-components/text-styles";
import { DefaultButton, LightButton } from "../shared-components/button-styles";
import PMoralButtonSwitch from "../button-switch";
import { PopupHolder, BGDiv } from "../shared-components/container-styles";
import Table from "./form-one-table";

import { getClientData } from "../../data/services/auth";

import "../../assets/styles/global.css";
import creditTypeCatalog from "../../utils/credit-type-catalog";

// CONTAINER GENERAL /////////////////
const FormContainer = styled.div`
  @media only screen and (min-width: 767px) {
    width: 100%;
    height: 100%;
    margin-top: -20px;
    padding: 1em 3em 2em;
  }
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2em;

  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

// SLIDER /////////////////
const PrettoSlider = withStyles({
  root: {
    color: "#dddddd",
    height: 8,
    gridColumn: "1/ span 2",
    gridRow: "2",
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: `3px solid ${Colors.PRIMARY_AFI_SOFOM}`,
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  track: {
    height: 0,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const SliderGroup = styled.div`
  margin-top: 1em;

  display: grid;
  grid-template-rows: 1fr 2fr 1fr;
  grid-template-columns: 1fr 1fr;
`;
const SliderInput = styled.input`
  grid-row: 1;
  grid-column: 2;
  justify-self: right;
  width: 35%;
  border-radius: 0;
  border: none;
  border-bottom: solid 1px black;
  text-align: right;
  font-family: inherit;
  font-size: inherit;

  @media only screen and (max-width: 540px) {
    width: 55%;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 55%;
  }
`;

const SliderLabel1 = styled.span`
  grid-row: 3;
  grid-column: 1;
`;

const SliderLabel2 = styled(SliderLabel1)`
  grid-column: 2;
  justify-self: right;
`;

// DROPDOWNS /////////////////
const StyledFormControl = styled(FormControl)`
  &.MuiFormControl-root {
    width: 100%;
    margin: 1em 0;
  }

  .MuiFormLabel-root {
    color: ${Colors.BLACK};
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${Colors.PRIMARY_AFI_SOFOM};
  }

  .MuiInput-underline::before {
    border-bottom: 1px solid ${Colors.BLACk} !important;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .MuiInput-underline:hover::before {
    border-bottom: 3px solid ${Colors.PRIMARY_AFI_SOFOM} !important;
  }
  .MuiInput-underline::after {
    border-bottom: 3px solid ${Colors.PRIMARY_AFI_SOFOM};
  }
`;
const StyledLabel = styled(InputLabel)`
  color: black;
`;

const StyledSelect = styled(Select)``;
const ErrorHelperText = styled(FormHelperText)`
  &.showHelperText {
    display: block !important;
  }

  &.MuiFormHelperText-root {
    display: none;
    color: red !important;
    font-family: ${Fonts.BOLD};
  }
`;

// TEXTO CON VÍNCULO /////////////////
const TextContainer = styled.div`
  margin: 1em 0;
`;

const BoldText = styled.span`
  font-size: 1em;
  font-family: ${Fonts.BOLD};
`;

// LADO DERECHO /////////////////
const RightHalf = styled.div`
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const Disclaimer = styled.p`
  font-size: 0.8em;
  color: ${Colors.GRAY};
  max-width: 95%;
  margin: 0.25em 0;
`;

export const TableSection = ({
  loanAmount,
  paymentMonths,
  toggleBox,
  creditType,
  rateType,
  tiie,
}) => (
  <div>
    <TextContainer>
      <BoldText>{Strings.AFISOFOM_FORMONE_TABLETITLE}</BoldText>
    </TextContainer>
    <Table
      loanAmount={loanAmount}
      paymentMonths={paymentMonths}
      creditType={creditType}
      rateType={rateType}
      tiie={tiie}
    />
    <TextContainer>
      {/* <Disclaimer>{Strings.AFISOFOM_FORMONE_DISCLAIMER_2}</Disclaimer> */}
      <Disclaimer style={{ fontWeight: "bold" }}>
        Comisión por disposición del {creditType?.minDisposalFee * 100}% al{" "}
        {creditType?.maxDisposalFee * 100}%
      </Disclaimer>
      {creditType?.minMonitoringFee && (
        <Disclaimer style={{ fontWeight: "bold" }}>
          Comisión por seguimiento del {creditType?.minMonitoringFee * 100}% al{" "}
          {creditType?.maxMonitoringFee * 100}%
        </Disclaimer>
      )}
      <Disclaimer style={{ fontWeight: "bold" }}>
        CAT del {(creditType?.minCat * 100).toFixed(2)}% al{" "}
        {(creditType?.maxCat * 100).toFixed(2)}%
      </Disclaimer>
      {creditType?.liquidWarrant && (
        <Disclaimer style={{ fontWeight: "bold" }}>
          Garantía líquida del {Math.floor(creditType?.liquidWarrant * 100)}%
        </Disclaimer>
      )}
      {rateType === FIXED_RATE && (
        <Disclaimer style={{ fontWeight: "bold" }}>
          Tasa de interés fija del{" "}
          {Math.floor(creditType?.minFixedInterestRate * 100)}% al{" "}
          {Math.floor(creditType?.maxFixedInterestRate * 100)}%
        </Disclaimer>
      )}
      {rateType === VARIABLE_RATE && (
        <Disclaimer style={{ fontWeight: "bold" }}>
          Tasa de interés variable de TIIE +{" "}
          {Math.floor(creditType?.minVariableInterestRate * 100)}
          p.p. a TIIE + {Math.floor(
            creditType?.maxVariableInterestRate * 100
          )}{" "}
          p.p.
        </Disclaimer>
      )}
      <Disclaimer>{Strings.AFISOFOM_FORMONE_DISCLAIMER_1}</Disclaimer>
      {rateType === VARIABLE_RATE && (
        <Disclaimer>
          **TIIE a 28 días Tasa de interés en por ciento anual. Valor al día{" "}
          {tiie.day}: <b>{tiie.value}</b>
        </Disclaimer>
      )}
    </TextContainer>
    <DefaultButton className="hideOnBigScreen" onClick={toggleBox}>
      CERRAR
    </DefaultButton>
  </div>
);

export const FIXED_RATE = 1;
export const VARIABLE_RATE = 2;

const SolicitudFormOneMain = ({ history }) => {
  const [currentMax, setCurrentMax] = useState(2150000);
  const [currentMin, setCurrentMin] = useState(50000);
  const [showTable, toggleShowTable] = useReducer((v) => !v, false);
  const [paymentMonths, setPaymentMonths] = useState(6);
  const [creditType, setCreditType] = useState(creditTypeCatalog[0]);
  const [rateType, setRateType] = useState(FIXED_RATE);
  const [personaMoral, setPersonaMoral] = useState(false);
  const [loanAmount, setLoanAmount] = useState(1050000);
  const [wasBackspace, setWasBackspace] = useState(false);
  const [monthsError, setMonthsError] = useState(false);
  const [cTypeError, setCTypeError] = useState(false);
  const [position, setPosition] = useState(0);
  const [inputRef, setInputRef] = useState();
  const [rateTypeError] = useState(false);
  const [tiie, setTIIE] = useState({ day: "0/0/0", value: 0 });
  const [change, activateChange] = useReducer(() => true, false);

  const formatCurrency = (value) => {
    let formatted = Intl.NumberFormat().format(value);
    formatted = "$" + formatted;
    return formatted;
  };

  const changeMonths = (event) => {
    setPaymentMonths(event.target.value);
    setMonthsError(false);
    activateChange();
  };

  const changeCredito = (event) => {
    setCreditType(
      creditTypeCatalog.find((ct) => ct.name === event.target.value)
    );
    activateChange();
  };

  const toggleBox = () => {
    toggleShowTable();
    killScroll();
  };

  const killScroll = () => {
    if (showTable) {
      document.body.style =
        "overflow-y:hidden; position: relative; margin-right: 15px;";
    } else {
      document.body.style =
        "overflow-y:visible; position: static; margin-right: 0;";
    }
  };

  const handleButtonPersona = (personaType) => {
    setPersonaMoral(personaType === "moral" ? true : false);
    activateChange();
  };

  const handleSliderChange = (event, value) => {
    setLoanAmount(Math.round(value / 10000) * 10000);
    activateChange();
  };

  const handleKeyDownSliderText = (event) => {
    // Se decide no utilizar setState aquí porque no requerimos
    // que el componente haga un render después de este cambio.
    if (event.key === "backspace") {
      // eslint-disable-next-line
      setWasBackspace(true);
      activateChange();
    }
  };

  const handleSliderTextChange = (event) => {
    if (wasBackspace) {
      setPosition(event.target.selectionStart - 1);
      setWasBackspace(false);
    } else {
      setPosition(event.target.selectionStart);
    }

    const text = event.target.value;
    const re = new RegExp(/[$,]/, "g");
    const textVal = text.replaceAll(re, "");

    if (!isNaN(textVal)) {
      let textInt = parseInt(textVal, 10);
      if (textInt > currentMax) {
        textInt = currentMax;
      }
      if (textInt < currentMin) {
        textInt = currentMin;
      }
      setLoanAmount(textInt);
      activateChange();
    }
  };

  const assignRef = (c) => {
    setInputRef(c);
  };

  const changeRateType = (e) => {
    setRateType(e.target.value);
    activateChange();
  };

  const generateTerms = () => {
    const items = [];
    for (
      let i = creditType?.minTerm;
      i <= creditType?.maxTerm;
      i += creditType?.termRange
    ) {
      items.push(
        <MenuItem key={i} value={i}>
          {i} Meses
        </MenuItem>
      );
    }
    return items;
  };

  const getTIIE = async () => {
    const res = await fetch(
      "https://afi-configuration-bucket.s3.us-east-2.amazonaws.com/indicators.json"
    );
    const values = await res.json();
    const {
      datos: [tiie],
    } = values.find((v) => v.idSerie === "SF43783");
    console.log({ day: tiie.fecha, value: parseFloat(tiie.dato) });
    setTIIE({ day: tiie.fecha, value: parseFloat(tiie.dato) });
  };

  useEffect(() => {
    const index = position;
    if (inputRef) {
      inputRef.setSelectionRange(index, index);
    }
  });

  //TODO: Revisar localStorage.
  useEffect(() => {
    setCreditType(creditTypeCatalog[0]);
  }, []);

  useEffect(() => {
    getTIIE();
  }, []);

  // Efecto llamado cuando se cambia el tipo de crédito
  useEffect(() => {
    const min = personaMoral
      ? creditType?.minPMAmount
      : creditType?.minPFAmount || 0;
    const max = personaMoral
      ? creditType?.maxPMAmount
      : creditType?.maxPFAmount || 0;
    setCurrentMin(min);
    setCurrentMax(max);
    setLoanAmount(loanAmount <= max ? loanAmount : max);
    setCTypeError(false);

    if (!creditType?.minVariableInterestRate) {
      setRateType(FIXED_RATE);
    }
    if (paymentMonths % !creditType?.termRange !== 0) {
      setPaymentMonths(creditType?.minTerm);
    }
  }, [creditType, personaMoral]);

  //Use effect para guardar en localStorage
  useEffect(() => {
    console.log(change);
    if (change) {
      localStorage.creditType = creditType.name;
      localStorage.loanAmount = loanAmount;
      localStorage.paymentMonths = paymentMonths;
      localStorage.personaMoral = personaMoral;
      localStorage.rateType = rateType;
    }
  }, [change, loanAmount, paymentMonths, personaMoral, rateType, creditType]);

  useEffect(() => {
    if (localStorage.creditType) {
      setCreditType(
        creditTypeCatalog.find((ct) => ct.name === localStorage.creditType)
      );
    }
    if (localStorage.loanAmount) {
      setLoanAmount(parseFloat(localStorage.loanAmount));
    }
    if (localStorage.paymentMonths) {
      setPaymentMonths(parseInt(localStorage.paymentMonths));
    }
    if (localStorage.personaMoral) {
      setPersonaMoral(localStorage.personaMoral === "true");
    }
    if (localStorage.rateType) {
      setRateType(parseInt(localStorage.rateType));
    }
  }, []);

  const validateSubmit = () => {
    if (paymentMonths === "" && creditType === "") {
      setMonthsError(true);
      setCTypeError(true);
    } else if (paymentMonths === "") {
      setMonthsError(true);
    } else if (creditType === "") {
      setCTypeError(true);
    } else {
      if (getClientData()) {
        history.push("/afisofom/confirmar-solicitud");
      } else {
        history.push("/afisofom/registro");
      }
    }
  };

  // const monthsError = monthsError;
  // const cTypeError = cTypeError;

  return (
    <FormContainer className="box-shadow">
      {showTable && (
        <div>
          <PopupHolder>
            <TableSection
              loanAmount={loanAmount}
              paymentMonths={paymentMonths}
              toggleBox={toggleBox}
              creditType={creditType}
              rateType={rateType}
              tiie={tiie}
            />
          </PopupHolder>
          <BGDiv onClick={toggleBox} />
        </div>
      )}
      <SectionTitle>{Strings.AFISOFOM_FORMONE_TITLE}</SectionTitle>
      <FormGrid>
        <div>
          <p>{Strings.AFISOFOM_FORMONE_DESCRIPTION}</p>

          <PMoralButtonSwitch
            onChange={handleButtonPersona}
            personaMoral={personaMoral}
          />

          <SliderGroup>
            <span id="slider-cantidad-prestamo">
              {Strings.AFISOFOM_FORMONE_LABEL_SLIDER}
            </span>
            <SliderInput
              onKeyDown={handleKeyDownSliderText}
              onChange={handleSliderTextChange}
              value={formatCurrency(loanAmount)}
              ref={assignRef}
            />
            <PrettoSlider
              value={loanAmount}
              onChange={handleSliderChange}
              aria-labelledby="slider-cantidad-prestamo"
              step={1000}
              min={currentMin}
              max={currentMax}
              valueLabelDisplay="off"
            />
            <SliderLabel1>{formatCurrency(currentMin)}</SliderLabel1>
            <SliderLabel2>{formatCurrency(currentMax)}</SliderLabel2>
          </SliderGroup>
          <StyledFormControl>
            <StyledLabel id="label-plazo-select">Plazo </StyledLabel>
            <StyledSelect
              labelId="label-plazo-select"
              value={paymentMonths}
              onChange={changeMonths}
              error={monthsError}
            >
              {generateTerms()}
            </StyledSelect>
            <ErrorHelperText className={monthsError ? "showHelperText" : ""}>
              Es necesario seleccionar un plazo.
            </ErrorHelperText>
          </StyledFormControl>

          {/* Tipo de crédito */}
          <StyledFormControl>
            <StyledLabel id="label-credito-select">Tipo de crédito</StyledLabel>
            <StyledSelect
              labelId="label-credito-select"
              value={creditType?.name}
              onChange={changeCredito}
              error={cTypeError}
            >
              {creditTypeCatalog.map(({ name }, key) => (
                <MenuItem key={key} value={name}>
                  {name}
                </MenuItem>
              ))}
            </StyledSelect>
            <ErrorHelperText className={cTypeError ? "showHelperText" : ""}>
              {Strings.AFISOFOM_FORMONE_ERROR_HELPER}
            </ErrorHelperText>
          </StyledFormControl>

          {/* Tipo de tasa */}
          <StyledFormControl>
            <StyledLabel id="label-credito-select">Tipo de tasa</StyledLabel>
            <StyledSelect
              labelId="label-tasa-select"
              value={rateType}
              onChange={changeRateType}
              error={rateTypeError}
            >
              <MenuItem value={FIXED_RATE}>Tasa fija</MenuItem>
              {creditType?.minVariableInterestRate && (
                <MenuItem value={VARIABLE_RATE}>Tasa variable</MenuItem>
              )}
            </StyledSelect>
            <ErrorHelperText className={rateTypeError ? "showHelperText" : ""}>
              {Strings.AFISOFOM_FORMONE_ERROR_HELPER}
            </ErrorHelperText>
          </StyledFormControl>
          <TextContainer>
            <BoldText>{Strings.AFISOFOM_FORMONE_INICIO_SESS_PROMPT}</BoldText>
            <Link className="styled-link" to="/afisofom/iniciar-sesion">
              {Strings.AFISOFOM_FORMONE_INICIO_SESS_LINK_TEXT}
            </Link>
          </TextContainer>

          <LightButton
            className="hideOnBigScreen"
            variant="contained"
            onClick={() => toggleBox()}
          >
            {Strings.AFISOFOM_FORMONE_CAL_BTN}
          </LightButton>
          <DefaultButton
            dark="true"
            onClick={validateSubmit}
            variant="contained"
          >
            {Strings.AFISOFOM_FORMONE_SUBMIT_BTN}
          </DefaultButton>
        </div>
        <RightHalf>
          <TableSection
            loanAmount={loanAmount}
            paymentMonths={paymentMonths}
            toggleBox={toggleBox}
            creditType={creditType}
            rateType={rateType}
            tiie={tiie}
          />
        </RightHalf>
      </FormGrid>
    </FormContainer>
  );
};

SolicitudFormOneMain.propTypes = {
  history: PropTypes.any.isRequired,
};

export default SolicitudFormOneMain;
