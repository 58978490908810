/**
 * Get Afiservicios client by ID
 * @author @leonard_lib
 * @date 05/08/2020
 * @type {string}
 */
export const getAfiserviciosClient = `
    query getAfiserviciosClient($id: ID!) {
        listClients(filter: {id: {eq: $id}}) {
            items {
                id
                name
                lastname
                mother_lastname
                phone
                email
                is_moral_person
                moral_person {
                    business_name
                }
                address {
                    street
                    colony
                    city
                    state
                    country
                    zipcode
                }
                afiservicios_form {
                    stage
                    type
                    advisor_id
                    advisor
                    chat_id
                    comments
                    is_requested
                    request_date
                }
            }
        }
    }
`;

export const getAfiserviciosClientByEmail = `
    query getAfiserviciosClient($email: String!) {
        listClients(filter: {email: {eq: $email}}) {
            items {
                id
                name
                lastname
                mother_lastname
                phone
                email
                admin {
                    roles
                }
                is_moral_person
                moral_person {
                    business_name
                }
                address {
                    street
                    colony
                    city
                    state
                    country
                    zipcode
                }
                afiservicios_form {
                    stage
                    type
                    advisor_id
                    advisor
                    chat_id
                    comments
                    is_requested
                    request_date
                }
            }
        }
    }
`;

export const createClient = `
    mutation CreateClient(
        $input: CreateClientInput!
        $condition: ModelClientConditionInput
    ) {
        createClient(input: $input, condition: $condition) {
            id
            name
            lastname
            mother_lastname
            phone
            email
            is_moral_person
            moral_person {
                business_name
            }
            address {
                street
                colony
                city
                state
                country
                zipcode
            }
            afiservicios_form {
			    stage
                type
                advisor_id
                advisor
                chat_id
                comments
                is_requested
                request_date
			}
            createdAt
            updatedAt
        }
    }
`;
