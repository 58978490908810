import React, {Component} from "react";
import styled from "styled-components";
import TextField from '@material-ui/core/TextField';
import PropTypes from "prop-types";
import Strings from "../assets/strings";
import Fonts from "../assets/fonts";
import Colors from "../assets/colors";
// estilo de texto
import {DefaultParagraph} from "./shared-components/text-styles";
// svg
import {ReactComponent as IdentitySVG} from "../assets/svg/identity.svg";
import {ReactComponent as EmptyFileSVG} from "../assets/svg/metro-file-empty.svg";
import {ReactComponent as UploadSVG} from "../assets/svg/feather-upload.svg";

/**
 * Styles for CustomInput
 * @author @leonard_lib
 * @date 02/07/2020
 */
const CustomInputWrapper = styled.div`
    margin-top: 20px;
    
    .MuiFormLabel-root {
        font-family: ${Fonts.MEDIUM} !important;
        font-size: 1em !important;
        color: ${Colors.BLACK} !important;
    }
    
    .MuiButton-root {
        font-family: ${Fonts.BOLD} !important;
        font-size: 1em !important;
    }
    
    .MuiInputBase-input {
        font-family: ${Fonts.MEDIUM} !important;
        font-size: 1em !important;
    }
    
    .MuiFormLabel-root {
        color: ${Colors.BLACK} !important;
    }
    
    .MuiFormLabel-root.Mui-focused {
        color: ${props => props.color} !important;
    }
    
    .MuiInput-underline:before {
        border-bottom: 1px solid ${Colors.GRAY} !important;
    }
    
    .MuiInput-underline:after {
        border-bottom: 2px solid ${props => props.color} !important;
    }
    
    .MuiFormLabel-root.Mui-error {
        color: ${Colors.ERROR} !important;
    }
    
    .MuiInput-underline.Mui-error:before {
        border-bottom: 1px solid ${Colors.ERROR} !important;
    }
    
    .MuiInput-underline.Mui-error:after {
        border-bottom: 2px solid ${Colors.ERROR} !important;
    }
    
    .MuiFormHelperText-root.Mui-error {
        font-family: ${Fonts.MEDIUM} !important;
        font-size: 1em !important;
    }
    
    .MuiTypography-body1 {
        font-family: ${Fonts.MEDIUM} !important;
        font-size: 1em !important;
    }
    
    .MuiCheckbox-colorPrimary.Mui-checked {
        color: ${props => props.color} !important;
    }
    
    .MuiPaper-root {
        background-color: var(--primary-color) !important;
        color: ${Colors.WHITE} !important;
    }
    
    .pseudo-button {
      background-color: ${Colors.DARK_GRAY} !important;
      color: ${Colors.WHITE} !important;
      display: inline-block;
      padding: 15px 30px;
      margin-bottom: 20px;
      border-radius: 30px;
      cursor: pointer;
    }
    
    .pseudo-button:hover {
      background-color: ${Colors.BLACK};
    }
    
    .pseudo-button:active {
      background-color: ${Colors.WHITE};
      color: ${Colors.DARK_GRAY}
    }
`;


const UploadButtonGroup = styled.div`
  justify-text: center;
`

const UploadButtonText = styled(DefaultParagraph)`
  text-align: center;
  font-size: .9em;
`

const UploadButton = styled.div`
  background-color: ${
    props => props.uploaded 
        ? (props.dark ? Colors.DARK_GRAY : Colors.PRIMARY_AFI_SOFOM) 
        : (props.dark ? Colors.LIGHT_GRAY : Colors.SECONDARY_AFI_SOFOM_BUTTON)
  };
  padding: 1.25em .75em;
  display: grid;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  > svg {
    height: 84.5px;
    width: 84.5px;
  }

  @media only screen and (max-width: 767px){
    padding: 2.25em .5em;
  }

  .upload {
    display: ${props => props.uploaded ? "none" : "block"};
    .a,.b {
        stroke: ${
            props => !props.uploaded
                ? (props.dark ? Colors.DARK_GRAY : Colors.PRIMARY_AFI_SOFOM)
                : (props.dark ? Colors.LIGHT_GRAY : Colors.SECONDARY_AFI_SOFOM_BUTTON)
        } !important;
    }
  }

  .uploaded {
    display: ${props => props.uploaded ? "block" : "none"};
    
    .a,.b {
        stroke: ${
            props => !props.uploaded
                ? (props.dark ? Colors.DARK_GRAY : Colors.PRIMARY_AFI_SOFOM)
                : (props.dark ? Colors.LIGHT_GRAY : Colors.SECONDARY_AFI_SOFOM_BUTTON)
        } !important;
    }
  }
`
const GenerateUploadButton = ({id, name, onChange, accept, uploaded, isID, label, dark}) => (
    <UploadButtonGroup>
        <input
            onChange={onChange}
            accept={accept}
            style={{display: 'none'}}
            id={id}
            type="file"
            name={name}
        />

        <label htmlFor={id}>
            <UploadButton
                dark={dark}
                component="span"
                uploaded={uploaded ? "true" : ""}>
                <UploadSVG className="upload"/>

                {isID ? <IdentitySVG className="uploaded"/> : <EmptyFileSVG className="uploaded"/>}
            </UploadButton>
        </label>
        <UploadButtonText>
            {label}
        </UploadButtonText>
    </UploadButtonGroup>
)

/**
 * Class component of CustomInput
 * @author @leonard_lib
 * @date 02/07/2020
 */
class CustomInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMsg: Strings.FIELD_REQUIRED
        };
    }

    render() {
        const valueParams = this.props.defaultValue ? {defaultValue: this.props.defaultValue} : {value: this.props.value};
        const valueLabel = this.props.label ? {label: this.props.label} : {};
        return (
            <CustomInputWrapper style={this.props.style}
                                className={this.props.className}
                                color={this.props.color}>
                {
                    this.props.type !== "file" ? (
                            <TextField error={this.props.invalid && this.props.submitted}
                                       id={this.props.id}
                                       name={this.props.name}
                                       multiline={this.props.type === 'textarea'}
                                       rowsMax={this.props.rowsMax}
                                       rows={this.props.rowsMax}
                                       type={this.props.type}
                                       onKeyDown={this.props.onKeyDown}
                                       disabled={this.props.disabled}
                                       fullWidth={this.props.fullWidth}
                                       onChange={(e) => this.onChange(e)}
                                       helperText={
                                           this.props.invalid && this.props.submitted
                                               ? this.state.errorMsg
                                               : this.props.hint
                                       }
                                       placeholder={this.props.onlyPlaceholder ? this.props.placeholder : ''}
                                       InputProps={this.props.inputProps}
                                       {...valueParams}
                                       {...valueLabel}
                            />
                        ) :
                        (
                            <>
                                <input
                                    id={this.props.id}
                                    name={this.props.name}
                                    type={"file"}
                                    accept={this.props.accept}
                                    value={this.props.value}
                                    disabled={this.props.disabled}
                                    onChange={this.props.onChange}
                                    style={{display: "none"}}
                                />

                                {
                                    this.props.buttonType === "text" && (
                                        <label htmlFor={this.props.id}>
                                            <div className={"pseudo-button"}>Cambiar imagen</div>
                                        </label>
                                    )
                                }
                                {
                                    // TODO: Este es el componente a modificar para tu caso @DanielVerdín
                                    this.props.buttonType === "icon" && (
                                        <GenerateUploadButton
                                            id={this.props.id}
                                            name={this.props.name}
                                            onChange={this.props.onChange}
                                            accept={this.props.accept}
                                            dark={this.props.inputFileDark}
                                            uploaded={this.props.uploaded}
                                            isID={this.props.isID}
                                            label={this.props.label}
                                        />
                                    )
                                }

                            </>

                        )
                }


            </CustomInputWrapper>
        );
    }

    /**
     * Function to handle input change
     * @author @leonard_lib
     * @date 02/07/2020
     * @param event
     * @returns {*}
     */
    onChange(event) {
        const value = event.target.value || "";
        const valid = this.checkValid(value);
        return this.props.onChange(value, valid);
    };

    /**
     * Function to check input value when change
     * @author @leonard_lib
     * @date 02/07/2020
     * @param value
     * @returns {boolean}
     */
    checkValid(value) {
        let valid = true;
        let msg = null;

        if (this.props.required) {
            if (!(value != null && value !== '')) {
                valid = false;
                msg = Strings.FIELD_REQUIRED;
            }
        }

        if (this.props.maxLength != null) {
            if (value.length > this.props.maxLength) {
                valid = false;
                msg = Strings.FIELD_OVERLOAD_SIZE;
            }
        }

        if (this.props.minLength != null) {
            if (value.length < this.props.minLength) {
                valid = false;
                msg = Strings.FIELD_OVERLOAD_SIZE;
            }
        }

        if (this.props.sameAs != null) {
            if (value !== this.props.sameAs) {
                valid = false;
                msg = Strings.INVALID_VALUE;
            }
        }

        if (this.props.type === 'email') {
            if (!this.isEmail(value)) {
                valid = false;
                msg = Strings.INVALID_EMAIL;
            }
        }

        this.setState({
            errorMsg: msg
        });
        return valid;
    }

    /**
     * Function to check if value is an email
     * @author @leonard_lib
     * @date 02/07/2020
     * @param value
     * @returns {boolean}
     */
    isEmail(value) {
        const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        return regex.test(value);
    }
}

/**
 * Property types of CustomInput
 * @author @leonard_lib
 * @date 02/07/2020
 * @type Object
 */
CustomInput.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    rowsMax: PropTypes.number,
    invalid: PropTypes.bool,
    submitted: PropTypes.bool,
    style: PropTypes.object,
    hint: PropTypes.string,
    value: PropTypes.string,
    inputProps: PropTypes.object,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    disabled: PropTypes.bool,
    color: PropTypes.string,
    inputFileDark: PropTypes.bool,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    onlyPlaceholder: PropTypes.bool,
    fullWidth: PropTypes.bool,
    sameAs: PropTypes.string,
    onKeyDown: PropTypes.func,
    accept: PropTypes.string,
    uploaded: PropTypes.bool,
    isID: PropTypes.bool
};

/**
 * Default property values of CustomInput
 * @author @leonard_lib
 * @date 02/07/2020
 * @type Object
 */
CustomInput.defaultProps = {
    required: false,
    rowsMax: 0,
    invalid: false,
    submitted: false,
    style: {},
    inputProps: {},
    hint: '',
    id: null,
    name: null,
    minLength: null,
    maxLength: null,
    disabled: false,
    placeholder: '',
    className: '',
    sameAs: null,
    onlyPlaceholder: false,
    inputFileDark: false,
    fullWidth: true,
    onKeyDown: () => {
    }
};

export default CustomInput;
