import React from "react";
import styled from "styled-components";
import Colors from "../assets/colors";
import Strings from "../assets/strings";
import {DefaultText, PageTitle} from "./shared-components/text-styles";
import PropTypes from "prop-types";
import {formatCurrency} from "../data/services/utils";
import AfisofomClient from "../data/models/afisofom-client";

const CardWrapper = styled.div`
    border: solid 1px ${Colors.PRIMARY_AFI_SOFOM};
    padding: 30px;
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2em;
`;

const CardHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-row-gap: 1em;
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 1em;
    }
`;

const CardHeaderItem = styled.div`
    text-align: center;
`;

const CreditAfisofomCard = ({ client }) => {
    return (
        <CardWrapper>
            <CardHeader>
                <CardHeaderItem>
                    <PageTitle>{formatCurrency(client.afisofom_credit.amount)}</PageTitle>
                    <br/>
                    <DefaultText>{Strings.AFISOFOM_REQUEST_AMOUNT}</DefaultText>
                </CardHeaderItem>
                <CardHeaderItem>
                    <PageTitle transform="none">
                        {client.afisofom_credit.term} {Strings.MONTHS}
                    </PageTitle>
                    <br/>
                    <DefaultText>{Strings.TERM}</DefaultText>
                </CardHeaderItem>
                <CardHeaderItem>
                    <PageTitle transform="none">
                        {client.afisofom_credit.type}
                    </PageTitle>
                    <br/>
                    <DefaultText>{Strings.AFISOFOM_CREDIT_TYPE}</DefaultText>
                </CardHeaderItem>
                <CardHeaderItem>
                    <PageTitle>{client.afisofom_credit.interest}%</PageTitle>
                    <br/>
                    <DefaultText>{Strings.AFISOFOM_ANNUAL_INTEREST}</DefaultText>
                </CardHeaderItem>
            </CardHeader>
            <CardHeader>
                <CardHeaderItem>
                    <PageTitle>{formatCurrency(client.afisofom_credit.payments_data.remaining_amount)}</PageTitle>
                    <br/>
                    <DefaultText>{Strings.AFISOFOM_REMAINING_AMOUNT}</DefaultText>
                </CardHeaderItem>
                <CardHeaderItem>
                    <PageTitle transform="none">
                        {client.afisofom_credit.payments_data.pending_payments}
                    </PageTitle>
                    <br/>
                    <DefaultText>{Strings.AFISOFOM_PENDING_PAYMENTS}</DefaultText>
                </CardHeaderItem>
                <CardHeaderItem>
                    <PageTitle transform="none"
                               color={
                                   client.afisofom_credit.payments_data.on_time
                                       ? Colors.PRIMARY_DARK_AFI_SERVICIOS
                                       : Colors.ERROR
                               }>
                        {
                            client.afisofom_credit.payments_data.on_time
                                ? Strings.AFISOFOM_ON_TIME
                                : Strings.AFISOFOM_OVERTIME
                        }
                    </PageTitle>
                    <br/>
                    <DefaultText>{Strings.AFISOFOM_PAYMENTS_STATUS}</DefaultText>
                </CardHeaderItem>
                <CardHeaderItem>
                    <PageTitle transform="none">{client.afisofom_credit.advisor}</PageTitle>
                    <br/>
                    <DefaultText>{Strings.ADVISOR}</DefaultText>
                </CardHeaderItem>
            </CardHeader>
        </CardWrapper>
    );
};

CreditAfisofomCard.propTypes = {
    client: PropTypes.instanceOf(AfisofomClient).isRequired,
};

CreditAfisofomCard.defaultProps = {
};

export default CreditAfisofomCard;
