import {getAuthenticatedUser} from "../data/services/auth";

/**
 * Check if there is an authenticated user
 * @author @leonard_lib
 * @date 05/08/2020
 * @param to
 * @param from
 * @param next
 */
export const isAuthenticated = (to, from, next) => {
    const path = to.meta.redirect;
    const path_not_confirmed =  to.meta.path_not_confirmed
    const check = to.meta.check_for_auth;

    if (check) {
        const user = getAuthenticatedUser();
        if (user != null) {
            if(user.attributes && user.attributes.email_verified) {
                next();
            }
            else {
                next.redirect(path_not_confirmed)
            }
        } else {

            next.redirect(path);
        }
    } else {
        next();
    }
};

/**
 * Check if user is a guest
 * @author @leonard_lib
 * @date 05/08/2020
 * @param to
 * @param from
 * @param next
 */
export const isNotAuthenticated = (to, from, next) => {
    const path = to.meta.redirect;
    const path_not_confirmed =  to.meta.path_not_confirmed
    const check = to.meta.check_for_is_not_auth;

    if (check) {
        const user = getAuthenticatedUser();
        if (user) {
            if(user.attributes && user.attributes.email_verified) {
                next.redirect(path);
            }
            else {
                next.redirect(path_not_confirmed)
            }
        } else {
            next();
        }
    } else {
        next();
    }
};

export const isAlreadyConfirmed = (to, from, next) => {
    const path = to.meta.redirect;
    const path_confirmed =  to.meta.path_confirmed
    const check = to.meta.check_for_already_confirmed;

    if (check) {
        const user = getAuthenticatedUser();
        if (user != null) {
            if(user.attributes && user.attributes.email_verified) {
                next.redirect(path_confirmed);
            }
            else {
                next()
            }
        } else {
            next();
        }
    } else {
        next();
    }
};

