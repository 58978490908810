import React from "react";
import LayoutClients from "../../components/layout-clients";
import ServiciosRestorePasswordForm from "../../components/afiservicios/restore-password-form";
import {useChangeTitleFavicon} from "../../hooks/useChangeTitle";
import Strings from "../../assets/strings";

const AfiserviciosRestorePassword = ({ history, location }) => {
    useChangeTitleFavicon(Strings.TITLE_RESTORE_PASSWORD, "afiservicios");
    return (
        <LayoutClients site="servicios" history={history}>
            <div className="container">
                <ServiciosRestorePasswordForm
                    location={location}
                    onRestore={() =>
                        history.push('/afiservicios/iniciar-sesion')
                    }
                />
            </div>
        </LayoutClients>
    );
}

export default AfiserviciosRestorePassword;
