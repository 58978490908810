import Client from "./client";

class Admin {
    roles;

    constructor(json = {}) {
        this.roles = json.roles ? json.roles : [];
    }
}

class AdminClient extends Client {
    static ROLE_AFISERVICIOS_ADVISOR = 32;
    static ROLE_AFISOFOM_ADVISOR = 64;
    static ROLE_AFISERVICIOS_ADMIN = 128;
    static ROLE_AFISOFOM_ADMIN = 256;
    static ROLE_SUPER_ADMIN = 512;
    static DEFAULT_ROLE_FOR_GRAPHQL = 0;

    constructor(json = {}) {
        super(json);
        this.admin = json.admin ? new Admin(json.admin) : new Admin();
    }

    static fromMultiple(array) {
    let items = [];

    if (array && array.length > 0) {
        array.forEach(item => {
            items.push(new AdminClient(item));
        });
    }

    return items;
    }

    hasRole(role) {
        let hasIt = false;

        if (this.admin.roles.length > 0) {
            hasIt = this.admin.roles.includes(role);
        }

        return hasIt;
    }

    addRole(role) {
        if (this.admin.roles.length < 1) {
            this.admin.roles.push(AdminClient.DEFAULT_ROLE_FOR_GRAPHQL);
        }

        const has_it = this.admin.roles.includes(role);

        if (!has_it) {
            this.admin.roles.push(role);
        }
    }

    get isAdvisor() {
        return this.hasRole(AdminClient.ROLE_AFISOFOM_ADVISOR)
            || this.hasRole(AdminClient.ROLE_AFISERVICIOS_ADVISOR);
    }

    get isAfisofomAdminOrAdvisor() {
        return this.hasRole(AdminClient.ROLE_AFISOFOM_ADMIN)
            || this.hasRole(AdminClient.ROLE_AFISOFOM_ADVISOR)
            || this.hasRole(AdminClient.ROLE_SUPER_ADMIN);
    }

    get isAfiserviciosAdminOrAdvisor() {
        return this.hasRole(AdminClient.ROLE_AFISERVICIOS_ADMIN)
            || this.hasRole(AdminClient.ROLE_AFISERVICIOS_ADVISOR)
            || this.hasRole(AdminClient.ROLE_SUPER_ADMIN);
    }

    get isSuperAdmin() {
        return this.hasRole(AdminClient.ROLE_SUPER_ADMIN)
    }
}

export default AdminClient
