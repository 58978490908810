import {FaSortDown} from "react-icons/fa";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Strings from "../../assets/strings";
import React from "react";
import {getAuthenticatedUser, logout} from "../../data/services/auth";
import styled from "styled-components";
import Fonts from "../../assets/fonts";
import Colors from "../../assets/colors";
import PropTypes from "prop-types";

const LogoutLink = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .link {
        font-family: ${Fonts.BOLD};
        color: ${Colors.DARK_GRAY};
        font-size: 1em;
        cursor: pointer;
        text-decoration: underline;
    }
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        .link {
            font-size: 0.85em;
        }
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .link {
            font-size: 1em;
        }
    }
`;

const StyledMenu = styled(Menu) `
		.MuiPaper-root{
			// top: 60px !important;
			// align-self: right;
			transform-origin: 'center bottom';
		}
`;

const AdminNavbarMenu = ({ onLogoutUser }) => {
    const user = getAuthenticatedUser();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const logoutUser = () => {
        logout().then(() => {
            setAnchorEl(null);
            onLogoutUser();
        }).catch(err => {
            setAnchorEl(null);
            onLogoutUser();
        });
    };

    const wrapperStyle = {
        zIndex: 9999999999999
    };

    const itemStyle = {
        fontFamily: Fonts.MEDIUM,
        fontSize: '1em',
        color: Colors.DARK_GRAY
    };
    return (
        <LogoutLink>
            {
                user !== null &&
                <React.Fragment>
                    <span aria-controls="simple-menu"
                          aria-haspopup="true"
                          className="link"
                          onClick={event => setAnchorEl(event.currentTarget)}>
                        {user.attributes ? user.attributes.email : (user.email? user.email : user.user.username)}<FaSortDown style={{fontSize: '1em'}}/>
                    </span>
                    <StyledMenu
                    		getContentAnchorEl={null}
                    		anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    										transformOrigin={{ vertical: "top", horizontal: "center" }}
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        style={wrapperStyle}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}>
                        <MenuItem onClick={() => logoutUser()}
                                  style={itemStyle} >
                            {Strings.LOGOUT}
                        </MenuItem>
                    </StyledMenu>
                </React.Fragment>
            }
        </LogoutLink>
    );
};

AdminNavbarMenu.propTypes = {
    onLogoutUser: PropTypes.func.isRequired
};

export default AdminNavbarMenu;
