import {API, graphqlOperation} from "aws-amplify";
import {updateClientBasic} from "../../graphql/queries";
import Strings from "../../assets/strings";
import Client from "../models/client";

export const updateClientBasicData = (client) => {

    return new Promise((resolve, reject) => {
        const format_client = new Client(client);

        API.graphql(graphqlOperation(updateClientBasic, {
            client: format_client,
            update_email: true
        })).then(res => {
            if (res.data.updateClientBasic.success) {
                resolve(true);
            } else {
                console.log('UPDATE CLIENT BASIC DATA ERROR');
                reject(Strings.UPDATE_CLIENT_BASIC_DATA_ERROR);
            }
        }).catch(err => {
            console.log('UPDATE CLIENT BASIC DATA ERROR: ', err);
            reject(Strings.UPDATE_CLIENT_BASIC_DATA_ERROR);
        });
    });
};
