import {API, graphqlOperation} from "aws-amplify";
import Strings from "../../../assets/strings";
import {afiserviciosUpdateForm} from "../../../graphql/queries";
import {getAfiserviciosClient, createClient, getAfiserviciosClientByEmail} from "../../queries/afiservicios/client";
import AfiserviciosClient from "../../models/afiservicios-client";
import AfisofomClient from "../../models/afisofom-client";


export const registerAfiservicios = (client) => {
    client.afiservicios_form.is_requested = true;
    client.search_term = `${client.name} ${client.lastname} ${client.mother_lastname} ${client.phone} ${client.email} ${client.is_moral_person ? client.moral_person.business_name : ""}`;
    client.search_term = client.search_term.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")

    return new Promise((resolve, reject) => {
        API.graphql(graphqlOperation(createClient, {
            input: client
        })).then(user => {
            resolve(user);
        }).catch(err => {
            console.log('REGISTER AFISERVICIOS ERROR: ', err);
            reject(Strings.AFISERVICIOS_REGISTER_ERROR);
        });
    });
};

export const getAfiserviciosClientData = (id) => {
    return new Promise((resolve, reject) => {
        API.graphql(graphqlOperation(getAfiserviciosClient, {
            id: id
        })).then(res => {
            if (res.data.listClients.items.length > 0) {
                const client = new AfiserviciosClient(res.data.listClients.items[0]);
                resolve(client);
            } else {
                console.log('GET AFISERVICIOS DATA ERROR');
                reject(Strings.GET_AFISERVICIOS_DATA_ERROR);
            }
        }).catch(err => {
            console.log('GET AFISERVICIOS DATA ERROR: ', err);
            reject(Strings.GET_AFISERVICIOS_DATA_ERROR);
        });
    });
};

export const getAfiserviciosClientDataByEmail = (email) => {
    return new Promise((resolve, reject) => {
        API.graphql(graphqlOperation(getAfiserviciosClientByEmail, {
            email: email
        })).then(res => {
            if (res.data.listClients.items.length > 0) {
                let client;
                if(res.data.listClients.items[0].afisofom_credit) {
                    client = new AfisofomClient(res.data.listClients.items[0]);
                }
                else {
                    client = new AfiserviciosClient(res.data.listClients.items[0])
                }
                resolve(client);
            } else {
                console.log('GET AFISERVICIOS DATA ERROR');
                reject(Strings.GET_AFISERVICIOS_DATA_ERROR);
            }
        }).catch(err => {
            console.log('GET AFISERVICIOS DATA ERROR: ', err);
            reject(Strings.GET_AFISERVICIOS_DATA_ERROR);
        });
    });
};

export const updateAfiserviciosForm = (id, email, afiservicios_form) => {
    return new Promise((resolve, reject) => {
        API.graphql(graphqlOperation(afiserviciosUpdateForm, {
            id: id,
            email: email,
            afiservicios_form: afiservicios_form
        })).then(res => {
            if (res.data.afiserviciosUpdateForm.success) {
                resolve(true);
            } else {
                console.log('UPDATE AFISERVICIOS FORM ERROR');
                reject(Strings.UPDATE_AFISERVICIOS_FORM_ERROR);
            }
        }).catch(err => {
            console.log('UPDATE AFISERVICIOS FORM ERROR: ', err);
            reject(Strings.UPDATE_AFISERVICIOS_FORM_ERROR);
        });
    });
};
