import styled from "styled-components";

import Colors from "../../assets/colors";
import Fonts from "../../assets/fonts";

export const PopupHolder = styled.div `
  background-color: ${Colors.WHITE};
  position: fixed;
  width: ${props => props.width ? props.width : "75vw"};
  height: ${props => props.height ? props.height : "auto"};
  top: ${props => props.top ? props.top : "15vh" };
  left: ${props => props.left ? props.left : "7vw" };
  padding: ${props => props.padding ? props.padding : "3em" };
  z-index: 1000000001;

  @media only screen and (max-width: 500px) {
    left: 2vw;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    width: 80vw;
    height: 70vh;
    margin: 0 auto;
    overflow-y: scroll;
    padding: 30px;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 80vw;
    height: 70vh;
    margin: 0 auto;
    overflow-y: scroll;
    padding: 30px;
  }
`


export const BGDiv = styled.div`
  background-color: #212322;
  filter: opacity(80%);
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000000000;
`;

export const DivMargin = styled.div`
    margin-top: 30px;
`;

export const DivMarginBottom = styled.div`
    margin-bottom: 30px;
`;

export const TabsContainer = styled.div`
    width: 100%;
    display: flex;
    border-bottom: solid 1px ${Colors.LIGHT_GRAY};
`;

export const TabItem = styled.div`
    font-family: ${Fonts.REGULAR};
    font-size: 1.25em;
    color: ${props => props.site === 'sofom' ? Colors.PRIMARY_AFI_SOFOM : (props.site === 'admin' ? Colors.DARK_GRAY : Colors.PRIMARY_AFI_SERVICIOS)};
    padding: 10px 20px;
    transition: background-color 0.15s ease;
    background-color: ${props => props.selected ? Colors.LIGHT_GRAY : Colors.WHITE};
    cursor: pointer;
    
    :hover {
        background-color: ${Colors.LIGHT_GRAY};
    }
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        font-size: 0.9em;
        padding: 5px 10px;
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
       font-size: 1em;
    }
`;
