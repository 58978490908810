import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import Colors from "../assets/colors";

const CustomButtonWrapper = styled.div`
    text-align: ${props => props.align};
    
    .custom-indicator-1 {
        color: var(${props => props.indicatorColor}) !important;
    }
    
    .custom-button-1 {
        color: ${props => props.disabled ? props.disabledColor : props.color} !important;
        background-color: ${props => props.disabled ? props.disabledBackground : props.background} !important;
        padding: 10px 60px !important;
        border-radius: 30px !important;
        font-family: ${props => props.font} !important;
        font-size: 1em !important;
    }
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        .custom-button-1 {
            padding: 10px 30px !important;
            width: ${props => props.fullWidthMobile ? '100%' : 'auto'};
        }
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .custom-button-1 {
            padding: 10px 30px !important;
            width: ${props => props.fullWidthMobile ? '100%' : 'auto'};
        }
    }
`;

const CustomButton = ({
  align, color, background, font, label, onClick, isLoading,
  indicatorColor, containerStyle, fullWidth, fullWidthMobile, disabled, id
}) => (
    <CustomButtonWrapper style={containerStyle}
                         indicatorColor={indicatorColor}
                         color={color}
                         background={background}
                         fullWidthMobile={fullWidthMobile}
                         font={font}
                         disabled={disabled}
                         align={align}>
        <Button variant="contained"
                fullWidth={fullWidth}
                id={id}
                className="custom-button-1"
                onClick={onClick}
                disabled={isLoading || disabled}>
            {
                isLoading &&
                <CircularProgress size={24}
                                  className="custom-indicator-1" />
            }
            {
                !isLoading && label
            }
        </Button>
    </CustomButtonWrapper>
);

/**
 * Property types of CustomButton
 * @author @leonard_lib
 * @date 02/07/2020
 * @type Object
 */
CustomButton.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    indicatorColor: PropTypes.string,
    containerStyle: PropTypes.object,
    fullWidth: PropTypes.bool,
    fullWidthMobile: PropTypes.bool,
    align: PropTypes.oneOf([
        'center',
        'left',
        'right'
    ]),
    color: PropTypes.string.isRequired,
    disabledColor: PropTypes.string,
    background: PropTypes.string.isRequired,
    disabledBackground: PropTypes.string,
    font: PropTypes.string.isRequired
};

/**
 * Default property values for CustomButton
 * @author @leonard_lib
 * @date 02/07/2020
 * @type Object
 */
CustomButton.defaultProps = {
    isLoading: false,
    disabled: false,
    indicatorColor: '--four-color',
    containerStyle: {},
    fullWidth: true,
    fullWidthMobile: true,
    disabledColor: Colors.LIGHT_GRAY,
    disabledBackground: Colors.GRAY,
    align: 'left'
};

export default CustomButton;
